import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import Lightbox from "yet-another-react-lightbox";
// eslint-disable-next-line import/no-unresolved
import "yet-another-react-lightbox/styles.css";
import { Card, CardHeader, Stack, Alert } from "@mui/material";
import { ASSETS_PATH, CheckPerm } from "../../../utils/apis";
import useAuth from "../../../hooks/useAuth";
import { UploadMultiFile } from "../../upload";

ProductPhotos.propTypes = {
  profile: PropTypes.object,
  uid: PropTypes.string,
};
export default function ProductPhotos({ profile, uid }) {
  const hasPermission = CheckPerm("update_products");
  const { uploadProductPhotos, addProductPhotos } = useAuth();
  const [newPhotos, setPhotos] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleDropPhotos = useCallback(
    (acceptedFiles) => {
      const upload = async (files) => {
        try {
          // upload to server
          setIsUploading(true);
          const formData = new FormData();
          files.forEach((file) => {
            formData.append("product_photos[]", file);
          });
          const res = await uploadProductPhotos(formData);
          if (res && res.length > 0) {
            const photos = [
              ...newPhotos,
              ...res.map((file) => ({
                name: file,
                preview: `${ASSETS_PATH.products}${file}`,
                size: 0,
                isPrimary: false,
              })),
            ];
            await addProductPhotos(photos, uid);
            setPhotos(photos);
          }
          enqueueSnackbar(`${files.length > 1 ? "Files" : "File"} uploaded`, {
            variant: "success",
          });
        } catch (err) {
          enqueueSnackbar(err.message, { variant: "error" });
        }
        setIsUploading(false);
      };
      if (acceptedFiles && acceptedFiles.length > 0) {
        setError("");
        upload(acceptedFiles);
      }
    },
    [uploadProductPhotos, addProductPhotos, newPhotos, uid, enqueueSnackbar]
  );
  const handleMakePrimaryPhoto = (file) => {
    if (file && file.name) {
      const images = [
        ...newPhotos.map((item) => ({
          ...item,
          isPrimary: item.name === file.name,
        })),
      ];
      setPhotos(images);
      addProductPhotos(images, uid);
      enqueueSnackbar(`Photo marked as featured!`, {
        variant: "success",
      });
    }
  };
  const handleRemoveAllPhotos = () => {
    setPhotos([]);
    addProductPhotos([], uid);
    enqueueSnackbar(`Photo(s) removed!`, {
      variant: "success",
    });
  };
  const handleRemovePhotos = (file) => {
    if (file && file.name) {
      const images = [...newPhotos.filter((item) => item.name !== file.name)];
      addProductPhotos(images, uid);
      setPhotos(images);
      enqueueSnackbar(`Photo removed!`, {
        variant: "success",
      });
    }
  };
  const handleOpenLightBox = (index) => {
    if (index || index === 0) {
      setSelectedIndex(index);
      setOpen(true);
    }
  };
  useEffect(() => {
    try {
      const { featuredPhoto, photos } = profile;
      if (photos) {
        const array = JSON.parse(photos);
        setPhotos(
          array.map((photo) => ({
            name: photo,
            preview: `${ASSETS_PATH.products}${photo}`,
            size: 0,
            isPrimary: photo === featuredPhoto,
          }))
        );
      }
    } catch (err) {
      // console.log(err);
    }
  }, [profile]);
  return (
    <>
      <Stack direction="column" spacing={2}>
        <Card
          variant="outlined"
          sx={{
            border: "0.4px solid",
            borderColor: "background.border",
            boxShadow:
              "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
            borderRadius: "8px",
          }}
        >
          <CardHeader
            title="Product Photos"
            subheader="Upload photos for this product."
          />
          <Stack spacing={2} sx={{ p: 3 }}>
            {error && <Alert severity="error">{error}</Alert>}
            <UploadMultiFile
              files={newPhotos}
              onDrop={handleDropPhotos}
              accept={{
                "image/*": [".jpeg", ".jpg", ".png"],
              }}
              showPreview
              maxSize={2000000}
              title="Upload Product Photos"
              isUploading={isUploading}
              hasPermission={hasPermission}
              onMakePrimary={handleMakePrimaryPhoto}
              onRemoveAll={handleRemoveAllPhotos}
              onRemove={handleRemovePhotos}
              onOpenLightBox={handleOpenLightBox}
            />
          </Stack>
        </Card>
      </Stack>
      <Lightbox
        open={open}
        index={selectedIndex}
        close={() => setOpen(false)}
        slides={newPhotos.map((photo) => ({
          src: photo.preview,
          alt: photo.name,
        }))}
      />
    </>
  );
}
