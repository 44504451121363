import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { sentenceCase } from "change-case";
// material
import { Box, Grid, Card, Button, Typography, Stack } from "@mui/material";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { fNumber } from "../../../utils/formatNumber";
import { ShowError } from "../../../utils/loader";
import { CheckPerm, DAMAGED_STATUS } from "../../../utils/apis";
import Label from "../../Label";
// ----------------------------------------------------------------------

SingleCard.propTypes = {
  product: PropTypes.object,
};

function SingleCard({ product }) {
  const { did, company, quantity, reason, status } = product;
  const stat = DAMAGED_STATUS.filter((debt) => debt.value === status)[0];
  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        p: 3,
      }}
    >
      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
        <Typography variant="subtitle2" noWrap>
          Quantity: {fNumber(quantity)} from {company}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {reason}
          </Typography>
        </Box>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          sx={{ mt: 2 }}
          component={RouterLink}
          to={`${PATH_DASHBOARD.subpages.damaged}/${did}`}
        >
          View
        </Button>
      </Box>
      <Label variant="ghost" color={stat.color}>
        {sentenceCase(stat.label)}
      </Label>
    </Card>
  );
}

Damaged.propTypes = {
  products: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

export default function Damaged({ products, type }) {
  const hasPermission = CheckPerm("view_damaged_products");
  return (
    <Box sx={{ mt: 5 }}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          Damaged
        </Typography>
        {hasPermission && (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            component={RouterLink}
            to={PATH_DASHBOARD.business.damagedProducts}
          >
            View All
          </Button>
        )}
      </Stack>
      {products.length > 0 ? (
        <Grid container spacing={3}>
          {products.map((product, index) => (
            <Grid key={index} item xs={12} md={4}>
              <SingleCard product={product} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <ShowError
          message={
            new Error(
              `This ${type} does not have any damaged attached at the moment!`
            )
          }
        />
      )}
    </Box>
  );
}
