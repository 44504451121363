// material
import PropTypes from "prop-types";
import { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Link,
  TableHead,
  CardHeader,
  Typography,
  TableContainer,
} from "@mui/material";
// utils
import { fCurrency, fNumber } from "../../../utils/formatNumber";
import { fDate } from "../../../utils/formatTime";
import { PATH_DASHBOARD } from "../../../routes/paths";
//
import Scrollbar from "../../Scrollbar";

// ----------------------------------------------------------------------
RecentSales.propTypes = {
  sales: PropTypes.array,
};
export default function RecentSales({ sales }) {
  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        pb: 3,
      }}
    >
      <CardHeader title="Recent Sales" sx={{ mb: 3 }} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID / Number</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Products</TableCell>
                <TableCell>Quantities</TableCell>
                <TableCell>Prices</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sales.map((row) => {
                const { rid, total, products, customerName, date } = row;
                const product = JSON.parse(products);
                return (
                  <TableRow hover key={rid} tabIndex={-1} category="checkbox">
                    <TableCell component="th" scope="row" padding="none">
                      <Box
                        sx={{
                          py: 2,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Link
                          underline="none"
                          component={RouterLink}
                          to={`${PATH_DASHBOARD.subpages.sale}/${rid}`}
                        >
                          <Typography variant="subtitle2" noWrap>
                            {rid}
                          </Typography>
                        </Link>
                      </Box>
                    </TableCell>
                    <TableCell style={{ minWidth: 160 }}>
                      {customerName}
                    </TableCell>
                    <TableCell style={{ minWidth: 160 }}>
                      {product.map((obj, index) => (
                        <Fragment key={index}>
                          {obj.name}
                          <br />
                        </Fragment>
                      ))}
                    </TableCell>
                    <TableCell style={{ minWidth: 160 }}>
                      {product.map((obj, index) => (
                        <Fragment key={index}>
                          {fNumber(obj.quantity)}
                          <br />
                        </Fragment>
                      ))}
                    </TableCell>
                    <TableCell style={{ minWidth: 160 }}>
                      {product.map((obj, index) => (
                        <Fragment key={index}>
                          {fCurrency(obj.price)}
                          <br />
                        </Fragment>
                      ))}
                    </TableCell>
                    <TableCell>{fCurrency(total)}</TableCell>
                    <TableCell style={{ minWidth: 160 }}>
                      {fDate(date)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}
