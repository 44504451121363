import PropTypes from "prop-types";
import { paramCase } from "change-case";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import {
  Link,
  Card,
  Typography,
  CardHeader,
  Stack,
  DialogActions,
  Dialog,
  DialogContent,
  TextField,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import BadgeIcon from "@mui/icons-material/Badge";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CategoryIcon from "@mui/icons-material/Category";
import Label from "../../Label";
import { isNumeric, fNumber } from "../../../utils/formatNumber";
import { fDate } from "../../../utils/formatTime";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { DAMAGED_STATUS, CheckPerm } from "../../../utils/apis";
import useAuth from "../../../hooks/useAuth";

ChangeStatus.propTypes = {
  handleStatusUpdate: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  handleStatus: PropTypes.func,
  onClose: PropTypes.func,
  status: PropTypes.string,
};
function ChangeStatus({
  open,
  loading,
  handleStatusUpdate,
  handleStatus,
  status,
  onClose,
}) {
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      onClose={onClose}
      sx={{ textAlign: "center" }}
    >
      <DialogTitle>Change Status</DialogTitle>
      <DialogContent sx={{ width: { xs: "100%", md: 400 } }}>
        <DialogContentText sx={{ mb: 5 }}>
          Please set the new status below
        </DialogContentText>
        <TextField
          select
          sx={{ width: { xs: "100%", md: 300 } }}
          label="New Status"
          value={status}
          onChange={handleStatus}
        >
          {DAMAGED_STATUS.map((stat) => (
            <MenuItem key={stat.value} value={stat.value}>
              {stat.label}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <LoadingButton
            fullWidth
            loading={loading}
            variant="contained"
            onClick={handleStatusUpdate}
          >
            Update
          </LoadingButton>
          <Button fullWidth onClick={onClose} color="inherit">
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

DamagedAbout.propTypes = {
  profile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function DamagedAbout({ profile, uid, fetch }) {
  const hasPermission = CheckPerm("update_damaged_products");
  const {
    branch,
    branchName,
    adminName,
    admin,
    dateAdded,
    productName,
    product,
    quantity,
    reason,
    dateUpdated,
    status,
    companyName,
  } = profile;
  const statusText = DAMAGED_STATUS[parseInt(status, 10)];
  const { updateDamagedStatus } = useAuth();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newStatus, setNewStatus] = useState(String(status));
  const { enqueueSnackbar } = useSnackbar();

  const handleStatus = (event) => {
    setNewStatus(event.target.value);
  };
  const handleStatusUpdate = async () => {
    if (newStatus) {
      try {
        setLoading(true);
        await updateDamagedStatus(newStatus, uid);
        setOpen(false);
        setNewStatus("");
        enqueueSnackbar("Status has been updated!", { variant: "success" });
        fetch();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
      setLoading(false);
    } else {
      enqueueSnackbar(`Please select a new status to proceed!`, {
        variant: "error",
      });
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
      }}
    >
      <CardHeader title="Details" />
      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography variant="body2">{reason}</Typography>
        <Stack direction="row">
          <BadgeIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            <Label color={statusText.color}>{statusText.label}</Label>
            {hasPermission && (
              <span>
                &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                <Link
                  variant="subtitle2"
                  color="error.main"
                  component="button"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Change
                </Link>
              </span>
            )}
          </Typography>
        </Stack>
        {productName !== "" && (
          <Stack direction="row">
            <BusinessCenterIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              Product:&nbsp;&nbsp;{" "}
              <Link
                variant="subtitle2"
                color="text.primary"
                component={RouterLink}
                to={`${PATH_DASHBOARD.subpages.product}/${product}/${paramCase(
                  productName
                )}`}
              >
                {productName}
              </Link>
            </Typography>
          </Stack>
        )}
        {adminName !== "" && (
          <Stack direction="row">
            <BusinessCenterIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              Added By:&nbsp;&nbsp;{" "}
              <Link
                variant="subtitle2"
                color="text.primary"
                component={RouterLink}
                to={`${PATH_DASHBOARD.subpages.admin}/${admin}/${paramCase(
                  adminName
                )}`}
              >
                {adminName}
              </Link>
            </Typography>
          </Stack>
        )}
        {branchName !== "" && (
          <Stack direction="row">
            <BusinessCenterIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              Branch:&nbsp;&nbsp;{" "}
              {branch ? (
                <Link
                  variant="subtitle2"
                  color="text.primary"
                  component={RouterLink}
                  to={`${PATH_DASHBOARD.subpages.branch}/${branch}/${paramCase(
                    branchName
                  )}`}
                >
                  {branchName}
                </Link>
              ) : (
                branchName
              )}
            </Typography>
          </Stack>
        )}
        <Stack direction="row">
          <CategoryIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Quantity:&nbsp;&nbsp; {isNumeric(quantity) ? fNumber(quantity) : ""}
          </Typography>
        </Stack>
        <Stack direction="row">
          <CategoryIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Purchased from:&nbsp;&nbsp; {companyName}
          </Typography>
        </Stack>
        <Stack direction="row">
          <CalendarTodayIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Added on{" "}
            {dateAdded !== "0000-00-00" ? fDate(dateAdded) : "Not Specified"}
          </Typography>
        </Stack>
        <Stack direction="row">
          <CalendarTodayIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Last updated on{" "}
            {dateUpdated !== "0000-00-00"
              ? fDate(dateUpdated)
              : "Not Specified"}
          </Typography>
        </Stack>
      </Stack>
      <ChangeStatus
        open={open}
        loading={loading}
        handleStatus={handleStatus}
        status={newStatus}
        handleStatusUpdate={handleStatusUpdate}
        onClose={() => {
          setOpen(false);
        }}
      />
    </Card>
  );
}
