import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
// material
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Card,
  Stack,
  Container,
  CardHeader,
  CardContent,
  Button,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Dialog,
} from "@mui/material";
// utils
import { IMPORT_TYPES, CheckSub } from "../../../utils/apis";
import { AddIcon } from "../../../assets";
// components
import useAuth from "../../../hooks/useAuth";
import { UploadSingleFile } from "../../upload";
import { iff, ShowError, CircularIndeterminate } from "../../../utils/loader";
import ImportPreview from "./ImportPreview";
import ImportType from "./ImportType";
import SubscriptionError from "../../../pages/errors/SubscriptionError";

AddSuccess.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  link: PropTypes.string,
};
function AddSuccess({ open, handleClose, link }) {
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      onClose={handleClose}
      sx={{ textAlign: "center" }}
    >
      <DialogTitle>
        <IconButton
          sx={{ position: "absolute", right: 8, top: 8 }}
          onClick={handleClose}
          color="error"
        >
          <CancelIcon />
        </IconButton>{" "}
      </DialogTitle>
      <DialogContent sx={{ width: { xs: "100%", md: 400 } }}>
        <AddIcon sx={{ mx: "auto" }} />
        <DialogTitle>Data Import successful!</DialogTitle>
        <DialogContentText sx={{ mb: 5 }}>
          Your data has been imported!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Button
            fullWidth
            component={RouterLink}
            to={link}
            variant="contained"
          >
            View
          </Button>
          <Button
            fullWidth
            onClick={handleClose}
            color="inherit"
            sx={{ mr: 5 }}
          >
            Add Another
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
export default function Import() {
  const permission = CheckSub("21");
  const { uploadImport, user, setImport } = useAuth();
  const { branches } = user;
  const { enqueueSnackbar } = useSnackbar();
  const [headers, setHeaders] = useState([]);
  const [preview, setPreview] = useState([]);
  const [type, setType] = useState("");
  const [link, setLink] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isTypeDone, setTypeDone] = useState(false);
  const [file, setFile] = useState([]);
  const [branch, setBranch] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isRemoveFirst, setIsRemoveFirst] = useState(0);

  const handlePreviewChange = useCallback(() => {
    if (preview.length > 0) {
      const rowLength = preview[0].length;
      const heads = [];
      let i = 0;
      while (i < rowLength) {
        heads.push({ value: "", title: "" });
        i += 1;
      }
      setHeaders(heads);
    }
  }, [preview]);

  useEffect(() => {
    handlePreviewChange();
  }, [handlePreviewChange]);

  const goBackPreview = () => {
    setTypeDone(false);
    handlePreviewChange();
  };

  const goBackType = () => {
    setPreview([]);
  };

  const handleSubmit = async () => {
    try {
      if (file) {
        if (type) {
          // check if all headers empty
          const checkHeaders = headers.filter((head) => head.value !== "");
          if (checkHeaders.length > 0) {
            const typeData = IMPORT_TYPES.filter(
              (typ) => typ.value === type
            )[0];
            const isBranchGood = typeData.isBranchRequired
              ? branch !== ""
              : true;
            if (isBranchGood) {
              setLoading(true);
              setMessage("Uploading your import...");
              await setImport(
                file,
                type,
                JSON.stringify(headers),
                branch,
                isRemoveFirst
              );
              setLink(typeData.link);
              setOpenSuccess(true);
            } else {
              enqueueSnackbar("Please select a valid branch to proceed.", {
                variant: "error",
              });
            }
          } else {
            enqueueSnackbar("Please set atleast a column's data to proceed.", {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Please select a valid data type to proceed.", {
            variant: "error",
          });
          setType("");
        }
      } else {
        enqueueSnackbar("Please upload a valid file to proceed.", {
          variant: "error",
        });
        setPreview([]);
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
    setLoading(false);
    setMessage("");
  };

  const handleDropSingleFile = useCallback(
    (acceptedFiles) => {
      const upload = async (file) => {
        try {
          // upload to server
          const formData = new FormData();
          formData.append("import_file", file);
          setMessage("Uploading file... Please wait.");
          setLoading(true);
          const data = await uploadImport(formData);
          setFile(data.file);
          setPreview(data.contents);
          setLoading(false);
          setMessage("");
        } catch (err) {
          setError(err);
        }
        setLoading(false);
      };

      const file = acceptedFiles[0];
      if (file) {
        upload(file);
      } else {
        setError(
          new Error(
            "There was an error with the file you uploaded! Please confirm and try again."
          )
        );
      }
    },
    [uploadImport]
  );

  return permission === 1 ? (
    <Container maxWidth="lg">
      <Stack spacing={2}>
        <Card
          variant="outlined"
          sx={{
            border: "0.4px solid",
            borderColor: "background.border",
            boxShadow:
              "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
            borderRadius: "8px",
          }}
        >
          <CardHeader title="Import Store data" />
          {loading || error
            ? iff(
                loading,
                <CircularIndeterminate message={message} />,
                <ShowError
                  message={error}
                  tryAgain
                  onTryAgain={() => {
                    setError("");
                    setLoading(false);
                  }}
                />
              )
            : (preview.length > 0 &&
                ((isTypeDone && (
                  <ImportPreview
                    headers={headers}
                    setHeaders={setHeaders}
                    goBack={goBackPreview}
                    type={type}
                    data={preview}
                    isRemoveFirst={isRemoveFirst}
                    handleIsFirstChange={(data) => setIsRemoveFirst(data)}
                    branches={branches}
                    branch={branch}
                    handleBranchChange={(bid) => setBranch(bid)}
                    onDone={handleSubmit}
                  />
                )) || (
                  <ImportType
                    goBack={goBackType}
                    type={type}
                    onSelect={setType}
                    onDone={() => setTypeDone(true)}
                  />
                ))) || (
                <CardContent>
                  <UploadSingleFile
                    accept={[
                      ".csv",
                      "application/vnd.ms-excel",
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    ]}
                    title="Drop or select your Excel or CSV file here."
                    onDrop={handleDropSingleFile}
                  />
                </CardContent>
              )}
        </Card>
      </Stack>
      <AddSuccess
        open={openSuccess}
        handleClose={() => {
          setOpenSuccess(false);
        }}
        link={link}
      />
    </Container>
  ) : (
    <SubscriptionError />
  );
}
