import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
// @mui
import {
  Button,
  IconButton,
  Dialog,
  Stack,
  Typography,
  Card,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { CopyToBoard } from "../../../utils/apis";
import useAuth from "../../../hooks/useAuth";
import { fCurrency } from "../../../utils/formatNumber";

// ----------------------------------------------------------------------

ViewAccount.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  amount: PropTypes.number,
};
export default function ViewAccount({ open, onClose, amount }) {
  const { user } = useAuth();
  const { dedicated } = user;
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ py: 2.5, px: 3 }}
      >
        <Typography variant="h6"> Pay {fCurrency(amount)} To:</Typography>
        <IconButton
          sx={{ position: "absolute", right: 8, top: 8 }}
          onClick={onClose}
          color="error"
        >
          <CancelIcon />
        </IconButton>
      </Stack>
      <Card
        variant="outlined"
        sx={{
          border: "0.4px solid",
          borderColor: "background.border",
          boxShadow:
            "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
          borderRadius: "8px",
          p: 1.5,
        }}
      >
        <Stack
          spacing={{ xs: 2, md: 1 }}
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="subtitle2">{dedicated.bank}</Typography>
          <Button
            onClick={() =>
              CopyToBoard(
                dedicated.accountNumber,
                enqueueSnackbar,
                "Account number copied successfully"
              )
            }
          >
            <Stack alignItems="center">
              <Typography variant="h3">{dedicated.accountNumber}</Typography>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                CLICK TO COPY
              </Typography>
            </Stack>
          </Button>
          <Typography variant="subtitle2">{dedicated.accountName}</Typography>
          <Typography variant="caption" sx={{ pt: 4, textAlign: "center" }}>
            Once the customer pays into this account, please click on{" "}
            <strong>"Verify Payment"</strong> to confirm transaction.
          </Typography>
        </Stack>
      </Card>
    </Dialog>
  );
}
