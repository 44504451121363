import PropTypes from "prop-types";
// material
import { Grid, Stack } from "@mui/material";

import ProductPhotos from "./ProductPhotos";
import ProductDetails from "./ProductDetails";
import ProductVarieties from "./ProductVarieties";
import ProductSpecifications from "./ProductSpecifications";

Ecommerce.propTypes = {
  myProfile: PropTypes.object,
  pid: PropTypes.string,
  fetch: PropTypes.func,
};

export default function Ecommerce({ myProfile, pid, fetch }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={0} md={2} />
      <Grid item xs={12} md={8}>
        <Stack spacing={3}>
          <ProductPhotos profile={myProfile} uid={pid} />
          <ProductDetails myProfile={myProfile} uid={pid} fetch={fetch} />
          <ProductVarieties myProfile={myProfile} uid={pid} />
          <ProductSpecifications myProfile={myProfile} uid={pid} />
        </Stack>
      </Grid>
      <Grid item xs={0} md={2} />
    </Grid>
  );
}
