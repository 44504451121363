import PropTypes from "prop-types";
import { paramCase } from "change-case";
import { Link as RouterLink } from "react-router-dom";
// material
import { Box, Grid, Card, Button, Typography } from "@mui/material";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { ShowError } from "../../../utils/loader";

// ----------------------------------------------------------------------

SingleCard.propTypes = {
  admin: PropTypes.object,
};

function SingleCard({ admin }) {
  const { fname, lname, username, uid } = admin;
  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        p: 3,
      }}
    >
      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {fname} {lname}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {username}
          </Typography>
        </Box>
      </Box>
      <Button
        size="small"
        variant="outlined"
        color="primary"
        component={RouterLink}
        to={`${PATH_DASHBOARD.subpages.admin}/${uid}/${paramCase(fname)}`}
      >
        View
      </Button>
    </Card>
  );
}

RoleAdmins.propTypes = {
  admins: PropTypes.array.isRequired,
};

export default function RoleAdmins({ admins }) {
  return (
    <Box sx={{ mt: 5 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Administrators
      </Typography>
      {admins.length > 0 ? (
        <Grid container spacing={3}>
          {admins.map((admin) => (
            <Grid key={admin.uid} item xs={12} md={4}>
              <SingleCard admin={admin} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <ShowError
          message={
            new Error(
              "This role does not have any administrator at the moment!"
            )
          }
        />
      )}
    </Box>
  );
}
