import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// material
import { Box, Grid, Card, Button, Typography, Stack } from "@mui/material";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { fDate } from "../../../utils/formatTime";
import { fCurrency, fNumber } from "../../../utils/formatNumber";
import { ShowError } from "../../../utils/loader";
import { CheckPerm } from "../../../utils/apis";
// ----------------------------------------------------------------------

SingleCard.propTypes = {
  order: PropTypes.object,
};

function SingleCard({ order }) {
  const { products, subtotal, date, customerName, orderId } = order;
  const productsArray = JSON.parse(products);
  const numProducts = productsArray.length;
  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        p: 3,
      }}
    >
      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {fNumber(numProducts)} {(numProducts > 1 && "products") || "product"}{" "}
          to {customerName || "Walk-In Customer"}{" "}
          <span>&nbsp;&nbsp;&middot;&nbsp;&nbsp;</span> #{orderId}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            On {fDate(date)} <span>&nbsp;&nbsp;&middot;&nbsp;&nbsp;</span> at{" "}
            {fCurrency(subtotal)}
          </Typography>
        </Box>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          sx={{ mt: 2 }}
          component={RouterLink}
          to={`${PATH_DASHBOARD.ecommerce.orders}/${orderId}`}
        >
          View
        </Button>
      </Box>
    </Card>
  );
}

Orders.propTypes = {
  orders: PropTypes.array.isRequired,
  type: PropTypes.string,
};

export default function Orders({ orders, type }) {
  const hasPermission = CheckPerm("view_sales");
  return (
    <Box sx={{ mt: 5 }}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 3 }}
      >
        <Typography variant="h4">Orders</Typography>
        {hasPermission && (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            component={RouterLink}
            to={PATH_DASHBOARD.ecommerce.orders}
          >
            View All
          </Button>
        )}
      </Stack>
      {orders.length > 0 ? (
        <Grid container spacing={3}>
          {orders.map((order, index) => (
            <Grid key={index} item xs={12} md={4}>
              <SingleCard order={order} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <ShowError
          message={new Error(`This ${type} has not been used at the moment!`)}
        />
      )}
    </Box>
  );
}
