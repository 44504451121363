import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// components
import LoadingScreen from "../components/LoadingScreen";
import AuthGuard from "../guards/AuthGuard";
import { frontendPages } from "./paths";

// ----------------------------------------------------------------------
// eslint-disable-next-line
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = !frontendPages.includes(pathname);
  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: (
        <AuthGuard>
          {" "}
          <DashboardLayout />{" "}
        </AuthGuard>
      ),
      children: [
        { path: "", element: <Dashboard /> },
        { path: "onboarding", element: <Onboarding /> },
        {
          path: "settings",
          children: [
            { path: "", element: <Settings /> },
            { path: ":tab", element: <Settings /> },
          ],
        },
        { path: "profile", element: <Profile /> },
        {
          path: "administrators",
          children: [
            { path: "", element: <Admins /> },
            { path: "add", element: <AddAdmin /> },
          ],
        },
        {
          path: "roles",
          children: [
            { path: "", element: <Roles /> },
            { path: "add", element: <AddRole /> },
          ],
        },
        { path: "branches", element: <Branches /> },
        { path: "customers", element: <Customers /> },
        { path: "companies", element: <Companies /> },
        { path: "generate", element: <Generate /> },
        { path: "sales", element: <Sales /> },
        { path: "invoices", element: <Invoices /> },
        { path: "quotations", element: <Quotations /> },
        { path: "waybills", element: <Waybills /> },
        {
          path: "subscription",
          children: [
            { path: "", element: <Subscription /> },
            { path: "cancel", element: <Subscription /> },
            { path: "activate", element: <Subscription /> },
          ],
        },
        { path: "choose-plan", element: <ChoosePlan /> },
        { path: "billings", element: <Billings /> },
        { path: "bank-transfers", element: <BankTransfers /> },
        { path: "log", element: <Log /> },
        { path: "devices", element: <Devices /> },
        { path: "notifications", element: <Notifications /> },
        { path: "import-export", element: <ImportExport /> },
        {
          path: "send",
          children: [
            { path: "", element: <Send /> },
            { path: "topup", element: <Send /> },
            { path: ":rcp", element: <AddSend /> },
            { path: "add", element: <AddSend /> },
            { path: "add/:mid", element: <AddSend /> },
            { path: "add/resend/:mid", element: <AddSend /> },
          ],
        },
        {
          path: "support",
          children: [
            { path: "", element: <Support /> },
            { path: "send", element: <SendSupport /> },
          ],
        },
        {
          path: "sale",
          children: [
            { path: "", element: <Sale /> },
            { path: ":sid", element: <Sale /> },
            { path: "edit/:sid", element: <EditSale /> },
            { path: ":sid/:name", element: <Sale /> },
          ],
        },
        {
          path: "invoice",
          children: [
            { path: "", element: <Invoice /> },
            { path: ":sid", element: <Invoice /> },
            { path: "edit/:sid", element: <EditInvoice /> },
            { path: ":sid/:name", element: <Invoice /> },
          ],
        },
        {
          path: "quotation",
          children: [
            { path: "", element: <Quotation /> },
            { path: ":qid", element: <Quotation /> },
            { path: "edit/:qid", element: <EditQuotation /> },
            { path: ":qid/:name", element: <Quotation /> },
          ],
        },
        {
          path: "waybill",
          children: [
            { path: "", element: <Waybill /> },
            { path: ":wid", element: <Waybill /> },
            { path: "edit/:wid", element: <EditWaybill /> },
            { path: ":wid/:name", element: <Waybill /> },
          ],
        },
        {
          path: "returned-product",
          children: [
            { path: "", element: <ReturnedProduct /> },
            { path: ":rid", element: <ReturnedProduct /> },
          ],
        },
        {
          path: "damaged-product",
          children: [
            { path: "", element: <DamagedProduct /> },
            { path: ":did", element: <DamagedProduct /> },
            { path: ":did/:name", element: <DamagedProduct /> },
          ],
        },
        {
          path: "purchase",
          children: [
            { path: "", element: <Purchase /> },
            { path: ":pid", element: <Purchase /> },
          ],
        },
        {
          path: "discrepancy-report",
          children: [
            { path: "", element: <DiscrepancyReport /> },
            { path: ":rid", element: <DiscrepancyReport /> },
            { path: "edit/:rid", element: <EditDiscrepancyReport /> },
          ],
        },
        {
          path: "debtor",
          children: [
            { path: "", element: <Debtor /> },
            { path: ":uid", element: <Debtor /> },
          ],
        },
        {
          path: "role",
          children: [
            { path: "", element: <Role /> },
            { path: ":rid", element: <Role /> },
            { path: ":rid/:name", element: <Role /> },
          ],
        },
        {
          path: "view-staff",
          children: [
            { path: "", element: <ViewStaff /> },
            { path: ":sid", element: <ViewStaff /> },
            { path: ":sid/:name", element: <ViewStaff /> },
          ],
        },
        {
          path: "branch",
          children: [
            { path: "", element: <Branch /> },
            { path: ":bid", element: <Branch /> },
            { path: ":bid/:name", element: <Branch /> },
          ],
        },
        {
          path: "category",
          children: [
            { path: "", element: <Category /> },
            { path: ":cid", element: <Category /> },
            { path: ":cid/:name", element: <Category /> },
          ],
        },
        {
          path: "customer",
          children: [
            { path: "", element: <Customer /> },
            { path: ":cid", element: <Customer /> },
            { path: ":cid/:name", element: <Customer /> },
          ],
        },
        {
          path: "company",
          children: [
            { path: "", element: <Company /> },
            { path: ":cid", element: <Company /> },
            { path: ":cid/:name", element: <Company /> },
          ],
        },
        {
          path: "administrator",
          children: [
            { path: "", element: <Admin /> },
            { path: ":uid", element: <Admin /> },
            { path: ":uid/:name", element: <Admin /> },
          ],
        },
        { path: "generate", element: <AddSale /> },
        { path: "generate-invoice", element: <AddInvoice /> },
        { path: "add-quote", element: <AddQuote /> },
        { path: "add-waybill", element: <AddWaybill /> },
        {
          path: "products",
          children: [
            { path: "", element: <Products /> },
            { path: "add", element: <AddProduct /> },
          ],
        },
        {
          path: "product",
          children: [
            { path: "", element: <Product /> },
            { path: ":uid", element: <Product /> },
            { path: ":uid/:name", element: <Product /> },
            { path: ":uid/:name/:tab", element: <Product /> },
          ],
        },
        {
          path: "online-sale",
          children: [
            { path: "", element: <Online /> },
            { path: ":oid", element: <Online /> },
          ],
        },
        {
          path: "staff",
          children: [
            { path: "", element: <Staff /> },
            { path: "add", element: <AddStaff /> },
          ],
        },
        {
          path: "returned-products",
          children: [
            { path: "", element: <ReturnedProducts /> },
            { path: "add", element: <AddReturnedProduct /> },
          ],
        },
        {
          path: "debtors",
          children: [
            { path: "", element: <Debtors /> },
            { path: "add", element: <AddDebtor /> },
          ],
        },
        {
          path: "damaged-products",
          children: [
            { path: "", element: <DamagedProducts /> },
            { path: "add", element: <AddDamaged /> },
          ],
        },
        {
          path: "purchases",
          children: [
            { path: "", element: <Purchases /> },
            { path: "add", element: <AddPurchases /> },
          ],
        },
        {
          path: "discrepancy-reports",
          children: [
            { path: "", element: <DiscrepancyReports /> },
            { path: "add", element: <AddDiscrepancyReport /> },
          ],
        },
        {
          path: "online-sales",
          children: [
            { path: "", element: <OnlineSales /> },
            { path: "add", element: <AddOnline /> },
          ],
        },
        {
          path: "expenses",
          children: [
            { path: "", element: <Expenses /> },
            { path: "add", element: <AddExpense /> },
          ],
        },
        {
          path: "cards",
          children: [
            {
              path: "",
              element: <Cards />,
            },
            { path: "add", element: <AddCard /> },
            {
              path: "settings",
              element: <CardSettings />,
            },
            {
              path: ":type",
              element: <Cards />,
            },
          ],
        },
        {
          path: "orders",
          children: [
            {
              path: "",
              element: <Orders />,
            },
            {
              path: ":orderId",
              element: <OrderDetails />,
            },
          ],
        },
        { path: "store-analysis", element: <StoreAnalysis /> },
        { path: "payment-analysis", element: <PaymentAnalysis /> },
        { path: "report", element: <Report /> },
        { path: "logs", element: <Logs /> },
        { path: "categories", element: <Categories /> },
        { path: "reviews", element: <Reviews /> },
        {
          path: "discount-codes",
          children: [
            { path: "", element: <DiscountCodes /> },
            { path: ":cid", element: <DiscountCode /> },
            { path: ":cid/:name", element: <DiscountCode /> },
          ],
        },
        {
          path: "featured-products",
          children: [
            { path: "", element: <FeaturedProducts /> },
            { path: "add", element: <AddFeaturedProduct /> },
            { path: ":cid/:name", element: <DiscountCode /> },
          ],
        },
        {
          path: "special-offers",
          children: [
            { path: "", element: <SpecialOffers /> },
            { path: "add", element: <AddSpecialOffer /> },
            { path: ":cid/:name", element: <DiscountCode /> },
          ],
        },
      ],
    },
    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "500", element: <ErrorOccured /> },
        { path: "store-error", element: <StoreError /> },
        { path: "*", element: <Navigate to="/404" replace /> },
        {
          path: "pay",
          children: [
            {
              path: "",
              element: (
                <AuthGuard>
                  <Pay />
                </AuthGuard>
              ),
            },
            {
              path: ":sid",
              element: (
                <AuthGuard>
                  <Pay />
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: "dns-records",
          element: (
            <AuthGuard>
              <DNSRecords />
            </AuthGuard>
          ),
        },
        {
          path: "login",
          element: (
            <AuthGuard>
              <Login />
            </AuthGuard>
          ),
        },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "access-denied", element: <AccessDenied /> },
        {
          path: "verify",
          element: (
            <AuthGuard>
              <VerifyCode />
            </AuthGuard>
          ),
        },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// admin pages
const Settings = Loadable(lazy(() => import("../pages/dashboard/Settings")));
const Admins = Loadable(lazy(() => import("../pages/dashboard/Admins")));
const Dashboard = Loadable(lazy(() => import("../pages/dashboard/Dashboard")));
const Roles = Loadable(lazy(() => import("../pages/dashboard/Roles")));
const Branches = Loadable(lazy(() => import("../pages/dashboard/Branches")));
const Generate = Loadable(lazy(() => import("../pages/dashboard/Generate")));
const Sales = Loadable(lazy(() => import("../pages/dashboard/Sales")));
const Invoices = Loadable(lazy(() => import("../pages/dashboard/Invoices")));
const Quotations = Loadable(
  lazy(() => import("../pages/dashboard/Quotations"))
);
const Waybills = Loadable(lazy(() => import("../pages/dashboard/Waybills")));
const Products = Loadable(lazy(() => import("../pages/dashboard/Products")));
const ReturnedProducts = Loadable(
  lazy(() => import("../pages/dashboard/ReturnedProducts"))
);
const Logs = Loadable(lazy(() => import("../pages/dashboard/Admins")));
const Debtors = Loadable(lazy(() => import("../pages/dashboard/Debtors")));
const Categories = Loadable(
  lazy(() => import("../pages/dashboard/Categories"))
);
const DamagedProducts = Loadable(
  lazy(() => import("../pages/dashboard/DamagedProducts"))
);
const OnlineSales = Loadable(
  lazy(() => import("../pages/dashboard/OnlineSales"))
);
const DiscrepancyReports = Loadable(
  lazy(() => import("../pages/dashboard/DiscrepancyReports"))
);
const Purchases = Loadable(lazy(() => import("../pages/dashboard/Purchases")));
const DiscrepancyReport = Loadable(
  lazy(() => import("../pages/dashboard/DiscrepancyReport"))
);
const Expenses = Loadable(lazy(() => import("../pages/dashboard/Expenses")));
const Customers = Loadable(lazy(() => import("../pages/dashboard/Customers")));
const Companies = Loadable(lazy(() => import("../pages/dashboard/Companies")));
const Staff = Loadable(lazy(() => import("../pages/dashboard/Staff")));
const Profile = Loadable(lazy(() => import("../pages/dashboard/Profile")));
const Subscription = Loadable(
  lazy(() => import("../pages/dashboard/Subscription"))
);
const ChoosePlan = Loadable(
  lazy(() => import("../pages/dashboard/ChoosePlan"))
);
const Billings = Loadable(lazy(() => import("../pages/dashboard/Billings")));
const BankTransfers = Loadable(
  lazy(() => import("../pages/dashboard/BankTransfers"))
);
const Orders = Loadable(lazy(() => import("../pages/dashboard/Orders")));
const DiscountCodes = Loadable(
  lazy(() => import("../pages/dashboard/DiscountCodes"))
);
const FeaturedProducts = Loadable(
  lazy(() => import("../pages/dashboard/FeaturedProducts"))
);
const SpecialOffers = Loadable(
  lazy(() => import("../pages/dashboard/SpecialOffers"))
);
const Reviews = Loadable(lazy(() => import("../pages/dashboard/Reviews")));
// const Withdrawals = Loadable(lazy(() => import("../pages/dashboard/Withdrawals")));
const Send = Loadable(lazy(() => import("../pages/dashboard/Send")));
const Log = Loadable(lazy(() => import("../pages/dashboard/Log")));
const Notifications = Loadable(
  lazy(() => import("../pages/dashboard/Notifications"))
);
const Devices = Loadable(lazy(() => import("../pages/dashboard/Devices")));
const ImportExport = Loadable(
  lazy(() => import("../pages/dashboard/ImportExport"))
);
const Support = Loadable(lazy(() => import("../pages/dashboard/Support")));
const EditInvoice = Loadable(
  lazy(() => import("../pages/dashboard/EditInvoice"))
);
const EditSale = Loadable(lazy(() => import("../pages/dashboard/EditSale")));
const EditQuotation = Loadable(
  lazy(() => import("../pages/dashboard/EditQuotation"))
);
const EditWaybill = Loadable(
  lazy(() => import("../pages/dashboard/EditWaybill"))
);
const AddRole = Loadable(lazy(() => import("../pages/dashboard/AddRole")));
const AddAdmin = Loadable(lazy(() => import("../pages/dashboard/AddAdmin")));
const AddProduct = Loadable(
  lazy(() => import("../pages/dashboard/AddProduct"))
);
const AddStaff = Loadable(lazy(() => import("../pages/dashboard/AddStaff")));
const AddReturnedProduct = Loadable(
  lazy(() => import("../pages/dashboard/AddReturnedProduct"))
);
const AddFeaturedProduct = Loadable(
  lazy(() => import("../pages/dashboard/AddFeaturedProduct"))
);
const AddSpecialOffer = Loadable(
  lazy(() => import("../pages/dashboard/AddSpecialOffer"))
);
const AddSale = Loadable(lazy(() => import("../pages/dashboard/Generate")));
const AddInvoice = Loadable(
  lazy(() => import("../pages/dashboard/GenerateInvoice"))
);
const AddQuote = Loadable(lazy(() => import("../pages/dashboard/AddQuote")));
const AddWaybill = Loadable(
  lazy(() => import("../pages/dashboard/AddWaybill"))
);
const AddDebtor = Loadable(lazy(() => import("../pages/dashboard/AddDebtor")));
const AddDamaged = Loadable(
  lazy(() => import("../pages/dashboard/AddDamaged"))
);
const AddPurchases = Loadable(
  lazy(() => import("../pages/dashboard/AddPurchases"))
);
const AddDiscrepancyReport = Loadable(
  lazy(() => import("../pages/dashboard/AddDiscrepancyReport"))
);
const EditDiscrepancyReport = Loadable(
  lazy(() => import("../pages/dashboard/EditDiscrepancyReport"))
);
const AddOnline = Loadable(lazy(() => import("../pages/dashboard/AddOnline")));
const AddExpense = Loadable(
  lazy(() => import("../pages/dashboard/AddExpense"))
);
const AddSend = Loadable(lazy(() => import("../pages/dashboard/AddSend")));
const AddCard = Loadable(lazy(() => import("../pages/dashboard/AddCard")));
const SendSupport = Loadable(
  lazy(() => import("../pages/dashboard/SendSupport"))
);
const Pay = Loadable(lazy(() => import("../pages/dashboard/Pay")));
const DNSRecords = Loadable(
  lazy(() => import("../pages/dashboard/DNSRecords"))
);
const Cards = Loadable(lazy(() => import("../pages/dashboard/Cards")));
const CardSettings = Loadable(
  lazy(() => import("../pages/dashboard/CardSettings"))
);
const Onboarding = Loadable(
  lazy(() => import("../pages/dashboard/Onboarding"))
);

const Sale = Loadable(lazy(() => import("../pages/dashboard/Sale")));
const Invoice = Loadable(lazy(() => import("../pages/dashboard/Invoice")));
const Quotation = Loadable(lazy(() => import("../pages/dashboard/Quotation")));
const Waybill = Loadable(lazy(() => import("../pages/dashboard/Waybill")));
const Product = Loadable(lazy(() => import("../pages/dashboard/Product")));
const Admin = Loadable(lazy(() => import("../pages/dashboard/Admin")));
const Role = Loadable(lazy(() => import("../pages/dashboard/Role")));
const ViewStaff = Loadable(lazy(() => import("../pages/dashboard/ViewStaff")));
const Branch = Loadable(lazy(() => import("../pages/dashboard/Branch")));
const Category = Loadable(lazy(() => import("../pages/dashboard/Category")));
const DiscountCode = Loadable(
  lazy(() => import("../pages/dashboard/DiscountCode"))
);
const Customer = Loadable(lazy(() => import("../pages/dashboard/Customer")));
const Company = Loadable(lazy(() => import("../pages/dashboard/Company")));
const ReturnedProduct = Loadable(
  lazy(() => import("../pages/dashboard/ReturnedProduct"))
);
const Debtor = Loadable(lazy(() => import("../pages/dashboard/Debtor")));
const DamagedProduct = Loadable(
  lazy(() => import("../pages/dashboard/DamagedProduct"))
);
const Purchase = Loadable(lazy(() => import("../pages/dashboard/Purchase")));
const Online = Loadable(lazy(() => import("../pages/dashboard/OnlineSale")));
const StoreAnalysis = Loadable(
  lazy(() => import("../pages/dashboard/StoreAnalysis"))
);
const Report = Loadable(lazy(() => import("../pages/dashboard/Report")));
const PaymentAnalysis = Loadable(
  lazy(() => import("../pages/dashboard/PaymentAnalysis"))
);
const OrderDetails = Loadable(
  lazy(() => import("../pages/dashboard/OrderDetails"))
);

// error
const StoreError = Loadable(lazy(() => import("../pages/errors/StoreError")));
const ErrorOccured = Loadable(
  lazy(() => import("../pages/errors/ErrorOccured"))
);
const NotFound = Loadable(lazy(() => import("../pages/errors/NotFound")));
const AccessDenied = Loadable(
  lazy(() => import("../pages/errors/AccessDenied"))
);

// auth
const VerifyCode = Loadable(
  lazy(() => import("../pages/authentication/Verify"))
);
const ForgotPassword = Loadable(
  lazy(() => import("../pages/authentication/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("../pages/authentication/ResetPassword"))
);
const Login = Loadable(lazy(() => import("../pages/authentication/Login")));
