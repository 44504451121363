/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { merge } from "lodash";
import { Link as RouterLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import trendingUpFill from "@iconify/icons-eva/trending-up-fill";
import trendingDownFill from "@iconify/icons-eva/trending-down-fill";
// material
import { alpha, styled } from "@mui/material/styles";
import { Box, Typography, Stack, Link } from "@mui/material";
// utils
import { fNumber, fPercent } from "../../../utils/formatNumber";
//
import { BaseOptionChart } from "../../charts";

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",
  borderRadius: "0.5rem",
  padding: "3px",
  paddingTop: "1px",
  paddingBottom: "1px",
  alignItems: "center",
  justifyContent: "center",
  marginRight: theme.spacing(1),
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16),
}));

// ----------------------------------------------------------------------
ReportBox.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  total: PropTypes.string,
  percent: PropTypes.number,
  chartData: PropTypes.array,
  percentLabel: PropTypes.string,
  link: PropTypes.string,
};
export default function ReportBox({
  title,
  total,
  subtitle,
  percent,
  chartData,
  percentLabel,
  link,
}) {
  const chartOptions = merge(BaseOptionChart(), {
    colors: [percent < 0 ? "#9B2820" : "#1C7F2A"],
    chart: { animations: { enabled: true }, sparkline: { enabled: true } },
    stroke: { width: 2 },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.91,
        opacityTo: 0.1,
      },
    },
    tooltip: {
      x: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => "",
        },
      },
      marker: { show: false },
    },
  });
  const [Chart, setChart] = useState(null);
  useEffect(() => {
    const importChart = async () => {
      const chart = await import("react-apexcharts");
      setChart(chart);
    };
    importChart();
    return () => {
      setChart(null);
    };
  }, []);

  return (
    <Stack direction="row" sx={{ height: "100%", width: 1 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography
          variant="body2"
          sx={{ color: "text.primary", mt: 1, whiteSpace: "pre-line" }}
        >
          {title}
        </Typography>
        <Typography
          sx={{ mb: 0 }}
          variant={total && total.length > 7 ? "h5" : "h4"}
          gutterBottom
        >
          {total}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {subtitle} &nbsp;
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="start">
          {percent !== undefined && (
            <Stack direction="row" alignItems="center" flexWrap="wrap">
              <IconWrapperStyle
                sx={{
                  ...(percent < 0 && {
                    color: "error.main",
                    bgcolor: (theme) => alpha(theme.palette.error.main, 0.16),
                  }),
                  ...(percent === 0 && {
                    color: "secondary.main",
                    bgcolor: (theme) =>
                      alpha(theme.palette.secondary.main, 0.16),
                  }),
                }}
              >
                <Icon
                  width={10}
                  height={10}
                  icon={percent >= 0 ? trendingUpFill : trendingDownFill}
                />
                <Typography
                  variant="caption"
                  component="span"
                  sx={{ fontWeight: "bold", fontSize: 10 }}
                >
                  {percent > 0 && "+"}
                  {fPercent(percent)}
                </Typography>
              </IconWrapperStyle>
              {percentLabel && (
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ color: "text.secondary", fontSize: 12 }}
                >
                  {percentLabel}
                  {link && (
                    <Typography variant="body2" component="span">
                      &nbsp;&middot;&nbsp;
                    </Typography>
                  )}
                </Typography>
              )}
            </Stack>
          )}
          {link && (
            <Link
              component={RouterLink}
              to={link}
              underline="always"
              color={percent < 0 ? "#9B2820" : "#1C7F2A"}
              sx={{ fontSize: 12 }}
            >
              <Typography
                component="span"
                variant="body2"
                sx={{
                  color: percent < 0 ? "#9B2820" : "#1C7F2A",
                  fontSize: 12,
                }}
              >
                View
              </Typography>
            </Link>
          )}
        </Stack>
      </Box>
      {chartData && Boolean(Chart) && (
        <Chart.default
          type="area"
          series={[{ data: chartData }]}
          options={chartOptions}
          width={120}
          height={80}
        />
      )}
    </Stack>
  );
}
