import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { API_PATH } from "../../utils/apis";

const initialState = {
  isLoading: false,
  isWithdrawSuccess: false,
  error: false,
  withdrawals: [],
  total: 0,
  balance: 0,
  summary: {},
};

const slice = createSlice({
  name: "withdrawal",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET PRODUCTS
    getBalanceSuccess(state, action) {
      state.isLoading = false;
      state.balance = action.payload;
    },
    getWithdrawalsSuccess(state, action) {
      state.isLoading = false;
      state.withdrawals = action.payload.data;
      state.summary = action.payload.summary;
      state.total = parseInt(action.payload.total, 10);
    },
    closeWithdrawSuccess(state) {
      state.isLoading = false;
      state.isWithdrawSuccess = false;
    },
    getWithdrawSuccess(state) {
      state.isLoading = false;
      state.isWithdrawSuccess = true;
    },
    //  SORT & FILTER PRODUCTS
    sortByWallet(state, action) {
      state.sortBy = action.payload;
    },

    filterWallet(state, action) {
      state.filters.category = action.payload.category;
    },
  },
});

// Reducer
export default slice.reducer;
export const { filterWallet, sortByWallet } = slice.actions;

export function getBalance() {
  return async (dispatch) => {
    try {
      const response = await axios.post(API_PATH.withdrawals, { balance: "" });
      const { status, balance } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getBalanceSuccess(balance));
      } else {
        dispatch(
          slice.actions.hasError(
            new Error(
              "We cannot fetch balance at the moment! Please check back later."
            )
          )
        );
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
    }
  };
}
export function getWithdrawals(stat, page, perpage, order, sortBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(API_PATH.withdrawals, {
        search: "",
        status: stat,
        page,
        perpage,
        order,
        sortBy,
      });
      const { status, data, total, summary } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getWithdrawalsSuccess({ data, total, summary }));
      } else {
        dispatch(
          slice.actions.hasError(
            new Error(
              "We cannot fetch withdrawals at the moment! Please check back later."
            )
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function closeWithdrawal() {
  return async (dispatch) => {
    dispatch(slice.actions.closeWithdrawSuccess());
  };
}
export function addWithdrawal(amount, password) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(API_PATH.withdrawals, {
        withdraw: "",
        amount,
        password,
      });
      const { status } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getWithdrawSuccess());
      } else if (status === "insufficient_balance") {
        dispatch(
          slice.actions.hasError(
            new Error(
              "You do not have enough balance to procees this withdrawal!"
            )
          )
        );
      } else if (status === "balance_unvailable") {
        dispatch(
          slice.actions.hasError(
            new Error(
              "You cannot request withdrawal at the moment! Please try again later."
            )
          )
        );
      } else if (status === "invalid_password") {
        dispatch(
          slice.actions.hasError(
            new Error(
              "The password you entered is incorrect! Please confirm and try again later."
            )
          )
        );
      } else if (status === "invalid_account") {
        dispatch(
          slice.actions.hasError(
            new Error(
              "Please enter a valid account information to process withdrawals."
            )
          )
        );
      } else {
        dispatch(
          slice.actions.hasError(
            new Error(
              "We cannot process your withdrawal at the moment! Please try again later"
            )
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
