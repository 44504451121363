import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { osName, getUA, browserName } from "react-device-detect";
// utils
import axios from "../utils/axios";
import { API_PATH, CARD_ASSIGNED_STATUS, SALE_STATUS } from "../utils/apis";
import {
  fCurrency,
  fNumber,
  getSalesID,
  isNumeric,
} from "../utils/formatNumber";
import { fDateServer, fDateTime } from "../utils/formatTime";
import {
  setSession,
  setHeader,
  setCurrentBranch,
  getCurrentBranch,
  setOfflineData,
  getOfflineData,
  saveOfflineData,
  setSupportLinks,
} from "../utils/jwt";
import { PATH_DASHBOARD } from "../routes/paths";
import { setFetching } from "../redux/slices/offline";
import { useDispatch } from "../redux/store";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isDomainOkay: false,
  isVerifyEmail: false,
  isDemoDomain: false,
  isSwitchingBranch: false,
  isCheckingBarcode: false,
  isPopupOpen: false,
  user: null,
  currentBranch: {},
};

const handlers = {
  INITIALIZE: (state, action) => {
    const {
      isAuthenticated,
      isDomainOkay,
      isVerifyEmail,
      user,
      currentBranch,
      isDemoDomain,
    } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isVerifyEmail,
      isInitialized: true,
      isSwitchingBranch: false,
      isCheckingBarcode: false,
      isPopupOpen: false,
      isDomainOkay,
      isDemoDomain,
      user,
      currentBranch,
    };
  },
  SWITCH_BRANCH: (state, action) => {
    const { isSwitchingBranch } = action.payload;
    return {
      ...state,
      isSwitchingBranch,
    };
  },
  CHECK_BARCODE: (state, action) => {
    const { isCheckingBarcode } = action.payload;
    return {
      ...state,
      isCheckingBarcode,
    };
  },
  UPDATE: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user, currentBranch, isVerifyEmail } = action.payload;
    return {
      ...state,
      isVerifyEmail,
      isAuthenticated: true,
      isPopupOpen: false,
      user,
      currentBranch,
    };
  },
  LOGOUT: (state, action) => {
    const { user, isPopUp } = action.payload;
    return {
      ...state,
      isAuthenticated: false,
      isVerifyEmail: false,
      isSwitchingBranch: false,
      isCheckingBarcode: false,
      isPopupOpen: isPopUp,
      currentBranch: {},
      user,
    };
  },
  VERIFY: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isDomainOkay: true,
      isVerifyEmail: true,
      user,
    };
  },
  REGISTER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isVerifyEmail: false,
      isAuthenticated: true,
      isDomainOkay: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const rootDispatch = useDispatch();
  const initialize = async () => {
    setHeader();
    let isAuthenticated = false;
    let isDomainOkay = false;
    let isVerifyEmail = false;
    let user = {};
    let currentBranch = {};
    let isDemoDomain = false;
    try {
      const subdomain = window.location.host.split(".")[1]
        ? window.location.host.split(".")[0]
        : false;
      if (subdomain) {
        const response = await axios.post(API_PATH.profile, {
          get: getCurrentBranch(),
          domain: subdomain,
        });
        const { status, data, supportLinks } = response.data;
        user = data;
        if (status === "success" && user.shop.domain === subdomain) {
          isDomainOkay = true;
          isAuthenticated = Boolean(user.user.uid);
          const { branches } = user;
          if (getCurrentBranch() === "") {
            setCurrentBranch(branches[0].bid);
          }
          // eslint-disable-next-line
          currentBranch = branches.filter(
            (branch) => branch.bid === getCurrentBranch()
          )[0];
          if (isAuthenticated && parseInt(user.shop.isVerified, 10) === 1) {
            isVerifyEmail = true;
          }
          // save supportlinks
          setSupportLinks(supportLinks);

          getRemoteOfflineData();
        }
        if (
          status === "userid_expired" ||
          status === "userid_error" ||
          status === "invalid_profile"
        ) {
          isDomainOkay = true;
          isAuthenticated = false;
          setSession(null);
          setCurrentBranch(null);
        }

        // check if is demo domain
        isDemoDomain = user.shop.domain === "demo";
      }
    } catch (err) {
      // null
    }
    dispatch({
      type: "INITIALIZE",
      payload: {
        isAuthenticated,
        isDomainOkay,
        isVerifyEmail,
        user,
        currentBranch,
        isDemoDomain,
      },
    });
  };

  useEffect(() => {
    initialize();
  }, []);

  const logout = async (isPopUp) => {
    if (isPopUp) {
      const currentUser = state.user.user;
      localStorage.setItem("popupName", currentUser.fname);
      localStorage.setItem("popupEmail", currentUser.email);
    } else {
      localStorage.removeItem("popupName");
      localStorage.removeItem("popupEmail");
    }

    const { shop } = state.user;
    const user = { shop };
    logDevice(0);
    setSession(null);
    setCurrentBranch(null);
    dispatch({ type: "LOGOUT", payload: { user, isPopUp } });
  };
  const login = async (username, password) => {
    const { shop } = state.user;
    const response = await axios.post(API_PATH.login, {
      username,
      password,
      domain: shop.domain,
    });
    const { status, data, token, supportLinks } = response.data;
    if (status === "success") {
      setSession(token);
      if (getCurrentBranch() === "") {
        setCurrentBranch(data.branches[0].bid);
      }
      const currentBranch = data.branches.filter(
        (branch) => branch.bid === getCurrentBranch()
      )[0];
      const isVerifyEmail = parseInt(data.shop.isVerified, 10) === 1;
      logDevice(1);
      dispatch({
        type: "LOGIN",
        payload: { user: data, currentBranch, isVerifyEmail },
      });

      // save supportlinks
      setSupportLinks(supportLinks);

      getRemoteOfflineData();
    }
    if (status === "invalid_login") {
      throw new Error(
        "Your username or password is incorrect! Please confirm and try again."
      );
    }
    if (status === "invalid_shop") {
      dispatch({ type: "LOGIN", payload: { isDomainOkay: false } });
    }
    if (status === "suspended") {
      throw new Error(
        "Your account has been placed on hold! Please contact your shop administrator."
      );
    }
  };
  const loginToken = async (string) => {
    const domain = window.location.host.split(".")[1]
      ? window.location.host.split(".")[0]
      : "";
    const response = await axios.post(API_PATH.login, {
      token: string,
      domain,
    });
    const { status, data, token } = response.data;
    if (status === "success") {
      setSession(token);
      if (getCurrentBranch() === "") {
        setCurrentBranch(data.branches[0].bid);
      }
      const currentBranch = data.branches.filter(
        (branch) => branch.bid === getCurrentBranch()
      )[0];
      const isVerifyEmail = parseInt(data.shop.isVerified, 10) === 1;
      logDevice(1);
      dispatch({
        type: "LOGIN",
        payload: { user: data, currentBranch, isVerifyEmail },
      });
    }
  };
  const logDevice = async (status) => {
    axios.post(API_PATH.login, {
      name: browserName,
      os: osName,
      deviceId: getUA,
      status,
      type: 0,
    });
  };
  const switchBranch = async (branchId) => {
    const currentBranch = getCurrentBranch();
    if (currentBranch === branchId) {
      throw new Error("This branch is currently selected.");
    }
    const isSwitchingBranch = true;
    dispatch({ type: "SWITCH_BRANCH", payload: { isSwitchingBranch } });
    setCurrentBranch(branchId);
    initialize();
  };

  const resetPassword = async (code, password) => {
    const { shop } = state.user;
    const response = await axios.post(API_PATH.forgot, {
      code,
      password,
      domain: shop.domain,
    });
    const { status } = response.data;
    if (status !== "success") {
      if (status === "expired_code") {
        throw new Error(
          "Your password reset code has expired! Please request and try again."
        );
      } else if (status === "invalid_code") {
        throw new Error(
          "Your password reset code is invalid! Please confirm and try again."
        );
      } else if (status === "used_code") {
        throw new Error(
          "Your password reset code has already been used! Please confirm and try again."
        );
      } else {
        throw new Error(
          "Something went wrong while resetting your password! Please confirm and try again."
        );
      }
    }
  };
  const forgotPassword = async (email) => {
    const { shop } = state.user;
    const response = await axios.post(API_PATH.forgot, {
      email,
      domain: shop.domain,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "Your email address or username is incorrect! Please confirm and try again."
      );
    }
  };
  const saveToken = async (saveToken) => {
    await axios.post(API_PATH.notifications, { saveToken, device: getUA });
  };
  const updateMilestone = async (productId, salesId, value) => {
    const response = await axios.post(API_PATH.sales, {
      updateMilestone: salesId,
      productId,
      ...value,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error("An error has occurred! Please try again later.");
    }
  };
  const deleteMilestone = async (productId, salesId, milestoneId) => {
    const response = await axios.post(API_PATH.sales, {
      deleteMilestone: salesId,
      productId,
      milestoneId,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error("An error has occurred! Please try again later.");
    }
  };
  const completeMilestone = async (productId, salesId, milestoneId) => {
    const response = await axios.post(API_PATH.sales, {
      completeMilestone: salesId,
      productId,
      milestoneId,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error("An error has occurred! Please try again later.");
    }
  };
  const authenticateDevice = async (channelName, socketId) => {
    const response = await axios.post(API_PATH.devices, {
      authenticate: "1",
      channelName,
      socketId,
    });
    const { status, token } = response.data;
    if (status === "success") {
      return token;
    }
    return "";
  };
  const verifyAccount = async (code) => {
    const response = await axios.post(API_PATH.verify, { code });
    const { status, data } = response.data;
    if (status !== "success") {
      if (status === "expired_code") {
        throw new Error(
          "Your verification code has expired! Please request and try again."
        );
      } else if (status === "invalid_code") {
        throw new Error(
          "Your verification code is invalid! Please confirm and try again."
        );
      } else if (status === "used_code") {
        throw new Error(
          "Your verification code has already been used! Please confirm and try again."
        );
      } else {
        throw new Error(
          "Something went wrong while verifying your account! Please confirm and try again."
        );
      }
    } else {
      dispatch({ type: "VERIFY", payload: { user: data } });
    }
  };
  const sendVerifyCode = async () => {
    const response = await axios.post(API_PATH.verify, { request: "" });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error("Something went wrong! Please try again later.");
    }
  };
  const signup = async (fname, lname, email, phone, password, shop, domain) => {
    const response = await axios.post(API_PATH.signup, {
      fname,
      lname,
      email,
      phone,
      password,
      shop,
      domain,
    });
    const { status, data, token } = response.data;
    if (status === "success") {
      setSession(token, data.user.usertype);
      dispatch({ type: "REGISTER", payload: { user: data } });
    }
    if (status === "invalid_shop") {
      throw new Error(
        "Your shop name appears to have some error or has already been used! Please confirm and try again."
      );
    } else if (status === "invalid_domain") {
      throw new Error(
        "Your shop domain appears to have some error or has already been used! Please confirm and try again."
      );
    } else if (status === "email_taken") {
      throw new Error(
        "Your email address is already registered with us! Please login or reset your password if you have forgotten it."
      );
    } else {
      throw new Error(
        "An error seems to have occurred while signing you up! We apologize. Please try again later."
      );
    }
  };
  const checkEmail = async (email, type) => {
    const response = await axios.post(API_PATH.login, {
      check_email: email,
      type,
    });
    const { status, data } = response.data;
    if (status === "set_password") {
      dispatch({ type: "SETPASSWORD", payload: { user: data } });
    }
    return status;
  };
  const checkDomain = async (domain) => {
    const response = await axios.post(API_PATH.signup, {
      check_shop_domain: domain,
    });
    return response.data;
  };
  const setPassword = async (code, password, type) => {
    const { user } = state;
    const uid = type === "1" ? user.user.lid : user.user.uid;
    const response = await axios.post(API_PATH.profile, {
      set_password: uid,
      type,
      password,
      code,
    });
    const { status, data, token } = response.data;
    if (status === "success") {
      setSession(token, data.user.usertype);
      dispatch({ type: "LOGIN", payload: { user: data } });
    } else if (status === "code_invalid" || status === "code_expired") {
      throw new Error("The code you entered is invalid or has expired!");
    } else if (status === "code_used") {
      throw new Error(
        "The code you entered has already been used! Please request another and try again."
      );
    } else {
      throw new Error(
        "Looks like an error occurred while setting your password! Please try again later."
      );
    }
  };
  const sendPasswordVerify = async () => {
    const { user } = state;
    const { usertype } = user.user;
    const uid = parseInt(usertype, 10) === 1 ? user.user.lid : user.user.uid;
    const response = await axios.post(API_PATH.profile, {
      generate_set_password: uid,
      type: usertype,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "We cannot send your set password code at the moment! Please try again later."
      );
    }
  };
  const updateSettings = async (values) => {
    const response = await axios.post(API_PATH.settings, {
      update_store: "",
      ...values,
      type:
        values.type && Object.prototype.hasOwnProperty.call(values.type, "tid")
          ? values.type.tid
          : "",
      photoURL: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
    }
    if (status === "error") {
      throw new Error(
        "Something went wrong while updating the store settings. Please try again later."
      );
    }
    if (status === "invalid_shop") {
      logout();
    }
    if (status === "invalid_user") {
      logout();
    }
    if (status === "suspended") {
      throw new Error(
        "Your account has been placed on hold! Please contact your shop administrator."
      );
    }
  };
  const getRemoteOfflineData = async () => {
    rootDispatch(setFetching(true));
    const response = await axios.post(API_PATH.misc, {
      getOfflineData: getCurrentBranch(),
    });
    const { status, data } = response.data;
    rootDispatch(setFetching(false));
    if (status === "success") {
      setOfflineData(JSON.stringify(data));
    }
    return [];
  };
  const getRoles = async () => {
    const response = await axios.post(API_PATH.misc, { roles: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getCategories = async () => {
    const response = await axios.post(API_PATH.misc, { categories: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const addBranch = async (name, location) => {
    const response = await axios.post(API_PATH.branches, {
      name,
      location,
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
      return data;
    }
    if (status === "name_exists") {
      throw new Error("A branch already exists with the name specified!");
    }
    if (status === "limit_exceeded") {
      throw new Error(
        `You cannot add more than ${data} branches at the moment! Please upgrade your plan to add more.`
      );
    }
    throw new Error(
      "Something went wrong while attempting to add your branch! Please try again later."
    );
  };
  const addCategory = async (name, description, attributes) => {
    const response = await axios.post(API_PATH.categories, {
      name,
      description,
      attributes: JSON.stringify(attributes),
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "name_exists") {
      throw new Error("A category already exists with the name specified!");
    }
    throw new Error(
      "Something went wrong while attempting to add your category! Please try again later."
    );
  };
  const addDiscountCode = async (values) => {
    const response = await axios.post(API_PATH.discountCodes, {
      ...values,
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "code_exists") {
      throw new Error(
        "The discount code has already been used! Please use another to proceed."
      );
    }
    throw new Error(
      "Something went wrong while attempting to add your discount code! Please try again later."
    );
  };
  const addAdmin = async (values) => {
    const response = await axios.post(API_PATH.admins, {
      ...values,
      role: values.role.rid,
      branch: values.branch.bid,
      addStaff: values.addStaff === 1 ? "yes" : "no",
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "limit_exceeded") {
      throw new Error(
        `You cannot add more than ${data} administrators at the moment! Please upgrade your plan to add more.`
      );
    }
    if (status === "name_exist") {
      throw new Error("An admin already exists with the name specified!");
    }
    if (status === "username_exists") {
      throw new Error("An admin already exists with the username specified!");
    }
    throw new Error(
      "Something went wrong while attempting to add your admin! Please try again later."
    );
  };
  const getPermissions = async () => {
    const response = await axios.post(API_PATH.misc, { permissions: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getJobs = async () => {
    const response = await axios.post(API_PATH.misc, { jobs: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getBanks = async () => {
    const response = await axios.post(API_PATH.misc, { banks: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getAccountName = async (bank, number) => {
    const response = await axios.post(API_PATH.misc, {
      verify_account: "",
      bank,
      number,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error("The account entered is invalid! Please confirm.");
  };
  const getTypes = async () => {
    const response = await axios.post(API_PATH.misc, { types: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getBranches = async (isOnline) => {
    if (isOnline) {
      const response = await axios.post(API_PATH.misc, { branches: "" });
      const { status, data } = response.data;
      if (status === "success") {
        return data;
      }
    }
    return getOfflineData("branches");
  };
  const getProducts = async (
    products,
    branch,
    isEcommerce = false,
    isOnline = true
  ) => {
    if (isOnline) {
      const response = await axios.post(API_PATH.misc, {
        products,
        branch,
        isEcommerce,
      });
      const { status, data } = response.data;
      if (status === "success") {
        return data;
      }
    }
    try {
      const data = getOfflineData("products");
      const filtered = data.filter(
        (product) =>
          product.name.toLowerCase().includes(products.toLowerCase()) ||
          product.number.toLowerCase().includes(products.toLowerCase())
      );
      return filtered.slice(0, 10);
    } catch (err) {
      // console.log(err);
    }
    return [];
  };
  const getDisputeProducts = async (products, branch, isAll) => {
    const response = await axios.post(API_PATH.discrepancy_reports, {
      products,
      branch,
      isAll: isAll ? "1" : "0",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getAdmins = async (admins, branch) => {
    const response = await axios.post(API_PATH.misc, { admins, branch });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getStaff = async (staff, branch, isOnline) => {
    if (isOnline) {
      const response = await axios.post(API_PATH.misc, { staff, branch });
      const { status, data } = response.data;
      if (status === "success") {
        return data;
      }
    }
    return getOfflineData("staff");
  };
  const getSales = async (sales, branch) => {
    const response = await axios.post(API_PATH.misc, { sales, branch });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getCustomers = async (customers, branch, isOnline) => {
    if (isOnline) {
      const response = await axios.post(API_PATH.misc, { customers, branch });
      const { status, data } = response.data;
      if (status === "success") {
        return data;
      }
    }
    try {
      const data = getOfflineData("customers");
      const filtered = data.filter(
        (customer) =>
          customer.name.toLowerCase().includes(customers.toLowerCase()) ||
          customer.phone.toLowerCase().includes(customers.toLowerCase())
      );
      return filtered.slice(0, 10);
    } catch (err) {
      // console.log(err);
    }
    return [];
  };
  const getCompanies = async (companies) => {
    const response = await axios.post(API_PATH.misc, { companies });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getCompany = async (company) => {
    if (!company) {
      throw new Error(
        "Invalid company's ID supplied! Please go back to the companies page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.companies, { company });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid company's ID supplied! Please go back to the companies page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching company's data! Please try again later."
    );
  };
  const addCustomer = async (values, branch, isOnline = true) => {
    if (isOnline) {
      const response = await axios.post(API_PATH.customers, {
        ...values,
        branch: branch || getCurrentBranch(),
        add: "",
      });
      const { status, data } = response.data;
      if (status === "success") {
        return data;
      }
      if (status === "name_exists") {
        throw new Error(
          "A customer already exists with the name specified! Please select them to proceed."
        );
      }
      throw new Error(
        "Something went wrong while attempting to add customer! Please try again later."
      );
    }
    // offline data
    saveOfflineData(
      {
        ...values,
        branch: branch || getCurrentBranch(),
        add: "",
      },
      "customers"
    );
    return values.phone || values.name || "";
  };
  const addCompany = async (values) => {
    const response = await axios.post(API_PATH.companies, {
      ...values,
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "name_exists") {
      throw new Error(
        "A company already exists with the name specified! Please select them to proceed."
      );
    }
    throw new Error(
      "Something went wrong while attempting to add company! Please try again later."
    );
  };
  const addRole = async (values) => {
    const response = await axios.post(API_PATH.roles, {
      ...values,
      permissions: values.permissions.join(","),
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "name_exist") {
      throw new Error("An admin already exists with the name specified!");
    }
    if (status === "username_exist") {
      throw new Error("An admin already exists with the username specified!");
    }
    throw new Error(
      "Something went wrong while attempting to add your admin! Please try again later."
    );
  };
  const addProduct = async (values) => {
    const response = await axios.post(API_PATH.products, {
      ...values,
      category:
        values.category &&
        Object.prototype.hasOwnProperty.call(values.category, "cid")
          ? values.category.cid
          : "",
      branch: values.branch.bid,
      attributes: JSON.stringify(values.attributes),
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "name_exists") {
      throw new Error("A product already exists with the name specified!");
    }
    if (status === "number_exist") {
      throw new Error("A product with the ID / number already exists!");
    }

    throw new Error(
      "Something went wrong while attempting to add your product! Please try again later."
    );
  };
  const addStaff = async (values) => {
    const response = await axios.post(API_PATH.staff, {
      ...values,
      branch: values.branch.bid,
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "name_exists") {
      throw new Error("A staff already exists with the name specified!");
    }
    if (status === "limit_exceeded") {
      throw new Error(
        `You cannot add more than ${data} staff at the moment! Please upgrade your plan to add more.`
      );
    }
    if (status === "number_exist") {
      throw new Error("A staff with the phone number already exists!");
    }

    throw new Error(
      "Something went wrong while attempting to add the staff! Please try again later."
    );
  };
  const checkScan = async (barcode, getReturn, isOnline) => {
    if (isOnline) {
      const response = await axios.post(API_PATH.scan, {
        barcode,
      });
      const { status, data, message } = response.data;
      if (status === "success") {
        if (getReturn) {
          return data;
        }
        const titleMap = {
          sale: "Sale's Details",
          gift_card: "Gift Card Details",
          loyalty_card: "Loyalty Card Details",
          product: "Product's Details",
        };
        const { type, result } = data;
        const title = type ? titleMap[type] : "";

        let goTo = "";
        let body = "";
        if (type === "product") {
          goTo = `${PATH_DASHBOARD.subpages.product}/${result.pid}`;
        }
        if (type === "sale") {
          goTo = `${PATH_DASHBOARD.subpages.sale}/${result.rid}`;
        }
        if (type === "order") {
          goTo = `${PATH_DASHBOARD.ecommerce.orders}/${result.oid}`;
        }
        if (type === "invoice") {
          goTo = `${PATH_DASHBOARD.subpages.invoice}/${result.rid}`;
        }
        if (type === "quotation") {
          goTo = `${PATH_DASHBOARD.subpages.quotation}/${result.rid}`;
        }
        if (type === "waybill") {
          goTo = `${PATH_DASHBOARD.subpages.waybill}/${result.wid}`;
        }
        if (type === "gift_card") {
          body += `ID: ${result.cid}\n\n`;
          body += `Customer Name: ${result.customerName}\n\n`;
          body += `Amount: ${fCurrency(result.amount)}\n\n`;
          body += `Status: ${
            CARD_ASSIGNED_STATUS[parseInt(result.status, 10)].label
          }\n\n`;
        }
        if (type === "loyalty_card") {
          body += `ID: ${result.cid}\n\n`;
          body += `Customer Name: ${result.customerName}\n\n`;
          body += `Store Credit: ${fNumber(result.amount)}\n\n`;
          body += `Status: ${
            CARD_ASSIGNED_STATUS[parseInt(result.status, 10)].label
          }\n\n`;
          body += `Last Used: ${fDateTime(result.lastUsed)}\n`;
        }
        return { body, goTo, title };
      }
      throw new Error(
        message ||
          "Something went wrong while processing the barcode! Please try again later"
      );
    }
    try {
      const data = {};
      const products = getOfflineData("products");
      if (barcode.length === 14 && isNumeric(barcode)) {
        const cardType = barcode.substr(6, 2);
        const cId = barcode.substr(8, 6);
        if (cardType === "07") {
          // fetch product details
          data.type = "product";
          const filter = products.filter((product) => product.pid === cId);
          data.result = filter.length > 0 ? filter[0] : {};
        }
      } else {
        // fetch product details
        data.type = "product";
        const filter = products.filter(
          (product) =>
            product.number.toLowerCase() === barcode.toLowerCase() ||
            product.number.toLowerCase() === `0${barcode.toLowerCase()}`
        );
        data.result = filter.length > 0 ? filter[0] : {};
      }
      if (data && data.result && Object.keys(data.result).length > 0) {
        return data;
      }
      throw new Error(
        "The barcode you scanned is invalid or does not belong to a product in this branch! Please confirm and try again."
      );
    } catch (err) {
      throw new Error(
        (err && err.message) ||
          "Something went wrong while processing the barcode! Please try again later"
      );
    }
  };
  const searchBarcode = async (search) => {
    const response = await axios.post(API_PATH.scan, {
      search,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "We could not find the product attached to the barcode you scanned! If you are sure it exists, please try again."
    );
  };
  const generate = async (
    branch,
    customer,
    stat,
    date,
    due,
    products,
    discount,
    tax,
    payments,
    change,
    amount,
    total,
    discountPercent,
    tip,
    saleRep,
    priceChange,
    terms,
    isOnline = true
  ) => {
    if (isOnline) {
      const response = await axios.post(API_PATH.sales, {
        branch,
        customer,
        status: stat,
        date,
        due,
        products,
        discount,
        tax,
        payments,
        change,
        amount,
        terms,
        total,
        priceChange,
        discountPercent,
        tip,
        saleRep: (saleRep && saleRep.uid) || "",
        add: "",
      });
      const { status, data } = response.data;
      if (status === "success") {
        return data;
      }
      if (status === "branch_not_found") {
        throw new Error(
          "The branch specified does not exist! Please refresh this page and try again."
        );
      }
      if (status === "customer_not_found") {
        throw new Error(
          "The customer specified does not exist! Please refresh this page and try again."
        );
      }

      throw new Error(
        "Something went wrong while attempting to generate invoice! Please try again later."
      );
    }

    try {
      const customers = getOfflineData("customers");
      const customerData = customer
        ? customers.filter(
            (cust) =>
              cust.cid === customer ||
              cust.name.toLowerCase() === customer.toLowerCase() ||
              cust.phone.toLowerCase() === customer.toLowerCase()
          )
        : [];
      const customerName =
        customerData.length > 0
          ? customerData[0]
          : { name: "Walk In Customer" };
      const currentUser = state.user.user;
      const cashierName = `${currentUser.fname} ${currentUser.lname}`;
      const branches = getOfflineData("branches");
      const branchData = branches.filter((bra) => bra.bid === branch);
      const branchName = branchData.length > 0 ? branchData[0].name : "";
      const branchAddress = branchData.length > 0 ? branchData[0].location : "";
      const dateAdded = fDateServer();
      const status = parseInt(
        SALE_STATUS.filter((st) => st.label.toLocaleLowerCase() === stat)[0]
          .value || "0",
        10
      );
      const saleId = await getSalesID();
      saveOfflineData(
        {
          branch,
          customer,
          customerName: (customerName && customerName.name) || "",
          status: stat,
          cashier: currentUser.uid || "",
          date,
          due,
          products,
          discount,
          tax,
          payments,
          change,
          amount,
          terms,
          total,
          priceChange,
          discountPercent,
          tip,
          saleRep: (saleRep && saleRep.uid) || "",
          rid: saleId,
        },
        "sales"
      );
      return {
        discountPercent,
        priceChange,
        due,
        terms,
        date,
        rid: saleId,
        customer: customerName,
        products,
        total,
        discount,
        tax,
        cashier: currentUser.uid,
        cashierName,
        payment: payments,
        status,
        paid: amount,
        balance: change,
        credits: 0,
        tip,
        saleRep: (saleRep && saleRep.uid) || "",
        saleRepName: (saleRep && saleRep.name) || "",
        dateAdded,
        branchName,
        branchAddress,
        branch,
      };
    } catch (err) {
      return {};
    }
  };
  const uploadSales = async (sales, customers) => {
    const response = await axios.post(API_PATH.sales, {
      sales,
      customers,
      upload: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while uploading the sales! Please check your internet connection and retry."
    );
  };
  const editSale = async (
    branch,
    customer,
    stat,
    date,
    due,
    products,
    discount,
    tax,
    payments,
    change,
    amount,
    total,
    priceChange,
    discountPercent,
    tip,
    saleRep,
    terms,
    rid
  ) => {
    const response = await axios.post(API_PATH.sales, {
      branch,
      customer,
      status: stat,
      date,
      due,
      products,
      discount,
      tax,
      payments,
      change,
      amount,
      total,
      priceChange,
      discountPercent,
      terms,
      tip,
      saleRep: (saleRep && saleRep.uid) || "",
      edit: rid,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "branch_not_found") {
      throw new Error(
        "The branch specified does not exist! Please refresh this page and try again."
      );
    }
    if (status === "customer_not_found") {
      throw new Error(
        "The customer specified does not exist! Please refresh this page and try again."
      );
    }

    throw new Error(
      "Something went wrong while attempting to save invoice! Please try again later."
    );
  };
  const generateInvoice = async (
    branch,
    customer,
    stat,
    date,
    due,
    products,
    discount,
    tax,
    payments,
    change,
    amount,
    total,
    information,
    autoReminder,
    terms,
    discountPercent
  ) => {
    const response = await axios.post(API_PATH.invoices, {
      branch,
      customer,
      status: stat,
      date,
      due,
      products,
      discount,
      tax,
      payments,
      change,
      amount,
      total,
      information,
      autoReminder,
      discountPercent,
      terms,
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "branch_not_found") {
      throw new Error(
        "The branch specified does not exist! Please refresh this page and try again."
      );
    }
    if (status === "customer_not_found") {
      throw new Error(
        "The customer specified does not exist! Please refresh this page and try again."
      );
    }

    throw new Error(
      "Something went wrong while attempting to generate invoice! Please try again later."
    );
  };
  const editInvoice = async (
    branch,
    customer,
    stat,
    date,
    due,
    products,
    discount,
    tax,
    payments,
    change,
    amount,
    total,
    information,
    autoReminder,
    discountPercent,
    terms,
    rid
  ) => {
    const response = await axios.post(API_PATH.invoices, {
      branch,
      customer,
      status: stat,
      date,
      due,
      products,
      discount,
      tax,
      payments,
      change,
      amount,
      total,
      autoReminder,
      information,
      discountPercent,
      terms,
      edit: rid,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "branch_not_found") {
      throw new Error(
        "The branch specified does not exist! Please refresh this page and try again."
      );
    }
    if (status === "customer_not_found") {
      throw new Error(
        "The customer specified does not exist! Please refresh this page and try again."
      );
    }

    throw new Error(
      "Something went wrong while attempting to save invoice! Please try again later."
    );
  };
  const editDiscrepancyReport = async (branch, products, rid) => {
    const response = await axios.post(API_PATH.discrepancy_reports, {
      branch,
      products,
      update: rid,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "branch_not_found") {
      throw new Error(
        "The branch specified does not exist! Please refresh this page and try again."
      );
    }
    if (status === "product_not_found") {
      throw new Error(
        "No product was added! Please refresh this page and try again."
      );
    }

    throw new Error(
      "Something went wrong while attempting to save report! Please try again later."
    );
  };
  const generateQuote = async (
    branch,
    customer,
    date,
    products,
    discount,
    tax,
    total,
    information,
    note,
    terms,
    header,
    greeting,
    discountPercent
  ) => {
    const response = await axios.post(API_PATH.quotations, {
      branch,
      customer,
      date,
      products,
      discount,
      tax,
      total,
      information,
      note,
      terms,
      header,
      greeting,
      discountPercent,
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "branch_not_found") {
      throw new Error(
        "The branch specified does not exist! Please refresh this page and try again."
      );
    }
    if (status === "customer_not_found") {
      throw new Error(
        "The recipient specified does not exist! Please refresh this page and try again."
      );
    }

    throw new Error(
      "Something went wrong while attempting to generate quote! Please try again later."
    );
  };
  const generateWaybill = async (
    branch,
    customer,
    date,
    products,
    total,
    information,
    terms,
    saleId
  ) => {
    const response = await axios.post(API_PATH.waybills, {
      branch,
      customer,
      date,
      products,
      total,
      information,
      terms,
      saleId,
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "branch_not_found") {
      throw new Error(
        "The branch specified does not exist! Please refresh this page and try again."
      );
    }
    if (status === "customer_not_found") {
      throw new Error(
        "The recipient specified does not exist! Please refresh this page and try again."
      );
    }

    throw new Error(
      "Something went wrong while attempting to generate waybill! Please try again later."
    );
  };
  const editQuote = async (
    branch,
    customer,
    date,
    products,
    discount,
    tax,
    total,
    information,
    note,
    terms,
    header,
    greeting,
    discountPercent,
    rid
  ) => {
    const response = await axios.post(API_PATH.quotations, {
      branch,
      customer,
      date,
      products,
      discount,
      tax,
      total,
      information,
      note,
      terms,
      header,
      greeting,
      discountPercent,
      edit: rid,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "branch_not_found") {
      throw new Error(
        "The branch specified does not exist! Please refresh this page and try again."
      );
    }
    if (status === "customer_not_found") {
      throw new Error(
        "The recipient specified does not exist! Please refresh this page and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to save quote! Please try again later."
    );
  };
  const editWaybill = async (
    branch,
    customer,
    date,
    products,
    total,
    information,
    terms,
    saleId,
    wid
  ) => {
    const response = await axios.post(API_PATH.waybills, {
      branch,
      customer,
      date,
      products,
      total,
      information,
      terms,
      saleId,
      edit: wid,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "branch_not_found") {
      throw new Error(
        "The branch specified does not exist! Please refresh this page and try again."
      );
    }
    if (status === "customer_not_found") {
      throw new Error(
        "The recipient specified does not exist! Please refresh this page and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to save waybill! Please try again later."
    );
  };
  const addReturned = async (
    branch,
    customer,
    stat,
    addToStock,
    date,
    products,
    total,
    saleId
  ) => {
    const response = await axios.post(API_PATH.returned, {
      branch,
      customer,
      status: stat,
      addToStock,
      date,
      products,
      sale_id: saleId,
      total,
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "branch_not_found") {
      throw new Error(
        "The branch specified does not exist! Please refresh this page and try again."
      );
    }
    if (status === "customer_not_found") {
      throw new Error(
        "The customer specified does not exist! Please refresh this page and try again."
      );
    }

    throw new Error(
      "Something went wrong while attempting to add returned products! Please try again later."
    );
  };
  const addDebtor = async (
    branch,
    debtor,
    stat,
    due,
    products,
    owed,
    paid,
    totalOwed,
    totalPaid,
    balance,
    deductQty
  ) => {
    const response = await axios.post(API_PATH.debtors, {
      branch,
      debtor,
      status: stat,
      due,
      products,
      owed,
      paid,
      total_owed: totalOwed,
      total_paid: totalPaid,
      balance,
      deduct_qty: deductQty,
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "branch_not_found") {
      throw new Error(
        "The branch specified does not exist! Please refresh this page and try again."
      );
    }
    if (status === "customer_not_found") {
      throw new Error(
        "The debtor specified does not exist! Please refresh this page and try again."
      );
    }

    throw new Error(
      "Something went wrong while attempting to add debtor! Please try again later."
    );
  };
  const uploadAttachment = async (formData) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await axios.post(API_PATH.misc, formData, config);
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while attempting to upload attachment! Please try again later."
    );
  };
  const uploadReceipt = async (formData) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await axios.post(API_PATH.misc, formData, config);
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while attempting to upload receipt! Please try again later."
    );
  };
  const uploadProductPhotos = async (formData) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await axios.post(API_PATH.products, formData, config);
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while attempting to upload photos! Please try again later."
    );
  };
  const updatePurchaseReceipt = async (formData) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await axios.post(API_PATH.purchases, formData, config);
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "Something went wrong while attempting to upload receipt! Please try again later."
      );
    }
  };
  const uploadEcomImage = async (formData) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await axios.post(API_PATH.settings, formData, config);
    const { status, filename } = response.data;
    if (status === "success") {
      return filename;
    }
    throw new Error(
      "Something went wrong while attempting to upload image! Please try again later."
    );
  };
  const uploadImport = async (formData) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await axios.post(API_PATH.misc, formData, config);
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "file_empty") {
      throw new Error(
        "The file you uploaded is empty! Please upload a valid file to proceed."
      );
    }
    throw new Error(
      "Something went wrong while attempting to upload file! Please try again later."
    );
  };
  const setImport = async (file, type, headers, branch, removeFirst) => {
    const response = await axios.post(API_PATH.importExport, {
      file,
      type,
      headers,
      branch,
      removeFirst,
      import: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "branch_not_found") {
      throw new Error(
        "The branch specified does not exist! Please refresh this page and try again."
      );
    }
    if (status === "staff_not_found") {
      throw new Error(
        "The staff specified does not exist! Please refresh this page and try again."
      );
    }

    throw new Error(
      "Something went wrong while attempting import! Please try again later."
    );
  };
  const setExport = async (type, branch, category, stock, dateFrom, dateTo) => {
    const response = await axios.post(API_PATH.importExport, {
      type,
      branch: branch.bid || "",
      dateFrom,
      dateTo,
      stock: stock.value || "",
      export: "",
      category: category.cid || "",
    });
    const { status } = response.data;
    if (status === "branch_not_found") {
      throw new Error(
        "The branch specified does not exist! Please refresh this page and try again."
      );
    }
    if (status === "category_not_found") {
      throw new Error(
        "The category specified does not exist! Please refresh this page and try again."
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while attempting export! Please try again later."
      );
    }
  };

  const addExpense = async (values) => {
    const response = await axios.post(API_PATH.expense, {
      ...values,
      staff: values.staff.uid,
      branch: values.branch.bid,
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "branch_not_found") {
      throw new Error(
        "The branch specified does not exist! Please refresh this page and try again."
      );
    }
    if (status === "staff_not_found") {
      throw new Error(
        "The staff specified does not exist! Please refresh this page and try again."
      );
    }

    throw new Error(
      "Something went wrong while attempting to add expense! Please try again later."
    );
  };
  const addDamaged = async (values) => {
    const response = await axios.post(API_PATH.damaged, {
      ...values,
      product: values.product.pid,
      branch: values.branch.bid,
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "branch_not_found") {
      throw new Error(
        "The branch specified does not exist! Please refresh this page and try again."
      );
    }
    if (status === "product_not_found") {
      throw new Error(
        "The product specified does not exist! Please refresh this page and try again."
      );
    }

    throw new Error(
      "Something went wrong while attempting to add damaged product! Please try again later."
    );
  };

  const addPurchase = async (
    branch,
    company,
    stat,
    date,
    products,
    invoice,
    receipt,
    amount,
    total,
    balance,
    addQty
  ) => {
    const response = await axios.post(API_PATH.purchases, {
      branch,
      company,
      status: stat,
      date,
      products,
      invoice,
      receipt,
      amount,
      total,
      balance,
      addQty,
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "company_not_found") {
      throw new Error(
        "The company specified does not exist! Please refresh this page and try again."
      );
    }

    throw new Error(
      "Something went wrong while attempting to add the purchase! Please try again later."
    );
  };
  const addDiscrepancyReport = async (branch, products) => {
    const response = await axios.post(API_PATH.discrepancy_reports, {
      branch,
      products,
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while attempting to add the report! Please try again later."
    );
  };
  const addOnline = async (
    branch,
    orderid,
    stat,
    date,
    due,
    products,
    discount,
    tax,
    total,
    store,
    deductQty
  ) => {
    const response = await axios.post(API_PATH.online, {
      branch,
      orderid,
      status: stat,
      date,
      products,
      total,
      discount,
      tax,
      due,
      store,
      deductQty,
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "branch_not_found") {
      throw new Error(
        "The branch specified does not exist! Please refresh this page and try again."
      );
    }
    if (status === "orderid_exists") {
      throw new Error(
        "The Order ID specified already exist! Please confirm the order ID and try again."
      );
    }

    throw new Error(
      "Something went wrong while attempting to add the sale! Please try again later."
    );
  };
  const updateOnline = async (values, uid) => {
    const response = await axios.post(API_PATH.online, {
      ...values,
      update: uid,
      branch: values.branch.bid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the online sale's details! Please try again later."
      );
    }
  };
  const updateOnlineStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.online, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the online sale's status! Please try again later."
      );
    }
  };
  const updateOnlineProducts = async (values, uid) => {
    const response = await axios.post(API_PATH.online, {
      update_products: uid,
      products: JSON.stringify(values.products),
      deduct_qty: values.deductQty,
      discount: values.discount,
      tax: values.tax,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the online sale's products! Please try again later."
      );
    }
  };
  const getProduct = async (product) => {
    if (!product) {
      throw new Error(
        "Invalid product's ID supplied! Please go back to the products page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.products, { product });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid product's ID supplied! Please go back to the products page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching product's data! Please try again later."
    );
  };
  const updateProduct = async (values, uid) => {
    const response = await axios.post(API_PATH.products, {
      ...values,
      update: uid,
      category:
        values.category &&
        Object.prototype.hasOwnProperty.call(values.category, "cid")
          ? values.category.cid
          : "",
      branch: values.branch.bid,
      attributes: JSON.stringify(values.attributes),
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the product! Please try again later."
      );
    }
  };
  const discountProducts = async (
    products,
    discountType,
    discountMode,
    discountAmount
  ) => {
    const response = await axios.post(API_PATH.products, {
      discountProducts: products,
      discountType,
      discountMode,
      discountAmount,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while discounting the product(s)! Please try again later."
      );
    }
  };
  const updateEcomProduct = async (values, uid) => {
    const response = await axios.post(API_PATH.products, {
      ...values,
      updateEcom: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the product! Please try again later."
      );
    }
  };
  const updateProductVarieties = async (varieties, uid) => {
    const response = await axios.post(API_PATH.products, {
      updateVarieties: uid,
      varieties: JSON.stringify(varieties),
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the varieties! Please try again later."
      );
    }
  };
  const updateProductSpecifications = async (specifications, uid) => {
    const response = await axios.post(API_PATH.products, {
      updateSpecifications: uid,
      specifications: JSON.stringify(specifications),
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the specifications! Please try again later."
      );
    }
  };
  const addProductPhotos = async (photos, uid) => {
    const response = await axios.post(API_PATH.products, {
      updatePhotos: uid,
      photos: JSON.stringify(photos),
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the photos! Please try again later."
      );
    }
  };
  const updateProductQuantity = async (quantity, uid, type) => {
    const response = await axios.post(API_PATH.products, {
      quantity,
      update_qty: uid,
      type,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the product! Please try again later."
      );
    }
  };
  const transferProductQuantity = async (quantity, newBranch, uid) => {
    const response = await axios.post(
      API_PATH.products,
      uid.includes(",")
        ? {
            quantity,
            transfer_qtys: uid,
            currentBranch: getCurrentBranch(),
            newBranch,
          }
        : {
            quantity,
            transfer_qty: uid,
            currentBranch: getCurrentBranch(),
            newBranch,
          }
    );
    const { status } = response.data;
    if (status === "low_quantity") {
      throw new Error(
        "The quantity selected is more than the available quantity for this product! Please confirm and try again."
      );
    }
    if (status !== "success") {
      throw new Error(
        "An error occurred while transferring the product! Please try again later."
      );
    }
  };
  const updateProductStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.products, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the product's status! Please try again later."
      );
    }
  };
  const updateProductEcommerceStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.products, {
      update_ecommerce_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the product's ecommerce status! Please try again later."
      );
    }
  };
  const getMessage = async (message) => {
    if (!message) {
      throw new Error(
        "Invalid message ID supplied! Please go back to the SMS / Email page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.message, { message });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid message ID supplied! Please go back to the SMS / Email page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching message's data! Please try again later."
    );
  };
  const getAdmin = async (admin) => {
    if (!admin) {
      throw new Error(
        "Invalid administrator's ID supplied! Please go back to the administrators page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.admins, { admin });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid administrator's ID supplied! Please go back to the administrators page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching administrator's data! Please try again later."
    );
  };
  const updateAdmin = async (values, uid) => {
    const response = await axios.post(API_PATH.admins, {
      ...values,
      update: uid,
      branch: values.branch.bid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the administrator! Please try again later."
      );
    }
  };
  const updateAdminPassword = async (values, uid) => {
    const response = await axios.post(API_PATH.admins, {
      ...values,
      update_password: uid,
    });
    const { status } = response.data;
    if (status === "invalid_password") {
      throw new Error(
        "The current password you provided is incorrect! Please confirm and try again."
      );
    }
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating administrator's password! Please try again later."
      );
    }
  };
  const updateAdminStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.admins, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the administrator's status! Please try again later."
      );
    }
  };
  const updateAdminRole = async (newRole, uid) => {
    const response = await axios.post(API_PATH.admins, {
      update_role: uid,
      newRole,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the administrator's role! Please try again later."
      );
    }
  };
  const updateAdminPhoto = async (photo, uid) => {
    const response = await axios.post(API_PATH.admins, {
      update_photo: uid,
      photo,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the administrator's photo! Please try again later."
      );
    }
  };
  const getRole = async (role) => {
    if (!role) {
      throw new Error(
        "Invalid role's ID supplied! Please go back to the roles page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.roles, { role });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid role's ID supplied! Please go back to the roles page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching role's data! Please try again later."
    );
  };
  const updateRole = async (values, uid) => {
    const response = await axios.post(API_PATH.roles, {
      ...values,
      update: uid,
      permissions: values.permissions.join(","),
    });
    const { status, data } = response.data;
    if (status === "success" && data.user) {
      dispatch({ type: "UPDATE", payload: { user: data } });
    }
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the role! Please try again later."
      );
    }
  };
  const updateRoleStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.roles, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the role's status! Please try again later."
      );
    }
  };
  const getStaffs = async (staff) => {
    if (!staff) {
      throw new Error(
        "Invalid staff's ID supplied! Please go back to the staff page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.staff, { staff });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid staff's ID supplied! Please go back to the staff page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching staff's data! Please try again later."
    );
  };
  const updateStaff = async (values, uid) => {
    const response = await axios.post(API_PATH.staff, {
      ...values,
      update: uid,
      branch: values.branch.bid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the staff! Please try again later."
      );
    }
  };
  const updateStaffNOK = async (values, uid) => {
    const response = await axios.post(API_PATH.staff, {
      ...values,
      update_nok: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating next of kin! Please try again later."
      );
    }
  };
  const updateStaffStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.staff, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the staff's status! Please try again later."
      );
    }
  };
  const updateStaffPhoto = async (photo, uid) => {
    const response = await axios.post(API_PATH.staff, {
      update_photo: uid,
      photo,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the staff's photo! Please try again later."
      );
    }
  };
  const getBranch = async (branch) => {
    if (!branch) {
      throw new Error(
        "Invalid branch's ID supplied! Please go back to the branches page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.branches, { branch });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid branch's ID supplied! Please go back to the branches page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching branch's data! Please try again later."
    );
  };
  const updateBranch = async (values, uid) => {
    const response = await axios.post(API_PATH.branches, {
      ...values,
      update: uid,
    });
    const { status, data } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the branch! Please try again later."
      );
    } else {
      dispatch({ type: "UPDATE", payload: { user: data } });
    }
  };
  const updateBranchStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.branches, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the branch's status! Please try again later."
      );
    }
  };

  const getProductCategories = async (category) => {
    if (!category) {
      throw new Error(
        "Invalid category's ID supplied! Please go back to the categories page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.categories, { category });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid category's ID supplied! Please go back to the categories page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching category's data! Please try again later."
    );
  };
  const updateCategory = async (values, uid) => {
    const response = await axios.post(API_PATH.categories, {
      ...values,
      attributes: JSON.stringify(values.attributes),
      update: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the category! Please try again later."
      );
    }
  };
  const updateCategoryStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.categories, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the category's status! Please try again later."
      );
    }
  };
  const getDiscountCode = async (discountCode) => {
    if (!discountCode) {
      throw new Error(
        "Invalid discount code's ID supplied! Please go back to the categories page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.discountCodes, { discountCode });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid discount code's ID supplied! Please go back to the categories page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching discount code's data! Please try again later."
    );
  };
  const updateDiscountCodeStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.discountCodes, {
      updateStatus: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the discount code's status! Please try again later."
      );
    }
  };
  const updateDiscountCode = async (values, uid) => {
    const response = await axios.post(API_PATH.discountCodes, {
      ...values,
      update: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the discount code! Please try again later."
      );
    }
  };

  const getAllCustomers = async (customer) => {
    if (!customer) {
      throw new Error(
        "Invalid customer's ID supplied! Please go back to the customers page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.customers, { customer });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid customer's ID supplied! Please go back to the customers page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching customer's data! Please try again later."
    );
  };
  const updateCustomer = async (values, uid) => {
    const response = await axios.post(API_PATH.customers, {
      ...values,
      update: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the customer! Please try again later."
      );
    }
  };
  const updateCompany = async (values, uid) => {
    const response = await axios.post(API_PATH.companies, {
      ...values,
      update: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the company! Please try again later."
      );
    }
  };
  const updateCustomerStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.customers, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the customer's status! Please try again later."
      );
    }
  };
  const getSale = async (sale) => {
    if (!sale) {
      throw new Error(
        "Invalid sale's ID supplied! Please go back to the sales page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.sales, { sale });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid sale's ID supplied! Please go back to the sales page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching sale's data! Please try again later."
    );
  };
  const getOrderDetails = async (orderId) => {
    if (!orderId) {
      throw new Error(
        "Invalid order ID supplied! Please go back to the orders page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.orders, { orderId });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid order ID supplied! Please go back to the orders page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching order's data! Please try again later."
    );
  };
  const getInvoice = async (invoice) => {
    if (!invoice) {
      throw new Error(
        "Invalid invoice's ID supplied! Please go back to the invoices page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.invoices, { invoice });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid invoice's ID supplied! Please go back to the invoices page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching invoice's data! Please try again later."
    );
  };
  const getQuotation = async (quote) => {
    if (!quote) {
      throw new Error(
        "Invalid quote's ID supplied! Please go back to the invoices page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.quotations, { quote });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid quote's ID supplied! Please go back to the quotations page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching quote's data! Please try again later."
    );
  };
  const getWaybill = async (waybill) => {
    if (!waybill) {
      throw new Error(
        "Invalid waybill's ID supplied! Please go back to the waybills page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.waybills, { waybill });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "not_found") {
      throw new Error(
        "Invalid waybill's ID supplied! Please go back to the waybills page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching waybill's data! Please try again later."
    );
  };

  const getPaySale = async (userSale) => {
    if (!userSale) {
      throw new Error(
        "Invalid receipt's ID supplied! Please confirm your link is correct and refresh."
      );
    }
    const subdomain = window.location.host.split(".")[1]
      ? window.location.host.split(".")[0]
      : false;
    const response = await axios.post(API_PATH.sales, {
      userSale,
      domain: subdomain,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid receipt's ID supplied! Please confirm your link is correct and refresh."
      );
    }
    throw new Error("Something went wrong! Please try again later.");
  };
  const getReturned = async (returned) => {
    if (!returned) {
      throw new Error(
        "Invalid returned product's ID supplied! Please go back to the returned products page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.returned, { returned });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid returned product's ID supplied! Please go back to the returned products page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching returned product's data! Please try again later."
    );
  };
  const updateReturned = async (values, uid) => {
    const response = await axios.post(API_PATH.returned, {
      ...values,
      update: uid,
      branch: values.branch.bid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the returned product! Please try again later."
      );
    }
  };
  const updateReturnedStatus = async (newStatus, addToStock, uid) => {
    const response = await axios.post(API_PATH.returned, {
      update_status: uid,
      newStatus,
      addToStock,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the returned product's status! Please try again later."
      );
    }
  };
  const updateReturnedProducts = async (values, uid) => {
    const response = await axios.post(API_PATH.returned, {
      update_products: uid,
      products: JSON.stringify(values.products),
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the returned products! Please try again later."
      );
    }
  };
  const getDebtor = async (debtor) => {
    if (!debtor) {
      throw new Error(
        "Invalid debtor's ID supplied! Please go back to the debtors page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.debtors, { debtor });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid debtor's ID supplied! Please go back to the debtors page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching debtor's data! Please try again later."
    );
  };
  const updateDebtor = async (values, uid) => {
    const response = await axios.post(API_PATH.debtors, {
      ...values,
      update: uid,
      branch: values.branch.bid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the debtor details! Please try again later."
      );
    }
  };
  const updateDebtorStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.debtors, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the debtor's status! Please try again later."
      );
    }
  };
  const updateDebtorProducts = async (values, uid) => {
    const response = await axios.post(API_PATH.debtors, {
      update_products: uid,
      products: JSON.stringify(values.products),
      deduct_qty: values.deductQty,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the debtor products! Please try again later."
      );
    }
  };
  const addDebtPayment = async (values, uid) => {
    const response = await axios.post(API_PATH.debtors, {
      update_payment: uid,
      payment: JSON.stringify(values),
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating payments! Please try again later."
      );
    }
  };
  const addDebtOwed = async (values, uid) => {
    const response = await axios.post(API_PATH.debtors, {
      update_owed: uid,
      owed: JSON.stringify(values),
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating debts! Please try again later."
      );
    }
  };
  const removeDebtPayment = async (index, uid) => {
    const response = await axios.post(API_PATH.debtors, {
      remove_payment: uid,
      payment: index,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating payments! Please try again later."
      );
    }
  };
  const removeDebtOwed = async (index, uid) => {
    const response = await axios.post(API_PATH.debtors, {
      remove_owed: uid,
      owed: index,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating debts! Please try again later."
      );
    }
  };
  const getDamagedProduct = async (damaged) => {
    if (!damaged) {
      throw new Error(
        "Invalid damaged product's ID supplied! Please go back to the damaged products page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.damaged, { damaged });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid damaged product's ID supplied! Please go back to the damaged products page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching damaged product's data! Please try again later."
    );
  };
  const updatePurchase = async (values, uid) => {
    const response = await axios.post(API_PATH.purchases, {
      ...values,
      update: uid,
      branch: values.branch.bid,
      company: values.company,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the purchase's details! Please try again later."
      );
    }
  };
  const updatePurchaseProducts = async (values, uid) => {
    const response = await axios.post(API_PATH.purchases, {
      update_products: uid,
      products: JSON.stringify(values.products),
      amount: values.amount,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the purchase products! Please try again later."
      );
    }
  };
  const updateDamaged = async (values, uid) => {
    const response = await axios.post(API_PATH.damaged, {
      ...values,
      update: uid,
      product: values.product.pid,
      branch: values.branch.bid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the damaged product's details! Please try again later."
      );
    }
  };
  const updateDamagedStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.damaged, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the product's status! Please try again later."
      );
    }
  };
  const updatePurchaseStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.purchases, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the purchase's status! Please try again later."
      );
    }
  };
  const getPurchase = async (purchase) => {
    if (!purchase) {
      throw new Error(
        "Invalid purchase's ID supplied! Please go back to the purchases page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.purchases, { purchase });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid purchase's ID supplied! Please go back to the purchases page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching purchase's data! Please try again later."
    );
  };
  const getDiscrepancyReport = async (report) => {
    if (!report) {
      throw new Error(
        "Invalid report's ID supplied! Please go back to the reports page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.discrepancy_reports, { report });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid report's ID supplied! Please go back to the discrepancy reports page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching report's details! Please try again later."
    );
  };
  const downloadProductsHistories = async (download) => {
    if (!download) {
      throw new Error(
        "Invalid report's ID supplied! Please go back to the reports page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.discrepancy_reports, {
      download,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid report's ID supplied! Please go back to the discrepancy reports page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while generating file! Please try again later."
    );
  };
  const downloadProductHistory = async (download) => {
    if (!download) {
      throw new Error(
        "Invalid product's ID supplied! Please go back to the products page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.products, {
      download,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid product's ID supplied! Please go back to the products reports page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while generating file! Please try again later."
    );
  };
  const getOnlineSale = async (online) => {
    if (!online) {
      throw new Error(
        "Invalid online sale's ID supplied! Please go back to the online sales page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.online, { online });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid online sale's ID supplied! Please go back to the online sales page to select the right one."
      );
    }

    throw new Error(
      "Something went wrong while fetching online sales data! Please try again later."
    );
  };
  const verifyDNS = async (website, subdomain) => {
    const response = await axios.post(API_PATH.settings, {
      verify_dns: website,
      subdomain,
    });
    const { status } = response.data;
    if (status === "invalid_records") {
      throw new Error(
        "Your CNAME records does not have the correct value. Please check and try again later."
      );
    }
    if (status === "too_many_records") {
      throw new Error(
        "We detected more than one CNAME records added! Please remove all CNAME record except for the current one."
      );
    }
    if (status === "no_records") {
      throw new Error(
        "We do not detect any CNAME records at the moment! Please check and try again later."
      );
    }
    if (status === "error") {
      throw new Error(
        "The website address provided is invalid or has not been registered. Please provide a valid address to proceed."
      );
    }
  };
  const verifyExternalDNS = async () => {
    const subdomain = window.location.host.split(".")[1]
      ? window.location.host.split(".")[0]
      : false;
    const response = await axios.post(API_PATH.misc, {
      verify_dns: subdomain,
    });
    const { status } = response.data;
    if (status === "success") {
      return status;
    }
    if (status === "invalid_records") {
      throw new Error(
        "Your CNAME records does not have the correct value. Please check and try again later."
      );
    }
    if (status === "too_many_records") {
      throw new Error(
        "We detected more than one CNAME records added! Please remove all CNAME record except for the current one."
      );
    }
    if (status === "no_records") {
      throw new Error(
        "We do not detect any CNAME records at the moment! Please check and try again later."
      );
    }
    if (status === "error") {
      throw new Error(
        "The website address provided is invalid or has not been registered. Please provide a valid address to proceed."
      );
    }
  };
  const verifyExternalWebsite = async (website) => {
    const subdomain = window.location.host.split(".")[1]
      ? window.location.host.split(".")[0]
      : false;
    const response = await axios.post(API_PATH.misc, {
      verify_website: website,
      subdomain,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "The website address provided is invalid or has not been registered. Please provide a valid address to proceed."
    );
  };
  const verifyWebsite = async (website, subdomain) => {
    const response = await axios.post(API_PATH.settings, {
      verify_website: website,
      subdomain,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "The website address provided is invalid or has not been registered. Please provide a valid address to proceed."
    );
  };
  const sendDNSRecords = async (email) => {
    const response = await axios.post(API_PATH.settings, {
      send_records: email,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "The website address provided is invalid or has not been registered. Please provide a valid address to proceed."
    );
  };
  const updateEcommerce = async (values) => {
    const response = await axios.post(API_PATH.settings, {
      ...values,
      branches: JSON.stringify(values.branches),
      ecommerce: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
      return;
    }
    if (status === "invalid_data") {
      throw new Error(
        "The data you supplied is invalid or incorrect! Please confirm and try again."
      );
    }
    if (status === "server_down") {
      throw new Error(
        "The verification portal is down at the moment! Not to worry, we will try again as soon as possible!"
      );
    }
    throw new Error(
      "There is an issue with your compliance information! Please check your email for more information."
    );
  };
  const updateEcommerceCustomizations = async (values) => {
    const response = await axios.post(API_PATH.settings, {
      ...values,
      ecommerce_customize: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
      return;
    }
    throw new Error("An error has occured! Please try again later.");
  };
  const updateCompliance = async (values) => {
    const response = await axios.post(API_PATH.settings, {
      ...values,
      compliance: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
      return;
    }
    if (status === "verified") {
      throw new Error("Your store is already verified!");
    }
    if (status === "invalid_data") {
      throw new Error(
        "The data you supplied is invalid or incorrect! Please confirm and try again."
      );
    }
    if (status === "server_down") {
      throw new Error(
        "The verification portal is down at the moment! Not to worry, we will try again as soon as possible!"
      );
    }
    throw new Error(
      "There is an issue with your compliance information! Please check your email for more information."
    );
  };
  const updateSettingsAccount = async (values) => {
    const response = await axios.post(API_PATH.settings, {
      ...values,
      bank: values.bank.code,
      bankName: values.bank.name,
      update_account: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
    }
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating account! Please try again later."
      );
    }
  };
  const updateSettingsOther = async (values) => {
    const response = await axios.post(API_PATH.settings, {
      update_other: "",
      values: JSON.stringify(values),
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
    }
    if (status === "error") {
      throw new Error(
        "Something went wrong while updating settings. Please try again later."
      );
    }
  };
  const getPlans = async () => {
    const response = await axios.post(API_PATH.subscription, { plans: "" });
    const { status, plans, general } = response.data;
    if (status === "success") {
      return { plans, general };
    }
    throw new Error(
      "Something went wrong while fetching plans! Please try again later."
    );
  };
  const processSubscription = async (plan, ref, duration) => {
    const response = await axios.post(API_PATH.subscription, {
      process: "",
      plan,
      ref,
      duration,
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
      return;
    }
    throw new Error(
      "Something went wrong while verifying your payment! Please contact us if you were debited."
    );
  };
  const changeSubscription = async (plan, duration) => {
    const response = await axios.post(API_PATH.subscription, {
      update_plan: "",
      plan,
      duration,
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
      return;
    }
    throw new Error(
      "Something went wrong while updating your subscription! Please try again later"
    );
  };
  const cancelSubscription = async () => {
    const response = await axios.post(API_PATH.subscription, {
      cancel_plan: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
      return;
    }
    throw new Error(
      "Something went wrong while cancelling your subscription! Please try again later."
    );
  };
  const activateSubscription = async (reference) => {
    const response = await axios.post(API_PATH.subscription, {
      activate_plan: "",
      reference,
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
      return;
    }
    if (status === "charge_failed") {
      throw new Error(
        reference
          ? "Something went wrong while trying to verify your payment! Please contact us if you were debited or try again later."
          : "Something went wrong while trying to charge your payment card! Please confirm and try again later."
      );
    }
    throw new Error(
      "Something went wrong while activating your subscription! Please try again later."
    );
  };
  const addCard = async (ref) => {
    const response = await axios.post(API_PATH.subscription, {
      addCard: "",
      ref,
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
      return;
    }
    throw new Error(
      "Something went wrong while verifying your card! Please contact us if your wallet is not credited."
    );
  };
  const removeCard = async () => {
    const response = await axios.post(API_PATH.subscription, {
      removeCard: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
      return;
    }
    throw new Error(
      "Something went wrong while removing your card! Please try again later,."
    );
  };
  const addSend = async (values) => {
    const response = await axios.post(API_PATH.message, {
      add: "",
      ...values,
      rcp: JSON.stringify(
        values.rcp
          .map((r) =>
            values.rcpType === "3" || r.name === "All"
              ? r.value
              : (values.rcpType === "0" && r.cid) || r.uid
          )
          .filter((r) => r !== "")
      ),
      branch: getCurrentBranch(),
    });
    const { status, data } = response.data;
    if (status === "limit_exceeded") {
      throw new Error(
        `You cannot send more than ${data} mails per month! Please upgrade your plan to send more.`
      );
    }
    if (status === "insufficient_balance") {
      throw new Error(
        `You do not sufficient SMS balance to send this message(NGN${data}). Please fund your balance and try again.`
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while sending your message! Please try again later."
      );
    }
  };
  const topupSMS = async (ref, amount) => {
    const response = await axios.post(API_PATH.message, {
      topup: "",
      ref,
      amount,
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
      return;
    }
    if (status === "insufficient_balance") {
      throw new Error(
        `You do not sufficient balance in your wallet to process this request. Please try again later.`
      );
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later or notify us if you were debited."
    );
  };
  const addSupport = async (values) => {
    const response = await axios.post(API_PATH.support, { add: "", ...values });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "Something went wrong while processing your request! Please try again later."
      );
    }
  };
  const checkPriceChangePerm = async (values) => {
    const response = await axios.post(API_PATH.sales, {
      checkPerm: "",
      ...values,
    });
    const { status } = response.data;
    if (status === "access_denied") {
      throw new Error(
        `You do not have the permission required to edit product's prices!`
      );
    }
    if (status === "invalid_account") {
      throw new Error(
        `Username or password is incorrect! Please confirm and try again.`
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while processing your request! Please try again later or notify us if you were debited."
      );
    }
  };
  const confirmCheckoutTransfer = async (amount) => {
    const response = await axios.post(API_PATH.sales, {
      confirmTransfer: "",
      amount,
    });
    const { status, payments } = response.data;
    if (status === "success") {
      return payments;
    }
    throw new Error(
      "Something went wrong while checking for transfers! Please confirm you are connected to the internet. We'll keep checking..."
    );
  };
  const sendInvoice = async (rid, type, value, isInvoice, sendMilestone) => {
    const response = await axios.post(
      isInvoice ? API_PATH.invoices : API_PATH.sales,
      {
        send: "",
        rid,
        type,
        value,
        sendMilestone,
      }
    );
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "Something went wrong while sending invoice / receipt! Please try again later."
      );
    }
  };
  const sendQuote = async (rid, type, value) => {
    const response = await axios.post(API_PATH.quotations, {
      send: "",
      rid,
      type,
      value,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "Something went wrong while sending quote! Please try again later."
      );
    }
  };
  const sendWaybill = async (wid, type, value) => {
    const response = await axios.post(API_PATH.waybills, {
      send: "",
      wid,
      type,
      value,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "Something went wrong while sending waybill! Please try again later."
      );
    }
  };
  const sendOrder = async (orderId, type, value) => {
    const response = await axios.post(API_PATH.orders, {
      send: "",
      orderId,
      type,
      value,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "Something went wrong while sending order! Please try again later."
      );
    }
  };
  const sendOrderUpdate = async (orderId, type, reason) => {
    const response = await axios.post(API_PATH.orders, {
      updateStatus: orderId,
      type,
      reason,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "Something went wrong while updating order! Please try again later."
      );
    }
  };
  const sendDiscrepancyReport = async (rid, value) => {
    const response = await axios.post(API_PATH.discrepancy_reports, {
      send: "",
      rid,
      value,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "Something went wrong while sending report! Please try again later."
      );
    }
  };
  const confirmInvoicePay = async (ref, amount, rid) => {
    const domain = window.location.host.split(".")[1]
      ? window.location.host.split(".")[0]
      : false;
    const response = await axios.post(API_PATH.sales, {
      confirmPay: "",
      ref,
      amount,
      rid,
      domain,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "Something went wrong while processing your payment! Please try again or notify us if you were debited."
      );
    }
  };
  const getPaymentAnalysis = async (filter) => {
    const response = await axios.post(API_PATH.paymentAnalysis, {
      get: "",
      ...filter,
      branch: getCurrentBranch(),
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while getting your payment analysis! Please try again later."
    );
  };
  const getEndofDayReport = async (filter) => {
    const response = await axios.post(API_PATH.report, {
      get: "",
      ...filter,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while getting your report! Please try again later."
    );
  };
  const getStoreAnalysis = async (filter) => {
    const response = await axios.post(API_PATH.storeAnalysis, {
      get: "",
      ...filter,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while getting your store analysis! Please try again later."
    );
  };
  const getGeneralAnalysis = async (filter) => {
    const response = await axios.post(API_PATH.generalAnalysis, {
      get: "",
      ...filter,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while getting your general analysis! Please try again later."
    );
  };
  const getPaymentTable = async (filter, paymentType, page, perpage) => {
    const response = await axios.post(API_PATH.paymentAnalysis, {
      payments: "",
      ...filter,
      paymentType,
      branch: getCurrentBranch(),
      page,
      perpage,
    });
    const { status, data, total } = response.data;
    if (status === "success") {
      return { total, data };
    }
    throw new Error(
      "Something went wrong while getting your payments! Please try again later."
    );
  };
  const exportPaymentAnalysis = async (filter) => {
    const response = await axios.post(API_PATH.paymentAnalysis, {
      export: "",
      ...filter,
      branch: getCurrentBranch(),
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while exporing your payments! Please try again later."
    );
  };
  const getCards = async (getType) => {
    const response = await axios.post(API_PATH.cards, { getType });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while fetching card types! Please try again later."
    );
  };
  const getCardsDefault = async () => {
    const response = await axios.post(API_PATH.cards, { defaults: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error("Something went wrong! Please try again later.");
  };
  const getFeaturedProductsPlans = async () => {
    const response = await axios.post(API_PATH.misc, { featuredPlans: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error("Something went wrong! Please try again later.");
  };
  const getCardSettings = async () => {
    const response = await axios.post(API_PATH.cards, { settings: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error("Something went wrong! Please try again later.");
  };
  const featureProducts = async (values) => {
    const response = await axios.post(API_PATH.featuredProducts, {
      add: "",
      ...values,
      products: JSON.stringify(values.products.map((product) => product.pid)),
      plan: (values.plan && values.plan.pid) || "",
    });
    const { status } = response.data;
    if (status === "invalid_reference") {
      throw new Error(
        "We could not confirm your payment! Please try again later or contact us if you were debited."
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to submit your request! Please try again later or contact us if you were debited."
      );
    }
  };
  const cancelOffer = async (selected) => {
    const response = await axios.post(API_PATH.specialOffers, {
      cancel: JSON.stringify(selected),
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to cancel the offer(s)! Please try again later."
      );
    }
  };
  const addOffers = async (values) => {
    const response = await axios.post(API_PATH.specialOffers, {
      add: "",
      ...values,
      products: JSON.stringify(
        values.products.map((product) => ({
          pid: product.pid,
          price: product.newPrice,
        }))
      ),
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to submit your request! Please try again later."
      );
    }
  };
  const requestCard = async (values) => {
    const response = await axios.post(API_PATH.cards, {
      request: "",
      ...values,
    });
    const { status } = response.data;
    if (status === "payment_error") {
      throw new Error(
        "We could not confirm your payment! Please try again later or contact us if you were debited."
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while trying to make your request! Please try again later or contact us if you were debited."
      );
    }
  };
  const checkCard = async (customer, branch, card, type) => {
    const response = await axios.post(API_PATH.misc, {
      checkCard: "",
      customer,
      branch,
      card,
      type,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "card_error") {
      throw new Error("This card is invalid. Please confirm and try again.");
    }
    if (status === "invalid_customer") {
      throw new Error(
        "This card is is not assigned to the customer selected. Please confirm and try again."
      );
    }
    if (status === "card_cancelled") {
      throw new Error(
        "This card is cancelled and cannot be used at the moment. Please confirm and try again."
      );
    }
    if (status === "card_used") {
      throw new Error(
        "This card has already been used. Please confirm and try again."
      );
    }
    throw new Error(
      "Something went wrong while verying your card! Please try again later."
    );
  };
  const saveCardSettings = async (values) => {
    const response = await axios.post(API_PATH.cards, {
      saveSettings: "",
      ...values,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error("Something went wrong! Please try again later.");
    }
  };
  const assignCardCustomer = async (customer, branch, card) => {
    const response = await axios.post(API_PATH.cards, {
      assignCard: "",
      customer: JSON.stringify(customer),
      branch,
      card,
    });
    const { status } = response.data;
    if (status === "invalid_customer") {
      throw new Error(
        "The customer does not exists or is invalid! Please confirm and try again."
      );
    }
    if (status === "invalid_card") {
      throw new Error(
        "The card you entered does not exists or is invalid! Please confirm and try again."
      );
    }
    if (status === "customer_card_exists") {
      throw new Error(
        "The customer already has an active loyalty card. Please cancel the existing card before assigning a new one."
      );
    }
    if (status !== "success") {
      throw new Error("Something went wrong! Please try again later.");
    }
  };

  return (
    <AuthContext.Provider
      // eslint-disable-next-line
      value={{
        ...state,
        method: "jwt",
        updatePurchaseReceipt,
        updatePurchaseProducts,
        uploadEcomImage,
        saveToken,
        updatePurchase,
        updatePurchaseStatus,
        updateDamagedStatus,
        checkCard,
        assignCardCustomer,
        saveCardSettings,
        getCardSettings,
        requestCard,
        getCardsDefault,
        getFeaturedProductsPlans,
        getCards,
        getGeneralAnalysis,
        getStoreAnalysis,
        exportPaymentAnalysis,
        getPaymentTable,
        getPaymentAnalysis,
        switchBranch,
        signup,
        checkDomain,
        login,
        logout,
        addDiscrepancyReport,
        checkEmail,
        setPassword,
        updateSettings,
        sendPasswordVerify,
        getRoles,
        addBranch,
        addCategory,
        addDiscountCode,
        addAdmin,
        getBranches,
        getPermissions,
        addRole,
        getCategories,
        addProduct,
        getJobs,
        addStaff,
        getTypes,
        getBanks,
        getAccountName,
        getCustomers,
        getCompanies,
        getCompany,
        addCustomer,
        addCompany,
        getProducts,
        getDisputeProducts,
        generate,
        generateInvoice,
        uploadSales,
        editSale,
        editDiscrepancyReport,
        editInvoice,
        getSales,
        getOrderDetails,
        getInvoice,
        addReturned,
        addDebtor,
        addDamaged,
        uploadReceipt,
        uploadAttachment,
        addPurchase,
        addOnline,
        updateOnline,
        updateOnlineStatus,
        updateOnlineProducts,
        getAdmins,
        getStaff,
        addExpense,
        getProduct,
        updateProduct,
        updateEcomProduct,
        discountProducts,
        updateProductVarieties,
        transferProductQuantity,
        updateProductSpecifications,
        updateProductQuantity,
        updateProductStatus,
        updateProductEcommerceStatus,
        getAdmin,
        updateAdmin,
        updateAdminPassword,
        updateAdminStatus,
        updateAdminRole,
        updateAdminPhoto,
        getRole,
        updateRole,
        updateRoleStatus,
        getStaffs,
        updateStaff,
        updateStaffStatus,
        updateStaffPhoto,
        updateStaffNOK,
        getBranch,
        updateBranch,
        updateBranchStatus,
        getProductCategories,
        updateCategory,
        updateCategoryStatus,
        getDiscountCode,
        updateDiscountCodeStatus,
        updateDiscountCode,
        getAllCustomers,
        updateCustomer,
        updateCompany,
        updateCustomerStatus,
        getSale,
        getPaySale,
        getReturned,
        updateReturned,
        updateReturnedStatus,
        updateReturnedProducts,
        getDebtor,
        updateDebtor,
        updateDebtorStatus,
        updateDebtorProducts,
        getDamagedProduct,
        updateDamaged,
        getPurchase,
        getDiscrepancyReport,
        downloadProductsHistories,
        downloadProductHistory,
        getOnlineSale,
        forgotPassword,
        resetPassword,
        updateSettingsAccount,
        updateSettingsOther,
        getPlans,
        processSubscription,
        addCard,
        removeCard,
        changeSubscription,
        cancelSubscription,
        activateSubscription,
        addSend,
        topupSMS,
        uploadImport,
        setImport,
        setExport,
        checkPriceChangePerm,
        confirmCheckoutTransfer,
        addSupport,
        sendDiscrepancyReport,
        sendInvoice,
        sendQuote,
        sendOrder,
        sendOrderUpdate,
        confirmInvoicePay,
        addDebtOwed,
        addDebtPayment,
        removeDebtOwed,
        removeDebtPayment,
        sendVerifyCode,
        verifyAccount,
        updateCompliance,
        updateEcommerce,
        updateEcommerceCustomizations,
        sendDNSRecords,
        verifyWebsite,
        verifyExternalWebsite,
        verifyDNS,
        verifyExternalDNS,
        initialize,
        loginToken,
        checkScan,
        searchBarcode,
        getMessage,
        getEndofDayReport,
        generateQuote,
        generateWaybill,
        editQuote,
        editWaybill,
        getQuotation,
        getWaybill,
        sendWaybill,
        uploadProductPhotos,
        addProductPhotos,
        featureProducts,
        addOffers,
        cancelOffer,
        getRemoteOfflineData,
        authenticateDevice,
        updateMilestone,
        deleteMilestone,
        completeMilestone,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
