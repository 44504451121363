import PropTypes from "prop-types";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
// material
import { Card, Alert, TextField, Stack, CardHeader, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { CheckPerm } from "../../../utils/apis";

// ----------------------------------------------------------------------
ProductQtyMinus.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function ProductQtyMinus({ myProfile, uid, fetch }) {
  const { updateProductQuantity } = useAuth();
  const hasPermission = CheckPerm("update_products_quantity");
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const Schema = Yup.object().shape({
    quantity: Yup.string().required("Quantity is required"),
  });

  const formik = useFormik({
    initialValues: {
      quantity: "",
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        if (myProfile.quantity >= parseInt(values.quantity, 10)) {
          await updateProductQuantity(values.quantity, uid, 0);
          enqueueSnackbar("Product's quantity data has been updated!", {
            variant: "success",
          });
          if (isMountedRef.current) {
            setSubmitting(false);
          }
          fetch();
        } else {
          throw new Error(
            "The number specified is higher than the available quantity."
          );
        }
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        px: 3,
        pb: 3,
      }}
    >
      <CardHeader
        title="Remove from Product Quantity"
        sx={{ color: "error.main" }}
      />
      <Box sx={{ p: { md: 3, xs: 1 } }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}
              <TextField
                fullWidth
                label="Number to remove from Stock"
                type="number"
                {...getFieldProps("quantity")}
                error={Boolean(touched.quantity && errors.quantity)}
                helperText={touched.quantity && errors.quantity}
                disabled={!hasPermission}
              />
            </Stack>
            {hasPermission && (
              <LoadingButton
                color="error"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Update
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
