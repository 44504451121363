import { isString } from "lodash";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
// material
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import {
  Box,
  Typography,
  Link,
  Button,
  Stack,
  Divider,
  Alert,
} from "@mui/material";
//
import { MBreadcrumbs } from "./@material-extend";
import { getSupportLinks } from "../utils/jwt";

// ----------------------------------------------------------------------
HeaderBreadcrumbs.propTypes = {
  links: PropTypes.array,
  action: PropTypes.node,
  isBack: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  moreLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sx: PropTypes.object,
};
const useBasePath = (location, params) =>
  Object.values(params).reduce(
    (path, param) => path.replace(`/${param}`, ""),
    location.pathname
  );
export default function HeaderBreadcrumbs({
  links,
  action,
  heading,
  moreLink = "" || [],
  sx,
  isBack,
  ...other
}) {
  const navigate = useNavigate();
  const [link, setLink] = useState("");
  const location = useLocation();
  const params = useParams();
  const currentPath = useBasePath(location, params);

  useEffect(() => {
    try {
      const links = getSupportLinks();
      let linkData = {};
      if (links) {
        linkData = JSON.parse(links);
      }
      const link = Object.keys(linkData).filter((page) =>
        currentPath.includes(page)
      );
      if (link.length > 0) {
        setLink(linkData[link[0]] || "");
      }
    } catch (err) {
      // console.log(err);
    }
    return () => {
      setLink("");
    };
  }, [currentPath]);

  return (
    <Box sx={{ mb: 5, ...sx }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Stack
            divider={<Divider orientation="vertical" flexItem />}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={1}
          >
            {isBack && (
              <Button
                color="inherit"
                startIcon={<KeyboardBackspaceIcon />}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </Button>
            )}
            <Typography variant="h4" gutterBottom>
              {heading}
            </Typography>
          </Stack>
          {links && <MBreadcrumbs links={links} {...other} />}
        </Box>
        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>

      <Box sx={{ mt: 2 }}>
        {isString(moreLink) ? (
          <Link href={moreLink} target="_blank" variant="body2">
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              sx={{ display: "table" }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
      {link && (
        <Box>
          <Alert
            severity="info"
            variant="outlined"
            sx={{
              flexDirection: { md: "row", xs: "column" },
              "& .MuiAlert-icon": {
                display: { md: "flex", xs: "none" },
              },
            }}
            action={
              <Button
                color="inherit"
                size="small"
                variant="text"
                href={link}
                target="_blank"
                startIcon={<PlayArrowOutlinedIcon fontSize="small" />}
              >
                Learn more
              </Button>
            }
          >
            <strong>Need help?</strong> Click the link to learn on how to use
            this section.
          </Alert>
        </Box>
      )}
    </Box>
  );
}
