/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { merge } from "lodash";
import PropTypes from "prop-types";
// material
import { useTheme, styled } from "@mui/material/styles";
import { Card, CardHeader } from "@mui/material";
// utils
import { fCurrency } from "../../../utils/formatNumber";
//
import { BaseOptionChart } from "../../charts";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(2),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

Donut.propTypes = {
  labels: PropTypes.array,
  title: PropTypes.string,
  total: PropTypes.number,
  data: PropTypes.array,
};
export default function Donut({ labels, title, total, data }) {
  const theme = useTheme();

  const chartOptions = merge(BaseOptionChart(), {
    labels,
    legend: { floating: true, horizontalAlign: "center" },
    fill: {
      type: "gradient",
      gradient: {
        colorStops: [
          [
            {
              offset: 0,
              color: theme.palette.primary.light,
            },
            {
              offset: 100,
              color: theme.palette.primary.main,
            },
          ],
          [
            {
              offset: 0,
              color: theme.palette.warning.light,
            },
            {
              offset: 100,
              color: theme.palette.warning.main,
            },
          ],
        ],
      },
    },
    plotOptions: {
      radialBar: {
        hollow: { size: "68%" },
        dataLabels: {
          value: {
            offsetY: 16,
            formatter: (val) => fCurrency(val),
          },
          total: {
            formatter: () => fCurrency(total),
          },
        },
      },
    },
  });
  const [Chart, setChart] = useState(null);
  useEffect(() => {
    const importChart = async () => {
      const chart = await import("react-apexcharts");
      setChart(chart);
    };
    importChart();
    return () => {
      setChart(null);
    };
  }, []);

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        height: "100%",
      }}
    >
      <CardHeader title={title} />
      <ChartWrapperStyle dir="ltr">
        {Boolean(Chart) && (
          <Chart.default
            type="radialBar"
            series={data}
            options={chartOptions}
            height={310}
          />
        )}
      </ChartWrapperStyle>
    </Card>
  );
}
