import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOnline: true,
  isSyncing: false,
  isFetching: false,
  error: false,
};

const slice = createSlice({
  name: "offline",
  initialState,
  reducers: {
    setOnline(state, action) {
      state.isOnline = action.payload;
    },
    setIsFetching(state, action) {
      state.error = false;
      state.isFetching = action.payload;
    },
    setIsSyncing(state, action) {
      state.error = false;
      state.isSyncing = action.payload;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isSyncing = false;
      state.error = action.payload;
    },
    //  SORT & FILTER POSTS
    sortBy(state, action) {
      state.sortBy = action.payload;
    },
    filter(state, action) {
      state.filters.method = action.payload.method;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filter, sortBy } = slice.actions;
export function setOnline(status) {
  return (dispatch) => {
    try {
      dispatch(slice.actions.setOnline(status));
    } catch (error) {
      // console.log(error);
    }
  };
}
export function setFetching(status) {
  return (dispatch) => {
    try {
      dispatch(slice.actions.setIsFetching(status));
    } catch (error) {
      // console.log(error);
    }
  };
}
export function setSyncing(status) {
  return (dispatch) => {
    try {
      dispatch(slice.actions.setIsSyncing(status));
    } catch (error) {
      // console.log(error);
    }
  };
}
export function setHasError(status) {
  return (dispatch) => {
    try {
      dispatch(slice.actions.hasError(status));
    } catch (error) {
      // console.log(error);
    }
  };
}
