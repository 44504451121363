import PropTypes from "prop-types";
import { useState } from "react";
import { paramCase } from "change-case";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import {
  Link,
  Card,
  Typography,
  CardHeader,
  Stack,
  DialogActions,
  Dialog,
  DialogContent,
  TextField,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Button,
} from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { LoadingButton } from "@mui/lab";
import BadgeIcon from "@mui/icons-material/Badge";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CategoryIcon from "@mui/icons-material/Category";
import MoneyIcon from "@mui/icons-material/Money";
import Label from "../../Label";
import { fCurrency, fNumber, isNumeric } from "../../../utils/formatNumber";
import { STAFF_STATUS, CheckPerm } from "../../../utils/apis";
import { fDate } from "../../../utils/formatTime";
import useAuth from "../../../hooks/useAuth";
import { PATH_DASHBOARD } from "../../../routes/paths";

StaffAbout.propTypes = {
  profile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};

ChangeStatus.propTypes = {
  handleStatusUpdate: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  handleStatus: PropTypes.func,
  onClose: PropTypes.func,
  status: PropTypes.string,
};
function ChangeStatus({
  open,
  loading,
  handleStatusUpdate,
  handleStatus,
  status,
  onClose,
}) {
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      onClose={onClose}
      sx={{ textAlign: "center" }}
    >
      <DialogTitle>Change Staff's Status</DialogTitle>
      <DialogContent sx={{ width: { xs: "100%", md: 400 } }}>
        <DialogContentText sx={{ mb: 5 }}>
          Please set the new status below
        </DialogContentText>
        <TextField
          select
          sx={{ width: { xs: "100%", md: 300 } }}
          label="New Status"
          value={status}
          onChange={handleStatus}
        >
          {STAFF_STATUS.map((stat) => (
            <MenuItem key={stat.value} value={stat.value}>
              {stat.label}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <LoadingButton
            fullWidth
            loading={loading}
            variant="contained"
            onClick={handleStatusUpdate}
          >
            Update
          </LoadingButton>
          <Button fullWidth onClick={onClose} color="inherit">
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default function StaffAbout({ profile, uid, fetch }) {
  const hasPermission = CheckPerm("update_staff");
  const {
    branch,
    branchName,
    email,
    dateAdded,
    phone,
    dateUpdated,
    status,
    gender,
    employed,
    dob,
    salary,
    address,
    marital,
    origin,
    job,
    dateLeft,
    totalExpenses,
    numSales,
    totalSales,
  } = profile;
  const statusText = STAFF_STATUS[parseInt(status, 10)];
  const { updateStaffStatus } = useAuth();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newStatus, setNewStatus] = useState(String(status));
  const { enqueueSnackbar } = useSnackbar();

  const handleStatus = (event) => {
    setNewStatus(event.target.value);
  };
  const handleStatusUpdate = async () => {
    if (newStatus) {
      try {
        setLoading(true);
        await updateStaffStatus(newStatus, uid);
        setOpen(false);
        setNewStatus("");
        enqueueSnackbar("Staff's status has been updated!", {
          variant: "success",
        });
        fetch();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
      setLoading(false);
    } else {
      enqueueSnackbar(`Please select a new status to proceed!`, {
        variant: "error",
      });
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
      }}
    >
      <CardHeader title="Details" />
      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction="row">
          <BadgeIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            <Label color={statusText.color}>{statusText.label}</Label>
            {hasPermission && (
              <span>
                &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                <Link
                  variant="subtitle2"
                  color="error.main"
                  component="button"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Change
                </Link>
              </span>
            )}
          </Typography>
        </Stack>
        <Stack direction="row">
          <EmailIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            <Link
              variant="subtitle2"
              color="text.primary"
              component={RouterLink}
              to={`${PATH_DASHBOARD.misc.send}/${email}`}
            >
              {email}
            </Link>
          </Typography>
        </Stack>
        <Stack direction="row">
          <PhoneAndroidIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            <Link
              variant="subtitle2"
              color="text.primary"
              component={RouterLink}
              to={`${PATH_DASHBOARD.misc.send}/${phone}`}
            >
              {phone}
            </Link>
          </Typography>
        </Stack>
        <Stack direction="row">
          <CategoryIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Job:&nbsp;&nbsp;
            {job}
          </Typography>
        </Stack>
        {branchName !== "" && (
          <Stack direction="row">
            <BusinessCenterIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              Branch:&nbsp;&nbsp;{" "}
              {branch ? (
                <Link
                  variant="subtitle2"
                  color="text.primary"
                  component={RouterLink}
                  to={`${PATH_DASHBOARD.subpages.branch}/${branch}/${paramCase(
                    branchName
                  )}`}
                >
                  {branchName}
                </Link>
              ) : (
                branchName
              )}
            </Typography>
          </Stack>
        )}
        <Stack direction="row">
          <MoneyIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Gender:&nbsp;&nbsp;{" "}
            <strong>
              {gender ? (gender === "M" && "Male") || "Female" : ""}
            </strong>
          </Typography>
        </Stack>
        <Stack direction="row">
          <CategoryIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Salary:&nbsp;&nbsp;
            {isNumeric(salary) ? fCurrency(salary) : ""}
          </Typography>
        </Stack>
        <Stack direction="row">
          <CategoryIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Address:&nbsp;&nbsp;
            {address}
          </Typography>
        </Stack>
        <Stack direction="row">
          <CategoryIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Marital Status:&nbsp;&nbsp;
            {marital}
          </Typography>
        </Stack>
        <Stack direction="row">
          <CategoryIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            State of origin:&nbsp;&nbsp;
            {origin}
          </Typography>
        </Stack>
        <Stack direction="row">
          <CreditCardIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Total Expenses:&nbsp;&nbsp;
            {fCurrency(totalExpenses)}
          </Typography>
        </Stack>
        <Stack direction="row">
          <CreditCardIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Total Sales:&nbsp;&nbsp;
            {fCurrency(totalSales)}
          </Typography>
        </Stack>
        <Stack direction="row">
          <MoneyIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            No. of Sales:&nbsp;&nbsp;
            {fNumber(numSales)}
          </Typography>
        </Stack>
        {dob && dob !== "0000-00-00" && (
          <Stack direction="row">
            <MoneyIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              Date of Birth:{" "}
              {dob !== "0000-00-00" ? fDate(dob) : "Not Specified"}
            </Typography>
          </Stack>
        )}
        {employed && employed !== "0000-00-00" && (
          <Stack direction="row">
            <MoneyIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              Employed on{" "}
              {employed !== "0000-00-00" ? fDate(employed) : "Not Specified"}
            </Typography>
          </Stack>
        )}
        {dateLeft && (
          <Stack direction="row">
            <MoneyIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              Date Left:{" "}
              {dateLeft !== "0000-00-00" ? fDate(dateLeft) : "Not Specified"}
            </Typography>
          </Stack>
        )}
        <Stack direction="row">
          <CalendarTodayIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Added on{" "}
            {dateAdded !== "0000-00-00" ? fDate(dateAdded) : "Not Specified"}
          </Typography>
        </Stack>
        <Stack direction="row">
          <CalendarTodayIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Last updated on{" "}
            {dateUpdated !== "0000-00-00"
              ? fDate(dateUpdated)
              : "Not Specified"}
          </Typography>
        </Stack>
      </Stack>
      {hasPermission && (
        <ChangeStatus
          open={open}
          loading={loading}
          handleStatus={handleStatus}
          status={newStatus}
          handleStatusUpdate={handleStatusUpdate}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </Card>
  );
}
