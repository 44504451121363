import { sentenceCase } from "change-case";
import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import { useTheme } from "@mui/material/styles";
import {
  Card,
  Stack,
  Container,
  Typography,
  Link,
  TableContainer,
  TablePagination,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "../../../redux/store";
import { getProductReviews } from "../../../redux/slices/product";
import { deleteReview } from "../../../redux/slices/review";
import { fDate } from "../../../utils/formatTime";
import { iff, CircularIndeterminate, ShowError } from "../../../utils/loader";
// components
import Scrollbar from "../../Scrollbar";
import SearchNotFound from "../../SearchNotFound";
import { ListHead, ListToolbar, MoreMenu } from "../tables";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { RATINGS_NUMBERS } from "../../../utils/apis";
import Label from "../../Label";

const TABLE_HEAD = [
  { id: "", label: "Order", alignRight: false },
  { id: "customer", label: "Customer", alignRight: false },
  { id: "rating", label: "Rating", alignRight: false },
  { id: "", label: "Review", alignRight: false },
  { id: "date", label: "Date Added", alignRight: false },
  { id: "" },
];
const TYPE = "review";

ReviewsLog.propTypes = {
  pid: PropTypes.string.isRequired,
};
export default function ReviewsLog({ pid }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { reviews, isReviewLoading, reviewError, reviewTotal } = useSelector(
    (state) => state.product
  );
  const [page, setPage] = useState(0);
  const [rating, setRating] = useState("");
  const [order, setOrder] = useState("desc");
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("id");
  const { enqueueSnackbar } = useSnackbar();

  const filter = useCallback(() => {
    dispatch(
      getProductReviews(search, pid, rating, page, rowsPerPage, order, orderBy)
    );
  }, [page, pid, rating, search, rowsPerPage, order, orderBy, dispatch]);

  useEffect(() => {
    filter();
  }, [filter]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDeleteReview = (reviewId, reasons) => {
    const reviews = reviewId;
    if (!reviews) {
      enqueueSnackbar(`Please select atleast a review to delete.`, {
        variant: "error",
      });
      return;
    }
    dispatch(deleteReview(reviews, String(reasons)));
    enqueueSnackbar(`Your request has been sent!`, {
      variant: "success",
    });
  };

  const isNotFound = reviews.length === 0;
  const isSorting = false;

  return (
    <Container maxWidth="lg">
      <Stack spacing={2}>
        {reviews !== null && !reviewError ? (
          <Card
            variant="outlined"
            sx={{
              border: "0.4px solid",
              borderColor: "background.border",
              boxShadow:
                "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
              borderRadius: "8px",
            }}
          >
            <ListToolbar
              filterName={search}
              onFilterName={(e) => setSearch(e.target.value || "")}
              numSelected={0}
              type={TYPE}
              onFilterChange={() => setPage(0)}
              setRating={setRating}
              rating={rating}
            />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={-1}
                    onRequestSort={handleRequestSort}
                    numSelected={0}
                  />
                  <TableBody>
                    {reviews.map((row, index) => {
                      // eslint-disable-next-line
                      const {
                        orderId,
                        reviewId,
                        customerName,
                        rating,
                        dateAdded,
                        review,
                      } = row;
                      const stat = RATINGS_NUMBERS.filter(
                        (stat) =>
                          parseInt(stat.value, 10) === parseInt(rating, 10)
                      )[0];
                      return (
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          category="checkbox"
                        >
                          <TableCell component="th" scope="row" padding="none">
                            <Box
                              sx={{
                                py: 2,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Link
                                underline="none"
                                component={RouterLink}
                                to={`${PATH_DASHBOARD.ecommerce.orders}/${orderId}`}
                              >
                                <Typography variant="subtitle2" noWrap>
                                  #{orderId}
                                </Typography>
                              </Link>
                            </Box>
                          </TableCell>
                          <TableCell style={{ minWidth: 160 }}>
                            {sentenceCase(customerName)}
                          </TableCell>
                          <TableCell>
                            <Label
                              variant={
                                theme.palette.mode === "light"
                                  ? "ghost"
                                  : "filled"
                              }
                              color={(stat && stat.color) || "warning"}
                            >
                              {sentenceCase((stat && stat.label) || "")}
                            </Label>
                          </TableCell>
                          <TableCell style={{ minWidth: 160 }}>
                            {sentenceCase(review)}
                          </TableCell>
                          <TableCell style={{ minWidth: 120 }}>
                            {fDate(dateAdded)}
                          </TableCell>
                          <TableCell align="right">
                            <MoreMenu
                              onDelete={(reasons) =>
                                handleDeleteReview(reviewId, reasons)
                              }
                              Name={reviewId}
                              Mid={reviewId}
                              type={TYPE}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={TABLE_HEAD.length}>
                          <Box sx={{ py: 3 }}>
                            <SearchNotFound
                              searchWord="Review"
                              isSorting={isSorting}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={reviewTotal}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        ) : (
          iff(
            isReviewLoading,
            <CircularIndeterminate />,
            <ShowError message={reviewError} tryAgain />
          )
        )}
      </Stack>
    </Container>
  );
}
