import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { sentenceCase } from "change-case";
// material
import { Box, Grid, Card, Button, Typography, Stack } from "@mui/material";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { fCurrency, fNumber } from "../../../utils/formatNumber";
import { fDate } from "../../../utils/formatTime";
import { ShowError } from "../../../utils/loader";
import { CheckPerm, RETURNED_STATUS } from "../../../utils/apis";
import Label from "../../Label";
// ----------------------------------------------------------------------

SingleCard.propTypes = {
  product: PropTypes.object,
  type: PropTypes.string,
  pid: PropTypes.string,
};

function SingleCard({ product, type, pid }) {
  const { products, total, status, rid, dateReturned } = product;
  const productsArray = JSON.parse(products);
  const prd = productsArray.filter((p) => p.pid === pid)[0];
  const numProducts = productsArray.length;
  const stat = RETURNED_STATUS.filter((debt) => debt.value === status)[0];
  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        p: 3,
      }}
    >
      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {type === "product" ? fNumber(prd.quantity) : fNumber(numProducts)}{" "}
          {type === "product"
            ? (parseInt(prd.quantity, 10) > 1 && "quantities") || "quantity"
            : (numProducts > 1 && "products") || "product"}
        </Typography>
        <Box
          sx={{ display: "flex", alignItems: "start", flexDirection: "column" }}
        >
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            Total:{" "}
            {type === "product" ? fCurrency(prd.total) : fCurrency(total)}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            Date: {fDate(dateReturned)}
          </Typography>
        </Box>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          sx={{ mt: 2 }}
          component={RouterLink}
          to={`${PATH_DASHBOARD.subpages.returnedProduct}/${rid}`}
        >
          View
        </Button>
      </Box>
      <Label variant="ghost" color={stat.color}>
        {sentenceCase(stat.label)}
      </Label>
    </Card>
  );
}

Returned.propTypes = {
  products: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  pid: PropTypes.string,
};

export default function Returned({ products, type, pid }) {
  const hasPermission = CheckPerm("view_returned_products");
  return (
    <Box sx={{ mt: 5 }}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          Returned Products
        </Typography>
        {hasPermission && (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            component={RouterLink}
            to={PATH_DASHBOARD.business.returnedProducts}
          >
            View All
          </Button>
        )}
      </Stack>
      {products.length > 0 ? (
        <Grid container spacing={3}>
          {products.map((product, index) => (
            <Grid key={index} item xs={12} md={4}>
              <SingleCard pid={pid} product={product} type={type} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <ShowError
          message={
            new Error(
              `This ${type} does not have any returned product attached at the moment!`
            )
          }
        />
      )}
    </Box>
  );
}
