import PropTypes from "prop-types";
import { useEffect, useState } from "react";
// @mui
import {
  Button,
  Dialog,
  Stack,
  Typography,
  CircularProgress,
  ListItemButton,
  Box,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { fCurrency } from "../../../utils/formatNumber";
import useAuth from "../../../hooks/useAuth";

// ----------------------------------------------------------------------

VerifyTransaction.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  amount: PropTypes.number,
  onSelect: PropTypes.func,
};
export default function VerifyTransaction({ open, onClose, onSelect, amount }) {
  const { confirmCheckoutTransfer } = useAuth();
  const [payments, setPayments] = useState([]);
  const [error, setError] = useState("");
  useEffect(() => {
    async function get() {
      try {
        setError("");
        setPayments(await confirmCheckoutTransfer(amount));
      } catch (err) {
        setError(err.message);
      }
    }
    if (open) {
      const retryCheck = setInterval(get, 5000);
      return () => {
        clearInterval(retryCheck);
      };
    }
    return () => {};
  }, [open, amount, confirmCheckoutTransfer]);
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ py: 2.5, px: 3 }}
      >
        <Typography variant="h6"> Checking for {fCurrency(amount)}</Typography>
        {(payments.length > 0 || error) && (
          <CircularProgress thickness={4.0} size={12} />
        )}
      </Stack>
      <DialogContent>
        <Stack direction="column" alignItems="center" justifyContent="center">
          {payments.length > 0 && (
            <Typography variant="subtitle1" sx={{ mb: 4, textAlign: "center" }}>
              Please select the customer transaction to verify.
            </Typography>
          )}
          {payments.length > 0 ? (
            <Box sx={{ width: 1 }}>
              {payments.map((payment, index) => (
                <ListItemButton
                  divider
                  onClick={() => {
                    onSelect(payment);
                  }}
                  key={index}
                >
                  {payment.accountNumber} - {payment.accountName}
                </ListItemButton>
              ))}
            </Box>
          ) : (
            (error && (
              <Typography variant="body2" sx={{ textAlign: "center" }}>
                {error}
              </Typography>
            )) || <CircularProgress sx={{ m: "0 auto" }} />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Cancel Verification
        </Button>
      </DialogActions>
    </Dialog>
  );
}
