// components
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import StorefrontIcon from "@mui/icons-material/Storefront";
import CategoryIcon from "@mui/icons-material/Category";
import PeopleIcon from "@mui/icons-material/People";
import InventoryIcon from "@mui/icons-material/Inventory";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BusinessIcon from "@mui/icons-material/Business";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import BlockIcon from "@mui/icons-material/Block";
import WaterDamageIcon from "@mui/icons-material/WaterDamage";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ReportIcon from "@mui/icons-material/Report";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import SendIcon from "@mui/icons-material/Send";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import DevicesIcon from "@mui/icons-material/Devices";
import PaymentsIcon from "@mui/icons-material/Payments";
import SummarizeIcon from "@mui/icons-material/Summarize";
import DescriptionIcon from "@mui/icons-material/Description";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import ReviewsOutlinedIcon from "@mui/icons-material/ReviewsOutlined";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";

// routes
import { PATH_DASHBOARD } from "../../routes/paths";

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.general.root,
        icon: <DashboardIcon />,
      },
      {
        title: "My Profile",
        path: PATH_DASHBOARD.general.profile,
        icon: <AccountCircleIcon />,
      },
      {
        title: "Store Settings",
        path: PATH_DASHBOARD.general.settings,
        icon: <SettingsIcon />,
      },
    ],
  },
  {
    subheader: "administrative",
    items: [
      {
        title: "Administrators",
        path: PATH_DASHBOARD.administrative.admins,
        icon: <SupervisorAccountIcon />,
      },
      {
        title: "Administrative Roles",
        path: PATH_DASHBOARD.administrative.roles,
        icon: <AdminPanelSettingsIcon />,
      },
      {
        title: "Staff",
        path: PATH_DASHBOARD.administrative.staff,
        icon: <DashboardIcon />,
      },
      {
        title: "Discrepancy Reports",
        path: PATH_DASHBOARD.administrative.discrepancyReports,
        icon: <FlagCircleIcon />,
      },
    ],
  },
  {
    subheader: "business",
    items: [
      {
        title: "Branches",
        path: PATH_DASHBOARD.business.branches,
        icon: <StorefrontIcon />,
      },
      {
        title: "Product Categories",
        path: PATH_DASHBOARD.business.categories,
        icon: <CategoryIcon />,
      },
      {
        title: "Customers",
        path: PATH_DASHBOARD.business.customers,
        icon: <PeopleIcon />,
      },
      {
        title: "Companies",
        path: PATH_DASHBOARD.business.companies,
        icon: <BusinessIcon />,
      },
      {
        title: "Products",
        path: PATH_DASHBOARD.business.products,
        icon: <InventoryIcon />,
      },
      {
        title: "Generate Sale",
        path: PATH_DASHBOARD.business.addSale,
        icon: <PointOfSaleIcon />,
      },
      {
        title: "Sales",
        path: PATH_DASHBOARD.business.sales,
        icon: <ReceiptIcon />,
      },
      {
        title: "Invoices",
        path: PATH_DASHBOARD.business.invoices,
        icon: <DescriptionIcon />,
      },
      {
        title: "Quotations",
        path: PATH_DASHBOARD.business.quotations,
        icon: <RequestQuoteIcon />,
      },
      {
        title: "Returned Products",
        path: PATH_DASHBOARD.business.returnedProducts,
        icon: <AssignmentReturnIcon />,
      },
      {
        title: "Debtors",
        path: PATH_DASHBOARD.business.debtors,
        icon: <BlockIcon />,
      },
      {
        title: "Damaged Products",
        path: PATH_DASHBOARD.business.damagedProducts,
        icon: <WaterDamageIcon />,
      },
      {
        title: "Purchases",
        path: PATH_DASHBOARD.business.purchases,
        icon: <ShoppingBasketIcon />,
      },
      {
        title: "Online Sales",
        path: PATH_DASHBOARD.business.onlineSales,
        icon: <ShoppingCartCheckoutIcon />,
      },
      {
        title: "Gift & Loyalty Cards",
        path: PATH_DASHBOARD.business.cards,
        icon: <CardMembershipIcon />,
      },
      {
        title: "Waybills",
        path: PATH_DASHBOARD.business.waybills,
        icon: <LocalShippingIcon />,
      },
    ],
  },
  {
    subheader: "ecommerce",
    isNew: true,
    items: [
      {
        title: "Orders",
        path: PATH_DASHBOARD.ecommerce.orders,
        icon: <LocalMallOutlinedIcon />,
      },
      {
        title: "Ratings & Reviews",
        path: PATH_DASHBOARD.ecommerce.reviews,
        icon: <ReviewsOutlinedIcon />,
      },
      {
        title: "Discount Codes",
        path: PATH_DASHBOARD.ecommerce.discountCodes,
        icon: <DiscountOutlinedIcon />,
      },
      {
        title: "Featured Products",
        path: PATH_DASHBOARD.ecommerce.FeaturedProducts,
        icon: <BookmarkBorderOutlinedIcon />,
      },
      {
        title: "Special Offers",
        path: PATH_DASHBOARD.ecommerce.specialOffers,
        icon: <LocalOfferOutlinedIcon />,
      },
    ],
  },
  {
    subheader: "accounting",
    items: [
      {
        title: "Expenses",
        path: PATH_DASHBOARD.accounting.expenses,
        icon: <CreditCardIcon />,
        permission: "view_expenses",
      },
      {
        title: "Report",
        path: PATH_DASHBOARD.accounting.report,
        icon: <SummarizeIcon />,
      },
      {
        title: "Store Analysis",
        path: PATH_DASHBOARD.accounting.storeAnalysis,
        icon: <AssessmentIcon />,
        permission: "view_store_analysis",
      },
      {
        title: "Payment Analysis",
        path: PATH_DASHBOARD.accounting.paymentAnalysis,
        icon: <AssessmentIcon />,
      },
      {
        title: "Subscription",
        path: PATH_DASHBOARD.accounting.subscription,
        icon: <LoyaltyIcon />,
      },
      {
        title: "Billing History",
        path: PATH_DASHBOARD.accounting.billings,
        icon: <AssessmentIcon />,
      },
      {
        title: "Bank Transfers",
        path: PATH_DASHBOARD.accounting.bankTransfers,
        icon: <PaymentsIcon />,
      },
    ],
  },
  {
    subheader: "miscellaneous",
    items: [
      {
        title: "SMS / Email",
        path: PATH_DASHBOARD.misc.send,
        icon: <SendIcon />,
      },
      {
        title: "Notifications",
        path: PATH_DASHBOARD.misc.notifications,
        icon: <NotificationsIcon />,
      },
      {
        title: "Devices",
        path: PATH_DASHBOARD.misc.devices,
        icon: <DevicesIcon />,
      },
      {
        title: "Support Messages",
        path: PATH_DASHBOARD.misc.support,
        icon: <ContactSupportIcon />,
      },
      {
        title: "Activity Logs",
        path: PATH_DASHBOARD.misc.log,
        icon: <ReportIcon />,
      },
      {
        title: "Import / Export",
        path: PATH_DASHBOARD.misc.importExport,
        icon: <ImportExportIcon />,
      },
    ],
  },
];

export default sidebarConfig;
