import { capitalCase } from "change-case";
import { useEffect, useState, useCallback } from "react";
import InfoIcon from "@mui/icons-material/Info";
import ReportIcon from "@mui/icons-material/Report";
import ReceiptIcon from "@mui/icons-material/Receipt";
// material
import { styled } from "@mui/material/styles";
import { Tab, Box, Card, Tabs, Container } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// hooks
import useAuth from "../../../hooks/useAuth";
import useSettings from "../../../hooks/useSettings";
// components
import Page from "../../Page";
import HeaderBreadcrumbs from "../../HeaderBreadcrumbs";
import { iff, CircularIndeterminate, ShowError } from "../../../utils/loader";
import { SingleData, Cover, Log, Sales } from "../singles";

// ----------------------------------------------------------------------

const TabsWrapperStyle = styled("div")(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: "100%",
  display: "flex",
  position: "absolute",
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up("sm")]: {
    justifyContent: "center",
  },
  [theme.breakpoints.up("md")]: {
    justifyContent: "flex-end",
    paddingRight: theme.spacing(3),
  },
}));
const TYPE = "admin";
export default function ProfileAdmin() {
  const { user, getAdmin } = useAuth();
  const uid = user && user.user ? user.user.uid : "";
  const title = "My Profile";
  const { themeStretch } = useSettings();
  const [admin, setAdmin] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [currentTab, setCurrentTab] = useState("details");

  const get = useCallback(() => {
    async function fetch() {
      try {
        setIsLoading(true);
        setAdmin(await getAdmin(uid));
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    }
    fetch();
  }, [uid, getAdmin]);

  useEffect(() => {
    get();
  }, [get]);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const TABS = [
    {
      value: "details",
      icon: <InfoIcon sx={{ width: 20, heigth: 20 }} />,
      component: (
        <SingleData
          myProfile={admin}
          rid={uid}
          fetch={get}
          type={TYPE}
          isProfile
        />
      ),
    },
    {
      value: "activity log",
      icon: <ReportIcon sx={{ width: 20, heigth: 20 }} />,
      component: <Log activities={admin.logs || []} />,
    },
    {
      value: "Sales",
      icon: <ReceiptIcon sx={{ width: 20, heigth: 20 }} />,
      component: <Sales sales={admin.salesLog || []} type={TYPE} />,
    },
  ];

  return (
    <Page title={title}>
      {admin.fname && !isLoading && !error ? (
        <Container maxWidth={themeStretch ? false : "lg"}>
          <HeaderBreadcrumbs
            heading={`${admin.fname} ${admin.lname}`}
            links={[
              { name: "Dashboard", href: PATH_DASHBOARD.root },
              { name: `My Profile` },
            ]}
          />
          <Card
            variant="outlined"
            sx={{
              border: "0.4px solid",
              borderColor: "background.border",
              boxShadow:
                "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
              borderRadius: "8px",
              mb: 3,
              height: 280,
              position: "relative",
            }}
          >
            <Cover myProfile={admin} uid={uid} type={TYPE} />
            <TabsWrapperStyle>
              <Tabs
                value={currentTab}
                scrollButtons="auto"
                variant="scrollable"
                allowScrollButtonsMobile
                onChange={handleChangeTab}
              >
                {TABS.map((tab) => (
                  <Tab
                    disableRipple
                    key={tab.value}
                    value={tab.value}
                    icon={tab.icon}
                    label={capitalCase(tab.value)}
                  />
                ))}
              </Tabs>
            </TabsWrapperStyle>
          </Card>

          {TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </Container>
      ) : (
        iff(
          isLoading,
          <CircularIndeterminate />,
          <ShowError message={error} tryAgain />
        )
      )}
    </Page>
  );
}
