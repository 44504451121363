import PropTypes from "prop-types";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Card,
  Alert,
  TextField,
  Stack,
  CardHeader,
  Box,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { CheckPerm, GENDERS } from "../../../utils/apis";

// ----------------------------------------------------------------------
AdminUpdate.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
  isProfile: PropTypes.bool,
};
export default function AdminUpdate({ myProfile, uid, fetch, isProfile }) {
  const { updateAdmin, getBranches } = useAuth();
  const [BRANCHES, setBRANCHES] = useState([]);
  const hasPermission = CheckPerm("update_administrators");
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const Schema = Yup.object().shape({
    fname: Yup.string().required("First name is required"),
    lname: Yup.string().required("Last name is required"),
    email: Yup.string().email("Email must be a valid email address"),
  });

  const formik = useFormik({
    initialValues: {
      fname: myProfile.fname,
      lname: myProfile.lname,
      email: myProfile.email,
      phone: myProfile.phone,
      gender: myProfile.gender,
      branch: {},
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateAdmin(values, uid);
        enqueueSnackbar("Admin's data has been updated!", {
          variant: "success",
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        fetch();
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;

  useEffect(() => {
    async function get() {
      const branches = await getBranches();
      setBRANCHES(branches);
      const selectedBranch = branches.filter(
        (branch) => branch.bid === myProfile.branch
      );
      setFieldValue(
        "branch",
        selectedBranch.length > 0 ? selectedBranch[0] : {}
      );
    }
    get();
  }, [getBranches, myProfile.branch, setFieldValue]);

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        px: 3,
        pb: 3,
      }}
    >
      <CardHeader title={`${isProfile ? "My" : "Administrator"} Information`} />
      <Box sx={{ p: { md: 3, xs: 1 } }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  disabled={!hasPermission && !isProfile}
                  fullWidth
                  label="First Name"
                  {...getFieldProps("fname")}
                  error={Boolean(touched.fname && errors.fname)}
                  helperText={touched.fname && errors.fname}
                />
                <TextField
                  disabled={!hasPermission && !isProfile}
                  fullWidth
                  label="Last Name"
                  {...getFieldProps("lname")}
                  error={Boolean(touched.lname && errors.lname)}
                  helperText={touched.lname && errors.lname}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  autoComplete="username"
                  type="email"
                  fullWidth
                  label="Email Address"
                  disabled
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
                <TextField
                  autoComplete="username"
                  type="tel"
                  fullWidth
                  disabled={!hasPermission && !isProfile}
                  label="Phone Number"
                  {...getFieldProps("phone")}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <Autocomplete
                  fullWidth
                  options={BRANCHES}
                  disabled={!hasPermission || isProfile}
                  {...getFieldProps("branch")}
                  name="branch"
                  value={values.branch}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setFieldValue("branch", newValue);
                    }
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Branch"
                      margin="none"
                      error={Boolean(touched.branch && errors.branch)}
                      helperText={touched.branch && errors.branch}
                    />
                  )}
                />
                <TextField
                  select
                  sx={{ width: { xs: "100%", md: 500 } }}
                  label="Gender"
                  disabled={!hasPermission && !isProfile}
                  value={values.gender}
                  onChange={(event) => {
                    setFieldValue("gender", event.target.value);
                  }}
                  error={Boolean(touched.gender && errors.gender)}
                  helperText={touched.gender && errors.gender}
                >
                  {GENDERS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            </Stack>
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Update
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
