import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Link,
  TableContainer,
  TablePagination,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "../../../redux/store";
import { getProductLog } from "../../../redux/slices/product";
import { fDateTime } from "../../../utils/formatTime";
import { iff, CircularIndeterminate, ShowError } from "../../../utils/loader";
// components
import Scrollbar from "../../Scrollbar";
import SearchNotFound from "../../SearchNotFound";
import { ListHead, ListToolbar } from "../tables";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { ASSETS_PATH } from "../../../utils/apis";
import useAuth from "../../../hooks/useAuth";

const TABLE_HEAD = [
  { id: "doer", label: "Performed By", alignRight: false },
  { id: "message", label: "Message", alignRight: false },
  { id: "date", label: "Date / Time", alignRight: false },
  { id: "" },
];
const TYPE = "product_log";

ProductLog.propTypes = {
  pid: PropTypes.string.isRequired,
};
export default function ProductLog({ pid }) {
  const dispatch = useDispatch();
  const { downloadProductHistory } = useAuth();
  const { log, isLogLoading, logError, logTotal } = useSelector(
    (state) => state.product
  );
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("id");
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const filter = useCallback(() => {
    dispatch(getProductLog(search, pid, page, rowsPerPage, order, orderBy));
  }, [page, pid, search, rowsPerPage, order, orderBy, dispatch]);

  useEffect(() => {
    filter();
  }, [filter]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const downloadHistory = async () => {
    try {
      setIsDownloadLoading(true);
      const file = await downloadProductHistory(pid);
      enqueueSnackbar("File generated successfully!", {
        variant: "success",
      });
      window.open(`${ASSETS_PATH.histories}${file}`);
    } catch (err) {
      // console.log(err);
      enqueueSnackbar(
        err.message ||
          "An error occured while generating file! Please try again later.",
        {
          variant: "error",
        }
      );
    }
    setIsDownloadLoading(false);
  };

  const isNotFound = log.length === 0;
  const isSorting = false;

  return (
    <Container maxWidth="lg">
      <Stack spacing={2}>
        {log !== null && !logError ? (
          <Card
            variant="outlined"
            sx={{
              border: "0.4px solid",
              borderColor: "background.border",
              boxShadow:
                "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
              borderRadius: "8px",
            }}
          >
            <Box sx={{ mt: 5, textAlign: "right", mx: 2 }}>
              <LoadingButton
                loading={isDownloadLoading}
                onClick={downloadHistory}
              >
                Download History
              </LoadingButton>
            </Box>
            <ListToolbar
              filterName={search}
              onFilterName={(e) => setSearch(e.target.value || "")}
              numSelected={0}
              type={TYPE}
            />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={-1}
                    onRequestSort={handleRequestSort}
                    numSelected={0}
                  />
                  <TableBody>
                    {log.map((row, index) => {
                      // eslint-disable-next-line
                      const { id, type, adminName, admin, message, dateAdded } =
                        row;
                      return (
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          log="checkbox"
                        >
                          <TableCell component="th" scope="row" padding="none">
                            <Box
                              sx={{
                                py: 2,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Link
                                underline="none"
                                component={RouterLink}
                                to={`${PATH_DASHBOARD.subpages.admin}/${admin}`}
                              >
                                <Typography variant="subtitle2" noWrap>
                                  {adminName}
                                </Typography>
                              </Link>
                            </Box>
                          </TableCell>
                          <TableCell>{message}</TableCell>
                          <TableCell style={{ minWidth: 120 }}>
                            {fDateTime(dateAdded)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={TABLE_HEAD.length}>
                          <Box sx={{ py: 3 }}>
                            <SearchNotFound
                              searchWord="Log"
                              isSorting={isSorting}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={logTotal}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        ) : (
          iff(
            isLogLoading,
            <CircularIndeterminate />,
            <ShowError message={logError} tryAgain />
          )
        )}
      </Stack>
    </Container>
  );
}
