import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import { useSnackbar } from "notistack";
// @mui
import {
  Box,
  Stack,
  Button,
  Divider,
  Typography,
  CircularProgress,
  InputAdornment,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  DialogContentText,
  Tooltip,
  Avatar,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// utils
import { ASSETS_PATH, CheckPerm, getFirstCharacter } from "../../../utils/apis";
import { fNumber, isNumeric } from "../../../utils/formatNumber";
import useAuth from "../../../hooks/useAuth";

RequestAccess.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleGrant: PropTypes.func,
  setValue: PropTypes.func,
  handleShowPassword: PropTypes.func,
  values: PropTypes.object,
  loading: PropTypes.bool,
  showPassword: PropTypes.bool,
};
function RequestAccess({
  open,
  handleClose,
  handleGrant,
  values,
  setValue,
  showPassword,
  handleShowPassword,
  loading,
}) {
  return (
    <Dialog sx={{ textAlign: "center" }} open={open} onClose={handleClose}>
      <DialogTitle>Grant Permission to update price.</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 5 }}>
          Please enter your email address and password to proceed.
        </DialogContentText>
        <Stack spacing={{ xs: 2, md: 3 }}>
          <TextField
            type="text"
            value={values.username}
            autoComplete="username"
            name="username"
            fullWidth
            label="Username / Email address"
            onChange={setValue}
          />
          <TextField
            fullWidth
            value={values.password}
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            name="password"
            label="Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={setValue}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ mx: "auto" }}>
        <Button onClick={handleClose} color="inherit" sx={{ mr: 5 }}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          onClick={handleGrant}
          color="warning"
          variant="contained"
        >
          Grant Access
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

InvoiceNewEditDetails.propTypes = {
  formik: PropTypes.object,
  isEdit: PropTypes.bool,
  isOnline: PropTypes.bool,
  branch: PropTypes.object,
  payable: PropTypes.number,
};
export default function InvoiceNewEditDetails({
  formik,
  branch,
  payable,
  isOnline,
}) {
  const { values, setFieldValue } = formik;
  const { getProducts, user, checkPriceChangePerm } = useAuth();
  const { settings } = user;
  const canEditPrice = parseInt(
    settings.filter((setting) => setting.name === "allow_edit_price")[0].value,
    10
  );
  const allowOutOfStock =
    parseInt(
      settings.filter((setting) => setting.name === "out_of_stock_sales")[0]
        .value,
      10
    ) === 1;
  const hasEditPricePerm = CheckPerm("allow_product_price_change");
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [allowEdit, setAllowEdit] = useState(hasEditPricePerm);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginValues, setLoginValues] = useState({
    username: "",
    password: "",
  });

  const branchId = branch && branch.bid ? branch.bid : "";
  const { enqueueSnackbar } = useSnackbar();
  const filter = useCallback(() => {
    if (currentIndex >= 0) {
      (async () => {
        setFieldValue(`products[${currentIndex}].loading`, true);
        const data = await getProducts(search, branchId, false, isOnline);
        setOptions(data);
        setFieldValue(`products[${currentIndex}].loading`, false);
      })();
    }
  }, [search, branchId, getProducts, isOnline, currentIndex, setFieldValue]);

  const setAmount = useCallback(() => {
    setFieldValue(`amount`, payable);
  }, [payable, setFieldValue]);

  useEffect(() => {
    filter();
  }, [filter]);

  useEffect(() => {
    setAmount();
  }, [setAmount]);

  const handleSelect = (product, index) => {
    const { products } = values;
    if (product) {
      products[index].pid = product.pid;
      products[index].name = product.name;
      products[index].quantity =
        parseInt(product.qty, 10) < 1 && !allowOutOfStock ? "0" : "1";
      products[index].qty = parseInt(product.qty, 10);
      products[index].price = parseFloat(product.selling_price);
      products[index].cost = parseFloat(product.cost_price);
      products[index].sell = parseFloat(product.selling_price);
      products[index].isService = product.isService;
      products[index].milestones =
        parseInt(product.isService, 10) === 1
          ? JSON.parse(product.attributes)
          : [];
      setFieldValue(`products`, products);
      setOptions([]);
      if (
        parseInt(product.isService, 10) === 0 &&
        parseInt(product.qty, 10) <= 0
      ) {
        enqueueSnackbar(`${product.name}'s stock is empty! Please top up.`, {
          variant: "warning",
        });
      }
    }
  };
  const handleAdd = () => {
    const { products } = values;
    products.push({
      pid: "",
      name: "",
      warranty: "",
      quantity: "",
      cost: "",
      sell: "",
      price: "",
      total: "",
      editPrice: false,
      isService: "0",
      qty: 0,
      loading: false,
      open: false,
    });
    setFieldValue("products", products);
  };

  const handleRemove = (index) => {
    const { products } = values;
    if (index > -1) {
      products.splice(index, 1);
      setFieldValue("products", products);
      setCurrentIndex(-1);
    }
  };

  const handleSearch = (search, index) => {
    setCurrentIndex(index);
    setSearch(search);
  };
  const handlePriceEdit = (index) => {
    if (!allowEdit) {
      setOpen(true);
      return;
    }
    const isEdit = values.products[index].editPrice;
    if (isEdit) {
      // revert back
      setFieldValue(`products[${index}].price`, values.products[index].sell);
    }
    setFieldValue(`products[${index}].editPrice`, !isEdit);
    setFieldValue("priceChange", user.user.uid);
  };
  const handleGrant = async () => {
    try {
      const { username, password } = loginValues;
      if (username && password) {
        setLoading(true);
        const admin = await checkPriceChangePerm(loginValues);
        setLoginValues({ username: "", password: "" });
        setAllowEdit(true);
        setOpen(false);
        setFieldValue("priceChange", admin);
        enqueueSnackbar(`Access Granted! You can now edit prices.`, {
          variant: "success",
        });
      } else {
        enqueueSnackbar(
          `Please enter a username or email address and password to proceed.`,
          { variant: "error" }
        );
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
    setLoading(false);
  };
  const handleValuesChange = (e) => {
    const { name, value } = e.target;
    setLoginValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ color: "text.disabled", mb: 3 }}>
        Products:
      </Typography>
      <Stack
        divider={<Divider flexItem sx={{ borderStyle: "dashed" }} />}
        spacing={3}
      >
        {values.products.map((item, index) => (
          <Stack
            key={index}
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            sx={{ width: 1 }}
          >
            <Stack>
              <Autocomplete
                sx={{ maxWidth: { md: 300 }, width: 300 }}
                size="small"
                value={item}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.name
                }
                options={options}
                onChange={(event, newValue) => {
                  handleSelect(newValue, index);
                }}
                isOptionEqualToValue={(option, value) =>
                  parseInt(option.pid, 10) === parseInt(value.pid, 10)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Product"
                    variant="outlined"
                    onChange={(ev) => {
                      // dont fire API if the user delete or not entered anything
                      if (ev.target.value !== "" || ev.target.value !== null) {
                        setFieldValue(
                          `products[${index}].name`,
                          ev.target.value
                        );
                        handleSearch(ev.target.value, index);
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {values.products[index].loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Box
                      sx={{
                        py: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        src={
                          option.featuredPhoto
                            ? `${ASSETS_PATH.products}${option.featuredPhoto}`
                            : ""
                        }
                        sx={{ mr: 1, bgcolor: "background.neutral" }}
                      >
                        {getFirstCharacter(option.name)}
                      </Avatar>
                      <Typography variant="body2">{option.name}</Typography>
                    </Box>
                  </li>
                )}
              />
              {values.products[index].name &&
                parseInt(values.products[index].isService, 10) === 0 && (
                  <Typography
                    variant="caption"
                    sx={{ pt: 1, color: "text.secondary" }}
                  >
                    Available Quantity: {fNumber(values.products[index].qty)}
                  </Typography>
                )}
            </Stack>
            <Stack spacing={1}>
              <TextField
                size="small"
                type="number"
                sx={{ maxWidth: { md: 200 }, width: "100%" }}
                value={values.products[index].quantity}
                name={`products.${index}.quantity`}
                label="Qty"
                onChange={(event) => {
                  const qty = isNumeric(event.target.value)
                    ? Number(event.target.value)
                    : "";
                  const isProduct =
                    parseInt(values.products[index].isService, 10) === 0;
                  if (isProduct && isNumeric(qty) && qty < 0.5) {
                    enqueueSnackbar(`Quantity cannot be lower than 1 or 1/2.`, {
                      variant: "error",
                    });
                    setFieldValue(`products[${index}].quantity`, 1);
                    return;
                  }
                  const prodQty = values.products[index].qty;
                  if (isProduct && isNumeric(qty) && qty > prodQty) {
                    const productName = values.products[index].name;
                    enqueueSnackbar(
                      `${productName}'s quantity (${prodQty}) is too low for the requested quantity.`,
                      { variant: "warning" }
                    );
                    if (!allowOutOfStock) {
                      // prevent product being sold
                      setFieldValue(`products[${index}].quantity`, prodQty);
                      return;
                    }
                  }
                  setFieldValue(`products[${index}].quantity`, qty);
                }}
              />
              <Stack
                direction="row"
                spacing={0.5}
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    const qty = Number(values.products[index].quantity ?? "0");
                    const prodQty = values.products[index].qty;
                    const isProduct =
                      parseInt(values.products[index].isService, 10) === 0;
                    if (isProduct && isNumeric(qty) && qty + 1 > prodQty) {
                      const productName = values.products[index].name;
                      enqueueSnackbar(
                        `${productName}'s quantity (${prodQty}) is too low for the requested quantity.`,
                        { variant: "warning" }
                      );
                      if (!allowOutOfStock) {
                        // prevent product being sold
                        setFieldValue(`products[${index}].quantity`, prodQty);
                        return;
                      }
                    }
                    setFieldValue(
                      `products[${index}].quantity`,
                      Number(values.products[index].quantity ?? "0") + 1
                    );
                  }}
                >
                  +1
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    const qty = Number(values.products[index].quantity ?? "0");
                    const prodQty = values.products[index].qty;
                    const isProduct =
                      parseInt(values.products[index].isService, 10) === 0;
                    if (isProduct && isNumeric(qty) && qty + 0.5 > prodQty) {
                      const productName = values.products[index].name;
                      enqueueSnackbar(
                        `${productName}'s quantity (${prodQty}) is too low for the requested quantity.`,
                        { variant: "warning" }
                      );
                      if (!allowOutOfStock) {
                        // prevent product being sold
                        setFieldValue(`products[${index}].quantity`, prodQty);
                        return;
                      }
                    }
                    setFieldValue(
                      `products[${index}].quantity`,
                      Number(values.products[index].quantity ?? "0") + 0.5
                    );
                  }}
                >
                  +1/2
                </Button>
              </Stack>
            </Stack>

            <Stack direction="column" alignItems="flex-start" spacing={0}>
              <TextField
                size="small"
                type="number"
                sx={{
                  width: { md: "auto", xs: "100%" },
                }}
                disabled={!values.products[index].editPrice}
                name={`products.${index}.price`}
                value={values.products[index].price}
                label="Unit Price"
                onChange={(event) =>
                  setFieldValue(
                    `products[${index}].price`,
                    Number(event.target.value)
                  )
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">&#8358;</InputAdornment>
                  ),
                }}
              />
              {canEditPrice === 1 && values.products[index].price !== "" && (
                <Button
                  sx={{ justifyContent: "flex-start" }}
                  size="small"
                  color="warning"
                  onClick={() => handlePriceEdit(index)}
                >
                  {(values.products[index].editPrice && "Revert") || "Edit"}
                </Button>
              )}
            </Stack>

            <TextField
              disabled
              size="small"
              name={`products.${index}.total`}
              label="Total"
              value={fNumber(
                values.products[index].quantity * values.products[index].price
              )}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">&#8358;</InputAdornment>
                ),
              }}
            />
            <TextField
              size="small"
              type="text"
              value={values.products[index].warranty}
              name={`products.${index}.warranty`}
              label="Warranty"
              onChange={(event) =>
                setFieldValue(`products[${index}].warranty`, event.target.value)
              }
            />
            <Button
              size="small"
              color="error"
              sx={{ alignSelf: "flex-start" }}
              startIcon={<DeleteIcon />}
              onClick={() => handleRemove(index)}
            >
              Remove
            </Button>
          </Stack>
        ))}
      </Stack>

      <Divider sx={{ my: 6, borderStyle: "dashed" }} />

      <Stack
        spacing={2}
        direction={{ xs: "column-reverse", md: "row" }}
        alignItems={{ xs: "flex-start", md: "start" }}
      >
        <Button
          size="small"
          startIcon={<AddIcon />}
          onClick={handleAdd}
          sx={{ flexShrink: 0 }}
        >
          Add product
        </Button>
        <Stack
          spacing={4}
          justifyContent="flex-end"
          direction="column"
          sx={{ width: 1 }}
        >
          <Stack
            spacing={2}
            justifyContent="flex-end"
            direction={{ xs: "column", md: "row" }}
            sx={{ width: 1 }}
          >
            <Tooltip
              placement="top-end"
              title="You can enter the percentage discount here for the total amount available here. The amount discounted will be automatically calculated and displayed in the Discount Field."
            >
              <TextField
                size="small"
                label="Discount (%)"
                name="discount_percent"
                value={values.discountPercent}
                onChange={(event) => {
                  const percent = Number(event.target.value);
                  if (percent <= 100) {
                    const total = parseFloat(
                      values.products
                        .map((obj) => Number(obj.price) * Number(obj.quantity))
                        .reduce((partialSum, a) => partialSum + a, 0) || 0
                    );
                    const discount =
                      Math.round((percent / 100) * Number(total) * 100) / 100;
                    setFieldValue("discount", discount);
                    setFieldValue("discountPercent", percent);
                  } else {
                    enqueueSnackbar(
                      `Discount percentage cannot be greater than 100.`,
                      {
                        variant: "warning",
                      }
                    );
                  }
                }}
                sx={{ maxWidth: { md: 200 } }}
              />
            </Tooltip>
            <TextField
              size="small"
              label="Discount"
              name="discount_flat"
              disabled={Boolean(values.discountPercent)}
              value={values.discount}
              onChange={(event) =>
                setFieldValue("discount", Number(event.target.value))
              }
              sx={{ maxWidth: { md: 200 } }}
            />

            <TextField
              size="small"
              label="VAT"
              type="number"
              name="tax"
              value={values.tax.toString()}
              onChange={(event) =>
                setFieldValue("tax", Number(event.target.value))
              }
              sx={{ maxWidth: { md: 200 } }}
            />
            <TextField
              size="small"
              label="Tip"
              type="number"
              name="tip"
              value={values.tip.toString()}
              onChange={(event) =>
                setFieldValue("tip", Number(event.target.value))
              }
              helperText="*Specify tip amount here."
              sx={{ maxWidth: { md: 200 } }}
            />
          </Stack>
          <Stack
            spacing={2}
            justifyContent="flex-end"
            direction={{ xs: "column", md: "row" }}
            sx={{ width: 1 }}
          >
            <TextField
              disabled
              size="small"
              name="total"
              label="Total Payable"
              value={fNumber(payable)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">&#8358;</InputAdornment>
                ),
              }}
            />
            <TextField
              size="small"
              name="amount"
              type="number"
              label={values.status === "paid" ? "Amount Paid" : "Amount To Pay"}
              value={values.amount.toString()}
              onChange={(event) => {
                const amount = parseFloat(event.target.value || 0);
                setFieldValue("amount", Number(event.target.value));
                setFieldValue("change", Number(amount - payable));
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">&#8358;</InputAdornment>
                ),
              }}
            />
            <TextField
              size="small"
              disabled
              name="change"
              label="Change"
              value={fNumber(values.change)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">&#8358;</InputAdornment>
                ),
              }}
            />
          </Stack>
        </Stack>
      </Stack>
      <RequestAccess
        open={open}
        handleClose={() => setOpen(false)}
        handleGrant={handleGrant}
        values={loginValues}
        loading={loading}
        setValue={handleValuesChange}
        showPassword={showPassword}
        handleShowPassword={() => setShowPassword(!showPassword)}
      />
    </Box>
  );
}
