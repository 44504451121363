import PropTypes from "prop-types";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Card,
  Alert,
  TextField,
  Stack,
  CardHeader,
  Box,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { CheckPerm } from "../../../utils/apis";

// ----------------------------------------------------------------------
ProductQtyTransfer.propTypes = {
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function ProductQtyTransfer({ uid, fetch }) {
  const { transferProductQuantity, getBranches, currentBranch } = useAuth();
  const hasPermission = CheckPerm("transfer_products_quantity");
  const isMountedRef = useIsMountedRef();
  const [branches, setBranches] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const Schema = Yup.object().shape({
    quantity: Yup.string().required("Quantity is required"),
    branch: Yup.object().required("Branch is required"),
  });

  const formik = useFormik({
    initialValues: {
      quantity: "",
      branch: {},
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await transferProductQuantity(
          values.quantity,
          values.branch.bid || "",
          uid
        );
        enqueueSnackbar(
          `Product's quantity has been transferred! to ${values.branch.name}!`,
          {
            variant: "success",
          }
        );
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        fetch();
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  useEffect(() => {
    async function fetch() {
      const branches = await getBranches();
      setBranches(
        branches.filter(
          (obj) => obj.bid !== "" && obj.bid !== currentBranch.bid
        )
      );
    }
    fetch();
    return () => {
      setBranches([]);
    };
  }, [getBranches, currentBranch]);

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        px: 3,
        pb: 3,
      }}
    >
      <CardHeader
        title="Transfer Product's Quantity"
        subheader="Transfer some or all quantity of this product to another branch."
      />
      <Box sx={{ p: { md: 3, xs: 1 } }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}
              <Autocomplete
                disabled={!hasPermission}
                sx={{ maxWidth: 1, width: 1 }}
                value={values.branch}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.name || ""
                }
                options={branches}
                onChange={(event, newValue) => {
                  if (newValue && newValue.bid) {
                    setFieldValue("branch", newValue);
                  }
                }}
                isOptionEqualToValue={(option, value) =>
                  parseInt(option.bid, 10) === parseInt(value.bid, 10)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Transfer to:"
                    variant="outlined"
                    error={Boolean(touched.branch && errors.branch)}
                    helperText={
                      (touched.branch && errors.branch) ||
                      "Select branch to transfer the product to."
                    }
                  />
                )}
              />
              <TextField
                fullWidth
                label="Quantity to Transfer"
                {...getFieldProps("quantity")}
                error={Boolean(touched.quantity && errors.quantity)}
                helperText={touched.quantity && errors.quantity}
                disabled={!hasPermission}
              />
            </Stack>
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Transfer
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
