import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { paramCase } from "change-case";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import {
  Link,
  Card,
  Typography,
  CardHeader,
  Stack,
  DialogActions,
  Dialog,
  DialogContent,
  TextField,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Button,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import BadgeIcon from "@mui/icons-material/Badge";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CategoryIcon from "@mui/icons-material/Category";
import MoneyIcon from "@mui/icons-material/Money";
import WcIcon from "@mui/icons-material/Wc";
import Label from "../../Label";
import { isNumeric, fNumber, fCurrency } from "../../../utils/formatNumber";
import { ADMIN_STATUS, CheckPerm } from "../../../utils/apis";
import { fDate } from "../../../utils/formatTime";
import useAuth from "../../../hooks/useAuth";
import { PATH_DASHBOARD } from "../../../routes/paths";

AdminAbout.propTypes = {
  profile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
  isProfile: PropTypes.bool,
};

ChangeStatus.propTypes = {
  handleStatusUpdate: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  handleStatus: PropTypes.func,
  onClose: PropTypes.func,
  status: PropTypes.string,
};
function ChangeStatus({
  open,
  loading,
  handleStatusUpdate,
  handleStatus,
  status,
  onClose,
}) {
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      onClose={onClose}
      sx={{ textAlign: "center" }}
    >
      <DialogTitle>Change Admin's Status</DialogTitle>
      <DialogContent sx={{ width: { xs: "100%", md: 400 } }}>
        <DialogContentText sx={{ mb: 5 }}>
          Please set the new status below
        </DialogContentText>
        <TextField
          select
          sx={{ width: { xs: "100%", md: 300 } }}
          label="New Status"
          value={status}
          onChange={handleStatus}
        >
          {ADMIN_STATUS.map((stat) => (
            <MenuItem key={stat.value} value={stat.value}>
              {stat.label}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <LoadingButton
            fullWidth
            loading={loading}
            variant="contained"
            onClick={handleStatusUpdate}
          >
            Update
          </LoadingButton>
          <Button fullWidth onClick={onClose} color="inherit">
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

ChangeRole.propTypes = {
  handleRoleUpdate: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  handleRoleChange: PropTypes.func,
  role: PropTypes.object,
  ROLES: PropTypes.array,
  onClose: PropTypes.func,
};
function ChangeRole({
  open,
  loading,
  handleRoleUpdate,
  handleRoleChange,
  role,
  onClose,
  ROLES,
}) {
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      onClose={onClose}
      sx={{ textAlign: "center" }}
    >
      <DialogTitle>Change Administrator's Role</DialogTitle>
      <DialogContent sx={{ width: { xs: "100%", md: 400 } }}>
        <DialogContentText sx={{ mb: 5 }}>
          Please select the new role below
        </DialogContentText>
        <Stack direction="column" spacing={2}>
          <Autocomplete
            fullWidth
            options={ROLES.filter((r) => r.rid !== "")}
            name="role"
            value={role}
            isOptionEqualToValue={(option, value) => option.rid === value.rid}
            onChange={(event, newValue) => {
              if (newValue) {
                handleRoleChange("role", newValue);
              }
            }}
            getOptionLabel={(option) => option.name || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Administrative Role"
                margin="none"
              />
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <LoadingButton
            fullWidth
            loading={loading}
            variant="contained"
            onClick={handleRoleUpdate}
          >
            Update
          </LoadingButton>
          <Button fullWidth onClick={onClose} color="inherit">
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default function AdminAbout({ profile, uid, fetch, isProfile }) {
  const hasPermission = CheckPerm("update_administrators");
  const {
    branch,
    branchName,
    role,
    roleName,
    email,
    dateAdded,
    phone,
    dateUpdated,
    status,
    sales,
    gender,
    totalAmountSold,
  } = profile;
  const statusText = ADMIN_STATUS[parseInt(status, 10)];
  const { updateAdminStatus, updateAdminRole, getRoles } = useAuth();
  const [open, setOpen] = useState(false);
  const [roleOpen, setRoleOpen] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [newRole, setNewRole] = useState({});
  const [loading, setLoading] = useState(false);
  const [ROLES, setROLES] = useState([]);
  const [newStatus, setNewStatus] = useState(String(status));
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setROLES([]);
    async function get() {
      setROLES(await getRoles());
    }
    get();
  }, [getRoles, roleOpen]);

  const handleStatus = (event) => {
    setNewStatus(event.target.value);
  };
  const handleStatusUpdate = async () => {
    if (newStatus) {
      try {
        setLoading(true);
        await updateAdminStatus(newStatus, uid);
        setOpen(false);
        setNewStatus("");
        enqueueSnackbar("Admin's status has been updated!", {
          variant: "success",
        });
        fetch();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
      setLoading(false);
    } else {
      enqueueSnackbar(`Please select a new status to proceed!`, {
        variant: "error",
      });
    }
  };

  const handleRoleChange = (name, value) => {
    setNewRole(value);
  };
  const handleRoleUpdate = async () => {
    if (newRole && newRole.rid) {
      try {
        setRoleLoading(true);
        await updateAdminRole(newRole.rid, uid);
        setOpen(false);
        setNewStatus("");
        enqueueSnackbar("Administrator's role has been updated!", {
          variant: "success",
        });
        fetch();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
      setRoleLoading(false);
    } else {
      enqueueSnackbar(`Please select a new role to proceed!`, {
        variant: "error",
      });
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
      }}
    >
      <CardHeader title="Details" />
      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction="row">
          <BadgeIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            <Label color={statusText.color}>{statusText.label}</Label>
            {hasPermission && !isProfile && (
              <span>
                &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                <Link
                  variant="subtitle2"
                  color="error.main"
                  component="button"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Change
                </Link>
              </span>
            )}
          </Typography>
        </Stack>
        <Stack direction="row">
          <EmailIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            <Link
              variant="subtitle2"
              color="text.primary"
              component={RouterLink}
              to={`${PATH_DASHBOARD.misc.send}/${email}`}
            >
              {email}
            </Link>
          </Typography>
        </Stack>
        <Stack direction="row">
          <PhoneAndroidIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            <Link
              variant="subtitle2"
              color="text.primary"
              component={RouterLink}
              to={`${PATH_DASHBOARD.misc.send}/${phone}`}
            >
              {phone}
            </Link>
          </Typography>
        </Stack>
        <Stack direction="row">
          <CategoryIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Role:&nbsp;&nbsp;
            <Link
              variant="subtitle2"
              color="text.primary"
              component={RouterLink}
              to={`${PATH_DASHBOARD.subpages.role}/${role}/${paramCase(
                roleName
              )}`}
            >
              {roleName}
            </Link>
            {hasPermission && !isProfile && (
              <span>
                &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                <Link
                  variant="subtitle2"
                  color="error.main"
                  component="button"
                  onClick={() => {
                    setRoleOpen(true);
                  }}
                >
                  Change
                </Link>
              </span>
            )}
          </Typography>
        </Stack>
        {branchName !== "" && (
          <Stack direction="row">
            <BusinessCenterIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              Branch:&nbsp;&nbsp;{" "}
              {branch ? (
                <Link
                  variant="subtitle2"
                  color="text.primary"
                  component={RouterLink}
                  to={`${PATH_DASHBOARD.subpages.branch}/${branch}/${paramCase(
                    branchName
                  )}`}
                >
                  {branchName}
                </Link>
              ) : (
                branchName
              )}
            </Typography>
          </Stack>
        )}
        <Stack direction="row">
          <WcIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Gender:&nbsp;&nbsp;{" "}
            <strong>
              {gender ? (gender === "M" && "Male") || "Female" : ""}
            </strong>
          </Typography>
        </Stack>
        <Stack direction="row">
          <MoneyIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Total Sales:&nbsp;&nbsp;{" "}
            <strong>{isNumeric(sales) ? fNumber(sales) : "0"}</strong>
          </Typography>
        </Stack>
        <Stack direction="row">
          <MoneyIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Total Amount Sold:&nbsp;&nbsp;{" "}
            <strong>{fCurrency(totalAmountSold || "0")}</strong>
          </Typography>
        </Stack>
        <Stack direction="row">
          <CalendarTodayIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Added on{" "}
            {dateAdded !== "0000-00-00" ? fDate(dateAdded) : "Not Specified"}
          </Typography>
        </Stack>
        <Stack direction="row">
          <CalendarTodayIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Last updated on{" "}
            {dateUpdated !== "0000-00-00"
              ? fDate(dateUpdated)
              : "Not Specified"}
          </Typography>
        </Stack>
      </Stack>
      {hasPermission && (
        <ChangeStatus
          open={open}
          loading={loading}
          handleStatus={handleStatus}
          status={newStatus}
          handleStatusUpdate={handleStatusUpdate}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
      {hasPermission && (
        <ChangeRole
          open={roleOpen}
          loading={roleLoading}
          handleRoleChange={handleRoleChange}
          ROLES={ROLES}
          role={newRole}
          onClose={() => {
            setRoleOpen(false);
          }}
          handleRoleUpdate={handleRoleUpdate}
        />
      )}
    </Card>
  );
}
