import PropTypes from "prop-types";
import { useState } from "react";
import { useSnackbar } from "notistack";
import {
  Link,
  Card,
  Typography,
  CardHeader,
  Stack,
  DialogActions,
  Dialog,
  DialogContent,
  TextField,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import BadgeIcon from "@mui/icons-material/Badge";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import NumbersIcon from "@mui/icons-material/Numbers";
import Label from "../../Label";
import { fCurrency, fNumber } from "../../../utils/formatNumber";
import {
  CheckPerm,
  DISCOUNT_CODES_TYPES,
  DISCOUNT_CODE_STATUS,
} from "../../../utils/apis";
import { fDate } from "../../../utils/formatTime";
import useAuth from "../../../hooks/useAuth";

DiscountCodeAbout.propTypes = {
  profile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};

ChangeStatus.propTypes = {
  handleStatusUpdate: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  handleStatus: PropTypes.func,
  onClose: PropTypes.func,
  status: PropTypes.string,
};
function ChangeStatus({
  open,
  loading,
  handleStatusUpdate,
  handleStatus,
  status,
  onClose,
}) {
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      onClose={onClose}
      sx={{ textAlign: "center" }}
    >
      <DialogTitle>Change Discount code's Status</DialogTitle>
      <DialogContent sx={{ width: { xs: "100%", md: 400 } }}>
        <DialogContentText sx={{ mb: 5 }}>
          Please set the new status below
        </DialogContentText>
        <TextField
          select
          sx={{ width: { xs: "100%", md: 300 } }}
          label="New Status"
          value={status}
          onChange={handleStatus}
        >
          {DISCOUNT_CODE_STATUS.map((stat) => (
            <MenuItem key={stat.value} value={stat.value}>
              {stat.label}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <LoadingButton
            fullWidth
            loading={loading}
            variant="contained"
            onClick={handleStatusUpdate}
          >
            Update
          </LoadingButton>
          <Button fullWidth onClick={onClose} color="inherit">
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
export default function DiscountCodeAbout({ profile, uid, fetch }) {
  const hasPermission = CheckPerm("update_product_categories");
  const {
    dateUpdated,
    code,
    amount,
    type,
    dateAdded,
    dateExpire,
    status,
    totalUsage,
    used,
  } = profile;
  const statusText = DISCOUNT_CODE_STATUS[parseInt(status, 10)];
  const { updateDiscountCodeStatus } = useAuth();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newStatus, setNewStatus] = useState(String(status));
  const { enqueueSnackbar } = useSnackbar();

  const handleStatus = (event) => {
    setNewStatus(event.target.value);
  };
  const handleStatusUpdate = async () => {
    if (newStatus) {
      try {
        setLoading(true);
        await updateDiscountCodeStatus(newStatus, uid);
        setOpen(false);
        setNewStatus("");
        enqueueSnackbar("Discount code's status has been updated!", {
          variant: "success",
        });
        fetch();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
      setLoading(false);
    } else {
      enqueueSnackbar(`Please select a new status to proceed!`, {
        variant: "error",
      });
    }
  };
  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
      }}
    >
      <CardHeader title="Details" />
      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography variant="body2">{code}</Typography>
        <Stack direction="row">
          <BadgeIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            <Label color={statusText.color}>{statusText.label}</Label>
            {hasPermission && (
              <span>
                &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                <Link
                  variant="subtitle2"
                  color="error.main"
                  component="button"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Change
                </Link>
              </span>
            )}
          </Typography>
        </Stack>
        <Stack direction="row">
          <NumbersIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Type:&nbsp;&nbsp;{" "}
            <strong>{DISCOUNT_CODES_TYPES[parseInt(type, 10)]}</strong>
          </Typography>
        </Stack>
        <Stack direction="row">
          <NumbersIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Flat Amount / Percentage Discount:&nbsp;&nbsp;{" "}
            <strong>
              {parseInt(type, 10) === 0
                ? fCurrency(amount)
                : `${fNumber(amount)}%`}
            </strong>
          </Typography>
        </Stack>
        <Stack direction="row">
          <NumbersIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            No. of Times Used:&nbsp;&nbsp; <strong>{fNumber(used)}</strong>
          </Typography>
        </Stack>
        <Stack direction="row">
          <NumbersIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Total Number of Usage:&nbsp;&nbsp;{" "}
            <strong>{fNumber(totalUsage)}</strong>
          </Typography>
        </Stack>
        <Stack direction="row">
          <CalendarTodayIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Added on{" "}
            {dateAdded !== "0000-00-00" ? fDate(dateAdded) : "Not Specified"}
          </Typography>
        </Stack>
        <Stack direction="row">
          <CalendarTodayIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Expires on{" "}
            {dateExpire !== "0000-00-00" ? fDate(dateExpire) : "Not Specified"}
          </Typography>
        </Stack>
        <Stack direction="row">
          <CalendarTodayIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Last updated on{" "}
            {dateUpdated !== "0000-00-00"
              ? fDate(dateUpdated)
              : "Not Specified"}
          </Typography>
        </Stack>
      </Stack>
      <ChangeStatus
        open={open}
        loading={loading}
        handleStatus={handleStatus}
        status={newStatus}
        handleStatusUpdate={handleStatusUpdate}
        onClose={() => {
          setOpen(false);
        }}
      />
    </Card>
  );
}
