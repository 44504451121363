import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    Button, Box, Divider,
    Typography,
    List,
    ListItemText,
    ListItemAvatar,
    ListItemButton
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Scrollbar from '../../components/Scrollbar';
import { fNumber } from '../../utils/formatNumber';
import MenuPopover from '../../components/MenuPopover';
import useAuth from '../../hooks/useAuth';
import { PATH_DASHBOARD } from '../../routes/paths';

FeatureItem.propTypes = {
    feature: PropTypes.object
};
function FeatureItem({ feature }) {
    const { hasNum, limit, title } = feature;

    return (
        <ListItemButton
            to="#"
            disableGutters
            component={RouterLink}
            sx={{
                py: 1.5,
                px: 2.5,
                mt: '1px'
            }}
        >
            <ListItemAvatar>
                <CheckIcon sx={{ width: 15, height: 15 }} />
            </ListItemAvatar>
            <ListItemText
                secondary={`${hasNum ? ((limit && fNumber(limit)) || "Unlimited") : ""} ${title}`}
            />
        </ListItemButton>
    );
}

export default function UpgradePopup() {
    const { user, getPlans } = useAuth();
    const { subscription } = user;
    const anchorRef = useRef(null);
    const navigate = useNavigate();
    const [isUpgrade, setIsUpgrade] = useState(false);
    const [features, setFeatures] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        async function get() {
            const status = parseInt(subscription.status, 10);
            if (status === 1) {
                if (subscription.plan !== "189768") {
                    setIsUpgrade(true);
                    const { plans } = await getPlans();
                    const fts = [];
                    const filteredPlans = subscription.plan === "189765" ? plans.filter((plan) => plan.pid !== subscription.plan) : [plans[2]];
                    if (filteredPlans.length > 0) {
                        filteredPlans.forEach((plan) => {
                            fts.push(...(plan.features));
                        });
                    }
                    const filteredFeatures = fts.filter((cur, index, array) => array.findIndex(f => f.code === cur.code) === index);
                    setFeatures(filteredFeatures);
                } else {
                    setIsUpgrade(false);
                }
            } else {
                setIsUpgrade(false);
            }
        }
        get();
    }, [setIsUpgrade, subscription, getPlans, setFeatures]);

    const handleClick = () => {
        setOpen(false);
        navigate(PATH_DASHBOARD.accounting.choosePlan);
    }

    return (
        isUpgrade && features && (
            <>
                <Button ref={anchorRef} onClick={() => { setOpen(true) }} color="success" variant={open ? 'contained' : 'outlined'} size="small" sx={{ borderRadius: 5 }}>Upgrade</Button>
                <MenuPopover open={open} onClose={() => { setOpen(false) }} anchorEl={anchorRef.current} sx={{ width: 360 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="subtitle1">Get more features!</Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                Upgrade your subscription and have access to:
                            </Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
                        <List disablePadding>
                            {features.map((feature, index) => (
                                <FeatureItem key={index} feature={feature} />
                            ))}
                        </List>
                    </Scrollbar>

                    <Divider />

                    <Box sx={{ p: 1 }}>
                        <Button fullWidth disableRipple onClick={handleClick}>
                            Upgrade Now
                        </Button>
                    </Box>
                </MenuPopover>
            </>

        )
    );
}