import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { API_PATH } from '../../utils/apis';

const initialState = {
    isLoading: false,
    error: false,
    deleteError: false,
    search: '',
    page: 0,
    perPage: 10,
    summary: {},
    total: 0,
    cards: [],
    sortBy: null,
    filters: {
        method: ''
    }
};

const slice = createSlice({
    name: 'card',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        removeDeleteError(state) {
            state.deleteError = false;
        },
        hasDeleteError(state, action) {
            state.isLoading = false;
            state.deleteError = action.payload;
        },
        getSuccess(state, action) {
            state.isLoading = false;
            state.cards = action.payload.data;
            state.summary = action.payload.summary;
            state.total = parseInt(action.payload.total, 10);
        },
        reloadSuccess(state) {
            state.isLoading = false;
            state.cards = [];
            state.summary = {};
            state.total = 0;
        },
        cancelledSuccess(state, action) {
            const cancelled = action.payload.split(",");
            state.cards = state.cards.map((obj) => { obj.status = cancelled.includes(obj.cid) ? "2" : obj.status; return obj; });
            state.isLoading = false;
        },
        activatedSuccess(state, action) {
            const activated = action.payload.split(",");
            state.cards = state.cards.map((obj) => { obj.status = activated.includes(obj.cid) ? "1" : obj.status; return obj; });
            state.isLoading = false;
        },
        //  SORT & FILTER POSTS
        sortBy(state, action) {
            state.sortBy = action.payload;
        },
        filter(state, action) {
            state.filters.method = action.payload.method;
        },
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {
    filter,
    sortBy
} = slice.actions;

export function getCards(search, type, cardType, stat, page, perpage, order, sortBy) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_PATH.cards, { type, cardType, page, status: stat, search, perpage, order, sortBy, });
            const { status, data, total, summary } = response.data;
            if (status === "success") {
                dispatch(slice.actions.getSuccess({ data, total, summary }));
            } else {
                dispatch(slice.actions.hasError(new Error("We cannot fetch your cards at the moment! Please check back later.")));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reloadCards() {
    return async (dispatch) => {
        dispatch(slice.actions.reloadSuccess());
    };
}

export function cancelCard(cancel) {
    return async (dispatch) => {
        dispatch(slice.actions.removeDeleteError());
        try {
            const response = await axios.post(API_PATH.cards, { cancel });
            if (response.data.status === "success") {
                dispatch(slice.actions.cancelledSuccess(cancel));
            } else if (response.data.status === "permission_denied") {
                dispatch(slice.actions.hasDeleteError(new Error("You do not have permission to perform this action!")));
            } else {
                dispatch(slice.actions.hasDeleteError(new Error("An error has occured! Please try again later.")));
            }
        } catch (error) {
            dispatch(slice.actions.hasDeleteError(error));
        }
    };
}

export function activateCard(activate) {
    return async (dispatch) => {
        dispatch(slice.actions.removeDeleteError());
        try {
            const response = await axios.post(API_PATH.cards, { activate });
            if (response.data.status === "success") {
                dispatch(slice.actions.activatedSuccess(activate));
            } else if (response.data.status === "permission_denied") {
                dispatch(slice.actions.hasDeleteError(new Error("You do not have permission to perform this action!")));
            } else {
                dispatch(slice.actions.hasDeleteError(new Error("An error has occured! Please try again later.")));
            }
        } catch (error) {
            dispatch(slice.actions.hasDeleteError(error));
        }
    };
}
