// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/";
const ROOTS_DASHBOARD = "/";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  Login: path(ROOTS_AUTH, "login"),
  signup: "https://selpay.co/signup",
  welcome: path(ROOTS_AUTH, "welcome"),
  forgotPassword: path(ROOTS_AUTH, "forgot-password"),
  resetPassword: path(ROOTS_AUTH, "reset-password"),
  verify: path(ROOTS_AUTH, "verify"),
  setPassword: path(ROOTS_AUTH, "set-password"),
};

export const PATH_PAGE = {
  about: "about",
  faqs: "faqs",
  page404: "404",
  errorOccured: "500",
  storeError: "store-error",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    root: path(ROOTS_DASHBOARD, ""),
    profile: path(ROOTS_DASHBOARD, "profile"),
    settings: path(ROOTS_DASHBOARD, "settings"),
    compliance: path(ROOTS_DASHBOARD, "settings/compliance"),
    account: path(ROOTS_DASHBOARD, "settings/account"),
    preferences: path(ROOTS_DASHBOARD, "settings/preferences"),
    ecommerce: path(ROOTS_DASHBOARD, "settings/ecommerce"),
  },
  administrative: {
    admins: path(ROOTS_DASHBOARD, "administrators"),
    addAdmin: path(ROOTS_DASHBOARD, "administrators/add"),
    roles: path(ROOTS_DASHBOARD, "roles"),
    addRole: path(ROOTS_DASHBOARD, "roles/add"),
    staff: path(ROOTS_DASHBOARD, "staff"),
    addStaff: path(ROOTS_DASHBOARD, "staff/add"),
    discrepancyReports: path(ROOTS_DASHBOARD, "discrepancy-reports"),
    adddiscrepancyReport: path(ROOTS_DASHBOARD, "discrepancy-reports/add"),
  },
  accounting: {
    expenses: path(ROOTS_DASHBOARD, "expenses"),
    addExpense: path(ROOTS_DASHBOARD, "expenses/add"),
    subscription: path(ROOTS_DASHBOARD, "subscription"),
    choosePlan: path(ROOTS_DASHBOARD, "choose-plan"),
    billings: path(ROOTS_DASHBOARD, "billings"),
    bankTransfers: path(ROOTS_DASHBOARD, "bank-transfers"),
    withdrawals: path(ROOTS_DASHBOARD, "withdrawals"),
    storeAnalysis: path(ROOTS_DASHBOARD, "store-analysis"),
    paymentAnalysis: path(ROOTS_DASHBOARD, "payment-analysis"),
    generalAnalysis: path(ROOTS_DASHBOARD, "general-analysis"),
    report: path(ROOTS_DASHBOARD, "report"),
  },
  misc: {
    send: path(ROOTS_DASHBOARD, "send"),
    addSend: path(ROOTS_DASHBOARD, "send/add"),
    log: path(ROOTS_DASHBOARD, "log"),
    devices: path(ROOTS_DASHBOARD, "devices"),
    notifications: path(ROOTS_DASHBOARD, "notifications"),
    importExport: path(ROOTS_DASHBOARD, "import-export"),
    support: path(ROOTS_DASHBOARD, "support"),
    sendSupport: path(ROOTS_DASHBOARD, "support/send"),
    onboarding: path(ROOTS_DASHBOARD, "onboarding"),
  },
  business: {
    branches: path(ROOTS_DASHBOARD, "branches"),
    categories: path(ROOTS_DASHBOARD, "categories"),
    customers: path(ROOTS_DASHBOARD, "customers"),
    companies: path(ROOTS_DASHBOARD, "companies"),
    products: path(ROOTS_DASHBOARD, "products"),
    sales: path(ROOTS_DASHBOARD, "sales"),
    invoices: path(ROOTS_DASHBOARD, "invoices"),
    quotations: path(ROOTS_DASHBOARD, "quotations"),
    waybills: path(ROOTS_DASHBOARD, "waybills"),
    returnedProducts: path(ROOTS_DASHBOARD, "returned-products"),
    debtors: path(ROOTS_DASHBOARD, "debtors"),
    damagedProducts: path(ROOTS_DASHBOARD, "damaged-products"),
    purchases: path(ROOTS_DASHBOARD, "purchases"),
    onlineSales: path(ROOTS_DASHBOARD, "online-sales"),
    cards: path(ROOTS_DASHBOARD, "cards"),
    addProduct: path(ROOTS_DASHBOARD, "products/add"),
    addSale: path(ROOTS_DASHBOARD, "generate"),
    addInvoice: path(ROOTS_DASHBOARD, "generate-invoice"),
    addQuote: path(ROOTS_DASHBOARD, "add-quote"),
    addWaybill: path(ROOTS_DASHBOARD, "add-waybill"),
    addReturned: path(ROOTS_DASHBOARD, "returned-products/add"),
    addDebtor: path(ROOTS_DASHBOARD, "debtors/add"),
    addDamaged: path(ROOTS_DASHBOARD, "damaged-products/add"),
    addPurchases: path(ROOTS_DASHBOARD, "purchases/add"),
    addOnline: path(ROOTS_DASHBOARD, "online-sales/add"),
    addCard: path(ROOTS_DASHBOARD, "cards/add"),
    settingsCard: path(ROOTS_DASHBOARD, "cards/settings"),
    assignedCard: path(ROOTS_DASHBOARD, "cards/assigned"),
  },
  ecommerce: {
    orders: path(ROOTS_DASHBOARD, "orders"),
    discountCodes: path(ROOTS_DASHBOARD, "discount-codes"),
    FeaturedProducts: path(ROOTS_DASHBOARD, "featured-products"),
    addFeaturedProduct: path(ROOTS_DASHBOARD, "featured-products/add"),
    specialOffers: path(ROOTS_DASHBOARD, "special-offers"),
    addSpecialOffer: path(ROOTS_DASHBOARD, "special-offers/add"),
    reviews: path(ROOTS_DASHBOARD, "reviews"),
  },
  subpages: {
    editSale: path(ROOTS_DASHBOARD, "sale/edit"),
    sale: path(ROOTS_DASHBOARD, "sale"),
    editInvoice: path(ROOTS_DASHBOARD, "invoice/edit"),
    editQuotation: path(ROOTS_DASHBOARD, "quotation/edit"),
    editWaybill: path(ROOTS_DASHBOARD, "waybill/edit"),
    editDiscrepancyReport: path(ROOTS_DASHBOARD, "discrepancy-report/edit"),
    invoice: path(ROOTS_DASHBOARD, "invoice"),
    quotation: path(ROOTS_DASHBOARD, "quotation"),
    waybill: path(ROOTS_DASHBOARD, "waybill"),
    product: path(ROOTS_DASHBOARD, "product"),
    admin: path(ROOTS_DASHBOARD, "administrator"),
    role: path(ROOTS_DASHBOARD, "role"),
    staff: path(ROOTS_DASHBOARD, "view-staff"),
    branch: path(ROOTS_DASHBOARD, "branch"),
    category: path(ROOTS_DASHBOARD, "category"),
    customer: path(ROOTS_DASHBOARD, "customer"),
    company: path(ROOTS_DASHBOARD, "company"),
    returnedProduct: path(ROOTS_DASHBOARD, "returned-product"),
    debtor: path(ROOTS_DASHBOARD, "debtor"),
    damaged: path(ROOTS_DASHBOARD, "damaged-product"),
    discrepancyReport: path(ROOTS_DASHBOARD, "discrepancy-report"),
    purchase: path(ROOTS_DASHBOARD, "purchase"),
    online: path(ROOTS_DASHBOARD, "online-sale"),
  },
};

export const frontendPages = [
  "/login",
  "/signup",
  "/forgot-password",
  "/reset-password",
  "/404",
  "/error-occured",
  "/welcome",
  "/store-error-occured",
  "/pay",
  "/dns-records",
];
