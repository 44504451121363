import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { paramCase } from "change-case";
import { useRef, useState } from "react";
import editFill from "@iconify/icons-eva/edit-fill";
import { Link as RouterLink } from "react-router-dom";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
import settingsOutline from "@iconify/icons-eva/settings-2-outline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddCardIcon from "@mui/icons-material/AddCard";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import CancelIcon from "@mui/icons-material/Cancel";
import LogoutIcon from "@mui/icons-material/Logout";
import RedoIcon from "@mui/icons-material/Redo";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Stack,
  FormControlLabel,
  Checkbox,
  TextField,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";

// ----------------------------------------------------------------------
DeleteModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  loading: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  restoreProducts: PropTypes.number,
  handleRestoreProducts: PropTypes.func,
  reason: PropTypes.string,
  error: PropTypes.string,
  handleReason: PropTypes.func,
};
function DeleteModal({
  open,
  handleClose,
  handleDelete,
  loading,
  name,
  type,
  restoreProducts,
  handleRestoreProducts,
  reason,
  handleReason,
  error,
}) {
  return (
    <Dialog maxWidth="xs" open={open} onClose={handleClose}>
      <DialogTitle sx={{ color: "text.primary", m: 0, p: 2 }}>
        {name === "Gift Card" || name === "Loyalty Card"
          ? "Cancel"
          : (name === "device" && "Logout") ||
            (type === "review" && "Request Deletion of Review") ||
            "Delete"}{" "}
        {type === "review" ? "" : name}{" "}
        <IconButton
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
          onClick={handleClose}
          color="error"
        >
          <CancelIcon />
        </IconButton>{" "}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {name === "Gift Card" || name === "Loyalty Card"
            ? `Are you sure you want to cancel ${name}?`
            : (name === "device" &&
                "Are you sure you want to log this device out?") ||
              (type === "review" &&
                "To remove this review, please state the reason why to enable us investigate and honour your request.") ||
              `Are you sure you want to delete ${name}? This action cannot be undone.`}
        </DialogContentText>
        {error && (
          <Alert severity="error" sx={{ mt: 3 }}>
            {error}
          </Alert>
        )}
        {type === "review" && (
          <TextField
            fullWidth
            multiline
            rows={4}
            sx={{ pt: error ? 1 : 3 }}
            placeholder="State your reason(s) here."
            value={reason}
            onChange={(event) => {
              handleReason(event.target.value || "");
            }}
          />
        )}
        {type === "sale" && (
          <FormControlLabel
            sx={{ pt: 2 }}
            control={
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                color="primary"
                checked={restoreProducts === 1}
                onChange={(event) => {
                  handleRestoreProducts(event.target.checked ? 1 : 0);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Restore product(s) back to stock?"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <LoadingButton
            fullWidth
            onClick={handleDelete}
            variant="contained"
            loading={loading}
            color="primary"
          >
            Yes, Proceed
          </LoadingButton>
          <Button fullWidth onClick={handleClose} color="inherit">
            No, Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

StatusModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleStatusChange: PropTypes.func,
  loading: PropTypes.bool,
  name: PropTypes.string,
  status: PropTypes.string,
  statuses: PropTypes.array,
  handleChange: PropTypes.func,
};
function StatusModal({
  open,
  handleClose,
  handleStatusChange,
  handleChange,
  loading,
  name,
  status,
  statuses,
}) {
  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose}>
      <DialogTitle sx={{ color: "text.primary", m: 0, p: 2 }}>
        Change Status for {name} .
        <IconButton
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
          onClick={handleClose}
          color="error"
        >
          <CancelIcon />
        </IconButton>{" "}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please select the new status below:
        </DialogContentText>
        <TextField
          sx={{ mt: 2 }}
          select
          value={status}
          name="status"
          onChange={(e) => handleChange(e.target.value)}
          fullWidth
          type="text"
          margin="dense"
          variant="outlined"
          label="New Status"
        >
          {statuses.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <LoadingButton
            fullWidth
            onClick={handleStatusChange}
            variant="contained"
            loading={loading}
            color="primary"
          >
            Update
          </LoadingButton>
          <Button fullWidth onClick={handleClose} color="inherit">
            No, Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

MoreMenu.propTypes = {
  onDelete: PropTypes.func,
  onRead: PropTypes.func,
  onAssign: PropTypes.func,
  onActivate: PropTypes.func,
  onResend: PropTypes.func,
  handleStatusUpdate: PropTypes.func,
  isRead: PropTypes.bool,
  isCancel: PropTypes.bool,
  isAssign: PropTypes.bool,
  isActive: PropTypes.bool,
  Name: PropTypes.string,
  Mid: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
  statuses: PropTypes.array,
  onStatusUpdate: PropTypes.func,
};

const LINKS = {
  sale: PATH_DASHBOARD.subpages.sale,
  product: PATH_DASHBOARD.subpages.product,
  admin: PATH_DASHBOARD.subpages.admin,
  role: PATH_DASHBOARD.subpages.role,
  staff: PATH_DASHBOARD.subpages.staff,
  branch: PATH_DASHBOARD.subpages.branch,
  category: PATH_DASHBOARD.subpages.category,
  customer: PATH_DASHBOARD.subpages.customer,
  returned: PATH_DASHBOARD.subpages.returnedProduct,
  debtor: PATH_DASHBOARD.subpages.debtor,
  damaged: PATH_DASHBOARD.subpages.damaged,
  purchase: PATH_DASHBOARD.subpages.purchase,
  online: PATH_DASHBOARD.subpages.online,
};

export default function MoreMenu({
  onDelete,
  Name,
  Mid,
  type,
  onRead,
  isRead,
  isCancel,
  isAssign,
  onAssign,
  isActive,
  onActivate,
  onResend,
  handleStatusUpdate,
  statuses,
  status,
  onStatusUpdate,
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [restoreProducts, setRestoreProducts] = useState(0);
  const [reviewReason, setReviewReason] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setIsOpen(false);
  };
  const handleDelete = () => {
    if (type === "review") {
      if (reviewReason) {
        setError("");
        setSubmitting(true);
        onDelete(reviewReason);
        setReviewReason("");
      } else {
        setError("Please specify your reason(s) to proceed.");
        return;
      }
    } else {
      setSubmitting(true);
      onDelete(restoreProducts);
    }
    setRestoreProducts(0);
    setSubmitting(false);
    handleClose();
  };
  const handleStatusClose = () => setOpenStatus(false);
  const handleStatusChange = async () => {
    setSubmitting(true);
    await onStatusUpdate();
    setRestoreProducts(0);
    setSubmitting(false);
    handleStatusClose();
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {Object.prototype.hasOwnProperty.call(LINKS, type) && (
          <MenuItem
            component={RouterLink}
            to={
              Mid !== Name
                ? `${LINKS[type]}/${Mid}/${paramCase(Name)}`
                : `${LINKS[type]}/${Mid}`
            }
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Icon icon={editFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="View/Edit"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {type === "send" && (
          <MenuItem onClick={onResend} sx={{ color: "text.secondary" }}>
            <ListItemIcon>
              <RedoIcon width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Resend"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {type === "notification" && !isRead && (
          <MenuItem onClick={onRead} sx={{ color: "text.secondary" }}>
            <ListItemIcon>
              <VisibilityIcon width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Mark as read"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {type === "assigned_cards" && isCancel && (
          <MenuItem onClick={onDelete} sx={{ color: "error.main" }}>
            <ListItemIcon>
              <DoNotDisturbIcon width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Cancel"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {type === "devices" && (
          <MenuItem
            onClick={() => {
              setOpen(true);
            }}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <LogoutIcon width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Logout"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {type === "assigned_cards" && isActive && (
          <MenuItem onClick={onActivate} sx={{ color: "success.main" }}>
            <ListItemIcon>
              <CardGiftcardIcon width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Activate"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {type === "assigned_cards" && isAssign && (
          <MenuItem onClick={onAssign} sx={{ color: "success.main" }}>
            <ListItemIcon>
              <AddCardIcon width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Assign"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {(type === "discountCode" || type === "expense") && (
          <MenuItem
            onClick={() => {
              setOpenStatus(true);
            }}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Icon icon={settingsOutline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Change Status"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {type !== "assigned_cards" && type !== "devices" && (
          <MenuItem
            onClick={() => {
              setOpen(true);
            }}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
      </Menu>
      {open && (
        <DeleteModal
          open={open}
          handleClose={handleClose}
          handleDelete={handleDelete}
          loading={isSubmitting}
          name={Name}
          type={type}
          restoreProducts={restoreProducts}
          handleRestoreProducts={(value) => setRestoreProducts(value)}
          reason={reviewReason}
          handleReason={(v) => {
            setReviewReason(v);
            if (error) {
              setError("");
            }
          }}
          error={error}
        />
      )}
      {(type === "discountCode" || type === "expense") && (
        <StatusModal
          open={openStatus}
          handleClose={handleStatusClose}
          handleStatusChange={handleStatusChange}
          handleChange={handleStatusUpdate}
          loading={isSubmitting}
          name={Name}
          status={status}
          statuses={statuses}
        />
      )}
    </>
  );
}
