import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { Link, Card, Typography, CardHeader, Stack } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import CategoryIcon from "@mui/icons-material/Category";
import MoneyIcon from "@mui/icons-material/Money";
import NearMeIcon from "@mui/icons-material/NearMe";
import InventoryIcon from "@mui/icons-material/Inventory";
import { fCurrency, fNumber, isNumeric } from "../../../utils/formatNumber";
import { fDate } from "../../../utils/formatTime";
import { PATH_DASHBOARD } from "../../../routes/paths";

CompanyAbout.propTypes = {
  profile: PropTypes.object,
};

export default function CompanyAbout({ profile }) {
  const {
    email,
    dateAdded,
    phone,
    dateUpdated,
    address,
    totalPayments,
    totalPurchases,
    totalProducts,
    totalPaid,
    totalBalance,
  } = profile;

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
      }}
    >
      <CardHeader title="Details" />
      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction="row">
          <EmailIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            <Link
              variant="subtitle2"
              color="text.primary"
              component={RouterLink}
              to={`${PATH_DASHBOARD.misc.send}/${email}`}
            >
              {email}
            </Link>
          </Typography>
        </Stack>
        <Stack direction="row">
          <PhoneAndroidIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            <Link
              variant="subtitle2"
              color="text.primary"
              component={RouterLink}
              to={`${PATH_DASHBOARD.misc.send}/${phone}`}
            >
              {phone}
            </Link>
          </Typography>
        </Stack>
        <Stack direction="row">
          <CategoryIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Total Number of Purchases:&nbsp;&nbsp;
            {isNumeric(totalPurchases) ? fNumber(totalPurchases) : ""}
          </Typography>
        </Stack>
        <Stack direction="row">
          <InventoryIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Total Products Purchased:&nbsp;&nbsp;
            {isNumeric(totalProducts) ? fNumber(totalProducts) : ""}
          </Typography>
        </Stack>
        <Stack direction="row">
          <MoneyIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Total Amount Purchased:&nbsp;&nbsp;
            {isNumeric(totalPayments) ? fCurrency(totalPayments) : ""}
          </Typography>
        </Stack>
        <Stack direction="row">
          <MoneyIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Total Amount Paid:&nbsp;&nbsp;
            {isNumeric(totalPaid) ? fCurrency(totalPaid) : ""}
          </Typography>
        </Stack>
        <Stack direction="row">
          <MoneyIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Total Amount Owed:&nbsp;&nbsp;
            {isNumeric(totalBalance) ? fCurrency(totalBalance) : ""}
          </Typography>
        </Stack>
        <Stack direction="row">
          <NearMeIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Address:&nbsp;&nbsp;
            {address}
          </Typography>
        </Stack>
        <Stack direction="row">
          <CalendarTodayIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Added on{" "}
            {dateAdded !== "0000-00-00" ? fDate(dateAdded) : "Not Specified"}
          </Typography>
        </Stack>
        <Stack direction="row">
          <CalendarTodayIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Last updated on{" "}
            {dateUpdated !== "0000-00-00"
              ? fDate(dateUpdated)
              : "Not Specified"}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
