import PropTypes from "prop-types";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
// material
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Card,
  Alert,
  TextField,
  Stack,
  CardHeader,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { CheckPerm } from "../../../utils/apis";

// ----------------------------------------------------------------------
CategoryUpdate.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function CategoryUpdate({ myProfile, uid, fetch }) {
  const { updateCategory } = useAuth();
  const hasPermission = CheckPerm("update_product_categories");
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const Schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: myProfile.name,
      description: myProfile.description,
      attributes: JSON.parse(myProfile.attributes),
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateCategory(values, uid);
        enqueueSnackbar("Category's data has been updated!", {
          variant: "success",
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        fetch();
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;
  const handleAttributeAdd = () => {
    const attrs = values.attributes;
    attrs.push("");
    setFieldValue("attributes", attrs);
  };
  const handleAttributeRemove = (index) => {
    const attrs = values.attributes;
    if (index > -1) {
      attrs.splice(index, 1);
    }
    setFieldValue("attributes", attrs);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        px: 3,
        pb: 3,
      }}
    >
      <CardHeader title="Category Information" />
      <Box sx={{ p: { md: 3, xs: 1 } }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}
              <Stack direction="column" spacing={2}>
                <TextField
                  disabled={!hasPermission}
                  fullWidth
                  label="Name"
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  disabled={!hasPermission}
                  fullWidth
                  label="Description"
                  {...getFieldProps("description")}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />
                <Typography sx={{ mt: 2 }} variant="h6">
                  Attributes (optional)
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "text.disabled" }}>
                  Attributes associated with this product category like
                  color,size etc.
                </Typography>
                {values.attributes.map((attribute, index) => (
                  <Stack
                    key={index}
                    direction={{ xs: "column", md: "row" }}
                    spacing={2}
                    sx={{ width: 1 }}
                  >
                    <TextField
                      size="small"
                      name="attribute"
                      {...getFieldProps(`attributes[${index}]`)}
                      disabled={!hasPermission}
                      fullWidth
                      type="text"
                      margin="dense"
                      variant="outlined"
                      label="Attribute"
                    />
                    <Button
                      size="small"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleAttributeRemove(index)}
                    >
                      Remove
                    </Button>
                  </Stack>
                ))}
                <Button onClick={handleAttributeAdd} color="info">
                  Add Attribute
                </Button>
              </Stack>
            </Stack>
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Update
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
