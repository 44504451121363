import { useState } from "react";
import PropTypes from "prop-types";
// @mui
import {
  Box,
  Stack,
  Button,
  Divider,
  Typography,
  InputAdornment,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
// utils
import { fNumber, fCurrency } from "../../../utils/formatNumber";
import useAuth from "../../../hooks/useAuth";
import Label from "../../Label";
import { GetSettings, METHOD_OPTIONS, CalcCharge } from "../../../utils/apis";
import ScanCardBarcode from "../singles/ScanCardBarcode";
import ViewAccount from "../singles/ViewAccount";
import VerifyTransaction from "../singles/VerifyTransaction";

InvoicePaymentDetails.propTypes = {
  formik: PropTypes.object,
};
export default function InvoicePaymentDetails({ formik }) {
  const { user, checkCard } = useAuth();
  const { dedicated } = user;
  const usesBankTransfer =
    GetSettings("allow_bank_transfer") &&
    Object.keys(dedicated).includes("bank") &&
    dedicated.bank !== "";
  const bankChargeBearer = GetSettings("bank_transfer_charge");
  const { values, setFieldValue, getFieldProps } = formik;
  const [openScan, setOpenScan] = useState(false);
  const [openAccount, setOpenAccount] = useState(false);
  const [openVerify, setOpenVerify] = useState(false);
  const [amountBank, setAmountBank] = useState(0);
  const [bankIndex, setBankIndex] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardIndex, setCardIndex] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const handleAdd = () => {
    const remainder =
      Number(values.amount) -
      values.payments
        .map((obj) => Number(obj.amount))
        .reduce((partialSum, a) => partialSum + a, 0);
    if (remainder > 0) {
      const { payments } = values;
      payments.push({
        method: "",
        amount: remainder,
        ref: "",
        status: false,
        isDebtorAdded: false,
      });
      setFieldValue("payments", payments);
    } else {
      enqueueSnackbar(`Total amount payable has been reached!`, {
        variant: "error",
      });
    }
  };

  const handleRemove = (index) => {
    const { payments } = values;
    if (index > -1) {
      payments.splice(index, 1);
      setFieldValue("payments", payments);
    }
  };
  const handleMarkPaid = (index) => {
    const { status } = values.payments[index];
    if (values.payments[index].method) {
      setFieldValue(`payments[${index}].status`, !status);
    } else {
      enqueueSnackbar(`Please select a payment method before setting this.`, {
        variant: "error",
      });
    }
  };

  const scanCode = async (index) => {
    if (values.payments[index].method) {
      setFieldValue(`payments[${index}].status`, false);
      setFieldValue(`payments[${index}].amount`, 0);
      if (values.payments[index].method === "gift card") {
        setCardType(
          values.payments[index].method === "gift card" ? "23" : "24"
        );
        setCardIndex(index.toString());
        setOpenScan(true);
      } else {
        // loyalty card
        // eslint-disable-next-line no-lonely-if
        if (values.invoiceTo.barcode) {
          try {
            setCardIndex(index.toString());
            const data = await checkCard(
              values.invoiceTo.cid,
              values.invoiceFrom.branch.bid,
              values.invoiceTo.barcode,
              "24"
            );
            verifyCard(data);
          } catch (err) {
            enqueueSnackbar(err.message, {
              variant: "error",
            });
          }
        } else if (!values.invoiceTo.cid) {
          enqueueSnackbar(
            `Please select a customer to use this payment method.`,
            {
              variant: "error",
            }
          );
        } else {
          enqueueSnackbar(
            `This customer does not have a valid loyalty card attached.`,
            {
              variant: "error",
            }
          );
        }
      }
    } else {
      enqueueSnackbar(`Please select a payment method before setting this.`, {
        variant: "error",
      });
    }
  };
  const verifyCard = (res) => {
    if (cardIndex && values.payments[cardIndex].method && res) {
      let status = false;
      if (Object.keys(res).includes("type")) {
        let amount =
          parseInt(res.type, 10) === 0
            ? parseFloat(res.total)
            : Math.round((parseFloat(res.total) / 100) * Number(values.amount));
        if (res.total) {
          if (Number(values.amount) < amount) {
            amount = Number(values.amount);
          }
          setFieldValue(`payments[${cardIndex}].amount`, amount);
          status = true;
        }
      }
      if (status) {
        setFieldValue(`payments[${cardIndex}].status`, status);
        setCardType(
          values.payments[cardIndex].method === "gift card" ? "23" : "24"
        );
        setFieldValue(`payments[${cardIndex}].ref`, JSON.stringify(res));
        setCardType("");
        setCardIndex("");
      }
    }
  };

  const handlePaymentSelected = async (payment) => {
    try {
      setFieldValue(`payments[${bankIndex}].ref`, payment.ref);
      setFieldValue(`payments[${bankIndex}].status`, true);
      setBankIndex("");
      setAmountBank("");
      setOpenVerify(false);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
      setFieldValue(`payments[${bankIndex}].status`, false);
    }
  };
  const calcTax = (index) => {
    const payment = values.payments[index];
    let price = Number(payment.amount) - Number(values.tax);
    const fee = CalcCharge(price, true);
    setFieldValue("tax", fee);
    price = fee + price;
    setFieldValue(`payments[${index}].amount`, price);
  };

  const defaultAmount = Number(values.amount);

  return (
    (values.status === "paid" ||
      values.status === "draft" ||
      values.status === "pending delivery") && (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ color: "text.disabled", mb: 3 }}>
          Payment Method(s):
        </Typography>
        <Stack
          divider={<Divider flexItem sx={{ borderStyle: "dashed" }} />}
          spacing={3}
        >
          {values.payments.map((item, index) => (
            <Stack key={index} alignItems="flex-end" spacing={1.5}>
              <Stack spacing={1} sx={{ width: 1 }}>
                <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
                  <Stack direction="column" alignItems="flex-start" spacing={0}>
                    <TextField
                      size="small"
                      sx={{ maxWidth: { md: 300 }, width: 300 }}
                      select
                      disabled={
                        (usesBankTransfer &&
                          item.method === "bank transfer" &&
                          item.status) ||
                        ((item.method === "gift card" ||
                          item.method === "loyalty card") &&
                          item.status)
                      }
                      name={`payments${index}.method`}
                      value={item.method}
                      label="Method"
                      InputLabelProps={{ shrink: true }}
                      onChange={(event) => {
                        setFieldValue(
                          `payments[${index}].method`,
                          event.target.value
                        );
                        if (
                          event.target.value === "cash" ||
                          event.target.value === "pos" ||
                          event.target.value === "credit"
                        ) {
                          setFieldValue(`payments[${index}].status`, true);
                        }
                        if (index === 0 && !item.amount) {
                          setFieldValue(
                            `payments[${index}].amount`,
                            defaultAmount
                          );
                        }
                        if (
                          event.target.value === "gift card" ||
                          event.target.value === "loyalty card" ||
                          event.target.value === "bank transfer"
                        ) {
                          setFieldValue(`payments[${index}].status`, false);
                        }
                        if (
                          event.target.value === "gift card" ||
                          event.target.value === "loyalty card"
                        ) {
                          setFieldValue(`payments[${index}].amount`, 0);
                        }
                      }}
                      SelectProps={{
                        native: false,
                        sx: { textTransform: "capitalize" },
                      }}
                    >
                      {METHOD_OPTIONS.map((option) => (
                        <MenuItem
                          key={option}
                          value={option}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                          }}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                  <TextField
                    size="small"
                    type="number"
                    name={`payments.${index}.amount`}
                    disabled={
                      (usesBankTransfer &&
                        item.method === "bank transfer" &&
                        item.status) ||
                      item.method === "gift card" ||
                      item.method === "loyalty card"
                    }
                    value={
                      item.method === "gift card" ||
                      item.method === "loyalty card"
                        ? item.amount
                        : item.amount || defaultAmount
                    }
                    label="Amount Paid"
                    onChange={(event) =>
                      setFieldValue(
                        `payments[${index}].amount`,
                        event.target.value
                      )
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          &#8358;
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
                {item.method && (
                  <>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={0}
                      sx={{ mt: 1 }}
                    >
                      <Label
                        variant={
                          theme.palette.mode === "light" ? "ghost" : "filled"
                        }
                        color={(item.status && "success") || "error"}
                      >
                        {(item.status && "Paid") || "Pending"}
                      </Label>
                      <div>&nbsp;&middot;&nbsp;</div>
                      {usesBankTransfer &&
                        item.method === "bank transfer" &&
                        !item.status && (
                          <>
                            <Button
                              sx={{ justifyContent: "flex-start" }}
                              size="small"
                              color="warning"
                              onClick={() => {
                                setBankIndex(index);
                                setAmountBank(Number(item.amount));
                                setOpenVerify(true);
                              }}
                            >
                              Verify Payment
                            </Button>
                            <div>&nbsp;&middot;&nbsp;</div>
                          </>
                        )}
                      {usesBankTransfer &&
                        item.method === "bank transfer" &&
                        !item.status && (
                          <Button
                            sx={{ justifyContent: "flex-start" }}
                            size="small"
                            color="info"
                            onClick={() => {
                              setAmountBank(item.amount);
                              setOpenAccount(true);
                            }}
                          >
                            View Account
                          </Button>
                        )}
                      {item.method === "bank transfer" && usesBankTransfer && (
                        <div>&nbsp;&middot;&nbsp;</div>
                      )}
                      {(item.method === "pos" ||
                        item.method === "cash" ||
                        item.method === "bank transfer") && (
                        <Button
                          sx={{ justifyContent: "flex-start" }}
                          size="small"
                          color={(item.status && "warning") || "success"}
                          onClick={() => handleMarkPaid(index)}
                        >
                          {(item.status && "Mark as Pending") || "Mark as Paid"}
                        </Button>
                      )}
                      {item.method === "bank transfer" &&
                        usesBankTransfer &&
                        bankChargeBearer && <div>&nbsp;&middot;&nbsp;</div>}
                      {usesBankTransfer &&
                        item.method === "bank transfer" &&
                        usesBankTransfer &&
                        bankChargeBearer &&
                        !item.status && (
                          <Button
                            sx={{ justifyContent: "flex-start" }}
                            size="small"
                            color="error"
                            onClick={() => calcTax(index)}
                          >
                            Calculate VAT
                          </Button>
                        )}
                      {(item.method === "gift card" ||
                        item.method === "loyalty card") &&
                        (item.ref === "" ? (
                          <Button
                            sx={{ justifyContent: "flex-start" }}
                            size="small"
                            color="warning"
                            onClick={() => scanCode(index)}
                          >
                            {item.method === "gift card"
                              ? "Scan or Enter Card"
                              : (values.invoiceTo.barcode &&
                                  `Apply Store Credit (${values.invoiceTo.credit})`) ||
                                (values.invoiceTo.cid &&
                                  "Customer has no loyalty card") ||
                                "No customer selected"}
                          </Button>
                        ) : (
                          Object.keys(JSON.parse(item.ref)).includes(
                            "type"
                          ) && (
                            <Typography
                              variant="overline"
                              sx={{ color: "text.disabled" }}
                            >
                              {JSON.parse(item.ref).type === 0
                                ? (item.method === "gift card" &&
                                    `Applied ${fCurrency(
                                      JSON.parse(item.ref).amount
                                    )}.`) ||
                                  `Applied ${fNumber(
                                    JSON.parse(item.ref).amount
                                  )} store credit(s).`
                                : `Applied ${
                                    JSON.parse(item.ref).amount
                                  } store credit(s).`}
                            </Typography>
                          )
                        ))}
                    </Stack>
                    {item.method === "credit" &&
                      (values.invoiceTo.cid ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                              color="primary"
                              checked={item.isDebtorAdded}
                              onChange={(event) => {
                                setFieldValue(
                                  `payments[${index}].isDebtorAdded`,
                                  event.target.checked
                                );
                              }}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Add this amount and customer to debtor?"
                        />
                      ) : (
                        <Typography variant="body2" sx={{ color: "info.main" }}>
                          Please add customer's name to add as debtor.
                        </Typography>
                      ))}
                  </>
                )}
              </Stack>

              <Button
                size="small"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => handleRemove(index)}
              >
                Remove
              </Button>
            </Stack>
          ))}
        </Stack>

        <Divider sx={{ my: 3, borderStyle: "dashed" }} />

        <Stack
          spacing={2}
          direction={{ xs: "column-reverse", md: "row" }}
          alignItems={{ xs: "flex-start", md: "center" }}
        >
          <Button
            size="small"
            startIcon={<AddIcon />}
            onClick={handleAdd}
            sx={{ flexShrink: 0 }}
          >
            Add method
          </Button>

          <Stack
            spacing={2}
            justifyContent="flex-end"
            direction={{ xs: "column", md: "row" }}
            sx={{ width: 1 }}
          >
            <TextField
              disabled
              size="small"
              name="total"
              label="Total Paid"
              value={fNumber(
                values.payments
                  .map((obj) => Number(obj.amount))
                  .reduce((partialSum, a) => partialSum + a, 0)
              )}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">&#8358;</InputAdornment>
                ),
              }}
            />
            <TextField
              disabled
              size="small"
              name="total"
              label="Balance"
              value={fNumber(
                values.amount -
                  values.payments
                    .map((obj) => Number(obj.amount))
                    .reduce((partialSum, a) => partialSum + a, 0)
              )}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">&#8358;</InputAdornment>
                ),
              }}
            />
          </Stack>
        </Stack>
        <Divider sx={{ my: 3, borderStyle: "dashed" }} />

        <Box sx={{ p: 3, width: 1 }}>
          <Typography variant="h6" sx={{ color: "text.disabled", mb: 0 }}>
            Terms:
          </Typography>
          <Typography variant="body2" sx={{ color: "text.disabled", mb: 3 }}>
            Eg: Terms and Conditions, Payment Information etc.
          </Typography>
          <TextField
            name="terms"
            label="Terms"
            sx={{ width: 1 }}
            multiline
            rows={5}
            {...getFieldProps("terms")}
          />
        </Box>

        <ScanCardBarcode
          open={openScan}
          type={cardType}
          onClose={(res) => {
            setOpenScan(false);
            if (res.amount) {
              verifyCard(res);
            }
          }}
          customer={values.invoiceTo.cid}
          branch={values.invoiceFrom.branch.bid}
        />
        <ViewAccount
          open={openAccount}
          amount={amountBank}
          onClose={() => {
            setOpenAccount(false);
            setAmountBank("");
          }}
        />
        <VerifyTransaction
          open={openVerify}
          onClose={() => {
            setOpenVerify(false);
          }}
          amount={amountBank}
          onSelect={handlePaymentSelected}
        />
      </Box>
    )
  );
}
