import React from "react";
import PropTypes from "prop-types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Box } from "@mui/material";

// eslint-disable-next-line
const Image = ({
  alt,
  src,
  width,
  height,
  isCenter,
  placeholder,
  className,
  isRight,
  sx,
}) => {
  let align = "left";
  if (isCenter) {
    align = "center";
  }
  if (isRight) {
    align = "right";
  }

  return (
    <Box sx={{ textAlign: align, ...sx }}>
      <LazyLoadImage
        wrapperClassName={className}
        alt={alt}
        height={height}
        src={src}
        effect="blur"
        placeholderSrc={placeholder}
        width={width}
      />
    </Box>
  );
};
Image.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
  isCenter: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  isRight: PropTypes.bool,
  sx: PropTypes.object,
};
export default Image;
