import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import homeFill from "@iconify/icons-eva/home-fill";
import personFill from "@iconify/icons-eva/person-fill";
import settings2Fill from "@iconify/icons-eva/settings-2-fill";
import { Link as RouterLink } from "react-router-dom";
// material
import { alpha } from "@mui/material/styles";
import {
  Avatar,
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
} from "@mui/material";
// components
import { MIconButton } from "../../components/@material-extend";
import MenuPopover from "../../components/MenuPopover";
import useAuth from "../../hooks/useAuth";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import { ASSETS_PATH, CheckPerm } from "../../utils/apis";
import { PATH_DASHBOARD } from "../../routes/paths";
import { useSelector } from "../../redux/store";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Home",
    icon: homeFill,
    linkTo: PATH_DASHBOARD.general.root,
    permissions: "view_dashboard_analytics",
  },
  {
    label: "My Profile",
    icon: personFill,
    linkTo: PATH_DASHBOARD.general.profile,
    permissions: "",
  },
  {
    label: "Settings",
    icon: settings2Fill,
    linkTo: PATH_DASHBOARD.general.settings,
    permissions: "view_settings",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { isOnline } = useSelector((state) => state.offline);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { user, logout } = useAuth();
  const photo =
    user && user.user && user.user.photo
      ? `${ASSETS_PATH.users}${user.user.photo}`
      : "/static/placeholder.svg";
  const OPTIONS = MENU_OPTIONS.filter((opt) => CheckPerm(opt.permissions));

  const handleOpen = () => {
    setOpen(isOnline);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleLogout = async () => {
    try {
      await logout();
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      enqueueSnackbar("Unable to logout", { variant: "error" });
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <Avatar alt={user && user.user ? user.user.fname : ""} src={photo} />
      </MIconButton>

      <MenuPopover
        open={open && isOnline}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user && user.user ? `${user.user.fname} ${user.user.lname}` : ""}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user && user.user ? user.role.name : ""}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
