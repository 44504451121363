import { useCallback, useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import SignalWifi4BarIcon from "@mui/icons-material/SignalWifi4Bar";
import SignalWifiOffIcon from "@mui/icons-material/SignalWifiOff";
import SyncIcon from "@mui/icons-material/Sync";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
// material
import { styled } from "@mui/material/styles";
import { Stack, Typography, Button, Tooltip, Link } from "@mui/material";
import { useSelector, useDispatch } from "../../redux/store";
import { getSavedOfflineData } from "../../utils/jwt";
import OfflineSales from "../../components/_dashboard/sale/OfflineSales";
import { setHasError, setSyncing } from "../../redux/slices/offline";
import { fNumber } from "../../utils/formatNumber";
import useAuth from "../../hooks/useAuth";

const AccountStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12],
}));

SyncData.propTypes = {
  switchGenerate: PropTypes.bool,
  setSwitchGenerate: PropTypes.func,
};
export default function SyncData({ switchGenerate, setSwitchGenerate }) {
  const { uploadSales } = useAuth();
  const salesTimerIdRef = useRef(null);
  const dispatch = useDispatch();
  const { isOnline, isSyncing, isFetching, error } = useSelector(
    (state) => state.offline
  );
  const [offlineSales, setOfflineSales] = useState(0);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const filter = useCallback(() => {
    const autoUpload = async () => {
      try {
        dispatch(setSyncing(true));
        const sales = getSavedOfflineData("sales");
        const customers = getSavedOfflineData("customers");
        const res = await uploadSales(
          JSON.stringify(sales),
          JSON.stringify(customers)
        );
        if (res.sales.length > 0) {
          if (res.sales.length !== sales.length) {
            // partial upload
            const pendingUpload = sales.filter(
              (sale) => !res.sales.includes(sale.rid)
            );
            window.localStorage.setItem(
              "savedData",
              JSON.stringify({
                sales: pendingUpload,
                customers,
              })
            );
            throw new Error(
              "Some of your sales could not be uplaoded! Please check your internet connection and retry."
            );
          }
        } else {
          // nothing uploaded.
          throw new Error(
            "An error occured while uploading your sales! Please check your internet connection and retry."
          );
        }
        enqueueSnackbar(
          "Offline sales have been uploaded successfully! Your page will refresh...",
          {
            variant: "success",
          }
        );
        window.localStorage.setItem("savedData", "{}");
        dispatch(setSyncing(false));
        setTimeout(() => window.location.reload(), 3000);
      } catch (err) {
        dispatch(setHasError(true));
        enqueueSnackbar(
          err.message ||
            "An error occured while uploading the sales! Please check your internet connection and retry.",
          {
            variant: "error",
          }
        );
      }
    };
    const sales = getSavedOfflineData("sales");
    if (sales.length > 0 && isOnline && !isSyncing && !error) {
      autoUpload();
    }
    if (isOnline) {
      setSwitchGenerate(false);
    }
  }, [
    error,
    isSyncing,
    uploadSales,
    dispatch,
    enqueueSnackbar,
    setSwitchGenerate,
    isOnline,
  ]);

  useEffect(() => {
    filter();
  }, [filter]);

  useEffect(() => {
    const autoCheckSales = () => {
      const timeOutId = window.setInterval(() => {
        const sales = getSavedOfflineData("sales");
        setOfflineSales(sales.length);
      }, 2000);
      salesTimerIdRef.current = timeOutId;
    };
    autoCheckSales();
    return () => {
      window.clearInterval(salesTimerIdRef.current);
    };
  }, []);
  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="center"
      >
        <AccountStyle>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            {isOnline ? (
              <SignalWifi4BarIcon sx={{ fontSize: 15 }} color="success" />
            ) : (
              <SignalWifiOffIcon sx={{ fontSize: 15 }} color="error" />
            )}
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {isOnline ? "Online" : "Offline"}
            </Typography>
          </Stack>
        </AccountStyle>
        {!switchGenerate && !isOnline && (
          <AccountStyle>
            <Stack
              direction="row"
              spacing={error ? 0 : 1}
              alignItems="center"
              justifyContent="center"
            >
              <Button
                color="primary"
                size="small"
                onClick={() => {
                  setSwitchGenerate(true);
                }}
              >
                Switch to Sales
              </Button>
            </Stack>
          </AccountStyle>
        )}
        {((isSyncing || error) && (
          <AccountStyle>
            <Stack
              direction="row"
              spacing={error ? 0 : 1}
              alignItems="center"
              justifyContent="center"
            >
              {isSyncing ? (
                <SyncIcon sx={{ fontSize: 15 }} color="warning" />
              ) : (
                error && <SyncProblemIcon sx={{ fontSize: 15 }} color="error" />
              )}
              {isSyncing && (
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  Uploading {fNumber(offlineSales)} sale
                  {offlineSales > 1 ? "s" : ""}...
                </Typography>
              )}
              {isFetching && (
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  Fetching offline data
                </Typography>
              )}
              {error && (
                <Button
                  color="primary"
                  size="small"
                  onClick={() => {
                    dispatch(setHasError(false));
                  }}
                >
                  Retry
                </Button>
              )}
            </Stack>
          </AccountStyle>
        )) ||
          (offlineSales > 0 && (
            <AccountStyle>
              <Tooltip title="Offline sales will be automatically uploaded when you go online.">
                <Button
                  size="small"
                  variant="text"
                  sx={{ color: "text.secondary", cursor: "pointer" }}
                  onClick={() => setOpen(!open)}
                  underline="none"
                >
                  {fNumber(offlineSales)} offline sale
                  {offlineSales > 1 ? "s" : ""}
                </Button>
              </Tooltip>
            </AccountStyle>
          ))}
      </Stack>
      {open && <OfflineSales open={open} setOpen={setOpen} />}
    </>
  );
}
