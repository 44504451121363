import { useState, useEffect, useCallback } from "react";
import { sentenceCase } from "change-case";
// material
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
import {
  Card,
  Stack,
  Container,
  CardHeader,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
  Link,
  TableContainer,
  TablePagination,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "../../../redux/store";
import { getExports } from "../../../redux/slices/export";
import { fDate } from "../../../utils/formatTime";
import { iff, CircularIndeterminate, ShowError } from "../../../utils/loader";
// utils
import {
  IMPORT_TYPES,
  EXPORT_STATUS,
  ASSETS_PATH,
  PRODUCT_STOCK_STATUS,
} from "../../../utils/apis";
// components
import useAuth from "../../../hooks/useAuth";
import Scrollbar from "../../Scrollbar";
import SearchNotFound from "../../SearchNotFound";
import Label from "../../Label";
import { ListHead, ListToolbar } from "../tables";

const EXPORT_IGNORE_BRANCHES = ["4", "5"];
const EXPORT_SHOW_CATEGORIES = ["7"];
const EXPORT_SHOW_STOCK = ["7"];

const TABLE_HEAD = [
  { id: "type", label: "Data Type", alignRight: false },
  { id: "branch", label: "Branch", alignRight: false },
  { id: "category", label: "Category", alignRight: false },
  { id: "stock", label: "Stock Type", alignRight: false },
  { id: "dateFrom", label: "Date From", alignRight: false },
  { id: "dateTo", label: "Date To", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "date", label: "Date Requested", alignRight: false },
  { id: "id", label: "", alignRight: false },
];
const TYPE = "export";
export default function Export() {
  const { user, setExport, getCategories } = useAuth();
  const { branches } = user;
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [type, setType] = useState("");
  const [dateFrom, setFrom] = useState("");
  const [loadingSubmit, setLoading] = useState(false);
  const [dateTo, setTo] = useState(new Date().toISOString().split("T")[0]);
  const [branch, setBranch] = useState("");
  const [category, setCategory] = useState("");
  const [stock, setStock] = useState({});
  const [categories, setCategories] = useState([]);

  const dispatch = useDispatch();
  const { exports, isLoading, error, total } = useSelector(
    (state) => state.export
  );
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState("id");

  const handleSubmit = async () => {
    try {
      if (EXPORT_IGNORE_BRANCHES.includes(type) || branch) {
        if (type) {
          setLoading(true);
          await setExport(type, branch, category, stock, dateFrom, dateTo);
          enqueueSnackbar(
            "Data export has been queued! We'll notify you when it is ready.",
            { variant: "success" }
          );
          setType("");
          setFrom("");
          setTo(new Date().toISOString().split("T")[0]);
          setBranch("");
          filter();
        } else {
          enqueueSnackbar("Please select a valid data type to proceed.", {
            variant: "error",
          });
          setType("");
        }
      } else {
        enqueueSnackbar("Please select a branch to proceed.", {
          variant: "error",
        });
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
    setLoading(false);
  };

  const filter = useCallback(() => {
    dispatch(getExports(page, rowsPerPage, order, orderBy));
  }, [page, rowsPerPage, order, orderBy, dispatch]);

  useEffect(() => {
    filter();
  }, [filter]);

  useEffect(() => {
    async function get() {
      setCategories(await getCategories());
    }
    get();
    return () => {
      setCategories([]);
    };
  }, [getCategories]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isExportNotFound = exports.length === 0;
  const isSorting = false;

  return (
    <Container maxWidth="lg">
      <Stack spacing={2}>
        <Card
          variant="outlined"
          sx={{
            border: "0.4px solid",
            borderColor: "background.border",
            boxShadow:
              "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
            borderRadius: "8px",
          }}
        >
          <CardHeader title="Export Store data" />
          <Stack
            spacing={2}
            sx={{ p: 3 }}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="subtitle2">
              Please select what type of data you want to export
            </Typography>
            <TextField
              select
              sx={{ width: { xs: "100%", md: 500 } }}
              label="Data Type"
              value={type}
              onChange={(event) => {
                if (EXPORT_IGNORE_BRANCHES.includes(event.target.value)) {
                  setBranch("");
                }
                if (!EXPORT_SHOW_CATEGORIES.includes(event.target.value)) {
                  setCategory("");
                }
                setType(event.target.value);
              }}
            >
              {IMPORT_TYPES.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {!EXPORT_IGNORE_BRANCHES.includes(type) && (
              <Autocomplete
                sx={{ maxWidth: { md: 500 }, width: "100%" }}
                options={branches}
                name="branch"
                value={branch}
                onChange={(event, newValue) => {
                  setBranch(newValue || "");
                }}
                getOptionLabel={(option) => option.name || ""}
                renderInput={(params) => (
                  <TextField {...params} label="Branch" margin="none" />
                )}
              />
            )}
            {EXPORT_SHOW_CATEGORIES.includes(type) && (
              <Autocomplete
                sx={{ maxWidth: { md: 500 }, width: "100%" }}
                options={categories}
                name="category"
                value={category}
                onChange={(event, newValue) => {
                  setCategory(newValue || "");
                }}
                getOptionLabel={(option) => option.name || ""}
                renderInput={(params) => (
                  <TextField {...params} label="Category" margin="none" />
                )}
              />
            )}
            {EXPORT_SHOW_STOCK.includes(type) && (
              <Autocomplete
                sx={{ maxWidth: { md: 500 }, width: "100%" }}
                options={PRODUCT_STOCK_STATUS}
                name="stock"
                value={stock}
                onChange={(event, newValue) => {
                  setStock(newValue || {});
                }}
                getOptionLabel={(option) => option.label || ""}
                renderInput={(params) => (
                  <TextField {...params} label="Filter Stock" margin="none" />
                )}
              />
            )}
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={2}
              justifyContent="center"
              alignItems="start"
            >
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
                fullWidth
                label="Date From"
                value={dateFrom}
                onChange={(event) => {
                  setFrom(event.target.value);
                }}
                helperText="If you leave this field empty, the data will be from the date your account was opened"
              />
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
                fullWidth
                label="Date To"
                value={dateTo}
                onChange={(event) => {
                  setTo(event.target.value);
                }}
                helperText=""
              />
            </Stack>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <LoadingButton
                loading={loadingSubmit}
                onClick={handleSubmit}
                color="primary"
                variant="contained"
              >
                Export
              </LoadingButton>
            </Stack>
          </Stack>
        </Card>
        {exports !== null && !isLoading && !error ? (
          <Card
            variant="outlined"
            sx={{
              border: "0.4px solid",
              borderColor: "background.border",
              boxShadow:
                "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
              borderRadius: "8px",
            }}
          >
            <ListToolbar numSelected={0} type={TYPE} />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={-1}
                    onRequestSort={handleRequestSort}
                    numSelected={0}
                  />
                  <TableBody>
                    {exports.map((row, index) => {
                      // eslint-disable-next-line
                      const {
                        type,
                        dateFrom,
                        dateTo,
                        dateAdded,
                        file,
                        branchName,
                        categoryName,
                        stock,
                        status,
                      } = row;
                      const typeData = IMPORT_TYPES.filter(
                        (typ) => typ.value === type
                      )[0];
                      const stat = EXPORT_STATUS[parseInt(status, 10)];
                      const stockType = PRODUCT_STOCK_STATUS.filter(
                        (stat) =>
                          parseInt(stat.value, 10) === parseInt(stock, 10)
                      );
                      return (
                        <TableRow hover key={index} tabIndex={-1}>
                          <TableCell component="th" scope="row" padding="none">
                            <Box
                              sx={{
                                py: 2,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Typography variant="subtitle2" noWrap>
                                {typeData.label}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>{branchName}</TableCell>
                          <TableCell>{categoryName}</TableCell>
                          {(stock &&
                            stockType.length > 0 &&
                            stockType[0].value && (
                              <TableCell style={{ minWidth: 160 }}>
                                <Label
                                  variant={
                                    theme.palette.mode === "light"
                                      ? "ghost"
                                      : "filled"
                                  }
                                  color={stockType[0].color}
                                >
                                  {sentenceCase(stockType[0].label)}
                                </Label>
                              </TableCell>
                            )) || (
                            <TableCell>
                              {stockType.length > 0 ? stockType[0].label : ""}
                            </TableCell>
                          )}

                          <TableCell>
                            {dateFrom ? fDate(dateFrom) : ""}
                          </TableCell>
                          <TableCell>{fDate(dateTo)}</TableCell>
                          <TableCell style={{ minWidth: 160 }}>
                            <Label
                              variant={
                                theme.palette.mode === "light"
                                  ? "ghost"
                                  : "filled"
                              }
                              color={stat.color}
                            >
                              {sentenceCase(stat.label)}
                            </Label>
                          </TableCell>
                          <TableCell>{fDate(dateAdded)}</TableCell>
                          <TableCell>
                            {parseInt(status, 10) === 0 ? (
                              ""
                            ) : (
                              <Link
                                underline="none"
                                target="_blank"
                                href={`${ASSETS_PATH.files}${file}`}
                              >
                                <Typography variant="subtitle2" noWrap>
                                  View
                                </Typography>
                              </Link>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {isExportNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={TABLE_HEAD.length}>
                          <Box sx={{ py: 3 }}>
                            <SearchNotFound
                              searchWord="Export"
                              isSorting={isSorting}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        ) : (
          iff(
            isLoading,
            <CircularIndeterminate />,
            <ShowError message={error} tryAgain />
          )
        )}
      </Stack>
    </Container>
  );
}
