import { useState } from "react";
// material
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  Typography,
  Stack,
  LinearProgress,
  TextField,
} from "@mui/material";
// utils
import { fPercent, fCurrency } from "../../../utils/formatNumber";

ProgressItem.propTypes = {
  progress: PropTypes.shape({
    color: PropTypes.string,
    label: PropTypes.string,
    amount: PropTypes.number,
    value: PropTypes.number,
  }),
};

function ProgressItem({ progress }) {
  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
          {progress.label}
        </Typography>
        <Typography variant="subtitle2">
          {fCurrency(progress.amount)}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          &nbsp;({fPercent(progress.value)})
        </Typography>
      </Stack>

      <LinearProgress
        variant="determinate"
        value={progress.value}
        color={progress.color}
      />
    </Stack>
  );
}

Overview.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  overview: PropTypes.array,
};
export default function Overview({ title, subtitle, overview }) {
  const [selected, setSelected] = useState(0);
  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
      }}
    >
      <CardHeader
        title={
          Object.prototype.hasOwnProperty.call(overview[0], "title")
            ? `${overview[selected].title} Breakdown`
            : title
        }
        subheader={subtitle}
        action={
          Object.prototype.hasOwnProperty.call(overview[0], "title") && (
            <TextField
              select
              fullWidth
              value={selected}
              SelectProps={{ native: true }}
              onChange={(e) => setSelected(parseInt(e.target.value, 10) || 0)}
              sx={{
                "& fieldset": { border: "0 !important" },
                "& select": {
                  pl: 1,
                  py: 0.5,
                  pr: "24px !important",
                  typography: "subtitle2",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: 0.75,
                  bgcolor: "background.neutral",
                },
                "& .MuiNativeSelect-icon": {
                  top: 4,
                  right: 0,
                  width: 20,
                  height: 20,
                },
              }}
            >
              {overview.map((option, index) => (
                <option key={index} value={index}>
                  {option.title}
                </option>
              ))}
            </TextField>
          )
        }
      />
      <Stack spacing={4} sx={{ p: 3 }}>
        {(Object.prototype.hasOwnProperty.call(overview[0], "title")
          ? overview[selected].data
          : overview
        ).map((progress, index) => (
          <ProgressItem key={index} progress={progress} />
        ))}
      </Stack>
    </Card>
  );
}
