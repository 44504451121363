import PropTypes from "prop-types";
import { sentenceCase } from "change-case";
import { useState, useEffect, useCallback } from "react";
// material
import {
  Card,
  Switch,
  Stack,
  CardHeader,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Button,
  Divider,
  TextField,
} from "@mui/material";
import useAuth from "../../../hooks/useAuth";

// ----------------------------------------------------------------------
Permissions.propTypes = {
  selected: PropTypes.array,
  hasPermission: PropTypes.bool,
  setSelected: PropTypes.func,
};
export default function Permissions({ selected, setSelected, hasPermission }) {
  const { getPermissions } = useAuth();
  const [permissions, setPermissions] = useState([]);
  const [availablePermissions, setAvailablePermissions] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    async function fetchPermissions() {
      const perms = await getPermissions();
      setAvailablePermissions(perms);
      setPermissions(perms);
    }
    fetchPermissions();
  }, [getPermissions]);

  const fetch = useCallback(() => {
    if (search) {
      setAvailablePermissions(
        permissions.filter(
          (permission) =>
            String(permission.title)
              .toLowerCase()
              .includes(String(search).toLowerCase()) ||
            String(permission.description)
              .toLowerCase()
              .includes(String(search).toLowerCase())
        )
      );
    } else {
      setAvailablePermissions(permissions);
    }
  }, [permissions, search]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handlePermSelected = (event) => {
    const perm = event.target.value;
    const perms = selected;

    if (event.target.checked && !perms.includes(perm)) {
      perms.push(perm);
    }

    if (!event.target.checked && perms.includes(perm)) {
      const index = perms.indexOf(perm);
      if (index > -1) {
        perms.splice(index, 1);
      }
    }
    setSelected(perms);
  };

  const allPermissionsChecker = (index) => {
    const arr = availablePermissions[index].permissions.map(
      (p) => p.permission
    );
    return arr.every((v) => selected.includes(v));
  };
  const isAllSelected = () => {
    const perm = availablePermissions
      .map((permissions) =>
        permissions.permissions.map((p) => p.permission).join(",")
      )
      .join(",")
      .split(",");
    return perm.every((v) => selected.includes(v));
  };
  const handleAll = () => {
    const perm = availablePermissions
      .map((permissions) =>
        permissions.permissions.map((p) => p.permission).join(",")
      )
      .join(",")
      .split(",");
    const selected = isAllSelected();
    setSelected(selected ? [] : perm);
  };

  const handleAllPermSelected = (event, index) => {
    const perm = availablePermissions[index].permissions.map(
      (p) => p.permission
    );
    const perms = selected;

    perm.forEach((element) => {
      if (event.target.checked && !perms.includes(element)) {
        perms.push(element);
      }

      if (!event.target.checked && perms.includes(element)) {
        const index = perms.indexOf(element);
        if (index > -1) {
          perms.splice(index, 1);
        }
      }
    });
    setSelected(perms);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        px: { md: 3, xs: 0 },
        pb: 3,
      }}
    >
      <CardHeader
        title="Permissions"
        subheader="Please select all the  permissions you want to assign to this role below:"
      />
      {availablePermissions.length > 0 && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            mt: 5,
            alignItems: { md: "center", xs: "end" },
            flexDirection: { md: "row", xs: "column-reverse" },
            px: { md: 0, xs: 2 },
          }}
        >
          <TextField
            value={search}
            name="search"
            placeholder="Search for permissions"
            size="small"
            sx={{ width: { md: "auto", xs: 1 } }}
            onChange={(e) => setSearch(e.target.value)}
          />

          <Button
            color={isAllSelected() ? "error" : "success"}
            onClick={handleAll}
          >
            {isAllSelected() ? "Unselect All" : "Select All"}
          </Button>
        </Stack>
      )}
      <Stack
        direction="column"
        spacing={2}
        sx={{ py: 2, px: { md: 0, xs: 2 } }}
      >
        {availablePermissions.map((permission, index) => (
          <Stack
            direction="column"
            spacing={2}
            key={index}
            divider={<Divider flexItem />}
            alignItems="start"
            justifyContent="center"
            sx={{
              borderRadius: 1,
              borderColor: "text.neutral",
              backgroundColor: "background.neutral",
              py: 2,
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={{ md: 2, xs: 1 }}
              key={index}
            >
              <Checkbox
                disabled={!hasPermission}
                checked={allPermissionsChecker(index)}
                onChange={(e) => handleAllPermSelected(e, index)}
              />
              <Stack
                direction="column"
                alignItems="start"
                justifyContent="start"
                spacing={0}
                key={index}
              >
                <Typography variant="subtitle2">{permission.title}</Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ color: "text.secondary" }}
                >
                  {permission.description}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              sx={{ px: 1 }}
            >
              {permission.permissions.map((perm, index) => (
                <FormGroup key={index}>
                  <FormControlLabel
                    sx={{ mx: 0 }}
                    labelPlacement="top"
                    control={
                      <Switch
                        disabled={!hasPermission}
                        checked={selected.includes(perm.permission)}
                        value={perm.permission}
                        onChange={handlePermSelected}
                      />
                    }
                    label={sentenceCase(perm.name)}
                  />
                </FormGroup>
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Card>
  );
}
