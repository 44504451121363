import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { useSnackbar } from "notistack";
// @mui
import { Box, Card, CardHeader, Link } from "@mui/material";
import { UploadSingleFile } from "../../upload";
// utils
import useAuth from "../../../hooks/useAuth";
import { CheckPerm, ASSETS_PATH } from "../../../utils/apis";
import { iff, ShowError, CircularIndeterminate } from "../../../utils/loader";

PurchaseReceipt.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function PurchaseReceipt({ myProfile, uid, fetch }) {
  const { updatePurchaseReceipt } = useAuth();
  const hasPermission = CheckPerm("update_purchases");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [receipt, setReceipt] = useState(myProfile.receipt);
  const { enqueueSnackbar } = useSnackbar();

  const handleDropSingleFile = useCallback(
    (acceptedFiles) => {
      const upload = async (file) => {
        try {
          // upload to server
          setReceipt("");
          setLoading(true);
          setMessage("Uploading receipt...");
          const formData = new FormData();
          formData.append("purchase_receipt", file);
          formData.append("update_receipt", uid);
          await updatePurchaseReceipt(formData);
          enqueueSnackbar(`Receipt updated`, { variant: "success" });
          fetch();
        } catch (err) {
          setError(err.message);
        }
        setLoading(false);
        setMessage("");
      };

      const file = acceptedFiles[0];
      if (file) {
        setFile({
          ...file,
          preview: URL.createObjectURL(file),
        });
        upload(file);
      }
    },
    [enqueueSnackbar, fetch, updatePurchaseReceipt, uid]
  );

  return (
    hasPermission && (
      <Card
        variant="outlined"
        sx={{
          border: "0.4px solid",
          borderColor: "background.border",
          boxShadow:
            "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
          borderRadius: "8px",
          px: 3,
          pb: 3,
        }}
      >
        <CardHeader
          title="Purchase Receipt"
          subheader={
            receipt ? (
              <Link href={`${ASSETS_PATH.receipts}/${receipt}`} target="_blank">
                View uploaded receipt
              </Link>
            ) : (
              ""
            )
          }
        />
        <Box sx={{ p: 3, mt: 2, bgcolor: "background.neutral" }}>
          {loading || error ? (
            iff(
              loading,
              <CircularIndeterminate message={message} />,
              <ShowError
                message={error}
                tryAgain
                onTryAgain={() => {
                  setError("");
                  setLoading(false);
                }}
              />
            )
          ) : (
            <UploadSingleFile
              title={
                file && receipt
                  ? "File uploaded successfully!"
                  : "Select or drop the purchase receipt"
              }
              message={
                file && receipt
                  ? `<a href="${ASSETS_PATH.receipts}/${receipt}" target="_blank">View uploaded file</a>`
                  : "Upload the purchase receipt."
              }
              accept={["application/pdf", "image/jpeg", "image/png"]}
              file={file}
              onDrop={handleDropSingleFile}
            />
          )}
        </Box>
      </Card>
    )
  );
}
