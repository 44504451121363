/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
// material
import { Box, Typography, Container } from "@mui/material";

export default function PermDenied() {
  const [Asset, setAsset] = useState(null);

  useEffect(() => {
    const load = async () => {
      setAsset(await import("../../assets/illustration_500"));
    };
    load();
    return () => {
      setAsset(null);
    };
  }, []);
  return (
    <Container>
      <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
        <Typography variant="h3" paragraph sx={{ color: "error.main" }}>
          Permission Denied
        </Typography>
        <Typography sx={{ color: "text.secondary" }}>
          You do not have permission to access this page.
        </Typography>
        {Boolean(Asset) && (
          <Asset.default sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        )}
      </Box>
    </Container>
  );
}
