import { useEffect, useRef } from "react";
import useAuth from "../hooks/useAuth";
import PopupLogin from "./PopupLogin";
import { useSelector } from "../redux/store";

export default function SessionTimeout() {
  const { isAuthenticated, isPopupOpen, logout } = useAuth();
  const { isOnline } = useSelector((state) => state.offline);
  const logoutTimerIdRef = useRef(null);

  useEffect(() => {
    const autoLogout = () => {
      if (
        document.visibilityState === "hidden" &&
        isAuthenticated &&
        isOnline
      ) {
        const timeOutId = window.setTimeout(() => {
          logout(true);
        }, 60 * 60 * 1000);
        logoutTimerIdRef.current = timeOutId;
      } else {
        window.clearTimeout(logoutTimerIdRef.current);
      }
    };
    document.addEventListener("visibilitychange", autoLogout);
    return () => {
      document.removeEventListener("visibilitychange", autoLogout);
    };
  }, [logout, isOnline, isAuthenticated]);

  return !isAuthenticated && isPopupOpen && <PopupLogin />;
}
