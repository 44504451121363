import PropTypes from "prop-types";
import { sum } from "lodash";
import * as Yup from "yup";
import { Fragment, useState, useCallback, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { useSnackbar } from "notistack";
// @mui
import {
  Box,
  Stack,
  Button,
  Divider,
  CircularProgress,
  InputAdornment,
  Autocomplete,
  TextField,
  Card,
  CardHeader,
  Alert,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// utils
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { fNumber } from "../../../utils/formatNumber";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { CheckPerm } from "../../../utils/apis";

OnlineProducts.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function OnlineProducts({ myProfile, uid, fetch }) {
  const { updateOnlineProducts, getProducts } = useAuth();
  const hasPermission = CheckPerm("update_online_sales");
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const Schema = Yup.object().shape({});
  const formik = useFormik({
    initialValues: {
      products: myProfile.products ? JSON.parse(myProfile.products) : [],
      deductQty: 0,
      discount: myProfile.discount,
      tax: myProfile.tax,
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateOnlineProducts(values, uid);
        enqueueSnackbar("Product(s) has been updated!", { variant: "success" });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        fetch();
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const { errors, handleSubmit, isSubmitting, setFieldValue, values } = formik;

  const filter = useCallback(() => {
    if (currentIndex >= 0) {
      (async () => {
        setFieldValue(`products[${currentIndex}].loading`, true);
        const data = await getProducts(search, myProfile.branch);
        setOptions(data);
        setFieldValue(`products[${currentIndex}].loading`, false);
      })();
    }
  }, [search, myProfile.branch, getProducts, currentIndex, setFieldValue]);

  const handleSelect = (product) => {
    const { products } = values;
    if (product) {
      products[currentIndex].pid = product.pid;
      products[currentIndex].name = product.name;
      products[currentIndex].cost = product.cost_price;
      products[currentIndex].quantity = "1";
      products[currentIndex].price = parseFloat(product.selling_price);
      setFieldValue(`products`, products);
      setOptions([]);
    }
  };
  const handleAdd = () => {
    const { products } = values;
    products.push({
      pid: "",
      name: "",
      quantity: "",
      price: "",
      total: "",
      cost: "",
      warranty: "",
      loading: false,
      open: false,
    });
    setFieldValue("products", products);
  };

  const handleRemove = (index) => {
    const { products } = values;
    if (index > -1) {
      products.splice(index, 1);
      setFieldValue("products", products);
    }
  };

  const handleSearch = (search, index) => {
    setCurrentIndex(index);
    setSearch(search);
  };

  useEffect(() => {
    filter();
  }, [filter]);

  const payable =
    sum(values.products.map((stat) => stat.quantity * stat.price)) +
    parseFloat(values.tax || 0) -
    parseFloat(values.discount || 0);

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        px: 3,
        pb: 3,
      }}
    >
      <CardHeader title="Online Sale Products" />
      <Box sx={{ p: 1 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack
              divider={<Divider flexItem sx={{ borderStyle: "dashed" }} />}
              spacing={3}
            >
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}
              {values.products.map((item, index) => (
                <Stack key={index} alignItems="flex-end" spacing={1.5}>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={2}
                    sx={{ width: 1 }}
                  >
                    <Autocomplete
                      sx={{ maxWidth: { md: 300, xs: 1 }, width: 300 }}
                      size="small"
                      disabled={!hasPermission}
                      id="asynchronous-demo"
                      name={`products.${index}.name`}
                      value={item}
                      getOptionLabel={(option) => option.name}
                      options={options}
                      onChange={(event, newValue) => {
                        setCurrentIndex(index);
                        handleSelect(newValue);
                      }}
                      loading={values.products[index].loading}
                      isOptionEqualToValue={(option, value) =>
                        option.pid === value.pid
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Product"
                          variant="outlined"
                          onChange={(ev) => {
                            // dont fire API if the user delete or not entered anything
                            if (
                              ev.target.value !== "" ||
                              ev.target.value !== null
                            ) {
                              handleSearch(ev.target.value, index);
                            }
                          }}
                          InputProps={{
                            ...params.InputProps,
                            autoComplete: "off",
                            endAdornment: (
                              <>
                                {values.products[index].loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                    <TextField
                      size="small"
                      type="number"
                      disabled={!hasPermission}
                      value={values.products[index].quantity}
                      name={`products.${index}.quantity`}
                      label="Quantity"
                      onChange={(event) =>
                        setFieldValue(
                          `products[${index}].quantity`,
                          Number(event.target.value)
                        )
                      }
                    />

                    <TextField
                      size="small"
                      type="number"
                      disabled={!hasPermission}
                      name={`products.${index}.price`}
                      value={values.products[index].price}
                      label="Unit Price"
                      onChange={(event) =>
                        setFieldValue(
                          `products[${index}].price`,
                          Number(event.target.value)
                        )
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            &#8358;
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={2}
                    sx={{ width: 1, mt: 1 }}
                  >
                    <TextField
                      disabled
                      size="small"
                      fullWidth
                      name={`products.${index}.total`}
                      label="Total"
                      value={fNumber(
                        values.products[index].quantity *
                          values.products[index].price
                      )}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            &#8358;
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      size="small"
                      type="text"
                      fullWidth
                      value={values.products[index].warranty}
                      name={`products.${index}.warranty`}
                      label="Warranty (in years)"
                      onChange={(event) =>
                        setFieldValue(
                          `products[${index}].warranty`,
                          event.target.value
                        )
                      }
                      helperText="Leave blank if warranty does not apply."
                    />
                  </Stack>
                  <Button
                    size="small"
                    color="error"
                    disabled={!hasPermission}
                    startIcon={<DeleteIcon />}
                    onClick={() => handleRemove(index)}
                  >
                    Remove
                  </Button>
                </Stack>
              ))}
            </Stack>

            <Divider sx={{ my: 3, borderStyle: "dashed" }} />

            <Stack
              spacing={2}
              direction={{ xs: "column-reverse", md: "row" }}
              alignItems={{ xs: "flex-start", md: "center" }}
            >
              <Button
                disabled={!hasPermission}
                size="small"
                startIcon={<AddIcon />}
                onClick={handleAdd}
                sx={{ flexShrink: 0 }}
              >
                Add product
              </Button>
              <Stack
                spacing={2}
                justifyContent="flex-end"
                direction={{ xs: "column", md: "row" }}
                sx={{ width: 1 }}
              >
                <TextField
                  size="small"
                  label="Discount"
                  name="discount"
                  value={values.discount}
                  onChange={(event) =>
                    setFieldValue("discount", Number(event.target.value))
                  }
                  sx={{ maxWidth: { md: 200 } }}
                />
                <TextField
                  size="small"
                  label="VAT"
                  name="tax"
                  value={values.tax}
                  onChange={(event) =>
                    setFieldValue("tax", Number(event.target.value))
                  }
                  sx={{ maxWidth: { md: 200 } }}
                />
                <TextField
                  disabled
                  size="small"
                  name="total"
                  label="Total Amount"
                  value={fNumber(payable)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">&#8358;</InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  color="primary"
                  checked={values.deductQty === 1}
                  onChange={(event) => {
                    const deductQty = event.target.checked ? 1 : 0;
                    setFieldValue("deductQty", deductQty);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Deduct or add product(s) quantities from stock?"
            />
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Update
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
