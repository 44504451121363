/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Button, Typography, Container } from "@mui/material";
// components
import { MotionContainer, varBounceIn } from "../../components/animate";
import Page from "../../components/Page";
import { PATH_DASHBOARD } from "../../routes/paths";
import useAuth from "../../hooks/useAuth";

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));
const useBasePath = (location, params) =>
  Object.values(params).reduce(
    (path, param) => path.replace(`/${param}`, ""),
    location.pathname
  );
const REQ_COMPLY = [
  PATH_DASHBOARD.business.sales,
  PATH_DASHBOARD.business.onlineSales,
  PATH_DASHBOARD.business.addSale,
  PATH_DASHBOARD.business.addOnline,
];
export default function SubscriptionError() {
  const location = useLocation();
  const params = useParams();
  const pathname = useBasePath(location, params);
  const { user } = useAuth();
  const isComplied =
    user &&
    user.compliance &&
    user.compliance.status &&
    parseInt(user.compliance.status, 10) === 1;
  const [data, setData] = useState({
    message: "Your current subscription does not offer this feature!",
    buttonText: "Upgrade",
    title: "Upgrade Your Subscription",
    link: PATH_DASHBOARD.accounting.choosePlan,
  });
  const [Asset, setAsset] = useState(null);

  useEffect(() => {
    if (REQ_COMPLY.includes(pathname) && !isComplied) {
      setData({
        message: "Please complete your compliance to access this feature.",
        buttonText: "Complete Compliance",
        title: "Complete your Compliance",
        link: PATH_DASHBOARD.general.compliance,
      });
    }
  }, [isComplied, pathname]);
  useEffect(() => {
    const load = async () => {
      setAsset(await import("../../assets/illustration_500"));
    };
    load();
    return () => {
      setAsset(null);
    };
  }, []);

  return (
    <RootStyle title={data.title}>
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                You cannot access this feature at the moment!
              </Typography>
            </motion.div>
            <Typography sx={{ color: "text.secondary" }}>
              {data.message}
            </Typography>

            <motion.div variants={varBounceIn}>
              {Boolean(Asset) && (
                <Asset.default sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
              )}
            </motion.div>

            <Button
              to={data.link}
              size="large"
              variant="contained"
              component={RouterLink}
            >
              {data.buttonText}
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
