import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// material
import { Box, Grid, Card, Button, Typography, Stack } from "@mui/material";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { fDateTime } from "../../../utils/formatTime";
import { ShowError } from "../../../utils/loader";
import { CheckPerm } from "../../../utils/apis";
// ----------------------------------------------------------------------

SingleCard.propTypes = {
  activity: PropTypes.object,
};

function SingleCard({ activity }) {
  const { message, dateAdded } = activity;
  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        p: 3,
      }}
    >
      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {message}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {fDateTime(dateAdded)}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

Log.propTypes = {
  activities: PropTypes.array.isRequired,
};

export default function Log({ activities }) {
  const hasPermission = CheckPerm("view_activity_logs");
  return (
    <Box sx={{ mt: 5 }}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: { md: 3, xs: 2 } }}
      >
        <Typography variant="h4">Activities Log</Typography>
        {hasPermission && (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            component={RouterLink}
            to={PATH_DASHBOARD.misc.log}
          >
            View All
          </Button>
        )}
      </Stack>
      {activities.length > 0 ? (
        <Grid container spacing={3}>
          {activities.map((activity, index) => (
            <Grid key={index} item xs={12} md={4}>
              <SingleCard activity={activity} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <ShowError
          message={
            new Error(
              "This staff does not have any activity attached at the moment!"
            )
          }
        />
      )}
    </Box>
  );
}
