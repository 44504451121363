/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { merge } from "lodash";
import PropTypes from "prop-types";
// material
import { Card, CardHeader, Box } from "@mui/material";
//
import { BaseOptionChart } from "../../charts";

LineGraph.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  chart: PropTypes.object,
};
export default function LineGraph({ title, subtitle, chart }) {
  const chartOptions = merge(BaseOptionChart(), {
    legend: { position: "top", horizontalAlign: "right" },
    xaxis: {
      type: "categories",
      tickAmount: "dataPoints",
      categories: chart.label,
    },
  });
  const [Chart, setChart] = useState(null);
  useEffect(() => {
    const importChart = async () => {
      const chart = await import("react-apexcharts");
      setChart(chart);
    };
    importChart();
    return () => {
      setChart(null);
    };
  }, []);

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
      }}
    >
      <CardHeader title={`${title} Progression`} subheader={subtitle} />
      <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
        {Boolean(Chart) && (
          <Chart.default
            type="area"
            series={chart.data}
            options={chartOptions}
            height={364}
          />
        )}
      </Box>
    </Card>
  );
}
