/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
// material
import { styled } from "@mui/material/styles";
import { Box, Button, Typography, Container } from "@mui/material";
// components
import { MotionContainer, varBounceIn } from "../../components/animate";
import Page from "../../components/Page";
import { PATH_AUTH } from "../../routes/paths";

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export default function StoreError() {
  const [Asset, setAsset] = useState(null);

  useEffect(() => {
    const load = async () => {
      setAsset(await import("../../assets/illustration_404"));
    };
    load();
    return () => {
      setAsset(null);
    };
  }, []);

  return (
    <RootStyle title="Store Not Found">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Sorry, store not found!
              </Typography>
            </motion.div>
            <Typography sx={{ color: "text.secondary" }}>
              Looks like you do not have a valid store link! Please contact the
              administrator to get a valid link or signup now.
            </Typography>

            <motion.div variants={varBounceIn}>
              {Boolean(Asset) && <Asset.default sx={{ height: 450 }} />}
            </motion.div>

            <Button href={PATH_AUTH.signup} size="large" variant="contained">
              Signup
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
