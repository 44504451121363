import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Card,
  Alert,
  TextField,
  Stack,
  CardHeader,
  Box,
  Typography,
  InputLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { CheckPerm, NumberFormatCustom } from "../../../utils/apis";
// ----------------------------------------------------------------------
ProductDetails.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function ProductDetails({ myProfile, uid, fetch }) {
  const { updateEcomProduct } = useAuth();
  const hasPermission = CheckPerm("update_products");
  const isMountedRef = useIsMountedRef();
  const [Editor, setEditor] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const Schema = Yup.object().shape({
    weight: Yup.number()
      .typeError("Enter a valid product weight to proceed")
      .moreThan(0, "Enter a valid product weight to proceed")
      .required("Product's weight is required"),
    discount: Yup.number().typeError(
      "Enter a valid discount amount to proceed."
    ),
    description: Yup.string().required("Product's description is required."),
  });

  const formik = useFormik({
    initialValues: {
      weight: myProfile.weight,
      discount: myProfile.discount,
      description: myProfile.description,
      details: myProfile.details,
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateEcomProduct(values, uid);
        enqueueSnackbar("Product's details has been updated!", {
          variant: "success",
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        fetch();
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;

  useEffect(() => {
    const importQuill = async () => {
      const quill = await import("../../editor");
      setEditor(quill);
    };
    importQuill();
    return () => {
      setEditor(null);
    };
  }, []);

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        px: 3,
        pb: 3,
      }}
    >
      <CardHeader title="Product Information" />
      <Box sx={{ p: { md: 3, xs: 1 } }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}
              <TextField
                fullWidth
                type="number"
                label="Weight (kg)"
                {...getFieldProps("weight")}
                error={Boolean(touched.weight && errors.weight)}
                helperText={touched.weight && errors.weight}
                disabled={!hasPermission}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                fullWidth
                label="Discount Price"
                {...getFieldProps("discount")}
                disabled={!hasPermission}
                error={Boolean(touched.discount && errors.discount)}
                helperText={
                  (touched.discount && errors.discount) ||
                  "This will be new price of the product if set. You can set it to 0 if you do not wish to specify a discount."
                }
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                fullWidth
                label="Description"
                {...getFieldProps("description")}
                error={Boolean(touched.description && errors.description)}
                disabled={!hasPermission}
                helperText={
                  (touched.description && errors.description) ||
                  "Give a short description of this product"
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <InputLabel>Details</InputLabel>
              <Typography
                variant="caption"
                sx={{ color: "text.secondary", mt: "4px!important" }}
              >
                {(touched.details && errors.details) ||
                  "Tell us a bit more about this product"}
              </Typography>
              {Boolean(Editor) && (
                <Editor.QuillEditor
                  simple
                  id="product-details"
                  value={values.details}
                  onChange={(value) => setFieldValue("details", value)}
                  placeholder="Describe this product in detail."
                />
              )}
            </Stack>
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Update
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
