import PropTypes from "prop-types";
import * as Yup from "yup";
import { useEffect, useState, useCallback } from "react";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Card,
  Alert,
  TextField,
  Stack,
  CardHeader,
  Box,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { CheckPerm } from "../../../utils/apis";

// ----------------------------------------------------------------------
PurchaseUpdate.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function PurchaseUpdate({ myProfile, uid, fetch }) {
  const { updatePurchase, getBranches, getCompanies } = useAuth();
  const hasPermission = CheckPerm("update_purchases");
  const [BRANCHES, setBRANCHES] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const Schema = Yup.object().shape({});

  const formik = useFormik({
    initialValues: {
      company: myProfile.companyName,
      invoice: myProfile.invoice,
      datePurchased: myProfile.datePurchased,
      branch: {},
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updatePurchase(values, uid);
        enqueueSnackbar("Purchase's data has been updated!", {
          variant: "success",
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        fetch();
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  const filter = useCallback(() => {
    (async () => {
      setLoading(true);
      const data = await getCompanies(search);
      setCompanies(data);
      setLoading(false);
    })();
  }, [search, getCompanies]);
  useEffect(() => {
    filter();
  }, [filter]);

  useEffect(() => {
    async function get() {
      const branches = await getBranches();
      setBRANCHES(branches);
      const selectedBranch = branches.filter(
        (branch) => branch.bid === myProfile.branch
      );
      setFieldValue(
        "branch",
        selectedBranch.length > 0 ? selectedBranch[0] : {}
      );
    }
    get();
  }, [getBranches, myProfile.branch, setFieldValue]);

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        px: 3,
        pb: 3,
      }}
    >
      <CardHeader title="Purchase Product Information" />
      <Box sx={{ p: 1 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}
              <Stack direction="column" spacing={2}>
                <Autocomplete
                  fullWidth
                  disabled={!hasPermission}
                  options={BRANCHES}
                  {...getFieldProps("branch")}
                  name="branch"
                  value={values.branch}
                  onChange={(event, newValue) => {
                    setFieldValue("branch", newValue);
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Branch"
                      margin="none"
                      error={Boolean(touched.branch && errors.branch)}
                      helperText={touched.branch && errors.branch}
                    />
                  )}
                />
                <Autocomplete
                  fullWidth
                  options={companies.map((option) => option.name)}
                  disabled={!hasPermission}
                  freeSolo
                  {...getFieldProps("company")}
                  name="company"
                  value={values.company}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setFieldValue("company", newValue);
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    setFieldValue("company", newInputValue);
                  }}
                  loading={loading}
                  getOptionLabel={(option) => option || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Company"
                      margin="none"
                      onChange={(ev) => {
                        // dont fire API if the user delete or not entered anything
                        if (
                          ev.target.value !== "" ||
                          ev.target.value !== null
                        ) {
                          setSearch(ev.target.value);
                        }
                      }}
                      error={Boolean(touched.company && errors.company)}
                      helperText={touched.company && errors.company}
                    />
                  )}
                />
                <TextField
                  disabled={!hasPermission}
                  fullWidth
                  label="Invoice Number"
                  {...getFieldProps("invoice")}
                  error={Boolean(touched.invoice && errors.invoice)}
                  helperText={touched.invoice && errors.invoice}
                />
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={!hasPermission}
                  type="date"
                  fullWidth
                  label="Date Purchased"
                  {...getFieldProps("datePurchased")}
                  error={Boolean(touched.datePurchased && errors.datePurchased)}
                  helperText={touched.datePurchased && errors.datePurchased}
                />
              </Stack>
            </Stack>
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Update
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
