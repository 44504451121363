import PropTypes from "prop-types";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Card,
  Alert,
  TextField,
  Stack,
  CardHeader,
  Box,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { CheckPerm, GENDERS, MARITALS, STATES } from "../../../utils/apis";

// ----------------------------------------------------------------------
StaffUpdate.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function StaffUpdate({ myProfile, uid, fetch }) {
  const { updateStaff, getBranches, getJobs } = useAuth();
  const [BRANCHES, setBRANCHES] = useState([]);
  const [ROLES, setROLES] = useState([]);
  const hasPermission = CheckPerm("update_staff");
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const Schema = Yup.object().shape({
    fname: Yup.string().required("First name is required"),
    lname: Yup.string().required("Last name is required"),
    job: Yup.string().required("Job is required"),
    branch: Yup.object().required("Branch is required!"),
    gender: Yup.string().required("Gender is required!"),
    email: Yup.string().email("Email must be a valid email address"),
  });

  const formik = useFormik({
    initialValues: {
      fname: myProfile.fname,
      lname: myProfile.lname,
      email: myProfile.email,
      phone: myProfile.phone,
      job: myProfile.job,
      branch: {},
      salary: myProfile.salary,
      gender: myProfile.gender,
      marital: myProfile.marital,
      dob: myProfile.dob,
      origin: myProfile.origin,
      address: myProfile.address,
      employed: myProfile.employed,
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateStaff(values, uid);
        enqueueSnackbar("Staff's data has been updated!", {
          variant: "success",
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        fetch();
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;

  useEffect(() => {
    async function get() {
      const branches = await getBranches();
      setBRANCHES(branches);
      const jobs = await getJobs();
      setROLES(jobs.filter((job) => job !== "All" && job !== ""));
      const selectedBranch = branches.filter(
        (branch) => branch.bid === myProfile.branch
      );
      setFieldValue(
        "branch",
        selectedBranch.length > 0 ? selectedBranch[0] : {}
      );
      const selectedJob = jobs.filter((job) => job === myProfile.job);
      setFieldValue("job", selectedJob.length > 0 ? selectedJob[0] : {});
    }
    get();
  }, [getBranches, getJobs, myProfile.branch, myProfile.job, setFieldValue]);

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        px: 3,
        pb: 3,
      }}
    >
      <CardHeader title="Staff Information" />
      <Box sx={{ p: { md: 3, xs: 1 } }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  disabled={!hasPermission}
                  fullWidth
                  label="First Name"
                  {...getFieldProps("fname")}
                  error={Boolean(touched.fname && errors.fname)}
                  helperText={touched.fname && errors.fname}
                />
                <TextField
                  disabled={!hasPermission}
                  fullWidth
                  label="Last Name"
                  {...getFieldProps("lname")}
                  error={Boolean(touched.lname && errors.lname)}
                  helperText={touched.lname && errors.lname}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  disabled={!hasPermission}
                  autoComplete="username"
                  type="email"
                  fullWidth
                  label="Email Address"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />

                <TextField
                  fullWidth
                  autoComplete="username"
                  disabled={!hasPermission}
                  type="tel"
                  label="Phone number"
                  {...getFieldProps("phone")}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <Autocomplete
                  fullWidth
                  freeSolo
                  disabled={!hasPermission}
                  options={ROLES}
                  {...getFieldProps("job")}
                  name="job"
                  value={values.job}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setFieldValue("job", newValue);
                    }
                  }}
                  onInput={(event) => {
                    setFieldValue("job", event.target.value);
                  }}
                  getOptionLabel={(option) => option || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Employed As"
                      margin="none"
                      error={Boolean(touched.job && errors.job)}
                      helperText={touched.job && errors.job}
                    />
                  )}
                />
                <Autocomplete
                  fullWidth
                  options={STATES}
                  disabled={!hasPermission}
                  {...getFieldProps("origin")}
                  name="origin"
                  value={values.origin}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setFieldValue("origin", newValue);
                    }
                  }}
                  getOptionLabel={(option) => option || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State of Origin"
                      margin="none"
                      error={Boolean(touched.origin && errors.origin)}
                      helperText={touched.origin && errors.origin}
                    />
                  )}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  disabled={!hasPermission}
                  label="Salary (optional)"
                  {...getFieldProps("salary")}
                  error={Boolean(touched.salary && errors.salary)}
                  helperText={touched.salary && errors.salary}
                />
                <TextField
                  select
                  sx={{ width: { xs: "100%", md: 500 } }}
                  label="Gender"
                  disabled={!hasPermission}
                  value={values.gender}
                  onChange={(event) => {
                    setFieldValue("gender", event.target.value);
                  }}
                  error={Boolean(touched.gender && errors.gender)}
                  helperText={touched.gender && errors.gender}
                >
                  {GENDERS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={!hasPermission}
                  type="date"
                  fullWidth
                  label="Date of Birth"
                  {...getFieldProps("dob")}
                  error={Boolean(touched.dob && errors.dob)}
                  helperText={touched.dob && errors.dob}
                />
                <TextField
                  select
                  sx={{ width: { xs: "100%", md: 500 } }}
                  label="Marital Status"
                  disabled={!hasPermission}
                  value={values.marital}
                  onChange={(event) => {
                    setFieldValue("marital", event.target.value);
                  }}
                  error={Boolean(touched.marital && errors.marital)}
                  helperText={touched.marital && errors.marital}
                >
                  {MARITALS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={!hasPermission}
                  type="date"
                  fullWidth
                  label="Date Employed"
                  {...getFieldProps("employed")}
                  error={Boolean(touched.employed && errors.employed)}
                  helperText={touched.employed && errors.employed}
                />
                <Autocomplete
                  fullWidth
                  options={BRANCHES}
                  {...getFieldProps("branch")}
                  name="branch"
                  value={values.branch}
                  disabled={!hasPermission}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setFieldValue("branch", newValue);
                    }
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Branch"
                      margin="none"
                      error={Boolean(touched.branch && errors.branch)}
                      helperText={touched.branch && errors.branch}
                    />
                  )}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  disabled={!hasPermission}
                  fullWidth
                  label="Address"
                  {...getFieldProps("address")}
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                />
              </Stack>
            </Stack>
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Update
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
