import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { API_PATH } from "../../utils/apis";
import { getCurrentBranch } from "../../utils/jwt";

const initialState = {
  isLoading: false,
  error: false,
  deleteError: false,
  search: "",
  page: 0,
  perPage: 10,
  total: 0,
  products: [],
  perfProducts: [],
  perfTotal: 0,
  perfError: "",
  isPerfLoading: false,
  log: [],
  logTotal: 0,
  isLogLoading: false,
  logError: "",
  reviews: [],
  reviewTotal: 0,
  isReviewLoading: false,
  reviewError: "",
  sortBy: null,
  filters: {
    method: "",
  },
};

const slice = createSlice({
  name: "product",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    startPerfLoading(state) {
      state.isPerfLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    hasPerfError(state, action) {
      state.isPerfLoading = false;
      state.perfError = action.payload;
    },
    removeDeleteError(state) {
      state.deleteError = false;
    },
    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = action.payload;
    },
    getSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload.data;
      state.total = parseInt(action.payload.total, 10);
    },
    startLogLoading(state) {
      state.isLogLoading = true;
    },
    getLogSuccess(state, action) {
      state.isLogLoading = false;
      state.log = action.payload.data;
      state.logTotal = parseInt(action.payload.total, 10);
    },
    hasLogError(state, action) {
      state.isLogLoading = false;
      state.logError = action.payload;
    },
    startReviewLoading(state) {
      state.isReviewLoading = true;
    },
    getReviewSuccess(state, action) {
      state.isReviewLoading = false;
      state.reviews = action.payload.data;
      state.reviewTotal = parseInt(action.payload.total, 10);
    },
    hasReviewError(state, action) {
      state.isReviewLoading = false;
      state.reviewError = action.payload;
    },
    getPerfSuccess(state, action) {
      state.isPerfLoading = false;
      state.perfProducts = action.payload.data;
      state.perfTotal = parseInt(action.payload.total, 10);
    },
    categoryChangeSuccess(state, action) {
      const products = action.payload.products.split(",");
      const { categoryName } = action.payload;
      state.products = state.products.map((obj) =>
        products.includes(obj.pid) ? { ...obj, categoryName } : obj
      );
      state.isLoading = false;
    },
    deletedSuccess(state, action) {
      const deleted = action.payload.split(",");
      state.products = state.products.filter(
        (obj) => !deleted.includes(obj.pid)
      );
      state.isLoading = false;
    },
    //  SORT & FILTER POSTS
    sortBy(state, action) {
      state.sortBy = action.payload;
    },
    filter(state, action) {
      state.filters.method = action.payload.method;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filter, sortBy } = slice.actions;

export function getProducts(
  search,
  stat,
  category,
  page,
  perpage,
  order,
  sortBy,
  stock,
  ecomStatus
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(API_PATH.products, {
        page,
        status: stat,
        search,
        perpage,
        order,
        sortBy,
        branch: getCurrentBranch(),
        category,
        stock,
        is_ecommerce: ecomStatus,
      });
      const { status, data, total } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getSuccess({ data, total }));
      } else {
        dispatch(
          slice.actions.hasError(
            new Error(
              "We cannot fetch your products at the moment! Please check back later."
            )
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductLog(search, pid, page, perpage, order, sortBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLogLoading());
    try {
      const response = await axios.post(API_PATH.log, {
        page,
        pid,
        search,
        perpage,
        order,
        sortBy,
      });
      const { status, data, total } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getLogSuccess({ data, total }));
      } else {
        dispatch(
          slice.actions.hasLogError(
            new Error(
              "We cannot fetch your product's log at the moment! Please check back later."
            )
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasLogError(error));
    }
  };
}
export function getProductReviews(
  search,
  pid,
  rating,
  page,
  perpage,
  order,
  sortBy
) {
  return async (dispatch) => {
    dispatch(slice.actions.startReviewLoading());
    try {
      const response = await axios.post(API_PATH.reviews, {
        page,
        pid,
        rating,
        search,
        perpage,
        order,
        sortBy,
      });
      const { status, data, total } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getReviewSuccess({ data, total }));
      } else {
        dispatch(
          slice.actions.hasReviewError(
            new Error(
              "We cannot fetch your product's reviews at the moment! Please check back later."
            )
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasReviewError(error));
    }
  };
}

export function getProductsPerformance(
  cashier,
  branch,
  performance,
  date,
  page,
  perpage
) {
  return async (dispatch) => {
    dispatch(slice.actions.startPerfLoading());
    try {
      const response = await axios.post(API_PATH.products, {
        cashier,
        branch,
        performance,
        date: JSON.stringify(date),
        page,
        perpage,
      });
      const { status, data, total } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getPerfSuccess({ data, total }));
      } else {
        dispatch(
          slice.actions.hasPerfError(
            new Error(
              "We cannot fetch your products at the moment! Please check back later."
            )
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteProduct(remove) {
  return async (dispatch) => {
    dispatch(slice.actions.removeDeleteError());
    try {
      const response = await axios.post(API_PATH.products, { remove });
      if (response.data.status === "success") {
        dispatch(slice.actions.deletedSuccess(remove));
      } else if (response.data.status === "permission_denied") {
        dispatch(
          slice.actions.hasDeleteError(
            new Error("You do not have permission to perform this action!")
          )
        );
      } else {
        dispatch(
          slice.actions.hasDeleteError(
            new Error("An error has occurred! Please try again later.")
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasDeleteError(error));
    }
  };
}
export function addProductsToCategory(products, category) {
  return async (dispatch) => {
    dispatch(slice.actions.removeDeleteError());
    try {
      const response = await axios.post(API_PATH.products, {
        change_category: category.cid,
        products,
      });
      if (response.data.status === "success") {
        dispatch(
          slice.actions.categoryChangeSuccess({
            products,
            categoryName: category.name,
          })
        );
      } else if (response.data.status === "permission_denied") {
        dispatch(
          slice.actions.hasDeleteError(
            new Error("You do not have permission to perform this action!")
          )
        );
      } else {
        dispatch(
          slice.actions.hasDeleteError(
            new Error("An error has occurred! Please try again later.")
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasDeleteError(error));
    }
  };
}
