import { isString } from "lodash";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { motion, AnimatePresence } from "framer-motion";
// material
import { alpha, styled } from "@mui/material/styles";
import { Paper, Box, Typography } from "@mui/material";
// utils
import { fData } from "../../utils/formatNumber";
//
import { UploadIllustration } from "../../assets";
import { varFadeInRight } from "../animate";
import { ASSETS_PATH } from "../../utils/apis";

// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  display: "flex",
  overflow: "hidden",
  textAlign: "center",
  position: "relative",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(5, 0),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create("padding"),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  "&:hover": {
    opacity: 0.72,
    cursor: "pointer",
  },
  [theme.breakpoints.up("md")]: { textAlign: "left", flexDirection: "row" },
}));

// ----------------------------------------------------------------------

UploadSingleFile.propTypes = {
  error: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  isShowPreview: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  sx: PropTypes.object,
};

ShowRejectionItems.propTypes = {
  fileRejections: PropTypes.array,
};

function ShowRejectionItems({ fileRejections }) {
  return (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: "error.light",
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );
}

export default function UploadSingleFile({
  error,
  file,
  sx,
  title,
  message,
  isShowPreview,
  ...other
}) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple: false,
    ...other,
  });

  return (
    <Box sx={{ width: "100%", ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: "error.main",
            borderColor: "error.light",
            bgcolor: "error.lighter",
          }),
          ...(file && { padding: "12% 0" }),
        }}
      >
        <input {...getInputProps()} />

        <UploadIllustration sx={{ width: 220 }} />

        <Box sx={{ p: 3, ml: { md: 2 } }}>
          <Typography gutterBottom variant="h5">
            {title || "Drop or Select file"}
          </Typography>

          {file ? (
            <Typography
              variant="body2"
              sx={{ color: "primary.main", textDecoration: "underline" }}
              dangerouslySetInnerHTML={{ __html: file ? message : "" }}
              onClick={(event) => event.stopPropagation()}
            />
          ) : (
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {message || ""}
            </Typography>
          )}
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Drop file here or click&nbsp;
            <Typography
              variant="body2"
              component="span"
              sx={{ color: "primary.main", textDecoration: "underline" }}
            >
              browse
            </Typography>
          </Typography>
        </Box>
      </DropZoneStyle>

      {fileRejections.length > 0 && (
        <ShowRejectionItems fileRejections={fileRejections} />
      )}

      {isShowPreview && file && (
        <Box disablePadding sx={{ ...(file && { my: 3 }) }}>
          <AnimatePresence>
            <Box
              component={motion.div}
              {...varFadeInRight}
              sx={{
                p: 0,
                m: 0.5,
                width: 500,
                height: 420,
                maxWidth: 1,
                borderRadius: 0.5,
                overflow: "hidden",
                position: "relative",
                display: "inline-flex",
                
              }}
            >
              <Paper
                variant="outlined"
                component="img"
                src={
                  isString(file) ? `${ASSETS_PATH.featuredImages}${file}` : file
                }
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                }}
              />
            </Box>
          </AnimatePresence>
        </Box>
      )}
    </Box>
  );
}
