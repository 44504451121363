/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
// material
import { styled } from "@mui/material/styles";
import { Typography, Button, Card, CardContent } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { PATH_DASHBOARD } from "../../../routes/paths";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.up("md")]: {
    height: "100%",
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

// ----------------------------------------------------------------------
export default function Welcome() {
  const { user } = useAuth();
  const { shop } = user;
  const [Asset, setAsset] = useState(null);

  useEffect(() => {
    const load = async () => {
      setAsset(await import("../../../assets/illustration_motivation"));
    };
    load();
    return () => {
      setAsset(null);
    };
  }, []);
  return (
    <RootStyle>
      <CardContent
        sx={{
          color: "grey.800",
          p: { md: 0 },
          pl: { md: 5 },
        }}
      >
        <Typography gutterBottom variant="h4">
          Welcome to SelPay,
          <br /> {shop.name}!
        </Typography>

        <Typography
          variant="body2"
          sx={{ pb: { xs: 3, xl: 5 }, maxWidth: 480, mx: "auto" }}
        >
          We'll need you to fill out some information to get you ready!
        </Typography>
        <Button
          component={RouterLink}
          to={PATH_DASHBOARD.misc.onboarding}
          variant="contained"
        >
          Start Now
        </Button>
      </CardContent>
      {Boolean(Asset) && (
        <Asset.default
          sx={{
            p: 3,
            width: 360,
            margin: { xs: "auto", md: "inherit" },
          }}
        />
      )}
    </RootStyle>
  );
}
