// material
import { Box } from '@mui/material';

export default function VisaIcon({ ...other }) {
  return (
    <Box {...other}>
      <svg
        height="24"
        viewBox="0 0 36 24"
        width="36"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <rect fill="#fff" height="24" rx="4" width="36" />
          <path
            d="m14.7.16-2 9.25h-2.4l2-9.25zm10.06 6 1.26-3.47.73 3.47zm2.67 3.28h2.22l-1.94-9.25h-2c-.4400378.0040247-.8344927.27225403-1 .68l-3.59 8.57h2.52l.49-1.38h3.01zm-6.24-3c.01-2.49-3.35-2.67-3.35-3.67 0-.33.32-.68 1-.77.8092985-.08007079 1.6251284.06166236 2.36.41l.42-2c-.7163838-.26864207-1.4749066-.40747883-2.24-.41-2.36 0-4 1.26-4 3.06 0 1.33 1.19 2.07 2.09 2.51s1.25.75 1.24 1.15c0 .62-.74.9-1.43.91-.8511467.01095758-1.6909993-.19556356-2.44-.6l-.43 2c.8474838.33084413 1.7502552.49714412 2.66.49 2.51 0 4.16-1.24 4.17-3.16m-9.91-6.23000941-3.88 9.25000941h-2.61l-1.91-7.39c-.03355557-.35173987-.2502416-.65966212-.57-.81-.75073479-.35821145-1.54366191-.62028058-2.36-.78l.06-.27000941h4.07c.55080612-.0022319 1.02148141.39635799 1.11.94000941l1 5.35 2.49-6.29000941z"
            fill="#2a2a6c"
            fillRule="nonzero"
            transform="translate(3 8)"
          />
        </g>
      </svg>
    </Box>
  );
}
