import { styled } from "@mui/material/styles";
import { Box, Stack, Container, Typography, Alert } from "@mui/material";
// hooks
import useAuth from "../../hooks/useAuth";
// components
import Page from "../../components/Page";
import { LoginForm } from "../../components/authentication/login";
import Logo from "../../components/Logo";
import Image from "../../components/Image";
import { ASSETS_PATH } from "../../utils/apis";

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { user, isDemoDomain } = useAuth();

  return (
    <RootStyle title="Login to Dashboard">
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
              {(user.shop.logo && (
                <Image
                  src={`${ASSETS_PATH.logos}${user.shop.logo}`}
                  width="auto"
                  height="100"
                  isCenter
                  alt={`${user.shop.name} logo`}
                />
              )) || <Logo sx={{ mx: "auto", mb: 5 }} />}
              <Typography variant="h4" gutterBottom>
                Sign in to {user.shop.name}
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                {isDemoDomain
                  ? "Enter the username we sent you and password to proceed!"
                  : "Enter your email address or username and password to proceed!"}
              </Typography>
              {isDemoDomain && (
                <Alert severity="info" sx={{ mt: 2 }}>
                  Your default password is: <strong>demo</strong>
                </Alert>
              )}
            </Box>
          </Stack>
          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
