import { useState, useEffect, useCallback } from "react";
import {
  Navigate,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import PropTypes from "prop-types";
// hooks
import useAuth from "../hooks/useAuth";
import useSettings from "../hooks/useSettings";
// pages
import Login from "../pages/authentication/Login";
import StoreError from "../pages/errors/StoreError";
import LoadingScreen from "../components/LoadingScreen";
import { frontendPages } from "../routes/paths";
// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

const useBasePath = () => {
  const location = useLocation();
  const params = useParams();
  if (Object.prototype.hasOwnProperty.call(params, "*")) {
    delete params["*"];
  }
  return location.pathname.split("/").length - 1 === 1
    ? location.pathname
    : Object.values(params).reduce(
        (path, param) => path.replace(`/${param}`, ""),
        location.pathname
      );
};

export default function AuthGuard({ children }) {
  const {
    isAuthenticated,
    isDomainOkay,
    isInitialized,
    user,
    isVerifyEmail,
    isSwitchingBranch,
    isPopupOpen,
    loginToken,
    isCheckingBarcode,
  } = useAuth();
  const pathname = useBasePath();
  const { changeColor, themeColor, changeMode } = useSettings();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const checkColor = useCallback(() => {
    if (isInitialized && isDomainOkay) {
      // set color if not set
      const storeColor = user && user.shop.color ? user.shop.color : "default";
      if (storeColor !== themeColor) {
        changeColor(storeColor);
      }
    }
  }, [changeColor, isDomainOkay, isInitialized, themeColor, user]);
  useEffect(() => {
    checkColor();
  }, [checkColor]);
  useEffect(() => {
    async function process() {
      // check for token
      let get = location.search;
      if (get && get.includes("token")) {
        get = get.split("&");
        const token = get[0].split("=")[1];
        if (token) {
          await loginToken(token);
        }
        try {
          if (get.length > 1) {
            const mode = get[1].split("=")[1];
            changeMode(mode);
          }
        } catch (err) {
          // console.log(err);
        }
        navigate(location.pathname);
      }
    }
    process();
  }, [location, loginToken, navigate, changeMode]);

  if (isInitialized && !isSwitchingBranch) {
    if (isCheckingBarcode) {
      // show loading
      return <LoadingScreen />;
    }
    // verify shop subdomain
    if (!isDomainOkay) {
      return <StoreError />;
    }

    if (!isAuthenticated && !frontendPages.includes(pathname) && !isPopupOpen) {
      return <Login />;
    }

    if (isAuthenticated && !isVerifyEmail && pathname !== "/verify") {
      return <Navigate to="/verify" />;
    }

    if (isAuthenticated && frontendPages.includes(pathname)) {
      return <Navigate to="/" />;
    }

    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
  } else {
    return <LoadingScreen />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  // eslint-disable-next-line
  return <>{children}</>;
}
