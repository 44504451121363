import PropTypes from "prop-types";
import { paramCase } from "change-case";
import { Link as RouterLink } from "react-router-dom";
import { Link, Card, Typography, CardHeader, Stack } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CategoryIcon from "@mui/icons-material/Category";
import MoneyIcon from "@mui/icons-material/Money";
import { fCurrency, isNumeric } from "../../../utils/formatNumber";
import { fDate } from "../../../utils/formatTime";
import { PATH_DASHBOARD } from "../../../routes/paths";

CustomerAbout.propTypes = {
  profile: PropTypes.object,
};

export default function CustomerAbout({ profile }) {
  const {
    branch,
    branchName,
    email,
    dateAdded,
    phone,
    dateUpdated,
    dob,
    address,
    totalPayments,
  } = profile;

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
      }}
    >
      <CardHeader title="Details" />
      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction="row">
          <EmailIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            <Link
              variant="subtitle2"
              color="text.primary"
              component={RouterLink}
              to={`${PATH_DASHBOARD.misc.send}/${email}`}
            >
              {email}
            </Link>
          </Typography>
        </Stack>
        <Stack direction="row">
          <PhoneAndroidIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            <Link
              variant="subtitle2"
              color="text.primary"
              component={RouterLink}
              to={`${PATH_DASHBOARD.misc.send}/${phone}`}
            >
              {phone}
            </Link>
          </Typography>
        </Stack>
        {branchName !== "" && (
          <Stack direction="row">
            <BusinessCenterIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              Branch:&nbsp;&nbsp;{" "}
              {branch ? (
                <Link
                  variant="subtitle2"
                  color="text.primary"
                  component={RouterLink}
                  to={`${PATH_DASHBOARD.subpages.branch}/${branch}/${paramCase(
                    branchName
                  )}`}
                >
                  {branchName}
                </Link>
              ) : (
                branchName
              )}
            </Typography>
          </Stack>
        )}
        <Stack direction="row">
          <CategoryIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Total Amount Paid:&nbsp;&nbsp;
            {isNumeric(totalPayments) ? fCurrency(totalPayments) : ""}
          </Typography>
        </Stack>
        <Stack direction="row">
          <CategoryIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Address:&nbsp;&nbsp;
            {address}
          </Typography>
        </Stack>
        {dob && dob !== "0000-00-00" && (
          <Stack direction="row">
            <MoneyIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              Date of Birth:{" "}
              {dob !== "0000-00-00" ? fDate(dob) : "Not Specified"}
            </Typography>
          </Stack>
        )}
        <Stack direction="row">
          <CalendarTodayIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Added on{" "}
            {dateAdded !== "0000-00-00" ? fDate(dateAdded) : "Not Specified"}
          </Typography>
        </Stack>
        <Stack direction="row">
          <CalendarTodayIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Last updated on{" "}
            {dateUpdated !== "0000-00-00"
              ? fDate(dateUpdated)
              : "Not Specified"}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
