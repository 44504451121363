import PropTypes from "prop-types";
// @mui
import { Stack, Divider, Typography, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
// hooks
import { CheckPerm } from "../../../utils/apis";
import { fNumber } from "../../../utils/formatNumber";
import useResponsive from "../../../hooks/useResponsive";
import useToggle from "../../../hooks/useToggle";
import useAuth from "../../../hooks/useAuth";
import InvoiceAddressListDialog from "./InvoiceAddressListDialog";

// ----------------------------------------------------------------------
InvoiceNewEditAddress.propTypes = {
  formik: PropTypes.object,
  _invoiceAddressFrom: PropTypes.array,
  _invoiceAddressTo: PropTypes.array,
  isOnline: PropTypes.bool,
};
export default function InvoiceNewEditAddress({
  formik,
  _invoiceAddressFrom,
  _invoiceAddressTo,
  isOnline,
}) {
  const { values, errors, setFieldValue } = formik;
  const { user } = useAuth();
  const { branches } = user;

  const upMd = useResponsive("up", "md");

  const {
    toggle: openFrom,
    onOpen: onOpenFrom,
    onClose: onCloseFrom,
  } = useToggle();

  const { toggle: openTo, onOpen: onOpenTo, onClose: onCloseTo } = useToggle();

  const { invoiceFrom, invoiceTo } = values;
  const hasBranchPermission = CheckPerm("view_branches") && branches.length > 1;

  return (
    <Stack
      spacing={{ xs: 2, md: 5 }}
      direction={{ xs: "column", md: "row" }}
      divider={
        <Divider
          flexItem
          orientation={upMd ? "vertical" : "horizontal"}
          sx={{ borderStyle: "dashed" }}
        />
      }
      sx={{ p: 3 }}
    >
      <Stack sx={{ width: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 1 }}
        >
          <Typography variant="h6" sx={{ color: "text.disabled" }}>
            Company / Branch:
          </Typography>
          {hasBranchPermission && (
            <Button size="small" startIcon={<EditIcon />} onClick={onOpenFrom}>
              Change Branch
            </Button>
          )}
          <InvoiceAddressListDialog
            open={openFrom}
            onClose={onCloseFrom}
            selected={(selectedId) => invoiceFrom?.branch.bid === selectedId}
            onSelect={(address) => setFieldValue("invoiceFrom.branch", address)}
            addressOptions={_invoiceAddressFrom}
          />
        </Stack>

        <AddressInfo
          name={invoiceFrom.name || ""}
          address={invoiceFrom.address || ""}
          phone={invoiceFrom.phone || ""}
          email={invoiceFrom.email || ""}
          branch={invoiceFrom.branch}
        />
      </Stack>

      <Stack sx={{ width: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 1 }}
        >
          <Typography variant="h6" sx={{ color: "text.disabled" }}>
            Customer:
          </Typography>

          <Button size="small" startIcon={<EditIcon />} onClick={onOpenTo}>
            {invoiceTo ? "Change" : "Add"}
          </Button>

          <InvoiceAddressListDialog
            isTo
            branch={invoiceFrom.branch ? invoiceFrom.branch.bid : ""}
            open={openTo}
            onClose={onCloseTo}
            selected={(selectedId) => invoiceTo?.id === selectedId}
            onSelect={(address) => setFieldValue("invoiceTo", address)}
            addressOptions={_invoiceAddressTo}
            isOnline={isOnline}
          />
        </Stack>

        {invoiceTo ? (
          <AddressInfo
            name={invoiceTo.name}
            address={invoiceTo.address}
            phone={invoiceTo.phone}
            email={invoiceTo.email}
            credit={invoiceTo.credit}
          />
        ) : (
          <Typography typography="caption" sx={{ color: "error.main" }}>
            {errors.invoiceTo ? errors.invoiceTo.message : null}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

// ----------------------------------------------------------------------

AddressInfo.propTypes = {
  address: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  credit: PropTypes.string,
  branch: PropTypes.object,
};

function AddressInfo({ name, address, phone, email, credit, branch }) {
  return (
    <>
      <Typography variant="subtitle2">{name}</Typography>
      <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
        {address}
      </Typography>
      {branch && <Typography variant="body2">Branch: {branch.name}</Typography>}
      {phone && <Typography variant="body2">Phone: {phone}</Typography>}
      {email && <Typography variant="body2">Email: {email}</Typography>}
      {credit && (
        <Typography variant="body2">Store Credit: {fNumber(credit)}</Typography>
      )}
    </>
  );
}
