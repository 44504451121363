import PropTypes from "prop-types";
// material
import { Grid, Stack } from "@mui/material";

import ProductAbout from "./ProductAbout";
import ProductUpdate from "./ProductUpdate";
import ProductQtyAdd from "./ProductQtyAdd";
import ProductQtyMinus from "./ProductQtyMinus";
import ProductQtyTransfer from "./ProductQtyTransfer";

import AdminAbout from "./AdminAbout";
import AdminUpdate from "./AdminUpdate";
import AdminPassword from "./AdminPassword";

import RoleAbout from "./RoleAbout";
import RoleUpdate from "./RoleUpdate";

import StaffAbout from "./StaffAbout";
import StaffUpdate from "./StaffUpdate";
import StaffNOK from "./StaffNOK";

import BranchAbout from "./BranchAbout";
import BranchUpdate from "./BranchUpdate";

import CategoryAbout from "./CategoryAbout";
import CategoryUpdate from "./CategoryUpdate";

import DiscountCodeAbout from "./DiscountCodeAbout";
import DiscountCodeUpdate from "./DiscountCodeUpdate";

import CustomerAbout from "./CustomerAbout";
import CustomerUpdate from "./CustomerUpdate";

import CompanyAbout from "./CompanyAbout";
import CompanyUpdate from "./CompanyUpdate";

import ReturnedAbout from "./ReturnedAbout";
import ReturnedUpdate from "./ReturnedUpdate";
import ReturnedProducts from "./ReturnedProducts";

import DebtorAbout from "./DebtorAbout";
import DebtorUpdate from "./DebtorUpdate";
import DebtorProducts from "./DebtorProducts";

import DamagedAbout from "./DamagedAbout";
import DamagedUpdate from "./DamagedUpdate";

import PurchaseAbout from "./PurchaseAbout";
import PurchaseUpdate from "./PurchaseUpdate";
import PurchaseProducts from "./PurchaseProducts";
import PurchaseReceipt from "./PurchaseReceipt";

import OnlineAbout from "./OnlineAbout";
import OnlineProducts from "./OnlineProducts";
import OnlineUpdate from "./OnlineUpdate";

SingleData.propTypes = {
  myProfile: PropTypes.object,
  rid: PropTypes.string,
  type: PropTypes.string,
  fetch: PropTypes.func,
  isProfile: PropTypes.bool,
};

export default function SingleData({ myProfile, rid, fetch, type, isProfile }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Stack spacing={3}>
          {type === "product" && (
            <ProductAbout profile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "admin" && (
            <AdminAbout
              isProfile={isProfile}
              profile={myProfile}
              uid={rid}
              fetch={fetch}
            />
          )}
          {type === "role" && (
            <RoleAbout profile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "staff" && (
            <StaffAbout profile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "branch" && (
            <BranchAbout profile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "category" && (
            <CategoryAbout profile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "discountCode" && (
            <DiscountCodeAbout profile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "customer" && (
            <CustomerAbout profile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "company" && (
            <CompanyAbout profile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "returned" && (
            <ReturnedAbout profile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "debtor" && (
            <DebtorAbout profile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "damaged" && (
            <DamagedAbout profile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "purchase" && (
            <PurchaseAbout profile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "online" && (
            <OnlineAbout profile={myProfile} uid={rid} fetch={fetch} />
          )}
        </Stack>
      </Grid>

      <Grid item xs={12} md={8}>
        <Stack spacing={3}>
          {type === "product" && (
            <ProductUpdate myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "product" && parseInt(myProfile.isService, 10) === 0 && (
            <ProductQtyAdd myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "product" && parseInt(myProfile.isService, 10) === 0 && (
            <ProductQtyMinus myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "product" && parseInt(myProfile.isService, 10) === 0 && (
            <ProductQtyTransfer myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "admin" && (
            <AdminUpdate
              isProfile={isProfile}
              myProfile={myProfile}
              uid={rid}
              fetch={fetch}
            />
          )}
          {type === "admin" && (
            <AdminPassword
              isProfile={isProfile}
              myProfile={myProfile}
              uid={rid}
              fetch={fetch}
            />
          )}
          {type === "role" && (
            <RoleUpdate myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "staff" && (
            <StaffUpdate myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "staff" && (
            <StaffNOK myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "branch" && (
            <BranchUpdate myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "category" && (
            <CategoryUpdate myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "discountCode" && (
            <DiscountCodeUpdate myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "customer" && (
            <CustomerUpdate myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "company" && (
            <CompanyUpdate myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "returned" && (
            <ReturnedUpdate myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "returned" && (
            <ReturnedProducts myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "debtor" && (
            <DebtorUpdate myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "debtor" && (
            <DebtorProducts myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "online" && (
            <OnlineUpdate myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "online" && (
            <OnlineProducts myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "damaged" && (
            <DamagedUpdate myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "purchase" && (
            <PurchaseUpdate myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "purchase" && (
            <PurchaseProducts myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "purchase" && (
            <PurchaseReceipt myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}
