import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Container,
  Grid,
  Link,
  Typography,
  Card,
  CardHeader,
  Divider,
  Stack,
  Button,
  Avatar,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import InventoryIcon from "@mui/icons-material/Inventory";
import ReceiptIcon from "@mui/icons-material/Receipt";
// hooks
import useSettings from "../../hooks/useSettings";
// components
import Page from "../../components/Page";
import useAuth from "../../hooks/useAuth";
import { PATH_DASHBOARD } from "../../routes/paths";

// ----------------------------------------------------------------------
const OTHERS = [
  {
    title: "Setup your administrators, their roles and permissions.",
    link: PATH_DASHBOARD.administrative.admins,
    icon: <SupervisorAccountIcon />,
  },
  {
    title: "Add your products and their categories.",
    link: PATH_DASHBOARD.business.products,
    icon: <InventoryIcon />,
  },
  {
    title: "Start recording your sales and/or invoices.",
    link: PATH_DASHBOARD.business.sales,
    icon: <ReceiptIcon />,
  },
  {
    title: "Keep track of your expenses and what they are for.",
    link: PATH_DASHBOARD.accounting.expenses,
    icon: <CreditCardIcon />,
  },
];
export default function Onboarding() {
  const { themeStretch } = useSettings();
  const { user } = useAuth();
  const { shop, account, subscription, compliance } = user;
  const [settingsDone, setSettingsDone] = useState(false);
  const [complianceDone, setComplianceDone] = useState(false);
  const [accountDone, setAccountDone] = useState(false);
  const [subDone, setSubDone] = useState(false);

  useEffect(() => {
    setSettingsDone(Boolean(shop && shop.logo && shop.address));
    setAccountDone(Boolean(account && account.code));
    setSubDone(
      subscription &&
        Object.keys(subscription).includes("plan") &&
        parseInt(subscription.status, 10) === 1
    );
    setComplianceDone(
      compliance &&
        Object.keys(compliance).includes("status") &&
        parseInt(compliance.status, 10) === 1
    );
  }, [account, shop, subscription, compliance]);

  return (
    <Page title="Get Started">
      <Container maxWidth={themeStretch ? false : "xl"}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Typography variant="h3" sx={{ color: "text.primary", mb: 3 }}>
              Welcome, {shop.name}!
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: "text.secondary", mb: 3 }}
            >
              To get up and running quickly, please follow the steps outlined
              below:
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Card
              variant="outlined"
              sx={{
                border: "0.4px solid",
                borderColor: "background.border",
                boxShadow:
                  "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
                borderRadius: "8px",
                p: 3,
              }}
            >
              <CardHeader
                title="Get Started Quickly"
                subheader={`${
                  [settingsDone, complianceDone, accountDone, subDone].filter(
                    (s) => s === true
                  ).length
                } out of 4 tasks.`}
              />
              <Stack
                divider={<Divider flexItem sx={{ borderStyle: "dashed" }} />}
                direction="column"
                spacing={3}
                sx={{ mt: 5 }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="column">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography sx={{ color: "text.primary" }}>
                        Update your settings
                      </Typography>
                      {settingsDone && (
                        <CheckCircleOutlineIcon
                          fontSize="small"
                          color="success"
                        />
                      )}
                    </Stack>
                    <Typography sx={{ color: "text.secondary" }}>
                      This contains information such as your store address,
                      colour and others.
                    </Typography>
                  </Stack>
                  <Button
                    disabled={settingsDone}
                    variant="outlined"
                    component={RouterLink}
                    to={PATH_DASHBOARD.general.settings}
                  >
                    Go to Settings
                  </Button>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="column">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography sx={{ color: "text.primary" }}>
                        Complete your Compliance
                      </Typography>
                      {complianceDone && (
                        <CheckCircleOutlineIcon
                          fontSize="small"
                          color="success"
                        />
                      )}
                    </Stack>
                    <Typography sx={{ color: "text.secondary" }}>
                      To verify your ownership of this store and prevent against
                      fraud, please complete your compliance.
                    </Typography>
                  </Stack>
                  <Button
                    disabled={complianceDone}
                    variant="outlined"
                    component={RouterLink}
                    to={PATH_DASHBOARD.general.compliance}
                  >
                    Verify
                  </Button>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  justifyContent="space-between"
                >
                  <Stack direction="column">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography sx={{ color: "text.primary" }}>
                        Account Information
                      </Typography>
                      {accountDone && (
                        <CheckCircleOutlineIcon
                          fontSize="small"
                          color="success"
                        />
                      )}
                    </Stack>
                    <Typography sx={{ color: "text.secondary" }}>
                      Please provide your company's bank name and account number
                      so we can pay you automatically when your customer uses
                      the bank transfer option.
                    </Typography>
                  </Stack>
                  <Button
                    disabled={accountDone}
                    variant="outlined"
                    component={RouterLink}
                    to={PATH_DASHBOARD.general.account}
                  >
                    Add
                  </Button>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="column">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography sx={{ color: "text.primary" }}>
                        Activate your Subscription
                      </Typography>
                      {subDone && (
                        <CheckCircleOutlineIcon
                          fontSize="small"
                          color="success"
                        />
                      )}
                    </Stack>
                    <Typography sx={{ color: "text.secondary" }}>
                      Choose your preffered plan and activate to unlock the full
                      potential of SelPay.
                    </Typography>
                  </Stack>
                  <Button
                    disabled={subDone}
                    variant="outlined"
                    component={RouterLink}
                    to={PATH_DASHBOARD.accounting.choosePlan}
                  >
                    Activate Now
                  </Button>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="column">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography sx={{ color: "text.primary" }}>
                        Make It Yours
                      </Typography>
                    </Stack>
                    <Typography sx={{ color: "text.secondary" }}>
                      Add your information and details such as administrators
                      and their roles, products and their categories and so on!
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction={{ md: "row", xs: "column" }}
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                  sx={{ p: 1 }}
                >
                  {OTHERS.map((other, index) => (
                    <Card
                      variant="outlined"
                      sx={{
                        border: "0.4px solid",
                        borderColor: "background.border",
                        boxShadow:
                          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
                        borderRadius: "8px",
                        px: 2,
                        py: 4,
                      }}
                      key={index}
                    >
                      <Stack
                        direction="column"
                        spacing={3}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Avatar
                          sx={{
                            color: "common.white",
                            bgcolor: "primary.dark",
                            p: 3,
                          }}
                        >
                          {other.icon}
                        </Avatar>
                        <Typography
                          sx={{ color: "text.secondary", textAlign: "center" }}
                        >
                          {other.title}
                        </Typography>
                        <Button
                          variant="outlined"
                          component={RouterLink}
                          to={other.link}
                        >
                          Go
                        </Button>
                      </Stack>
                    </Card>
                  ))}
                </Stack>
                <Stack
                  direction={{ md: "row", xs: "column" }}
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                  sx={{ p: 1 }}
                >
                  <Typography sx={{ color: "text.secondary" }}>
                    Still not sure what to do next?
                  </Typography>
                  <Link
                    underline="none"
                    href="https://selpay.co/faq"
                    target="_blank"
                  >
                    Visit our FAQ
                  </Link>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
