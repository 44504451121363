import { sentenceCase } from "change-case";
import PropTypes from "prop-types";
import { useState, useEffect, useCallback, Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import { useTheme } from "@mui/material/styles";
import {
  Card,
  Container,
  Typography,
  Link,
  TableContainer,
  TablePagination,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Box,
  Grid,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "../../../redux/store";
import { getSales, deleteSale } from "../../../redux/slices/sale";
import { fDate } from "../../../utils/formatTime";
import { iff, CircularIndeterminate, ShowError } from "../../../utils/loader";
// components
import Scrollbar from "../../Scrollbar";
import SearchNotFound from "../../SearchNotFound";
import { ListHead, ListToolbar, InvoiceAnalytic } from "../tables";
import { PATH_DASHBOARD } from "../../../routes/paths";
import Label from "../../Label";
import { SALE_STATUS } from "../../../utils/apis";
import { fCurrency, fNumber, fPercentOnly } from "../../../utils/formatNumber";
import { Filter } from "../analysis";

const TABLE_HEAD = [
  { id: "rid", label: "ID", alignRight: false },
  { id: "customer", label: "Customer", alignRight: false },
  { id: "", label: "Products", alignRight: false },
  { id: "", label: "Quantity", alignRight: false },
  { id: "", label: "Price", alignRight: false },
  { id: "total", label: "Total", alignRight: false },
  { id: "discount", label: "Discount", alignRight: false },
  { id: "", label: "Sold By", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "date", label: "Date Added", alignRight: false },
];
const TYPE = "sale";

Sales.propTypes = {
  pid: PropTypes.string.isRequired,
};
export default function Sales({ pid }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { sales, isLoading, error, deleteError, summary, total } = useSelector(
    (state) => state.sale
  );
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [filterDate, setFilterDate] = useState({ type: "today", value: "" });
  const [filterDateSet, setFilterDateSet] = useState({
    type: "today",
    value: "",
  });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("id");
  const [status, setStatus] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const filter = useCallback(() => {
    dispatch(
      getSales(
        filterName,
        status,
        page,
        rowsPerPage,
        order,
        orderBy,
        filterDateSet,
        pid
      )
    );
  }, [
    pid,
    filterName,
    status,
    page,
    rowsPerPage,
    order,
    orderBy,
    filterDateSet,
    dispatch,
  ]);

  useEffect(() => {
    filter();
    if (deleteError) {
      enqueueSnackbar(deleteError.message, { variant: "error" });
    }
  }, [filter, deleteError, enqueueSnackbar]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = sales.map((n) => n.rid);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    if (page !== 0) {
      setPage(0);
    }
    setFilterName(event.target.value);
  };

  const handleDeleteSale = (saleId, restoreProducts) => {
    const sales = saleId || selected.join(",");
    if (!sales) {
      enqueueSnackbar(`Please select atleast a sale to delete.`, {
        variant: "error",
      });
      return;
    }
    dispatch(deleteSale(sales, String(restoreProducts)));
    setSelected([]);
  };
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    let curValue = value;
    let curName = name;
    if (name === "month_month" || name === "range_first") {
      const split = filterDate.value ? filterDate.value.split("_") : [];
      split[0] = curValue;
      curValue = split.join("_");
      curName = "value";
    }
    if (name === "month_year" || name === "range_second") {
      const split = filterDate.value ? filterDate.value.split("_") : [];
      if (split.length === 0) {
        split[0] = "";
      }
      split[1] = curValue;
      curValue = split.join("_");
      curName = "value";
    }
    setFilterDate((prevState) => ({
      ...prevState,
      [curName]: curValue,
    }));
    if (curName === "type") {
      let newValue = "";
      if (curValue === "month") {
        const date = new Date();
        newValue = `${date.getMonth() + 1}_${date.getFullYear()}`;
      }
      if (curValue === "year") {
        const date = new Date();
        newValue = date.getFullYear();
      }
      if (curValue === "range") {
        const date = new Date().toISOString().split("T")[0];
        newValue = `${date}_${date}`;
      }
      setFilterDate((prevState) => ({
        ...prevState,
        value: newValue,
      }));
    }
  };
  const handleDateSet = () => {
    if (
      filterDate.type === "today" ||
      filterDate.type === "week" ||
      filterDate.type === "current_month" ||
      filterDate.type === "current_year"
    ) {
      setFilterDate((prevState) => ({
        ...prevState,
        value: "",
      }));
    }
    setFilterDateSet(filterDate);
  };

  const isSaleNotFound = sales.length === 0;
  const isSorting = filterName !== "" || status !== "";

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item md={8} xs={12}>
          <Card
            variant="outlined"
            sx={{
              border: "0.4px solid",
              borderColor: "background.border",
              boxShadow:
                "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
              borderRadius: "8px",
              height: "100%",
            }}
          >
            <Grid
              container
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderStyle: "dashed" }}
                />
              }
              spacing={2}
              sx={{ py: 2 }}
            >
              <Grid item md={6} xs={12}>
                <InvoiceAnalytic
                  title="Total"
                  total={summary.total ? summary.total.total : 0}
                  percent={100}
                  name="sales"
                  other={
                    summary.total
                      ? `${parseFloat(summary.total.amount)} quantit${
                          parseFloat(summary.total.amount) > 1 ? "ies" : "y"
                        } sold`
                      : ""
                  }
                  icon="ic:round-receipt"
                  color={theme.palette.info.main}
                />
              </Grid>
              {SALE_STATUS.map((status, index) => (
                <Grid key={index} item md={6} xs={12}>
                  <InvoiceAnalytic
                    title={sentenceCase(status.label)}
                    total={
                      summary.total
                        ? parseInt(summary[status.value].total, 10)
                        : 0
                    }
                    percent={
                      summary.total
                        ? fPercentOnly(
                            parseInt(summary[status.value].amount, 10),
                            parseInt(summary.total.amount, 10)
                          )
                        : 0
                    }
                    name="sales"
                    other={
                      summary.total
                        ? `${parseFloat(summary[status.value].amount)} quantit${
                            parseFloat(summary[status.value].amount) > 1
                              ? "ies"
                              : "y"
                          } sold`
                        : ""
                    }
                    icon={status.icon}
                    color={theme.palette[status.color].main}
                  />
                </Grid>
              ))}
            </Grid>
          </Card>
        </Grid>
        <Grid sx={{ height: "100%" }} item md={4} xs={12}>
          <Filter
            handleChange={handleDateChange}
            values={filterDate}
            handleClick={handleDateSet}
            title="by Date"
            hidePrint
          />
        </Grid>
      </Grid>
      {(isLoading || error) && sales.length === 0 && !isSorting ? (
        iff(
          isLoading,
          <CircularIndeterminate />,
          <ShowError message={error} tryAgain />
        )
      ) : (
        <Card
          variant="outlined"
          sx={{
            border: "0.4px solid",
            borderColor: "background.border",
            boxShadow:
              "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
            borderRadius: "8px",
          }}
        >
          <ListToolbar
            onFilterChange={() => setPage(0)}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            handleDelete={(restoreProducts) =>
              handleDeleteSale("", restoreProducts)
            }
            status={status}
            setStatus={setStatus}
            type={TYPE}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={-1}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {sales.map((row) => {
                    const {
                      rid,
                      cashierName,
                      discount,
                      total,
                      products,
                      customerName,
                      dateAdded,
                      status,
                    } = row;
                    const product = JSON.parse(products);
                    const isItemSelected = selected.indexOf(rid) !== -1;
                    const stat = SALE_STATUS.filter(
                      (stat) => stat.value === status
                    )[0];
                    return (
                      <TableRow
                        hover
                        key={rid}
                        tabIndex={-1}
                        category="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell component="th" scope="row" padding="none">
                          <Box
                            sx={{
                              py: 2,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Link
                              underline="none"
                              component={RouterLink}
                              to={`${PATH_DASHBOARD.subpages.sale}/${rid}`}
                            >
                              <Typography variant="subtitle2" noWrap>
                                {rid}
                              </Typography>
                            </Link>
                          </Box>
                        </TableCell>
                        <TableCell style={{ minWidth: 160 }}>
                          {sentenceCase(customerName)}
                        </TableCell>
                        <TableCell style={{ minWidth: 160 }}>
                          {product.map((obj, index) => (
                            <Fragment key={index}>
                              {obj.name}
                              <br />
                            </Fragment>
                          ))}
                        </TableCell>
                        <TableCell>
                          {product.map((obj, index) => (
                            <Fragment key={index}>
                              {fNumber(obj.quantity)}
                              <br />
                            </Fragment>
                          ))}
                        </TableCell>
                        <TableCell>
                          {product.map((obj, index) => (
                            <Fragment key={index}>
                              {fCurrency(obj.price)}
                              <br />
                            </Fragment>
                          ))}
                        </TableCell>
                        <TableCell>{fCurrency(total)}</TableCell>
                        <TableCell>{fCurrency(discount)}</TableCell>
                        <TableCell>{sentenceCase(cashierName)}</TableCell>
                        <TableCell>
                          <Label
                            variant={
                              theme.palette.mode === "light"
                                ? "ghost"
                                : "filled"
                            }
                            color={(stat && stat.color) || "warning"}
                          >
                            {sentenceCase((stat && stat.label) || "")}
                          </Label>
                        </TableCell>
                        <TableCell style={{ minWidth: 120 }}>
                          {fDate(dateAdded)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isSaleNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={TABLE_HEAD.length}>
                        <Box sx={{ py: 3 }}>
                          <SearchNotFound
                            searchWord="Sale"
                            isSorting={isSorting}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 500, 1000]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      )}
    </Container>
  );
}
