import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import { Typography, Button, Stack } from "@mui/material";
import useAuth from "../hooks/useAuth";
import { PATH_DASHBOARD } from "../routes/paths";
import { CheckOnboardingDone } from "../utils/apis";
import { setOnline, setSyncing } from "../redux/slices/offline";
import { useDispatch, useSelector } from "../redux/store";

const NoticeStyle = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center",
  padding: 5,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
}));

const useBasePath = () => {
  const location = useLocation();
  const params = useParams();

  return Object.values(params).reduce(
    (path, param) => path.replace(`/${param}`, ""),
    location.pathname
  );
};
export default function NotificationBar() {
  const { isOnline } = useSelector((state) => state.offline);
  const pathname = useBasePath();
  const dispatch = useDispatch();
  const { user, isDemoDomain, isAuthenticated } = useAuth();
  const { subscription } = user;
  const isDone = CheckOnboardingDone();
  const [notice, setNotice] = useState({
    message: "",
    button: "",
    path: "",
    color: "",
  });

  useEffect(() => {
    setNotice({ message: "", button: "", path: "", color: "" });
    if (isOnline) {
      if (
        isAuthenticated &&
        !isDone &&
        pathname !== "/dashboard" &&
        pathname !== "/" &&
        pathname !== "/onboarding"
      ) {
        setNotice({
          message: "Complete your onboarding experience!",
          button: "Continue",
          path: PATH_DASHBOARD.misc.onboarding,
          color: "success",
        });
      }
      if (
        isAuthenticated &&
        subscription &&
        Object.keys(subscription).includes("status") &&
        parseInt(subscription.status, 10) === 0 &&
        pathname !== "/subscription" &&
        pathname !== "/choose-plan"
      ) {
        setNotice({
          message: "Renew your subscription to continue enjoying the platform.",
          button: "Renew",
          path: PATH_DASHBOARD.accounting.subscription,
          color: "error",
        });
      }
      if (isAuthenticated && isDemoDomain) {
        setNotice({
          message: "Enjoy unlimited access to all the features on SelPay!",
          button: "Signup Now",
          path: "https://selpay.co/signup",
          color: "success",
        });
      }
    } else {
      setNotice({
        message:
          "You are currently in offline mode! You can still generate sales and print receipts.",
        color: "warning",
      });
    }
  }, [subscription, pathname, isDone, isDemoDomain, isOnline, isAuthenticated]);

  useEffect(() => {
    const updateOnlineStatus = () => {
      dispatch(setOnline(navigator.onLine));
      if (!navigator.onLine) {
        dispatch(setSyncing(false));
      }
    };
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    updateOnlineStatus();
  });

  return (
    notice.message && (
      <NoticeStyle sx={{ bgcolor: `${notice.color}.light` }}>
        <Stack
          direction={{ md: "row", xs: "column" }}
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body2" sx={{ color: "common.black" }}>
            {notice.message}
          </Typography>
          <Button color="secondary" href={notice.path}>
            {notice.button}
          </Button>
        </Stack>
      </NoticeStyle>
    )
  );
}
