import { useState } from "react";
import { PropTypes } from "prop-types";
import {
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";
// components
import Scrollbar from "../../Scrollbar";
import { ImportHead } from "../tables";
import { IMPORT_TYPES } from "../../../utils/apis";

AddNew.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleBranchChange: PropTypes.func,
  branches: PropTypes.array,
};
function AddNew({ open, handleClose, branches, handleBranchChange }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Which branch should we put this data?</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 5 }}>
          Please Select the branch you want us to add this data to:
        </DialogContentText>
        {branches.map((branch) => (
          <Button
            key={branch.bid}
            fullWidth
            onClick={() => {
              handleBranchChange(branch.bid);
              handleClose();
            }}
            color="info"
          >
            {branch.name}
          </Button>
        ))}
        <Button fullWidth onClick={handleClose} color="error" sx={{ mt: 2 }}>
          Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
}
ImportPreview.propTypes = {
  headers: PropTypes.object,
  setHeaders: PropTypes.func,
  data: PropTypes.array,
  type: PropTypes.string,
  isRemoveFirst: PropTypes.bool,
  handleIsFirstChange: PropTypes.func,
  branch: PropTypes.string,
  handleBranchChange: PropTypes.func,
  onDone: PropTypes.func,
  goBack: PropTypes.func,
  branches: PropTypes.array,
};
export default function ImportPreview({
  headers,
  setHeaders,
  data,
  type,
  isRemoveFirst,
  handleIsFirstChange,
  branch,
  handleBranchChange,
  branches,
  onDone,
  goBack,
}) {
  const [open, setOpen] = useState(false);
  const typeData = IMPORT_TYPES.filter((typ) => typ.value === type)[0];
  const handleHeaderChange = (index, value) => {
    setHeaders((prevState) => {
      prevState[index] = value || { value: "", title: "" };
      return [...prevState];
    });
  };
  return (
    <Stack
      sx={{ p: 3 }}
      spacing={2}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h6">
        Please select the appropriate data for each column below.
      </Typography>
      <Typography variant="subtitle2" sx={{ color: "text.disabled", mb: 1 }}>
        This will let us know where to place each data.
      </Typography>
      <Typography variant="subtitle2" sx={{ color: "text.disabled", mb: 3 }}>
        Please note that the data for the file has been truncated to 10 columns
        or less for easy visibility and performance issues. All your data will
        be added once you submit.
      </Typography>
      <Scrollbar sx={{ flex: 1 }}>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <ImportHead
              headers={headers}
              columnsOptions={typeData.columns}
              onChange={handleHeaderChange}
            />
            <TableBody>
              {data.map((row, index) => (
                <TableRow hover key={index} tabIndex={-1} log="checkbox">
                  {row.map((col, i) => (
                    <TableCell key={i}>{col}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <FormControlLabel
        sx={{ mt: 2 }}
        control={
          <Checkbox
            sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
            color="primary"
            checked={isRemoveFirst === 1}
            onChange={(event) => {
              const removeFirst = event.target.checked ? 1 : 0;
              handleIsFirstChange(removeFirst);
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Remove first row from data?"
      />
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Button
          onClick={() => {
            if (typeData.isBranchRequired && !branch) {
              setOpen(true);
            } else {
              onDone();
            }
          }}
          color="primary"
          variant="contained"
        >
          {(typeData.isBranchRequired && !branch && "Select Branch") ||
            "Submit"}
        </Button>
        <Button
          onClick={() => {
            if (typeData.isBranchRequired && branch) {
              handleBranchChange("");
              setOpen(true);
            } else {
              goBack();
            }
          }}
          color="warning"
        >
          {(typeData.isBranchRequired && branch && "Change Branch") ||
            "Change Data Type"}
        </Button>
      </Stack>
      <AddNew
        open={open}
        handleClose={() => setOpen(false)}
        branches={branches}
        handleBranchChange={(bid) => handleBranchChange(bid)}
      />
    </Stack>
  );
}
