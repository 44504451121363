import PropTypes from "prop-types";
// @mui
import { Stack, TextField, MenuItem, Autocomplete } from "@mui/material";
import { SALE_STATUS } from "../../../utils/apis";

InvoiceNewEditStatusDate.propTypes = {
  formik: PropTypes.object,
  staffs: PropTypes.array,
};
export default function InvoiceNewEditStatusDate({ formik, staffs }) {
  const { values, errors, touched, getFieldProps, setFieldValue } = formik;
  return (
    <Stack
      spacing={2}
      direction={{ xs: "column", sm: "row" }}
      sx={{ p: 3, bgcolor: "background.neutral" }}
    >
      <TextField
        select
        fullWidth
        {...getFieldProps("status")}
        name="status"
        label="Status"
        InputLabelProps={{ shrink: true }}
        SelectProps={{ native: false, sx: { textTransform: "capitalize" } }}
      >
        {SALE_STATUS.map((option) => (
          <MenuItem
            key={option.label.toLowerCase()}
            value={option.label.toLowerCase()}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: "body2",
              textTransform: "capitalize",
            }}
          >
            {option.label.toLowerCase()}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        type="date"
        fullWidth
        label="Receipt Date"
        {...getFieldProps("date")}
        error={Boolean(touched.date && errors.date)}
        helperText={touched.date && errors.date}
      />

      {values.status !== "paid" && values.status !== "pending delivery" && (
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          type="date"
          fullWidth
          label="Date due"
          {...getFieldProps("due")}
          error={Boolean(touched.due && errors.due)}
          helperText={touched.due && errors.due}
        />
      )}
      <Autocomplete
        fullWidth
        options={staffs}
        {...getFieldProps("saleRep")}
        name="saleRep"
        value={values.saleRep}
        onChange={(event, newValue) => {
          if (newValue) {
            setFieldValue("saleRep", newValue);
          }
        }}
        getOptionLabel={(option) => option.name || ""}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Sales Representative"
            margin="none"
            error={Boolean(touched.saleRep && errors.saleRep)}
            helperText="Select the sales rep for this sale from your staff."
          />
        )}
      />
    </Stack>
  );
}
