import PropTypes from "prop-types";
import { sum } from "lodash";
import * as Yup from "yup";
import { Fragment, useState, useCallback, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { useSnackbar } from "notistack";
// @mui
import {
  Box,
  Stack,
  Button,
  Divider,
  CircularProgress,
  InputAdornment,
  Autocomplete,
  TextField,
  Card,
  CardHeader,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// utils
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { fNumber } from "../../../utils/formatNumber";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { CheckPerm } from "../../../utils/apis";

ReturnedProducts.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function ReturnedProducts({ myProfile, uid, fetch }) {
  const { updateReturnedProducts, getProducts } = useAuth();
  const hasPermission = CheckPerm("update_returned_products");
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const Schema = Yup.object().shape({});
  const formik = useFormik({
    initialValues: {
      products: myProfile.products ? JSON.parse(myProfile.products) : [],
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateReturnedProducts(values, uid);
        enqueueSnackbar("Product(s) has been updated!", { variant: "success" });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        fetch();
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const { errors, handleSubmit, isSubmitting, setFieldValue, values } = formik;

  const filter = useCallback(() => {
    if (currentIndex >= 0) {
      (async () => {
        setFieldValue(`products[${currentIndex}].loading`, true);
        const data = await getProducts(search, myProfile.branch);
        setOptions(data);
        setFieldValue(`products[${currentIndex}].loading`, false);
      })();
    }
  }, [search, myProfile.branch, getProducts, currentIndex, setFieldValue]);

  const handleSelect = (product) => {
    const { products } = values;
    if (product) {
      products[currentIndex].pid = product.pid;
      products[currentIndex].name = product.name;
      products[currentIndex].cost = product.cost_price;
      products[currentIndex].quantity = "1";
      products[currentIndex].price = parseFloat(product.selling_price);
      setFieldValue(`products`, products);
      setOptions([]);
    }
  };
  const handleAdd = () => {
    const { products } = values;
    products.push({
      pid: "",
      name: "",
      reason: "",
      quantity: "",
      cost: "",
      price: "",
      total: "",
      loading: false,
      open: false,
    });
    setFieldValue("products", products);
  };

  const handleRemove = (index) => {
    const { products } = values;
    if (index > -1) {
      products.splice(index, 1);
      setFieldValue("products", products);
    }
  };

  const handleSearch = (search, index) => {
    setCurrentIndex(index);
    setSearch(search);
  };

  useEffect(() => {
    filter();
  }, [filter]);

  const payable = sum(
    values.products.map((stat) => stat.quantity * stat.price)
  );

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        px: 3,
        pb: 3,
      }}
    >
      <CardHeader title="Returned Products" />
      <Box sx={{ p: 1 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack
              divider={<Divider flexItem sx={{ borderStyle: "dashed" }} />}
              spacing={3}
            >
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}
              {values.products.map((item, index) => (
                <Stack key={index} alignItems="flex-end" spacing={1.5}>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={2}
                    sx={{ width: 1 }}
                  >
                    <Autocomplete
                      sx={{
                        maxWidth: { md: 300, xs: 1 },
                        width: { md: 300, xs: 1 },
                      }}
                      size="small"
                      disabled={!hasPermission}
                      id="asynchronous-demo"
                      name={`products.${index}.name`}
                      value={item}
                      getOptionLabel={(option) => option.name}
                      options={options}
                      onChange={(event, newValue) => {
                        setCurrentIndex(index);
                        handleSelect(newValue);
                      }}
                      loading={values.products[index].loading}
                      isOptionEqualToValue={(option, value) =>
                        option.pid === value.pid
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Product"
                          variant="outlined"
                          onChange={(ev) => {
                            // dont fire API if the user delete or not entered anything
                            if (
                              ev.target.value !== "" ||
                              ev.target.value !== null
                            ) {
                              handleSearch(ev.target.value, index);
                            }
                          }}
                          InputProps={{
                            ...params.InputProps,
                            autoComplete: "off",
                            endAdornment: (
                              <>
                                {values.products[index].loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />

                    <TextField
                      size="small"
                      type="number"
                      value={values.products[index].quantity}
                      name={`products.${index}.quantity`}
                      label="Quantity"
                      disabled={!hasPermission}
                      onChange={(event) =>
                        setFieldValue(
                          `products[${index}].quantity`,
                          Number(event.target.value)
                        )
                      }
                    />

                    <TextField
                      size="small"
                      type="number"
                      disabled={!hasPermission}
                      name={`products.${index}.price`}
                      value={values.products[index].price}
                      label="Unit Price"
                      onChange={(event) =>
                        setFieldValue(
                          `products[${index}].price`,
                          Number(event.target.value)
                        )
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            &#8358;
                          </InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      disabled
                      size="small"
                      name={`products.${index}.total`}
                      label="Total"
                      value={fNumber(
                        values.products[index].quantity *
                          values.products[index].price
                      )}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            &#8358;
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                  <TextField
                    size="small"
                    type="text"
                    disabled={!hasPermission}
                    fullWidth
                    value={values.products[index].reason}
                    name={`products.${index}.reason`}
                    label="Reason"
                    onChange={(event) =>
                      setFieldValue(
                        `products[${index}].reason`,
                        event.target.value
                      )
                    }
                    helperText="Enter the reason why product was returned"
                  />

                  <Button
                    size="small"
                    color="error"
                    disabled={!hasPermission}
                    startIcon={<DeleteIcon />}
                    onClick={() => handleRemove(index)}
                  >
                    Remove
                  </Button>
                </Stack>
              ))}
            </Stack>

            <Divider sx={{ my: 3, borderStyle: "dashed" }} />

            <Stack
              spacing={2}
              direction={{ xs: "column-reverse", md: "row" }}
              alignItems={{ xs: "flex-start", md: "center" }}
            >
              <Button
                disabled={!hasPermission}
                size="small"
                startIcon={<AddIcon />}
                onClick={handleAdd}
                sx={{ flexShrink: 0 }}
              >
                Add product
              </Button>

              <Stack
                spacing={2}
                justifyContent="flex-end"
                direction={{ xs: "column", md: "row" }}
                sx={{ width: 1 }}
              >
                <TextField
                  disabled
                  size="small"
                  name="total"
                  label="Total Amount"
                  value={fNumber(payable)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">&#8358;</InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Update
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
