/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import {
  CircularProgress,
  Typography,
  Button,
  Box,
  Stack,
} from "@mui/material";
import { MotionContainer, varBounceIn } from "../components/animate";

// eslint-disable-next-line
export const CircularIndeterminate = ({ message }) => (
  <Stack
    spacing={2}
    direction="column"
    alignItems="center"
    justifyContent="center"
    sx={{ display: "flex", height: "100vh" }}
  >
    {" "}
    <CircularProgress sx={{ mx: "auto" }} />
    {message && (
      <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
        {message}
      </Typography>
    )}
  </Stack>
);

// eslint-disable-next-line
export const ShowError = ({
  message,
  tryAgain,
  onTryAgain,
  buttonText,
  isHideImage,
}) => {
  const [Asset, setAsset] = useState(null);
  useEffect(() => {
    const load = async () => {
      setAsset(await import("../assets/illustration_500"));
    };
    load();
    return () => {
      setAsset(null);
    };
  }, []);
  if (!message) {
    return <Box />;
  }
  return (
    <MotionContainer initial="initial" open>
      <Box
        sx={{ maxWidth: 480, margin: "auto", textAlign: "center", mt: 5, p: 2 }}
      >
        <Typography sx={{ color: "error.main" }}>{message.message}</Typography>
        {!isHideImage && (
          <motion.div variants={varBounceIn}>
            {Boolean(Asset) && (
              <Asset.default sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
            )}
          </motion.div>
        )}
        {tryAgain && (
          <Button
            sx={{ mt: isHideImage ? 2 : 0 }}
            size="large"
            variant="contained"
            onClick={() => {
              if (onTryAgain) {
                onTryAgain();
              } else {
                window.location.reload();
              }
            }}
          >
            {buttonText || "Try Again"}
          </Button>
        )}
      </Box>
    </MotionContainer>
  );
};
ShowError.propTypes = {
  message: PropTypes.any,
  tryAgain: PropTypes.bool,
  onTryAgain: PropTypes.any,
  buttonText: PropTypes.string,
  isHideImage: PropTypes.bool,
};
export const iff = (condition, then, otherwise) =>
  condition ? then : otherwise;
