import { getUA } from "react-device-detect";
import { useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getMessaging, getToken } from "firebase/messaging";
import Pusher from "pusher-js/with-encryption";
import useAuth from "../hooks/useAuth";
import {
  firebaseConfig,
  PUSHER_APP_CLUSTER,
  PUSHER_APP_KEY,
  vapidKey,
} from "../utils/apis";

// ----------------------------------------------------------------------

export default function FirebaseInit() {
  const {
    isAuthenticated,
    saveToken,
    logout,
    initialize,
    user,
    authenticateDevice,
  } = useAuth();

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
    getPerformance(app);

    const UpdateData = (data) => {
      if (data === "PROFILE") {
        // updated data
        initialize();
      }
    };

    /* const notifyData = async (data) => {
      try {
        if (Object.prototype.hasOwnProperty.call(data, "title")) {
          let actionClick = null;
          const payload = JSON.parse(data.payload);
          if (
            payload &&
            Object.prototype.hasOwnProperty.call(payload, "data_type")
          ) {
            switch (payload.data_type) {
              case "SALES":
                actionClick = () =>
                  navigate(
                    `${PATH_DASHBOARD.subpages.sale}${payload.id || ""}`
                  );
                break;
              case "INVOICE":
                actionClick = () =>
                  navigate(
                    `${PATH_DASHBOARD.subpages.sale}${payload.id || ""}`
                  );
                break;
              default:
                break;
            }
          }
          const action = (key) => (
            <>
              {actionClick && (
                <Button
                  onClick={() => {
                    actionClick();
                    closeSnackbar(key);
                  }}
                >
                  View
                </Button>
              )}
              <Button
                onClick={() => {
                  closeSnackbar(key);
                }}
              >
                Dismiss
              </Button>
            </>
          );
          enqueueSnackbar(`${data.title}\n${data.message}`, {
            variant: "info",
            action,
          });
        }
      } catch (err) {
        // console.log(err);
      }
    }; */

    const CheckPusherMessage = (type, data) => {
      switch (type) {
        case "LOGOUT":
          // logout user.
          if (data === getUA || data === "") {
            logout();
          }
          break;
        case "UPDATE":
          UpdateData(data);
          break;
        /* case "NOTIFY":
              notifyData(data);
              break; */
        default:
          break;
      }
    };

    if (isAuthenticated) {
      // listen to messages
      const pusher = new Pusher(PUSHER_APP_KEY, {
        cluster: PUSHER_APP_CLUSTER,
        channelAuthorization: {
          customHandler: async (data, callback) => {
            try {
              const token = JSON.parse(
                await authenticateDevice(data.channelName, data.socketId)
              );
              callback(false, token);
            } catch (err) {
              // console.log(err);
            }
            callback(true, "An error has occured");
          },
        },
      });
      pusher.connection.bind("connected", () => {
        const userId = (user && user.user && user.user.uid) || "";
        const shopId = (user && user.shop && user.shop.sid) || "";
        if (userId) {
          const userChannel = pusher.subscribe(`private-${userId}`);
          userChannel.bind_global((event, data) => {
            CheckPusherMessage(event, data);
          });
        }
        if (shopId) {
          const shopChannel = pusher.subscribe(`private-${shopId}`);
          shopChannel.bind_global((event, data) => {
            CheckPusherMessage(event, data);
          });
        }
        const allChannel = pusher.subscribe(`general`);
        allChannel.bind_global((event, data) => {
          CheckPusherMessage(event, data);
        });
      });
      if ("Notification" in window) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            const messaging = getMessaging();
            getToken(messaging, {
              vapidKey,
            })
              .then((currentToken) => {
                if (currentToken) {
                  saveToken(currentToken);
                }
              })
              .catch((err) => {
                // console.log(err);
              });
          }
        });
      }
      return () => {
        pusher.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return null;
}
