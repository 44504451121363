/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { merge } from "lodash";
import { Icon } from "@iconify/react";
import trendingUpFill from "@iconify/icons-eva/trending-up-fill";
import trendingDownFill from "@iconify/icons-eva/trending-down-fill";
// material
import { alpha, styled } from "@mui/material/styles";
import { Box, Card, Typography, Stack } from "@mui/material";
// utils
import { fNumber, fPercent } from "../../../utils/formatNumber";
//
import { BaseOptionChart } from "../../charts";

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  width: 24,
  height: 24,
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  marginRight: theme.spacing(1),
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16),
}));

// ----------------------------------------------------------------------
BoxForm.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  total: PropTypes.string,
  percent: PropTypes.number,
  chartData: PropTypes.array,
};
export default function BoxForm({
  title,
  color,
  total,
  subtitle,
  percent,
  chartData,
}) {
  const chartOptions = merge(BaseOptionChart(), {
    chart: { animations: { enabled: true }, sparkline: { enabled: true } },
    stroke: { width: 2 },
    tooltip: {
      x: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => "",
        },
      },
      marker: { show: false },
    },
  });
  const [Chart, setChart] = useState(null);
  useEffect(() => {
    const importChart = async () => {
      const chart = await import("react-apexcharts");
      setChart(chart);
    };
    importChart();
    return () => {
      setChart(null);
    };
  }, []);

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        display: "flex",
        alignItems: "stretch",
        p: 3,
        height: "100%",
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2" sx={{ mb: 0 }}>
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: "text.disabled", mt: 0, whiteSpace: "pre-line" }}
        >
          {subtitle}
        </Typography>
        <Typography
          sx={{ color }}
          variant={total && total.length > 7 ? "h4" : "h3"}
          gutterBottom
        >
          {total}
        </Typography>
        {percent !== undefined && (
          <Stack direction="row" alignItems="center" flexWrap="wrap">
            <IconWrapperStyle
              sx={{
                ...(percent < 0 && {
                  color: "error.main",
                  bgcolor: (theme) => alpha(theme.palette.error.main, 0.16),
                }),
              }}
            >
              <Icon
                width={16}
                height={16}
                icon={percent >= 0 ? trendingUpFill : trendingDownFill}
              />
            </IconWrapperStyle>

            <Typography variant="subtitle2" component="span">
              {percent > 0 && "+"}
              {fPercent(percent)}
            </Typography>
            <Typography
              variant="body2"
              component="span"
              sx={{ color: "text.secondary" }}
            >
              &nbsp;than last week
            </Typography>
          </Stack>
        )}
      </Box>
      {chartData && Boolean(Chart) && (
        <Chart.default
          type="line"
          series={[{ data: chartData }]}
          options={chartOptions}
          width={120}
          height={80}
        />
      )}
    </Card>
  );
}
