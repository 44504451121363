import PropTypes from "prop-types";
import { useState } from "react";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Card,
  Alert,
  TextField,
  Stack,
  CardHeader,
  Box,
  Autocomplete,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { CheckPerm, SPECIFICATIONS } from "../../../utils/apis";

// ----------------------------------------------------------------------

DeleteAttribute.propTypes = {
  handleRemoval: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  name: PropTypes.string,
};
function DeleteAttribute({ open, loading, handleRemoval, name, onClose }) {
  return (
    <Dialog open={open} maxWidth="xs" onClose={onClose}>
      <DialogTitle>
        Remove &quot;{String(name).toLocaleUpperCase()}&quot; from
        specifications?
      </DialogTitle>
      <DialogContent sx={{ width: { xs: "100%", md: 400 } }}>
        <DialogContentText sx={{ mb: 5 }}>
          Please confirm you want to remove this specification.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <LoadingButton
            fullWidth
            loading={loading}
            variant="contained"
            onClick={handleRemoval}
          >
            Yes, Proceed
          </LoadingButton>
          <Button fullWidth onClick={onClose} color="inherit">
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
ProductSpecifications.propTypes = {
  uid: PropTypes.string,
  myProfile: PropTypes.object,
};
export default function ProductSpecifications({ uid, myProfile }) {
  const { updateProductSpecifications } = useAuth();
  const [openRemoval, setOpenRemoval] = useState(false);
  const [loadingRemoval, setLoadingRemoval] = useState(false);
  const [selectRemoval, setSelectedRemoval] = useState("");

  const hasPermission = CheckPerm("update_products");
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const handleRemoval = async () => {
    try {
      setLoadingRemoval(true);
      const specifications = values.specifications.filter(
        (specification) =>
          String(specification.key).toLocaleLowerCase() !==
          String(selectRemoval).toLocaleLowerCase()
      );
      await updateProductSpecifications(specifications, uid);
      setFieldValue("specifications", specifications);
      enqueueSnackbar(`Product's specification has been removed!`, {
        variant: "success",
      });
      handleCloseRemoval();
    } catch (err) {
      enqueueSnackbar(err.message || err || "An error has occurred", {
        variant: "error",
      });
    }
    setLoadingRemoval(false);
  };
  const handleCloseRemoval = () => {
    setOpenRemoval(false);
    setSelectedRemoval("");
  };

  const Schema = Yup.object().shape({
    specifications: Yup.array().required(
      "Add atleast one specification to proceed."
    ),
  });

  const formik = useFormik({
    initialValues: {
      specifications: myProfile.specifications
        ? JSON.parse(myProfile.specifications)
        : [],
      key: "",
      value: "",
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        if (values.key && values.value) {
          const { specifications } = values;
          specifications.push({
            key: values.key,
            value: values.value,
          });
          await updateProductSpecifications(specifications, uid);
          setFieldValue("specifications", specifications);
          enqueueSnackbar(`Product's specification has been added!`, {
            variant: "success",
          });
          setFieldValue("key", "");
          setFieldValue("value", "");
          if (isMountedRef.current) {
            setSubmitting(false);
          }
        } else {
          setErrors({
            afterSubmit:
              "Please select the specification type you want to add and it's value to proceed.",
          });
        }
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, values, setFieldValue } =
    formik;

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        px: 3,
        pb: 3,
      }}
    >
      <CardHeader
        title="Product's Specifications"
        subheader="You can specify the key information regarding this product such as Dimensions, capacity, Power rating, Length etc."
      />
      <Box sx={{ p: { md: 3, xs: 1 } }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Stack direction="column" spacing={2}>
                <TableContainer>
                  <Table>
                    <TableHead
                      sx={{
                        borderBottom: (theme) =>
                          `solid 1px ${theme.palette.divider}`,
                        "& th": { backgroundColor: "transparent" },
                      }}
                    >
                      <TableRow>
                        <TableCell align="left">Specification</TableCell>
                        <TableCell align="right">Value</TableCell>
                        <TableCell colSpan={1} />
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {(values.specifications.length > 0 &&
                        values.specifications.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              borderBottom: (theme) =>
                                `solid 1px ${theme.palette.divider}`,
                            }}
                          >
                            <TableCell align="left">
                              <Box sx={{ maxWidth: 560 }}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {row.key}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell align="right">{row.value}</TableCell>
                            <TableCell>
                              <Tooltip title="Remove specification">
                                <IconButton
                                  size="small"
                                  color="error"
                                  onClick={() => {
                                    setSelectedRemoval(
                                      String(row.key).toLocaleLowerCase()
                                    );
                                    setOpenRemoval(true);
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))) || (
                        <TableCell align="center" colSpan={3}>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "error.main", textAlign: "center" }}
                          >
                            No specification added yet
                          </Typography>
                        </TableCell>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
              <Divider flexItem orientation="horizontal" sx={{ py: 2 }} />
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}
              <Autocomplete
                disabled={!hasPermission}
                sx={{ maxWidth: 1, width: 1 }}
                value={values.key}
                freeSolo
                getOptionLabel={(option) => option || ""}
                options={SPECIFICATIONS}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setFieldValue("key", newValue);
                    setFieldValue("value", "");
                  }
                }}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Specification Title"
                    variant="outlined"
                    onChange={(ev) => {
                      if (ev.target.value !== "" || ev.target.value !== null) {
                        setFieldValue("key", ev.target.value);
                      }
                    }}
                    error={Boolean(touched.type && errors.type)}
                    helperText={
                      (touched.type && errors.type) ||
                      "Select a specification to add or enter your own to proceed"
                    }
                  />
                )}
              />
              <TextField
                fullWidth
                label="Specification Value"
                InputLabelProps={{
                  shrink: true,
                }}
                value={values.value}
                onChange={(e) => setFieldValue("value", e.target.value)}
              />
            </Stack>
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Add Specification
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
      <DeleteAttribute
        open={openRemoval}
        loading={loadingRemoval}
        handleRemoval={handleRemoval}
        name={selectRemoval}
        onClose={handleCloseRemoval}
      />
    </Card>
  );
}
