import { NumericFormat } from "react-number-format";
import useAuth from "../hooks/useAuth";
import { PATH_DASHBOARD } from "../routes/paths";

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_API = "https://api.selpay.co/app/";
const ROOTS_ASSETS = "https://assets.selpay.co/";

export const API_PATH = {
  root: ROOTS_API,
  dashboard: path(ROOTS_API, "dashboard"),
  admins: path(ROOTS_API, "admins"),
  products: path(ROOTS_API, "products"),
  branches: path(ROOTS_API, "branches"),
  waybills: path(ROOTS_API, "waybills"),
  login: path(ROOTS_API, "login"),
  verify: path(ROOTS_API, "verify"),
  forgot: path(ROOTS_API, "forgot-password"),
  profile: path(ROOTS_API, "profile"),
  roles: path(ROOTS_API, "roles"),
  settings: path(ROOTS_API, "settings"),
  signup: path(ROOTS_API, "signup"),
  misc: path(ROOTS_API, "misc"),
  featuredProducts: path(ROOTS_API, "featured-products"),
  specialOffers: path(ROOTS_API, "special-offers"),
  categories: path(ROOTS_API, "categories"),
  discountCodes: path(ROOTS_API, "discount-codes"),
  reviews: path(ROOTS_API, "reviews"),
  customers: path(ROOTS_API, "customers"),
  staff: path(ROOTS_API, "staff"),
  sales: path(ROOTS_API, "sales"),
  invoices: path(ROOTS_API, "invoices"),
  quotations: path(ROOTS_API, "quotations"),
  returned: path(ROOTS_API, "returned-products"),
  debtors: path(ROOTS_API, "debtors"),
  devices: path(ROOTS_API, "devices"),
  damaged: path(ROOTS_API, "damaged-products"),
  companies: path(ROOTS_API, "companies"),
  purchases: path(ROOTS_API, "purchases"),
  online: path(ROOTS_API, "online-sales"),
  expense: path(ROOTS_API, "expenses"),
  subscription: path(ROOTS_API, "subscription"),
  withdrawals: path(ROOTS_API, "withdrawals"),
  sale: path(ROOTS_API, "sale"),
  message: path(ROOTS_API, "message"),
  log: path(ROOTS_API, "log"),
  notifications: path(ROOTS_API, "notifications"),
  importExport: path(ROOTS_API, "import-export"),
  support: path(ROOTS_API, "support"),
  paymentAnalysis: path(ROOTS_API, "payment-analysis"),
  storeAnalysis: path(ROOTS_API, "store-analysis"),
  generalAnalysis: path(ROOTS_API, "general-analysis"),
  report: path(ROOTS_API, "report"),
  cards: path(ROOTS_API, "cards"),
  scan: path(ROOTS_API, "scan"),
  bankTransfers: path(ROOTS_API, "bank-transfers"),
  discrepancy_reports: path(ROOTS_API, "discrepancy-reports"),
  orders: path(ROOTS_API, "orders"),
};

export const ASSETS_PATH = {
  logos: path(ROOTS_ASSETS, "logos/"),
  users: path(ROOTS_ASSETS, "users/"),
  products: path(ROOTS_ASSETS, "products/"),
  staff: path(ROOTS_ASSETS, "staff/"),
  receipts: path(ROOTS_ASSETS, "receipts/"),
  attachments: path(ROOTS_ASSETS, "attachments/"),
  files: path(ROOTS_ASSETS, "files/"),
  cards: path(ROOTS_ASSETS, "cards/"),
  histories: path(ROOTS_ASSETS, "histories/"),
  featuredImages: path(ROOTS_ASSETS, "featured-images/"),
  posts: path(ROOTS_ASSETS, "posts/"),
  agreements: path(ROOTS_ASSETS, "agreements/"),
};

export const STATES = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "FCT - Abuja",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];
export const MONTHS = [
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

export const LGAS = [
  {
    state: "Abia",
    lgas: [
      "Aba North",
      "Aba South",
      "Arochukwu",
      "Bende",
      "Ikawuno",
      "Ikwuano",
      "Isiala-Ngwa North",
      "Isiala-Ngwa South",
      "Isuikwuato",
      "Umu Nneochi",
      "Obi Ngwa",
      "Obioma Ngwa",
      "Ohafia",
      "Ohaozara",
      "Osisioma",
      "Ugwunagbo",
      "Ukwa West",
      "Ukwa East",
      "Umuahia North",
      "Umuahia South",
    ],
  },
  {
    state: "Adamawa",
    lgas: [
      "Demsa",
      "Fufore",
      "Ganye",
      "Girei",
      "Gombi",
      "Guyuk",
      "Hong",
      "Jada",
      "Lamurde",
      "Madagali",
      "Maiha",
      "Mayo-Belwa",
      "Michika",
      "Mubi-North",
      "Mubi-South",
      "Numan",
      "Shelleng",
      "Song",
      "Toungo",
      "Yola North",
      "Yola South",
    ],
  },
  {
    state: "Akwa Ibom",
    lgas: [
      "Abak",
      "Eastern-Obolo",
      "Eket",
      "Esit-Eket",
      "Essien-Udim",
      "Etim-Ekpo",
      "Etinan",
      "Ibeno",
      "Ibesikpo-Asutan",
      "Ibiono-Ibom",
      "Ika",
      "Ikono",
      "Ikot-Abasi",
      "Ikot-Ekpene",
      "Ini",
      "Itu",
      "Mbo",
      "Mkpat-Enin",
      "Nsit-Atai",
      "Nsit-Ibom",
      "Nsit-Ubium",
      "Obot-Akara",
      "Okobo",
      "Onna",
      "Oron",
      "Oruk Anam",
      "Udung-Uko",
      "Ukanafun",
      "Urue-Offong/Oruko",
      "Uruan",
      "Uyo",
    ],
  },
  {
    state: "Anambra",
    lgas: [
      "Aguata",
      "Anambra East",
      "Anambra West",
      "Anaocha",
      "Awka North",
      "Awka South",
      "Ayamelum",
      "Dunukofia",
      "Ekwusigo",
      "Idemili-North",
      "Idemili-South",
      "Ihiala",
      "Njikoka",
      "Nnewi-North",
      "Nnewi-South",
      "Ogbaru",
      "Onitsha-North",
      "Onitsha-South",
      "Orumba-North",
      "Orumba-South",
    ],
  },
  {
    state: "Bauchi",
    lgas: [
      "Alkaleri",
      "Bauchi",
      "Bogoro",
      "Damban",
      "Darazo",
      "Dass",
      "Gamawa",
      "Ganjuwa",
      "Giade",
      "Itas/Gadau",
      "Jama'Are",
      "Katagum",
      "Kirfi",
      "Misau",
      "Ningi",
      "Shira",
      "Tafawa-Balewa",
      "Toro",
      "Warji",
      "Zaki",
    ],
  },
  {
    state: "Benue",
    lgas: [
      "Ado",
      "Agatu",
      "Apa",
      "Buruku",
      "Gboko",
      "Guma",
      "Gwer-East",
      "Gwer-West",
      "Katsina-Ala",
      "Konshisha",
      "Kwande",
      "Logo",
      "Makurdi",
      "Ogbadibo",
      "Ohimini",
      "Oju",
      "Okpokwu",
      "Otukpo",
      "Tarka",
      "Ukum",
      "Ushongo",
      "Vandeikya",
    ],
  },
  {
    state: "Borno",
    lgas: [
      "Abadam",
      "Askira-Uba",
      "Bama",
      "Bayo",
      "Biu",
      "Chibok",
      "Damboa",
      "Dikwa",
      "Gubio",
      "Guzamala",
      "Gwoza",
      "Hawul",
      "Jere",
      "Kaga",
      "Kala/Balge",
      "Konduga",
      "Kukawa",
      "Kwaya-Kusar",
      "Mafa",
      "Magumeri",
      "Maiduguri",
      "Marte",
      "Mobbar",
      "Monguno",
      "Ngala",
      "Nganzai",
      "Shani",
    ],
  },
  {
    state: "Bayelsa",
    lgas: [
      "Brass",
      "Ekeremor",
      "Kolokuma/Opokuma",
      "Nembe",
      "Ogbia",
      "Sagbama",
      "Southern-Ijaw",
      "Yenagoa",
    ],
  },
  {
    state: "Cross River",
    lgas: [
      "Abi",
      "Akamkpa",
      "Akpabuyo",
      "Bakassi",
      "Bekwarra",
      "Biase",
      "Boki",
      "Calabar-Municipal",
      "Calabar-South",
      "Etung",
      "Ikom",
      "Obanliku",
      "Obubra",
      "Obudu",
      "Odukpani",
      "Ogoja",
      "Yakurr",
      "Yala",
    ],
  },
  {
    state: "Delta",
    lgas: [
      "Aniocha North",
      "Aniocha-North",
      "Aniocha-South",
      "Bomadi",
      "Burutu",
      "Ethiope-East",
      "Ethiope-West",
      "Ika-North-East",
      "Ika-South",
      "Isoko-North",
      "Isoko-South",
      "Ndokwa-East",
      "Ndokwa-West",
      "Okpe",
      "Oshimili-North",
      "Oshimili-South",
      "Patani",
      "Sapele",
      "Udu",
      "Ughelli-North",
      "Ughelli-South",
      "Ukwuani",
      "Uvwie",
      "Warri South-West",
      "Warri North",
      "Warri South",
    ],
  },
  {
    state: "Ebonyi",
    lgas: [
      "Abakaliki",
      "Afikpo-North",
      "Afikpo South (Edda)",
      "Ebonyi",
      "Ezza-North",
      "Ezza-South",
      "Ikwo",
      "Ishielu",
      "Ivo",
      "Izzi",
      "Ohaukwu",
      "Onicha",
    ],
  },
  {
    state: "Edo",
    lgas: [
      "Akoko Edo",
      "Egor",
      "Esan-Central",
      "Esan-North-East",
      "Esan-South-East",
      "Esan-West",
      "Etsako-Central",
      "Etsako-East",
      "Etsako-West",
      "Igueben",
      "Ikpoba-Okha",
      "Oredo",
      "Orhionmwon",
      "Ovia-North-East",
      "Ovia-South-West",
      "Owan East",
      "Owan-West",
      "Uhunmwonde",
    ],
  },
  {
    state: "Ekiti",
    lgas: [
      "Ado-Ekiti",
      "Efon",
      "Ekiti-East",
      "Ekiti-South-West",
      "Ekiti-West",
      "Emure",
      "Gbonyin",
      "Ido-Osi",
      "Ijero",
      "Ikere",
      "Ikole",
      "Ilejemeje",
      "Irepodun/Ifelodun",
      "Ise-Orun",
      "Moba",
      "Oye",
    ],
  },
  {
    state: "Enugu",
    lgas: [
      "Aninri",
      "Awgu",
      "Enugu-East",
      "Enugu-North",
      "Enugu-South",
      "Ezeagu",
      "Igbo-Etiti",
      "Igbo-Eze-North",
      "Igbo-Eze-South",
      "Isi-Uzo",
      "Nkanu-East",
      "Nkanu-West",
      "Nsukka",
      "Oji-River",
      "Udenu",
      "Udi",
      "Uzo-Uwani",
    ],
  },
  {
    state: "FCT - Abuja",
    lgas: ["Abuja", "Kwali", "Kuje", "Gwagwalada", "Bwari", "Abaji"],
  },
  {
    state: "Gombe",
    lgas: [
      "Akko",
      "Balanga",
      "Billiri",
      "Dukku",
      "Funakaye",
      "Gombe",
      "Kaltungo",
      "Kwami",
      "Nafada",
      "Shongom",
      "Yamaltu/Deba",
    ],
  },
  {
    state: "Imo",
    lgas: [
      "Aboh-Mbaise",
      "Ahiazu-Mbaise",
      "Ehime-Mbano",
      "Ezinihitte",
      "Ideato-North",
      "Ideato-South",
      "Ihitte/Uboma",
      "Ikeduru",
      "Isiala-Mbano",
      "Isu",
      "Mbaitoli",
      "Ngor-Okpala",
      "Njaba",
      "Nkwerre",
      "Nwangele",
      "Obowo",
      "Oguta",
      "Ohaji-Egbema",
      "Okigwe",
      "Onuimo",
      "Orlu",
      "Orsu",
      "Oru-East",
      "Oru-West",
      "Owerri-Municipal",
      "Owerri-North",
      "Owerri-West",
    ],
  },
  {
    state: "Jigawa",
    lgas: [
      "Auyo",
      "Babura",
      "Biriniwa",
      "Birnin-Kudu",
      "Buji",
      "Dutse",
      "Gagarawa",
      "Garki",
      "Gumel",
      "Guri",
      "Gwaram",
      "Gwiwa",
      "Hadejia",
      "Jahun",
      "Kafin-Hausa",
      "Kaugama",
      "Kazaure",
      "Kiri kasama",
      "Maigatari",
      "Malam Madori",
      "Miga",
      "Ringim",
      "Roni",
      "Sule-Tankarkar",
      "Taura",
      "Yankwashi",
    ],
  },
  {
    state: "Kebbi",
    lgas: [
      "Aleiro",
      "Arewa-Dandi",
      "Argungu",
      "Augie",
      "Bagudo",
      "Birnin-Kebbi",
      "Bunza",
      "Dandi",
      "Fakai",
      "Gwandu",
      "Jega",
      "Kalgo",
      "Koko-Besse",
      "Maiyama",
      "Ngaski",
      "Sakaba",
      "Shanga",
      "Suru",
      "Wasagu/Danko",
      "Yauri",
      "Zuru",
    ],
  },
  {
    state: "Kaduna",
    lgas: [
      "Birnin-Gwari",
      "Chikun",
      "Giwa",
      "Igabi",
      "Ikara",
      "Jaba",
      "Jema'A",
      "Kachia",
      "Kaduna-North",
      "Kaduna-South",
      "Kagarko",
      "Kajuru",
      "Kaura",
      "Kauru",
      "Kubau",
      "Kudan",
      "Lere",
      "Makarfi",
      "Sabon-Gari",
      "Sanga",
      "Soba",
      "Zangon-Kataf",
      "Zaria",
    ],
  },
  {
    state: "Kano",
    lgas: [
      "Ajingi",
      "Albasu",
      "Bagwai",
      "Bebeji",
      "Bichi",
      "Bunkure",
      "Dala",
      "Dambatta",
      "Dawakin-Kudu",
      "Dawakin-Tofa",
      "Doguwa",
      "Fagge",
      "Gabasawa",
      "Garko",
      "Garun-Mallam",
      "Gaya",
      "Gezawa",
      "Gwale",
      "Gwarzo",
      "Kabo",
      "Kano-Municipal",
      "Karaye",
      "Kibiya",
      "Kiru",
      "Kumbotso",
      "Kunchi",
      "Kura",
      "Madobi",
      "Makoda",
      "Minjibir",
      "Nasarawa",
      "Rano",
      "Rimin-Gado",
      "Rogo",
      "Shanono",
      "Sumaila",
      "Takai",
      "Tarauni",
      "Tofa",
      "Tsanyawa",
      "Tudun-Wada",
      "Ungogo",
      "Warawa",
      "Wudil",
    ],
  },
  {
    state: "Kogi",
    lgas: [
      "Adavi",
      "Ajaokuta",
      "Ankpa",
      "Dekina",
      "Ibaji",
      "Idah",
      "Igalamela-Odolu",
      "Ijumu",
      "Kabba/Bunu",
      "Kogi",
      "Lokoja",
      "Mopa-Muro",
      "Ofu",
      "Ogori/Magongo",
      "Okehi",
      "Okene",
      "Olamaboro",
      "Omala",
      "Oyi",
      "Yagba-East",
      "Yagba-West",
    ],
  },
  {
    state: "Katsina",
    lgas: [
      "Bakori",
      "Batagarawa",
      "Batsari",
      "Baure",
      "Bindawa",
      "Charanchi",
      "Dan-Musa",
      "Dandume",
      "Danja",
      "Daura",
      "Dutsi",
      "Dutsin-Ma",
      "Faskari",
      "Funtua",
      "Ingawa",
      "Jibia",
      "Kafur",
      "Kaita",
      "Kankara",
      "Kankia",
      "Katsina",
      "Kurfi",
      "Kusada",
      "Mai-Adua",
      "Malumfashi",
      "Mani",
      "Mashi",
      "Matazu",
      "Musawa",
      "Rimi",
      "Sabuwa",
      "Safana",
      "Sandamu",
      "Zango",
    ],
  },
  {
    state: "Kwara",
    lgas: [
      "Asa",
      "Baruten",
      "Edu",
      "Ekiti (Araromi/Opin)",
      "Ilorin-East",
      "Ilorin-South",
      "Ilorin-West",
      "Isin",
      "Kaiama",
      "Moro",
      "Offa",
      "Oke-Ero",
      "Oyun",
      "Pategi",
    ],
  },
  {
    state: "Lagos",
    lgas: [
      "Agege",
      "Ajeromi-Ifelodun",
      "Alimosho",
      "Amuwo-Odofin",
      "Apapa",
      "Badagry",
      "Epe",
      "Eti-Osa",
      "Ibeju-Lekki",
      "Ifako-Ijaiye",
      "Ikeja",
      "Ikorodu",
      "Kosofe",
      "Lagos-Island",
      "Lagos-Mainland",
      "Mushin",
      "Ojo",
      "Oshodi-Isolo",
      "Shomolu",
      "Surulere",
      "Yewa-South",
    ],
  },
  {
    state: "Nasarawa",
    lgas: [
      "Akwanga",
      "Awe",
      "Doma",
      "Karu",
      "Keana",
      "Keffi",
      "Kokona",
      "Lafia",
      "Nasarawa",
      "Nasarawa-Eggon",
      "Obi",
      "Wamba",
      "Toto",
    ],
  },
  {
    state: "Niger",
    lgas: [
      "Agaie",
      "Agwara",
      "Bida",
      "Borgu",
      "Bosso",
      "Chanchaga",
      "Edati",
      "Gbako",
      "Gurara",
      "Katcha",
      "Kontagora",
      "Lapai",
      "Lavun",
      "Magama",
      "Mariga",
      "Mashegu",
      "Mokwa",
      "Moya",
      "Paikoro",
      "Rafi",
      "Rijau",
      "Shiroro",
      "Suleja",
      "Tafa",
      "Wushishi",
    ],
  },
  {
    state: "Ogun",
    lgas: [
      "Abeokuta-North",
      "Abeokuta-South",
      "Ado-Odo/Ota",
      "Ewekoro",
      "Ifo",
      "Ijebu-East",
      "Ijebu-North",
      "Ijebu-North-East",
      "Ijebu-Ode",
      "Ikenne",
      "Imeko-Afon",
      "Ipokia",
      "Obafemi-Owode",
      "Odeda",
      "Odogbolu",
      "Ogun-Waterside",
      "Remo-North",
      "Shagamu",
      "Yewa North",
    ],
  },
  {
    state: "Ondo",
    lgas: [
      "Akoko North-East",
      "Akoko North-West",
      "Akoko South-West",
      "Akoko South-East",
      "Akure-North",
      "Akure-South",
      "Ese-Odo",
      "Idanre",
      "Ifedore",
      "Ilaje",
      "Ile-Oluji-Okeigbo",
      "Irele",
      "Odigbo",
      "Okitipupa",
      "Ondo West",
      "Ondo-East",
      "Ose",
      "Owo",
    ],
  },
  {
    state: "Osun",
    lgas: [
      "Atakumosa West",
      "Atakumosa East",
      "Ayedaade",
      "Ayedire",
      "Boluwaduro",
      "Boripe",
      "Ede South",
      "Ede North",
      "Egbedore",
      "Ejigbo",
      "Ife North",
      "Ife South",
      "Ife-Central",
      "Ife-East",
      "Ifelodun",
      "Ila",
      "Ilesa-East",
      "Ilesa-West",
      "Irepodun",
      "Irewole",
      "Isokan",
      "Iwo",
      "Obokun",
      "Odo-Otin",
      "Ola Oluwa",
      "Olorunda",
      "Oriade",
      "Orolu",
      "Osogbo",
    ],
  },
  {
    state: "Oyo",
    lgas: [
      "Afijio",
      "Akinyele",
      "Atiba",
      "Atisbo",
      "Egbeda",
      "Ibadan North",
      "Ibadan North-East",
      "Ibadan North-West",
      "Ibadan South-East",
      "Ibadan South-West",
      "Ibarapa-Central",
      "Ibarapa-East",
      "Ibarapa-North",
      "Ido",
      "Ifedayo",
      "Irepo",
      "Iseyin",
      "Itesiwaju",
      "Iwajowa",
      "Kajola",
      "Lagelu",
      "Ogo-Oluwa",
      "Ogbomosho-North",
      "Ogbomosho-South",
      "Olorunsogo",
      "Oluyole",
      "Ona-Ara",
      "Orelope",
      "Ori-Ire",
      "Oyo-West",
      "Oyo-East",
      "Saki-East",
      "Saki-West",
      "Surulere",
    ],
  },
  {
    state: "Plateau",
    lgas: [
      "Barkin-Ladi",
      "Bassa",
      "Bokkos",
      "Jos-East",
      "Jos-North",
      "Jos-South",
      "Kanam",
      "Kanke",
      "Langtang-North",
      "Langtang-South",
      "Mangu",
      "Mikang",
      "Pankshin",
      "Qua'an Pan",
      "Riyom",
      "Shendam",
      "Wase",
    ],
  },
  {
    state: "Rivers",
    lgas: [
      "Abua/Odual",
      "Ahoada-East",
      "Ahoada-West",
      "Akuku Toru",
      "Andoni",
      "Asari-Toru",
      "Bonny",
      "Degema",
      "Eleme",
      "Emuoha",
      "Etche",
      "Gokana",
      "Ikwerre",
      "Khana",
      "Obio/Akpor",
      "Ogba-Egbema-Ndoni",
      "Ogba/Egbema/Ndoni",
      "Ogu/Bolo",
      "Okrika",
      "Omuma",
      "Opobo/Nkoro",
      "Oyigbo",
      "Port-Harcourt",
      "Tai",
    ],
  },
  {
    state: "Sokoto",
    lgas: [
      "Binji",
      "Bodinga",
      "Dange-Shuni",
      "Gada",
      "Goronyo",
      "Gudu",
      "Gwadabawa",
      "Illela",
      "Kebbe",
      "Kware",
      "Rabah",
      "Sabon Birni",
      "Shagari",
      "Silame",
      "Sokoto-North",
      "Sokoto-South",
      "Tambuwal",
      "Tangaza",
      "Tureta",
      "Wamako",
      "Wurno",
      "Yabo",
    ],
  },
  {
    state: "Taraba",
    lgas: [
      "Ardo-Kola",
      "Bali",
      "Donga",
      "Gashaka",
      "Gassol",
      "Ibi",
      "Jalingo",
      "Karim-Lamido",
      "Kurmi",
      "Lau",
      "Sardauna",
      "Takum",
      "Ussa",
      "Wukari",
      "Yorro",
      "Zing",
    ],
  },
  {
    state: "Yobe",
    lgas: [
      "Bade",
      "Bursari",
      "Damaturu",
      "Fika",
      "Fune",
      "Geidam",
      "Gujba",
      "Gulani",
      "Jakusko",
      "Karasuwa",
      "Machina",
      "Nangere",
      "Nguru",
      "Potiskum",
      "Tarmuwa",
      "Yunusari",
      "Yusufari",
    ],
  },
  {
    state: "Zamfara",
    lgas: [
      "Anka",
      "Bakura",
      "Birnin Magaji/Kiyaw",
      "Bukkuyum",
      "Bungudu",
      "Gummi",
      "Gusau",
      "Isa",
      "Kaura-Namoda",
      "Kiyawa",
      "Maradun",
      "Maru",
      "Shinkafi",
      "Talata-Mafara",
      "Tsafe",
      "Zurmi",
    ],
  },
];

export const METHOD_OPTIONS = [
  "pos",
  "bank transfer",
  "cash",
  "credit",
  "gift card",
  "loyalty card",
];
export const SPECIFICATIONS = [
  "Quality",
  "Length",
  "Width",
  "Size",
  "Dimension",
  "Interior",
  "Exterior",
  "Power Rating",
  "Capacity",
];
export const VARIETIES = [
  {
    name: "Sizes",
    value: "size",
    options: ["S", "M", "L", "XL", "XXL", "XXXL"],
  },
  {
    name: "Shapes",
    value: "shape",
    options: ["Circle", "Rectangle", "Square", "Oval", "Round", "Oblong"],
  },
  {
    name: "Gender",
    value: "gender",
    options: ["Female", "Male", "Others"],
  },
  {
    name: "Colours",
    value: "color",
    options: [
      `AliceBlue`,
      `AntiqueWhite`,
      `Aqua`,
      `Aquamarine`,
      `Azure`,
      `Beige`,
      `Bisque`,
      `Black`,
      `BlanchedAlmond`,
      `Blue`,
      `BlueViolet`,
      `Brown`,
      `BurlyWood`,
      `CadetBlue`,
      `Chartreuse`,
      `Chocolate`,
      `Coral`,
      `CornflowerBlue`,
      `Cornsilk`,
      `Crimson`,
      `Cyan`,
      `DarkBlue`,
      `DarkCyan`,
      `DarkGoldenRod`,
      `DarkGray`,
      `DarkGrey`,
      `DarkGreen`,
      `DarkKhaki`,
      `DarkMagenta`,
      `DarkOliveGreen`,
      `Darkorange`,
      `DarkOrchid`,
      `DarkRed`,
      `DarkSalmon`,
      `DarkSeaGreen`,
      `DarkSlateBlue`,
      `DarkSlateGray`,
      `DarkSlateGrey`,
      `DarkTurquoise`,
      `DarkViolet`,
      `DeepPink`,
      `DeepSkyBlue`,
      `DimGray`,
      `DimGrey`,
      `DodgerBlue`,
      `FireBrick`,
      `FloralWhite`,
      `ForestGreen`,
      `Fuchsia`,
      `Gainsboro`,
      `GhostWhite`,
      `Gold`,
      `GoldenRod`,
      `Gray`,
      `Grey`,
      `Green`,
      `GreenYellow`,
      `HoneyDew`,
      `HotPink`,
      `IndianRed`,
      `Indigo`,
      `Ivory`,
      `Khaki`,
      `Lavender`,
      `LavenderBlush`,
      `LawnGreen`,
      `LemonChiffon`,
      `LightBlue`,
      `LightCoral`,
      `LightCyan`,
      `LightGoldenRodYellow`,
      `LightGray`,
      `LightGrey`,
      `LightGreen`,
      `LightPink`,
      `LightSalmon`,
      `LightSeaGreen`,
      `LightSkyBlue`,
      `LightSlateGray`,
      `LightSlateGrey`,
      `LightSteelBlue`,
      `LightYellow`,
      `Lime`,
      `LimeGreen`,
      `Linen`,
      `Magenta`,
      `Maroon`,
      `MediumAquaMarine`,
      `MediumBlue`,
      `MediumOrchid`,
      `MediumPurple`,
      `MediumSeaGreen`,
      `MediumSlateBlue`,
      `MediumSpringGreen`,
      `MediumTurquoise`,
      `MediumVioletRed`,
      `MidnightBlue`,
      `MintCream`,
      `MistyRose`,
      `Moccasin`,
      `NavajoWhite`,
      `Navy`,
      `OldLace`,
      `Olive`,
      `OliveDrab`,
      `Orange`,
      `OrangeRed`,
      `Orchid`,
      `PaleGoldenRod`,
      `PaleGreen`,
      `PaleTurquoise`,
      `PaleVioletRed`,
      `PapayaWhip`,
      `PeachPuff`,
      `Peru`,
      `Pink`,
      `Plum`,
      `PowderBlue`,
      `Purple`,
      `Red`,
      `RosyBrown`,
      `RoyalBlue`,
      `SaddleBrown`,
      `Salmon`,
      `SandyBrown`,
      `SeaGreen`,
      `SeaShell`,
      `Sienna`,
      `Silver`,
      `SkyBlue`,
      `SlateBlue`,
      `SlateGray`,
      `SlateGrey`,
      `Snow`,
      `SpringGreen`,
      `SteelBlue`,
      `Tan`,
      `Teal`,
      `Thistle`,
      `Tomato`,
      `Turquoise`,
      `Violet`,
      `Wheat`,
      `White`,
      `WhiteSmoke`,
      `Yellow`,
      `YellowGreen`,
    ],
  },
];
export const INVOICE_METHOD_OPTIONS = ["pos", "bank transfer", "cash"];
export const COMPLIANCE_TYPES = [
  {
    label: "Starter",
    description: "You have not registered your business.",
    value: "0",
  },
  {
    label: "Registered",
    description: "Your business is registered.",
    value: "1",
  },
];
export const ECOMMERCE_TYPES = [
  {
    label: "SelPlace.com",
    description:
      "Your products will be displayed on our ecommerce website, selplace.com. You'll reach our wide array of customers from all over the country and beyond!",
    value: "0",
  },
  {
    label: "My Website Only",
    description:
      "Select this option if you have your own ecommerce website and want to display your products only on your website.",
    value: "1",
  },
  {
    label: "SelPlace.com and My Website",
    description:
      "Your products will be displayed on our ecommerce website, selplace.com as well as your own website.",
    value: "2",
  },
];
export const COMPLIANCE_STATUS = [
  { label: "Pending", color: "warning" },
  {
    label: "Verified",
    color: "success",
  },
  { label: "Failed", color: "error" },
];
export const LABEL_WIDTH = [
  { name: "2in x 1in", size: "2x1" },
  { name: "2in x 2in", size: "2x2" },
  { name: "2in x 3in", size: "2x3" },
  { name: "2in x 4in", size: "2x4" },
  { name: "4in x 4in", size: "4x4" },
  { name: "4in x 6in", size: "4x6" },
];
export const CONNECTIVITY_STATUS = [
  { label: "Pending", color: "warning" },
  {
    label: "Connected",
    color: "success",
  },
  { label: "Failed", color: "error" },
];
export const TRANSFER_STATUS = [
  {
    label: "Amount Received",
    color: "success",
    value: "received",
    icon: "wpf:paid",
  },
  {
    label: "Paid Payouts",
    color: "error",
    value: "paidOut",
    icon: "material-symbols:pending-actions",
  },
  {
    label: "Pending Payouts",
    color: "warning",
    value: "pendingPayout",
    icon: "gg:calendar-due",
  },
  {
    label: "Total Payouts",
    color: "info",
    value: "totalPayout",
    icon: "carbon:license-draft",
  },
];
export const PLAN_LEVELS = { 189765: 0, 189823: 1, 189978: 2, 189768: 3 };
export const CANCELLATION_REASONS = [
  "The product(s) selected are not available.",
  "The product(s) selected are out of stock.",
  "The store is currently closed.",
  "Other",
];
export const SALE_STATUS = [
  { label: "Paid", color: "success", value: "0", icon: "wpf:paid" },
  {
    label: "Unpaid",
    color: "error",
    value: "1",
    icon: "material-symbols:pending-actions",
  },
  { label: "Overdue", color: "warning", value: "2", icon: "gg:calendar-due" },
  {
    label: "Pending Delivery",
    color: "info",
    value: "5",
    icon: "carbon:delivery",
  },
  {
    label: "Draft",
    color: "warning",
    value: "4",
    icon: "carbon:license-draft",
  },
];
export const ORDER_STATUS = [
  {
    label: "Pending",
    color: "warning",
    value: "0",
    icon: "mdi:receipt-text-pending",
  },
  {
    label: "Accepted",
    color: "success",
    value: "1",
    icon: "healthicons:i-documents-accepted-outline",
  },
  {
    label: "Cancelled",
    color: "error",
    value: "2",
    icon: "carbon:rule-cancelled",
  },
  {
    label: "Enroute",
    color: "info",
    value: "3",
    icon: "hugeicons:delivered-sent",
  },
  {
    label: "Delivered",
    color: "info",
    value: "4",
    icon: "hugeicons:package-delivered",
  },
  {
    label: "Completed",
    color: "info",
    value: "5",
    icon: "mdi:store-complete-outline",
  },
  {
    label: "Rejected",
    color: "warning",
    value: "6",
    icon: "icon-park-outline:reject",
  },
  {
    label: "Refunded",
    color: "error",
    value: "7",
    icon: "heroicons:receipt-refund",
  },
];
export const RATINGS_NUMBERS = [
  {
    label: "1 star",
    color: "error",
    value: "1",
    icon: "streamline:star-1-solid",
  },
  {
    label: "2 stars",
    color: "error",
    value: "2",
    icon: "solar:stars-bold",
  },
  {
    label: "3 stars",
    color: "warning",
    value: "3",
    icon: "tabler:stars-filled",
  },
  {
    label: "4 stars",
    color: "success",
    value: "4",
    icon: "wi:stars",
  },
  {
    label: "5 stars",
    color: "success",
    value: "5",
    icon: "game-icons:knocked-out-stars",
  },
];
export const INVOICE_STATUS = [
  {
    label: "Unpaid",
    color: "error",
    value: "1",
    icon: "material-symbols:pending-actions",
  },
  { label: "Paid", color: "success", value: "0", icon: "wpf:paid" },
  { label: "Overdue", color: "warning", value: "2", icon: "gg:calendar-due" },
];
export const QUOTE_STATUS = [
  {
    label: "Sent",
    color: "success",
    value: "1",
    icon: "icon-park-solid:message-sent",
  },
  {
    label: "Pending",
    color: "warning",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
];
export const QUOTE_GREETINGS = ["Dear Sir", "Dear Ma", "Dear Sir / Ma", ""];
export const DISCOUNT_CODES_TYPES = ["Flat Amount", "Percentage Discount"];
export const RETURNED_STATUS = [
  {
    label: "Pending",
    color: "warning",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  {
    label: "Refunded",
    color: "error",
    value: "1",
    icon: "mdi:credit-card-refund-outline",
  },
  {
    label: "Fixed or Repaired",
    color: "success",
    value: "2",
    icon: "iconoir:laptop-fix",
  },
];
export const DEBTOR_STATUS = [
  {
    label: "Pending",
    color: "error",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  {
    label: "Part Payment",
    color: "warning",
    value: "1",
    icon: "carbon:license-third-party",
  },
  { label: "Paid", color: "success", value: "2", icon: "wpf:paid" },
];
export const PURCHASE_STATUS = [
  {
    label: "Pending",
    color: "error",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  {
    label: "Part Payment",
    color: "warning",
    value: "1",
    icon: "carbon:license-third-party",
  },
  { label: "Paid", color: "success", value: "2", icon: "wpf:paid" },
];
export const DISCREPANCY_STATUS = [
  {
    label: "Pending",
    color: "error",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  {
    label: "Resolved",
    color: "success",
    value: "1",
    icon: "ant-design:file-done-outlined",
  },
];
export const ONLINE_STATUS = [
  {
    label: "Pending",
    color: "warning",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  { label: "Sold", color: "success", value: "1", icon: "wpf:paid" },
];
export const EXPENSE_STATUS = [
  {
    label: "Pending",
    color: "warning",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  { label: "Paid", color: "success", value: "1", icon: "wpf:paid" },
];
export const DEVICE_TYPES = [
  {
    label: "Web/Browser",
    color: "warning",
    value: "0",
    icon: "foundation:web",
  },
  {
    label: "Windows",
    color: "info",
    value: "1",
    icon: "ant-design:windows-filled",
  },
  { label: "Mac OS", color: "secondary", value: "2", icon: "wpf:mac-os" },
  {
    label: "Android (Mobile)",
    color: "primary",
    value: "3",
    icon: "ant-design:android-filled",
  },
  {
    label: "IOS (Mobile)",
    color: "primary",
    value: "4",
    icon: "cib:app-store-ios",
  },
];
export const EXPENSE_ANALYSIS = [
  { label: "Today", color: "warning", value: "0", icon: "uiw:pay" },
  { label: "This Week", color: "info", value: "1", icon: "uiw:pay" },
  { label: "This Month", color: "secondary", value: "2", icon: "uiw:pay" },
  { label: "This Year", color: "primary", value: "3", icon: "uiw:pay" },
];
export const WITHDRAWAL_ANALYSIS = [
  { label: "Today", color: "warning", value: "0", icon: "uil:money-withdraw" },
  { label: "This Week", color: "info", value: "1", icon: "uil:money-withdraw" },
  {
    label: "This Month",
    color: "secondary",
    value: "2",
    icon: "uil:money-withdraw",
  },
  {
    label: "This Year",
    color: "primary",
    value: "3",
    icon: "uil:money-withdraw",
  },
];
export const DAMAGED_STATUS = [
  {
    label: "Pending",
    color: "warning",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  { label: "Fixed", color: "success", value: "1", icon: "iconoir:laptop-fix" },
  {
    label: "Returned",
    color: "info",
    value: "2",
    icon: "mdi:credit-card-refund-outline",
  },
];
export const CARD_REQUESTS_STATUS = [
  {
    label: "Pending",
    color: "warning",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  {
    label: "Delivered",
    color: "success",
    value: "1",
    icon: "iconoir:laptop-fix",
  },
  {
    label: "Refunded",
    color: "info",
    value: "2",
    icon: "mdi:credit-card-refund-outline",
  },
];
export const CARD_ASSIGNED_STATUS = [
  {
    label: "Pending",
    color: "warning",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  { label: "Active", color: "success", value: "1", icon: "iconoir:laptop-fix" },
  {
    label: "Cancelled",
    color: "error",
    value: "2",
    icon: "fluent:calendar-cancel-20-filled",
  },
  {
    label: "Used",
    color: "info",
    value: "3",
    icon: "fluent:gift-card-multiple-24-filled",
  },
];
export const PRODUCT_STATUS = [
  { label: "Suspended", color: "error", value: "0" },
  { label: "Available", color: "success", value: "1" },
];
export const PRODUCT_ECOMMERCE_STATUS = [
  { label: "Not Available For Ecommerce", color: "error", value: "0" },
  { label: "Available For Ecommerce", color: "success", value: "1" },
];
export const FEATURED_PRODUCT_STATUS = [
  { label: "Pending", color: "warning", value: "0" },
  { label: "Active", color: "success", value: "1" },
  { label: "Concluded", color: "success", value: "2" },
];
export const SPECIAL_OFFER_STATUS = [
  { label: "Pending", color: "warning", value: "0" },
  { label: "Active", color: "success", value: "1" },
  { label: "Concluded", color: "success", value: "2" },
  { label: "Cancelled", color: "error", value: "3" },
];
export const PRODUCT_STOCK_STATUS = [
  { label: "All", color: "info", value: "" },
  { label: "Out of Stock", color: "error", value: "1" },
  { label: "Available", color: "success", value: "2" },
  { label: "Low Stock", color: "warning", value: "3" },
];
export const PAPER_WIDTH = [
  { name: "48mm x 210mm", size: "48" },
  { name: "57mm x 50mm", size: "57" },
  { name: "80mm x 60mm", size: "80" },
];
export const PAPER_SIZE = [
  { name: "Small", size: "sm" },
  { name: "Normal", size: "md" },
  { name: "Large", size: "xl" },
];
export const ROLE_STATUS = [
  { label: "Suspended", color: "error", value: "0" },
  { label: "Active", color: "success", value: "1" },
];
export const ADMIN_STATUS = [
  { label: "Suspended", color: "error", value: "0" },
  { label: "Active", color: "success", value: "1" },
];
export const BRANCH_STATUS = [
  { label: "Suspended", color: "error", value: "0" },
  { label: "Active", color: "success", value: "1" },
];
export const CATEGORY_STATUS = [
  { label: "Hidden", color: "error", value: "0" },
  { label: "Shown", color: "success", value: "1" },
];
export const DISCOUNT_CODE_STATUS = [
  { label: "Inactive", color: "warning", value: "0" },
  { label: "Active", color: "success", value: "1" },
];
export const STAFF_STATUS = [
  { label: "Suspended", color: "warning", value: "0" },
  { label: "Active", color: "success", value: "1" },
  { label: "Sacked", color: "error", value: "3" },
  { label: "Resigned", color: "info", value: "4" },
];
export const WITHDRAWAL_STATUS = [
  { label: "Pending", color: "warning", value: "0" },
  { label: "Paid", color: "success", value: "1" },
];
export const MESSAGE_STATUS = [
  {
    label: "Pending",
    color: "warning",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  {
    label: "Sent",
    color: "success",
    value: "1",
    icon: "icon-park-solid:message-sent",
  },
  {
    label: "Failed",
    color: "error",
    value: "3",
    icon: "icon-park:message-failed",
  },
];
export const CARD_TYPES = [
  { label: "Gift Cards", color: "info", value: "0" },
  { label: "Loyalty Cards", color: "success", value: "1" },
];
export const SEND_TYPES = [
  { label: "Email Address", color: "info", value: "0" },
  { label: "Phone Number (SMS)", color: "success", value: "1" },
  { label: "WhatsApp Number", color: "warning", value: "2" },
];
export const STORE_CREDIT_TYPES = [
  { label: "Flat Amount", color: "info", value: "0" },
  { label: "Percentage Discount", color: "success", value: "1" },
];
export const STORE_CREDIT_CONDITIONS = [
  { label: "Purchase Price", color: "info", value: "0" },
  { label: "Product Quantity Purchased", color: "success", value: "1" },
];
export const ANALYTICS_FILTER_TYPES_REPORT = [
  { label: "Today", value: "today" },
  { label: "This Week", value: "week" },
  { label: "This Month", value: "current_month" },
  { label: "This Year", value: "current_year" },
];
export const ANALYTICS_FILTER_TYPES = [
  { label: "Today", value: "today" },
  { label: "This Week", value: "week" },
  { label: "This Month", value: "current_month" },
  { label: "This Year", value: "current_year" },
  { label: "All Time", value: "all_time" },
  { label: "Date", value: "date" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
  { label: "Date Range", value: "range" },
];
export const SUPPORT_STATUS = [
  {
    label: "Pending",
    color: "warning",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  {
    label: "Replied",
    color: "success",
    value: "1",
    icon: "icon-park-solid:message-sent",
  },
];
export const ONLINE_STORE_OPTIONS = [
  { label: "Own Store", value: "own store", color: "primary" },
  { label: "Jumia", value: "jumia", color: "info" },
  { label: "Konga", value: "konga", color: "info" },
  { label: "PayPorte", value: "PayPorte", color: "info" },
  { label: "VConnect", value: "VConnect", color: "info" },
  { label: "Kara", value: "Kara", color: "info" },
  { label: "Jiji", value: "Jiji", color: "info" },
  { label: "Other", value: "other", color: "warning" },
];
export const MESSAGE_TYPES = [
  { label: "Email", value: "0" },
  { label: "SMS", value: "1" },
];
export const RECIPIENT_TYPES = [
  { label: "Customers", value: "0" },
  { label: "Staff", value: "1" },
  { label: "Administrators", value: "2" },
  { label: "Others", value: "3" },
];
export const GENDERS = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
];
export const WITHDRAWAL_TYPES = [
  "End-of-day Withdrawal",
  "Requested Withdrawal",
];
export const SUBSCRIPTION_STATUS = [
  { label: "Inactive", color: "warning" },
  { label: "Active", color: "success" },
  { label: "Cancelled", color: "error" },
];
export const NOTIFICATION_STATUS = [
  { label: "Unread", color: "warning" },
  { label: "Read", color: "success" },
];
export const EXPORT_STATUS = [
  { label: "Pending", color: "warning" },
  { label: "Exported", color: "success" },
];
export const MARITALS = [
  { label: "Single", value: "single" },
  { label: "Married", value: "married" },
];
export const SUPPORT_REASONS = [
  "Enquiries",
  "Billing & Subscription",
  "Payment Error",
  "System Error",
  "Others",
];
export const LOG_TYPES = [
  { label: "System", value: "0", color: "primary" },
  { label: "Administrator", value: "1", color: "secondary" },
  { label: "Role", value: "2", color: "secondary" },
  { label: "Staff", value: "3", color: "secondary" },
  { label: "Branch", value: "4", color: "info" },
  { label: "Product Category", value: "5", color: "info" },
  { label: "Customer", value: "6", color: "info" },
  { label: "Product", value: "7", color: "info" },
  { label: "Sale", value: "8", color: "info" },
  { label: "Returned Product", value: "9", color: "info" },
  { label: "Debtor", value: "10", color: "info" },
  { label: "Damaged Product", value: "11", color: "info" },
  { label: "Purchases", value: "12", color: "info" },
  { label: "Online Sales", value: "13", color: "info" },
  { label: "Gift Card", value: "14", color: "info" },
  { label: "Loyalty Card", value: "15", color: "info" },
  { label: "Wallet", value: "16", color: "warning" },
  { label: "Withdrawal", value: "17", color: "warning" },
  { label: "Expenses", value: "18", color: "warning" },
  { label: "Subscription", value: "19", color: "warning" },
  { label: "Message", value: "20", color: "success" },
  { label: "Import/Export", value: "21", color: "success" },
  { label: "Login", value: "22", color: "success" },
  { label: "Invoice", value: "26", color: "info" },
  { label: "Quotation", value: "27", color: "warning" },
  { label: "Waybill", value: "28", color: "success" },
  { label: "Company", value: "29", color: "info" },
  { label: "Discrepancy Report", value: "30", color: "warning" },
];
export const IMPORT_TYPES = [
  {
    label: "Staff",
    value: "3",
    link: PATH_DASHBOARD.administrative.staff,
    columns: [
      { title: "Full Name", value: "name" },
      { title: "Phone Number", value: "phone" },
      { title: "Email Address", value: "email" },
      { title: "Date of Birth", value: "dob" },
      { title: "Gender", value: "gender" },
      { title: "Salary", value: "salary" },
      { title: "Address", value: "address" },
      { title: "Marital Status", value: "marital" },
      { title: "State of Origin", value: "origin" },
      { title: "Job Title", value: "job" },
      { title: "Employment Date", value: "employed" },
    ],
    isBranchRequired: true,
  },
  {
    label: "Branches",
    value: "4",
    color: "info",
    link: PATH_DASHBOARD.business.branches,
    columns: [
      { title: "Name", value: "name" },
      { title: "Location", value: "location" },
    ],
    isBranchRequired: false,
  },
  {
    label: "Product Categories",
    value: "5",
    color: "info",
    link: PATH_DASHBOARD.business.categories,
    columns: [
      { title: "Name", value: "name" },
      { title: "Description", value: "description" },
      { title: "Attributes", value: "attributes" },
    ],
    isBranchRequired: true,
  },
  {
    label: "Customers",
    value: "6",
    color: "info",
    link: PATH_DASHBOARD.business.customers,
    columns: [
      { title: "Name", value: "name" },
      { title: "Email Address", value: "email" },
      { title: "Phone Number", value: "phone" },
      { title: "Date of Birth", value: "dob" },
      { title: "Address", value: "address" },
    ],
    isBranchRequired: true,
  },
  {
    label: "Products",
    value: "7",
    color: "info",
    link: PATH_DASHBOARD.business.products,
    columns: [
      { title: "Name", value: "name" },
      { title: "Product Number", value: "number" },
      { title: "Quantity", value: "qty" },
      { title: "Cost Price", value: "cost_price" },
      { title: "Selling Price", value: "selling_price" },
      { title: "Low Stock Limit", value: "low_stock" },
      { title: "Product Category", value: "category" },
      { title: "Purchased From / Company", value: "company" },
    ],
    isBranchRequired: true,
  },
  {
    label: "Sales or Invoices",
    value: "8",
    color: "info",
    isBranchRequired: true,
    link: PATH_DASHBOARD.business.sales,
    columns: [
      { title: "Purchased By / Customer", value: "customer" },
      { title: "Product(s)", value: "product" },
      { title: "Quantity(ies)", value: "quantity" },
      { title: "Price per Product", value: "price" },
      { title: "Warranty", value: "warranty" },
      { title: "Payment Method(s)", value: "methods" },
      { title: "Amount Paid (per method) ", value: "payments" },
      { title: "Discount", value: "discount" },
      { title: "VAT", value: "tax" },
      { title: "Amount Paid (total)", value: "amount" },
      { title: "Date due", value: "due" },
      { title: "Date Purchased", value: "date" },
    ],
  },
  {
    label: "Returned Products",
    value: "9",
    color: "info",
    isBranchRequired: true,
    link: PATH_DASHBOARD.business.returnedProducts,
    columns: [
      { title: "Purchased by / Customer", value: "customer" },
      { title: "Receipt ID", value: "sales_id" },
      { title: "Product(s)", value: "products" },
      { title: "Quantity", value: "quantity" },
      { title: "Price per Product", value: "price" },
      { title: "Return Reason", value: "reason" },
      { title: "Date Returned", value: "returned" },
    ],
  },
  {
    label: "Debtors",
    value: "10",
    color: "info",
    isBranchRequired: true,
    link: PATH_DASHBOARD.business.debtors,
    columns: [
      { title: "Debtor / Customer", value: "customer" },
      { title: "Product(s)", value: "products" },
      { title: "Quantity", value: "quantity" },
      { title: "Price per Product", value: "price" },
      { title: "Date Purchased", value: "date" },
      { title: "Amount Owed", value: "owed" },
      { title: "Debt reason", value: "reason" },
      { title: "Amount Paid", value: "paid" },
      { title: "Payment Method(s)", value: "method" },
      { title: "Payment Date", value: "date_paid" },
      { title: "Date Due", value: "date_due" },
    ],
  },
  {
    label: "Damaged Products",
    value: "11",
    color: "info",
    isBranchRequired: true,
    link: PATH_DASHBOARD.business.damagedProducts,
    columns: [
      { title: "Product", value: "product" },
      { title: "Quantity", value: "quantity" },
      { title: "Purchased From / Company", value: "company" },
      { title: "Reason", value: "reason" },
    ],
  },
  {
    label: "Purchases",
    value: "12",
    color: "info",
    isBranchRequired: true,
    link: PATH_DASHBOARD.business.purchases,
    columns: [
      { title: "Product", value: "product" },
      { title: "Quantity", value: "quantity" },
      { title: "Purchased From / Company", value: "company" },
      { title: "Invoice Number / ID", value: "invoice" },
      { title: "Amount Paid", value: "amount" },
      { title: "Product Price", value: "price" },
      { title: "Date Purchased", value: "purchase_date" },
      { title: "Invoice Number / ID", value: "invoice" },
    ],
  },
  {
    label: "Online Sales",
    value: "13",
    color: "info",
    isBranchRequired: true,
    link: PATH_DASHBOARD.business.onlineSales,
    columns: [
      { title: "Product", value: "product" },
      { title: "Quantity", value: "quantity" },
      { title: "Product Price", value: "price" },
      { title: "Date Ordered", value: "date" },
      { title: "Online Store", value: "store" },
      { title: "Order Number / ID", value: "orderid" },
      { title: "Warranty", value: "warranty" },
      { title: "Date Due", value: "due" },
      { title: "Discount", value: "discount" },
    ],
  },
  {
    label: "Expenses",
    value: "18",
    color: "warning",
    isBranchRequired: true,
    link: PATH_DASHBOARD.accounting.expenses,
    columns: [
      { title: "Staff", value: "staff" },
      { title: "Amount", value: "amount" },
      { title: "Reason", value: "reason" },
      { title: "Date", value: "date" },
    ],
  },
];
export const PAYSTACK_KEY = "pk_live_d702d9481331a20dd35beded7e602138e7437479";
export const CheckPerm = (perm) => {
  const { user } = useAuth();
  const permissions =
    user && user.role && user.role.permissions && user.role.permissions !== ""
      ? user.role.permissions.split(",")
      : [];
  if (perm.split(",").length > 0) {
    return perm.split(",").some((p) => permissions.includes(p));
  }
  return perm === "" || permissions.includes(perm);
};
export function NumberFormatCustom(props) {
  // eslint-disable-next-line react/prop-types
  const { inputRef, onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      prefix="₦"
      onValueChange={(values) => {
        onChange({
          target: {
            // eslint-disable-next-line react/prop-types
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
    />
  );
}
export const GetSettings = (name) => {
  const { user } = useAuth();
  const { settings } = user;
  const vals = {};
  settings.map((setting) => (vals[setting.name] = setting.value));
  return Object.prototype.hasOwnProperty.call(vals, name)
    ? vals[name] === "1"
    : false;
};
export const CheckOnboardingDone = () => {
  const { user } = useAuth();
  const { shop, account, subscription, compliance } = user;
  const settingsDone =
    shop && shop.logo && shop.address && account && account.code;
  const subDone = subscription && Object.keys(subscription).includes("plan");
  const complianceDone =
    compliance &&
    Object.keys(compliance).includes("status") &&
    parseInt(compliance.status, 10) === 1;
  return settingsDone && subDone && complianceDone;
};
export const CheckSub = (action) => {
  /**
   * Sub response type
   * 0 - new user
   * 1 - active
   * 2 - cancelled.
   */

  const { user } = useAuth();
  const { subscription } = user;

  if (action === "" || action === "0") {
    // free features
    return 1;
  }

  if (subscription && Object.keys(subscription).includes("plan")) {
    const status = parseInt(subscription.status, 10);
    if (status === 1 || status === 2) {
      // active or cancelled subscription
      const features = subscription.features
        ? JSON.parse(subscription.features)
        : [];
      const isComplied =
        user &&
        user.compliance &&
        user.compliance.status &&
        parseInt(user.compliance.status, 10) === 1;
      if (action.split(",").length > 0) {
        if (
          (action.split(",").includes("8") ||
            action.split(",").includes("13")) &&
          !isComplied
        ) {
          // check if action is sales or online sales
          return 2;
        }
        return action.split(",").some((a) => features.includes(a)) ? 1 : 2;
      }
      if (features.includes(action)) {
        if ((action === "8" || action === "13") && !isComplied) {
          // check if action is sales or online sales
          return 2;
        }
        return 1;
      }
    }
    return 2;
  }
  // new user
  return 0;
};
export const CalcCharge = (amount, isBank = false) => {
  if (isBank) {
    return 0.01 * amount > 300 ? 300 : 0.01 * amount;
  }
  const decimalFee = 0.015;
  const FlatFee = 100;
  const FlatFeeCap = 2500;
  const ChargeCap = 2000;

  let price = 0;
  let applicableFees = 0;
  if (amount > FlatFeeCap) {
    applicableFees = decimalFee * amount + FlatFee;
    price = Math.round(
      applicableFees > ChargeCap
        ? amount + ChargeCap
        : (amount + FlatFee) / (1 - decimalFee) + 0.01
    );
  } else {
    applicableFees = decimalFee * amount;
    price = Math.round(
      applicableFees > ChargeCap
        ? amount + ChargeCap
        : amount / (1 - decimalFee) + 0.01
    );
  }
  return price;
};
export const CopyToBoard = (barcode, enqueueSnackbar, toCopy = "") => {
  navigator.clipboard.writeText(barcode);
  enqueueSnackbar(toCopy || "Barcode copied to clipboard.", {
    variant: "success",
  });
};
export const isValidEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
export const PAGE_DATA = [
  {
    path: PATH_DASHBOARD.root,
    permission: "view_dashboard_analytics",
    code: "0",
    video: "4LZe5JR8G5o",
  },
  {
    path: PATH_DASHBOARD.general.settings,
    permission: "view_settings",
    code: "0",
    video: "8WrrXcoMzXA",
  },
  { path: PATH_DASHBOARD.general.profile, permission: "", code: "0" },

  {
    path: PATH_DASHBOARD.administrative.admins,
    permission: "view_administrators",
    code: "1",
    video: "31chktGp8ew",
  },
  {
    path: PATH_DASHBOARD.administrative.addAdmin,
    permission: "add_administrator",
    code: "1",
  },
  {
    path: PATH_DASHBOARD.administrative.roles,
    permission: "view_administrative_roles",
    code: "2",
    video: "mxuARNoMCHI",
  },
  {
    path: PATH_DASHBOARD.administrative.staff,
    permission: "view_staff",
    code: "3",
    video: "NlSHrR16gUE",
  },
  {
    path: PATH_DASHBOARD.administrative.addStaff,
    permission: "add_staff",
    code: "3",
  },
  {
    path: PATH_DASHBOARD.accounting.expenses,
    permission: "view_expenses",
    code: "18",
    video: "70514B73W4E",
  },
  {
    path: PATH_DASHBOARD.accounting.addExpense,
    permission: "add_expense",
    code: "18",
  },
  {
    path: PATH_DASHBOARD.accounting.billings,
    permission: "view_subscription_and_billing",
    code: "0",
  },
  {
    path: PATH_DASHBOARD.accounting.bankTransfers,
    permission: "view_bank_transfers",
    code: "25",
  },
  {
    path: PATH_DASHBOARD.accounting.withdrawals,
    permission: "view_withdrawals",
    code: "25",
  },
  {
    path: PATH_DASHBOARD.accounting.subscription,
    permission: "view_subscription_and_billing",
    code: "0",
  },
  {
    path: PATH_DASHBOARD.accounting.choosePlan,
    permission: "view_subscription_and_billing",
    code: "0",
  },
  {
    path: PATH_DASHBOARD.accounting.paymentAnalysis,
    permission: "view_payment_analysis",
    code: "0",
  },
  {
    path: PATH_DASHBOARD.accounting.storeAnalysis,
    permission: "view_store_analysis",
    code: "0",
  },
  {
    path: PATH_DASHBOARD.accounting.generalAnalysis,
    permission: "view_general_analysis",
    code: "0",
  },
  {
    path: PATH_DASHBOARD.accounting.report,
    permission: "view_report",
    code: "0",
  },
  {
    path: PATH_DASHBOARD.misc.send,
    permission: "view_sms_and_email_messages",
    code: "20",
    video: "ZqbTG7Q-wG8",
  },
  {
    path: PATH_DASHBOARD.misc.log,
    permission: "view_activity_logs",
    code: "0",
    video: "0pNpmowXX9A",
  },
  {
    path: PATH_DASHBOARD.misc.notifications,
    permission: "view_and_receive_notifications",
    code: "0",
  },
  { path: PATH_DASHBOARD.misc.devices, permission: "view_devices", code: "0" },
  {
    path: PATH_DASHBOARD.misc.importExport,
    permission: "import_and_export_data",
    code: "",
    video: ["rEnXQTUscLo"],
  },
  {
    path: PATH_DASHBOARD.misc.support,
    permission: "view_support_messages",
    code: "0",
  },
  {
    path: PATH_DASHBOARD.misc.addSend,
    permission: "send_sms_and_email_messages",
    code: "20",
  },
  {
    path: PATH_DASHBOARD.misc.sendSupport,
    permission: "send_support_messages",
    code: "0",
  },
  {
    path: PATH_DASHBOARD.business.branches,
    permission: "view_branches",
    code: "4",
    video: "1BDd2LzQU-0",
  },
  {
    path: PATH_DASHBOARD.business.categories,
    permission: "view_product_categories",
    code: "5",
    video: "9nlyKyzkrz8",
  },
  {
    path: PATH_DASHBOARD.business.customers,
    permission: "view_customers",
    code: "6",
    video: "nKaFkP6Vq1c",
  },
  {
    path: PATH_DASHBOARD.business.companies,
    permission: "view_companies",
    code: "29",
    video: "4qpDbhn5Khw",
  },
  {
    path: PATH_DASHBOARD.business.products,
    permission: "view_products",
    code: "7",
    video: "2HNdrlwGDyM",
  },
  { path: PATH_DASHBOARD.business.sales, permission: "view_sales", code: "8" },
  {
    path: PATH_DASHBOARD.business.invoices,
    permission: "view_invoices",
    code: "26",
  },
  {
    path: PATH_DASHBOARD.business.quotations,
    permission: "view_quotations",
    code: "27",
  },
  {
    path: PATH_DASHBOARD.business.waybills,
    permission: "view_waybills",
    code: "28",
  },
  {
    path: PATH_DASHBOARD.business.returnedProducts,
    permission: "view_returned_products",
    code: "9",
    video: "GF2Ic1blmWk",
  },
  {
    path: PATH_DASHBOARD.business.debtors,
    permission: "view_debtors",
    code: "10",
    video: "BSD9_-tOL0Y",
  },
  {
    path: PATH_DASHBOARD.business.damagedProducts,
    permission: "view_damaged_products",
    code: "11",
    video: "XEYbQxy7J7s",
  },
  {
    path: PATH_DASHBOARD.business.purchases,
    permission: "view_purchases",
    code: "12",
    video: "FnACokOPhrQ",
  },
  {
    path: PATH_DASHBOARD.administrative.discrepancyReports,
    permission: "view_discrepancy_reports",
    code: "30",
  },
  {
    path: PATH_DASHBOARD.business.onlineSales,
    permission: "view_online_sales",
    code: "13",
  },
  {
    path: PATH_DASHBOARD.business.addProduct,
    permission: "add_product",
    code: "7",
    video: "2HNdrlwGDyM",
  },
  {
    path: PATH_DASHBOARD.business.addSale,
    permission: "generate_sales_and_invoices",
    code: "8",
  },
  {
    path: PATH_DASHBOARD.business.addReturned,
    permission: "add_returned_product",
    code: "9",
  },
  {
    path: PATH_DASHBOARD.business.addDebtor,
    permission: "add_debtor",
    code: "10",
  },
  {
    path: PATH_DASHBOARD.business.addDamaged,
    permission: "add_damaged_product",
    code: "11",
  },
  {
    path: PATH_DASHBOARD.business.addPurchases,
    permission: "add_purchase",
    code: "12",
  },
  {
    path: PATH_DASHBOARD.administrative.adddiscrepancyReport,
    permission: "add_discrepancy_report",
    code: "30",
  },
  {
    path: PATH_DASHBOARD.business.addOnline,
    permission: "add_online_sale",
    code: "13",
  },
  {
    path: PATH_DASHBOARD.business.cards,
    permission: "view_gifts_and_loyalty_cards",
    code: "23,24",
  },
  {
    path: PATH_DASHBOARD.business.addCard,
    permission: "add_gift_and_loyalty_cards",
    code: "23,24",
  },
  {
    path: PATH_DASHBOARD.business.cardSettings,
    permission: "view_gifts_and_loyalty_cards",
    code: "23,24",
  },

  { path: PATH_DASHBOARD.subpages.sale, permission: "view_sales", code: "8" },
  {
    path: PATH_DASHBOARD.subpages.product,
    permission: "view_products",
    code: "7",
  },
  {
    path: PATH_DASHBOARD.subpages.admin,
    permission: "view_administrators",
    code: "1",
  },
  {
    path: PATH_DASHBOARD.subpages.role,
    permission: "view_administrative_roles",
    code: "2",
  },
  { path: PATH_DASHBOARD.subpages.staff, permission: "view_staff", code: "3" },
  {
    path: PATH_DASHBOARD.subpages.branch,
    permission: "view_branches",
    code: "4",
  },
  {
    path: PATH_DASHBOARD.subpages.category,
    permission: "view_product_categories",
    code: "5",
  },
  {
    path: PATH_DASHBOARD.subpages.customer,
    permission: "view_customers",
    code: "6",
  },
  {
    path: PATH_DASHBOARD.subpages.company,
    permission: "view_companies",
    code: "29",
  },
  {
    path: PATH_DASHBOARD.subpages.returnedProduct,
    permission: "view_returned_products",
    code: "9",
  },
  {
    path: PATH_DASHBOARD.subpages.debtor,
    permission: "view_debtors",
    code: "10",
  },
  {
    path: PATH_DASHBOARD.subpages.damaged,
    permission: "view_damaged_products",
    code: "11",
  },
  {
    path: PATH_DASHBOARD.subpages.purchase,
    permission: "view_purchases",
    code: "12",
  },
  {
    path: PATH_DASHBOARD.subpages.discrepancyReport,
    permission: "view_discrepancy_reports",
    code: "30",
  },
  {
    path: PATH_DASHBOARD.subpages.editDiscrepancyReport,
    permission: "update_discrepancy_reports",
    code: "30",
  },
  {
    path: PATH_DASHBOARD.subpages.online,
    permission: "view_online_sales",
    code: "13",
  },
  {
    path: PATH_DASHBOARD.subpages.quotation,
    permission: "view_quotations",
    code: "27",
  },
  {
    path: PATH_DASHBOARD.subpages.invoice,
    permission: "view_invoices",
    code: "26",
    video: "3SK4CVZWDGs",
  },
  {
    path: PATH_DASHBOARD.subpages.waybill,
    permission: "view_waybills",
    code: "28",
  },
  {
    path: PATH_DASHBOARD.business.addQuote,
    permission: "generate_quotes",
    code: "27",
  },
  {
    path: PATH_DASHBOARD.business.addInvoice,
    permission: "generate_sales_and_invoices",
    code: "26",
  },
  {
    path: PATH_DASHBOARD.business.addWaybill,
    permission: "generate_waybills",
    code: "28",
  },
  {
    path: PATH_DASHBOARD.ecommerce.orders,
    permission: "view_orders",
    code: "31",
  },
  {
    path: PATH_DASHBOARD.ecommerce.reviews,
    permission: "view_ratings_and_reviews",
    code: "31",
  },
  {
    path: PATH_DASHBOARD.ecommerce.discountCodes,
    permission: "view_discount_codes",
    code: "31",
  },
  {
    path: PATH_DASHBOARD.ecommerce.FeaturedProducts,
    permission: "view_featured_products",
    code: "31",
  },
  {
    path: PATH_DASHBOARD.ecommerce.specialOffers,
    permission: "view_special_offers",
    code: "31",
  },
];
export function getFirstCharacter(name) {
  const names = name.split(" ");
  return String(
    names
      .map((n) => n.charAt(0))
      .filter((n) => n !== "")
      .slice(0, 2)
      .join("")
  ).toLocaleUpperCase();
}
export const firebaseConfig = {
  apiKey: "AIzaSyCNIBwXu80OFCr-UXdbHUZeLfqBar8R4Ho",
  authDomain: "selpay-main.firebaseapp.com",
  projectId: "selpay-main",
  storageBucket: "selpay-main.appspot.com",
  messagingSenderId: "364010949380",
  appId: "1:364010949380:web:ed46b14fdb16669094de51",
  measurementId: "G-ELZLXS3G49",
};
export const vapidKey =
  "BFTsTfXxoNBGy1tDcxiFRvUe7fK89AkIAu5IOsNY6Y5J7jYOOYy9W53R5d0qhBHupEZV5BJbxG6hXRI9joWwYeU";
export const placeKey = "AIzaSyDpQ36nsSZofr7QF7MHRqGr6VdV0uh4IRc";
export const PUSHER_APP_KEY = "50e8fdba9cdd94fa6aca";
export const PUSHER_APP_CLUSTER = "mt1";
