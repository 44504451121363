import { useState, useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
// material
import { styled, useTheme } from "@mui/material/styles";
// hooks
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import RoleBasedGuard from "../../guards/RoleBasedGuard";
import { useSelector } from "../../redux/store";
import InvoiceNewEditForm from "../../components/_dashboard/sales";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 71,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 50,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const theme = useTheme();
  const { isOnline } = useSelector((state) => state.offline);
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const [switchGenerate, setSwitchGenerate] = useState(false);
  const ref = useRef(null);
  const [pdf, setPdf] = useState(null);
  const [Receipt, setReceipt] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location) {
      ref.current.focus();
    }
  }, [location]);
  useEffect(() => {
    const load = async () => {
      try {
        const { pdf } = await import("@react-pdf/renderer");
        const Receipt = await import(
          "../../components/_dashboard/sale/InvoiceReceipt"
        );
        setPdf(() => pdf);
        setReceipt(Receipt);
      } catch (err) {
        // console.log(err);
      }
    };
    load();
  }, []);

  return (
    <RootStyle className={isOnline ? "" : "disable-all-link"}>
      <DashboardNavbar
        onOpenSidebar={() => setOpen(true)}
        switchGenerate={switchGenerate}
        setSwitchGenerate={setSwitchGenerate}
      />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
        isOnline={isOnline}
      />
      <MainStyle
        ref={ref}
        sx={{
          transition: theme.transitions.create("margin", {
            duration: theme.transitions.duration.complex,
          }),
          ...(collapseClick && {
            ml: "102px",
          }),
        }}
      >
        <RoleBasedGuard>
          {!isOnline && switchGenerate ? (
            <InvoiceNewEditForm pdf={pdf} Receipt={Receipt} />
          ) : (
            <Outlet />
          )}
        </RoleBasedGuard>
      </MainStyle>
    </RootStyle>
  );
}
