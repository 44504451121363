import axios from "./axios";

const setCurrentBranch = (branchId) => {
  if (branchId) {
    localStorage.setItem("branchId", branchId);
  } else {
    localStorage.removeItem("branchId");
  }
};
const getCurrentBranch = () => window.localStorage.getItem("branchId") || "";

const setSupportLinks = (links) => {
  if (links) {
    localStorage.setItem("supportLinks", JSON.stringify(links));
  } else {
    localStorage.removeItem("supportLinks");
  }
};
const getSupportLinks = () => window.localStorage.getItem("supportLinks") || "";

const setOfflineData = (data) => {
  if (data) {
    localStorage.setItem("offlineData", data);
  } else {
    localStorage.removeItem("offlineData");
  }
};
const getOfflineData = (type) => {
  try {
    let data = window.localStorage.getItem("offlineData") || "";
    if (data) {
      data = JSON.parse(data);
      if (type) {
        return data[type];
      }
    }
  } catch (err) {
    // console.log(err);
  }
  return [];
};
const saveOfflineData = (item, type) => {
  try {
    const res = window.localStorage.getItem("savedData");
    let data = res ? JSON.parse(res) : { sales: [], customers: [] };
    if (!(data && data.sales)) {
      data = { sales: [], customers: [] };
    }
    data[type].push(item);
    localStorage.setItem("savedData", JSON.stringify(data));
  } catch (err) {
    // console.log("saveOffline", err);
  }
};
const getSavedOfflineData = (type) => {
  try {
    let data = window.localStorage.getItem("savedData") || "";
    if (data) {
      data = JSON.parse(data);
      if (type) {
        return data[type] || [];
      }
    }
  } catch (err) {
    // console.log(err);
  }
  return [];
};

const setPaperSize = (size) => {
  if (size) {
    localStorage.setItem("paperSize", size);
  } else {
    localStorage.removeItem("paperSize");
  }
};
const getPaperSize = () => window.localStorage.getItem("paperSize") || "48";

const setPaperTextSize = (size) => {
  if (size) {
    localStorage.setItem("paperTextSize", size);
  } else {
    localStorage.removeItem("paperTextSize");
  }
};
const getPaperTextSize = () =>
  window.localStorage.getItem("paperTextSize") || "md";

const setLabelPaperSize = (size) => {
  if (size) {
    localStorage.setItem("labelPaperSize", size);
  } else {
    localStorage.removeItem("labelPaperSize");
  }
};
const getLabelPaperSize = () =>
  window.localStorage.getItem("labelPaperSize") || "2x4";

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    setHeader();
  } else {
    localStorage.removeItem("accessToken");
  }
};
const setHeader = () => {
  const accessToken = window.localStorage.getItem("accessToken")
    ? window.localStorage.getItem("accessToken")
    : "";
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export {
  setSession,
  setHeader,
  setCurrentBranch,
  getCurrentBranch,
  setPaperSize,
  getPaperSize,
  setLabelPaperSize,
  getLabelPaperSize,
  setPaperTextSize,
  getPaperTextSize,
  getOfflineData,
  setOfflineData,
  getSavedOfflineData,
  saveOfflineData,
  setSupportLinks,
  getSupportLinks,
};
