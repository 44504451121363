import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { API_PATH } from "../../utils/apis";
import { getCurrentBranch } from "../../utils/jwt";

const initialState = {
  isLoading: false,
  error: false,
  deleteError: false,
  search: "",
  page: 0,
  perPage: 10,
  total: 0,
  waybills: [],
  summary: {},
  sortBy: null,
  barcodeRes: {},
  filters: {
    method: "",
  },
};

const slice = createSlice({
  name: "waybill",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    removeDeleteError(state) {
      state.deleteError = false;
    },
    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = action.payload;
    },
    setBarcodeRes(state, action) {
      state.barcodeRes = action.payload;
    },
    getSuccess(state, action) {
      state.isLoading = false;
      state.waybills = action.payload.data;
      state.summary = action.payload.summary;
      state.total = parseInt(action.payload.total, 10);
    },
    deletedSuccess(state, action) {
      const deleted = action.payload.split(",");
      state.waybills = state.waybills.filter(
        (obj) => !deleted.includes(obj.wid)
      );
      state.isLoading = false;
    },
    //  SORT & FILTER POSTS
    sortBy(state, action) {
      state.sortBy = action.payload;
    },
    filter(state, action) {
      state.filters.method = action.payload.method;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filter, sortBy } = slice.actions;

export function getWaybills(search, stat, page, perpage, order, sortBy, date) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(API_PATH.waybills, {
        page,
        status: stat,
        search,
        perpage,
        order,
        sortBy,
        branch: getCurrentBranch(),
        date: JSON.stringify(date),
      });
      const { status, data, total, summary } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getSuccess({ data, summary, total }));
      } else {
        dispatch(
          slice.actions.hasError(
            new Error(
              "We cannot fetch your waybills at the moment! Please check back later."
            )
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteWaybill(remove) {
  return async (dispatch) => {
    dispatch(slice.actions.removeDeleteError());
    try {
      const response = await axios.post(API_PATH.waybills, { remove });
      if (response.data.status === "success") {
        dispatch(slice.actions.deletedSuccess(remove));
      } else if (response.data.status === "permission_denied") {
        dispatch(
          slice.actions.hasDeleteError(
            new Error("You do not have permission to perform this action!")
          )
        );
      } else {
        dispatch(
          slice.actions.hasDeleteError(
            new Error("An error has occured! Please try again later.")
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasDeleteError(error));
    }
  };
}
