import PropTypes from "prop-types";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Card,
  Alert,
  TextField,
  Stack,
  CardHeader,
  Box,
  Autocomplete,
  Typography,
  InputAdornment,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import { CheckPerm, NumberFormatCustom } from "../../../utils/apis";
import { fCurrency } from "../../../utils/formatNumber";
// ----------------------------------------------------------------------
ProductUpdate.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function ProductUpdate({ myProfile, uid, fetch }) {
  const { updateProduct, getCategories, getBranches, getCompanies } = useAuth();
  const [CATEGORIES, setCATEGORIES] = useState([]);
  const [BRANCHES, setBRANCHES] = useState([]);
  const [COMPANIES, setCOMPANIES] = useState([]);
  const hasPermission = CheckPerm("update_products");
  const { enqueueSnackbar } = useSnackbar();

  const Schema = Yup.object().shape({
    name: Yup.string().required("Product name is required"),
    selling: Yup.number("Enter a valid selling price to proceed.").required(
      "Selling price is required"
    ),
    branch: Yup.object().required("Branch is required!"),
  });

  const formik = useFormik({
    initialValues: {
      name: myProfile.name,
      number: myProfile.number,
      selling: myProfile.sellingPrice,
      category: {},
      company: "",
      cost: myProfile.costPrice,
      low_stock: myProfile.lowStock,
      branch: {},
      attributes: JSON.parse(myProfile.attributes),
      isService: parseInt(myProfile.isService, 10),
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        if (values.isService === 1) {
          const isEmpty = values.attributes.filter(
            (milestone) => milestone.name === "" || milestone.value === ""
          );
          if (isEmpty.length === 0) {
            // check if percentage is equal to 100.
            const totalPercentage = values.attributes.reduce(
              (total, current) => total + parseFloat(current.value),
              0
            );
            if (totalPercentage > 100) {
              throw new Error(
                "The total completion percentage is greater than 100%. Please make sure your completion percentage equals 100% and try again."
              );
            }
            if (totalPercentage < 100) {
              throw new Error(
                "The total completion percentage is less than 100%. Please make sure your completion percentage equals 100% and try again."
              );
            }
          } else {
            throw new Error("Please fill all the milestone fields to proceed.");
          }
        } else if (values.cost === "") {
          setErrors({ cost: "Enter a valid cost price to proceed." });
          return;
        } else if (values.low_stock === "") {
          setErrors({ low_stock: "Enter a valid limit to proceed." });
          return;
        }
        await updateProduct(values, uid);
        enqueueSnackbar(
          `${
            values.isService === 0 ? "Product" : "Service"
          }'s data has been updated!`,
          {
            variant: "success",
          }
        );
        setSubmitting(false);
        fetch();
      } catch (error) {
        setErrors({ afterSubmit: error.message });
        setSubmitting(false);
        window.scrollTo(0, 0);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;

  useEffect(() => {
    async function get() {
      const categories = await getCategories();
      const branches = await getBranches();
      const companies = await getCompanies("");
      setCATEGORIES(categories.filter((cat) => cat.name !== "All"));
      setBRANCHES([{ name: "All Branches", bid: "" }, ...branches]);
      setCOMPANIES(companies);
      const selectedCategory = categories.filter(
        (category) => category.cid === myProfile.category
      );
      const selectedBranch = branches.filter(
        (branch) => branch.bid === myProfile.branch
      );
      const selectedCompany = companies.filter(
        (company) => company.cid === myProfile.company
      );
      setFieldValue(
        "category",
        selectedCategory.length > 0 ? selectedCategory[0] : {}
      );
      setFieldValue(
        "branch",
        selectedBranch.length > 0 ? selectedBranch[0] : {}
      );
      setFieldValue(
        "company",
        selectedCompany.length > 0 ? selectedCompany[0].name : ""
      );
    }
    get();
  }, [
    getCategories,
    getBranches,
    getCompanies,
    myProfile.branch,
    myProfile.category,
    myProfile.company,
    setFieldValue,
  ]);

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        px: 3,
        pb: 3,
      }}
    >
      <CardHeader
        title={`${values.isService === 1 ? "Service" : "Product"} Information`}
      />
      <Box sx={{ p: { md: 3, xs: 1 } }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}
              <TextField
                fullWidth
                label="Name"
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                disabled={!hasPermission}
              />
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                {values.isService === 0 && (
                  <TextField
                    fullWidth
                    label="Low Stock Limit"
                    {...getFieldProps("low_stock")}
                    disabled={!hasPermission}
                    error={Boolean(touched.low_stock && errors.low_stock)}
                    helperText={touched.low_stock && errors.low_stock}
                  />
                )}

                <TextField
                  fullWidth
                  label="Product ID / Number"
                  {...getFieldProps("number")}
                  error={Boolean(touched.number && errors.number)}
                  disabled={!hasPermission}
                  helperText={touched.number && errors.number}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                {(hasPermission && values.isService === 0 && (
                  <TextField
                    fullWidth
                    label="Cost Price"
                    {...getFieldProps("cost")}
                    disabled={!hasPermission}
                    error={Boolean(touched.cost && errors.cost)}
                    helperText={touched.cost && errors.cost}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )) ||
                  ""}
                <Stack sx={{ width: 1 }} spacing={0.5}>
                  <TextField
                    fullWidth
                    label={
                      values.isService === 0 ? "Selling Price" : "Service Cost"
                    }
                    {...getFieldProps("selling")}
                    disabled={!hasPermission}
                    error={Boolean(touched.selling && errors.selling)}
                    helperText={touched.selling && errors.selling}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {(hasPermission && values.selling && values.cost && (
                    <Typography
                      variant="caption"
                      sx={{ color: "text.secondary" }}
                    >
                      Profit Margin:{" "}
                      <Typography
                        component="span"
                        variant="caption"
                        sx={{ fontWeight: 600 }}
                      >
                        {fCurrency(
                          parseFloat(values.selling) - parseFloat(values.cost)
                        )}
                      </Typography>
                    </Typography>
                  )) ||
                    ""}
                </Stack>
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <Autocomplete
                  fullWidth
                  options={CATEGORIES}
                  {...getFieldProps("category")}
                  disabled={!hasPermission}
                  name="category"
                  value={values.category}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setFieldValue("category", newValue);
                      const attributes = JSON.parse(newValue.attributes);
                      setFieldValue(
                        "attributes",
                        attributes.map((attr) => ({ name: attr, value: "" }))
                      );
                    }
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        values.isService === 0
                          ? "Product Category"
                          : "Service Category"
                      }
                      margin="none"
                      error={Boolean(touched.category && errors.category)}
                      helperText={touched.category && errors.category}
                    />
                  )}
                />
                <Autocomplete
                  fullWidth
                  options={BRANCHES}
                  {...getFieldProps("branch")}
                  name="branch"
                  value={values.branch}
                  disabled={!hasPermission}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setFieldValue("branch", newValue);
                    }
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Branch"
                      margin="none"
                      error={Boolean(touched.branch && errors.branch)}
                      helperText={touched.branch && errors.branch}
                    />
                  )}
                />
                {values.isService === 0 && (
                  <Autocomplete
                    fullWidth
                    freeSolo
                    options={COMPANIES.map((option) => option.name)}
                    {...getFieldProps("company")}
                    name="company"
                    value={values.company}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setFieldValue("company", newValue);
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      setFieldValue("company", newInputValue);
                    }}
                    getOptionLabel={(option) => option || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Purchased From"
                        margin="none"
                        error={Boolean(touched.company && errors.company)}
                        helperText={touched.company && errors.company}
                      />
                    )}
                  />
                )}
              </Stack>
              {values.attributes.length > 0 && values.isService === 0 && (
                <Stack direction="column" spacing={2}>
                  <Typography sx={{ mt: 2 }} variant="h6">
                    Attributes
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "text.disabled" }}
                  >
                    Please enter appropriate value(s) for the attributes
                    associated with this product category.
                  </Typography>
                  {values.attributes.map((attribute, index) => (
                    <Stack
                      alignItems="center"
                      direction={{ xs: "column", md: "row" }}
                      key={index}
                      spacing={2}
                      sx={{ width: 1 }}
                    >
                      <Typography>{attribute.name}:</Typography>
                      <TextField
                        size="small"
                        name="attribute"
                        {...getFieldProps(`attributes[${index}].value`)}
                        fullWidth
                        type="text"
                        margin="dense"
                        variant="outlined"
                        label={`${attribute.name} value`}
                      />
                    </Stack>
                  ))}
                </Stack>
              )}
              {values.isService === 1 && (
                <Stack direction="column" spacing={1}>
                  <Typography variant="subtitle2">Milestones:</Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    Please specify the milestones and their completion
                    percentages for this service.
                  </Typography>
                  <Stack spacing={2} sx={{ pt: 2 }}>
                    {values.attributes.map((attribute, index) => (
                      <Stack
                        alignItems="center"
                        direction={{ xs: "column", md: "row" }}
                        key={index}
                        spacing={2}
                        sx={{ width: 1 }}
                      >
                        <TextField
                          size="small"
                          name="name"
                          {...getFieldProps(`attributes[${index}].name`)}
                          fullWidth
                          type="text"
                          margin="dense"
                          variant="outlined"
                          label="Milestone name/description"
                        />
                        <TextField
                          size="small"
                          name="value"
                          {...getFieldProps(`attributes[${index}].value`)}
                          sx={{
                            maxWidth: { md: 300, xs: 1 },
                            width: { md: "auto", xs: 1 },
                            "& label": { fontSize: 13 },
                          }}
                          type="number"
                          margin="dense"
                          variant="outlined"
                          label="Completion Percentage"
                          InputProps={{
                            endAdornment: <InputAdornment>%</InputAdornment>,
                          }}
                        />
                        <Button
                          variant="outlined"
                          size="small"
                          color="error"
                          onClick={() =>
                            setFieldValue(
                              "attributes",
                              values.attributes.filter((e, i) => i !== index)
                            )
                          }
                        >
                          Remove
                        </Button>
                      </Stack>
                    ))}
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ width: "max-content" }}
                      onClick={() =>
                        setFieldValue("attributes", [
                          ...values.attributes,
                          { name: "", value: "" },
                        ])
                      }
                    >
                      Add Milestone
                    </Button>
                  </Stack>
                </Stack>
              )}
            </Stack>
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Update
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
