import PropTypes from "prop-types";

import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import closeFill from "@iconify/icons-eva/close-fill";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
// material
import {
  Box,
  Backdrop,
  Paper,
  Tooltip,
  Divider,
  Typography,
  Stack,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContentText,
  DialogContent,
} from "@mui/material";
import Scrollbar from "../../Scrollbar";
import { MIconButton } from "../../@material-extend";
import SalesSingle from "./SalesSingle";
// redux
import { useSelector, useDispatch } from "../../../redux/store";
import { deleteSale } from "../../../redux/slices/sale";
import { removeSale, removeSaleIndex } from "../../../redux/slices/global";
import { fNumber } from "../../../utils/formatNumber";
import { PATH_DASHBOARD } from "../../../routes/paths";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 260;

// ----------------------------------------------------------------------
DeleteModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  handleRemove: PropTypes.func,
};
function DeleteModal({ open, handleClose, handleDelete, handleRemove }) {
  return (
    <Dialog maxWidth="xs" open={open} onClose={handleClose}>
      <DialogTitle sx={{ color: "error.main" }}>
        Delete sale?
        <IconButton
          sx={{ position: "absolute", right: 8, top: 8 }}
          onClick={handleClose}
          color="error"
        >
          <CancelIcon />
        </IconButton>{" "}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this sale?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Button
            fullWidth
            onClick={handleRemove}
            variant="contained"
            color="warning"
          >
            Remove from Pending Sales
          </Button>
          <Button
            fullWidth
            onClick={handleDelete}
            variant="contained"
            color="error"
          >
            Remove & Delete
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default function PendingSales() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const { sales } = useSelector((state) => state.global);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = (index) => {
    setOpen(false);
    const sale = sales[index];
    navigate(`${PATH_DASHBOARD.subpages.editSale}/${sale.rid}`);
  };

  const onDelete = () => {
    dispatch(removeSale(deleteIndex));
    dispatch(deleteSale(sales[deleteIndex].rid));
    setOpenDelete(false);
    setDeleteIndex(-1);
    if (sales.length === 0) {
      handleClose();
    }
  };
  const onRemove = () => {
    dispatch(removeSaleIndex(sales[deleteIndex].rid));
    setOpenDelete(false);
    setDeleteIndex(-1);
    if (sales.length === 0) {
      handleClose();
    }
  };

  const handleDelete = (index) => {
    setDeleteIndex(index);
    setOpenDelete(true);
  };

  return (
    sales.length > 0 && (
      <>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        />
        <Box
          sx={{
            top: 12,
            bottom: 12,
            right: 0,
            position: "fixed",
            zIndex: 2001,
            ...(open && { right: 12 }),
          }}
        >
          <Box
            sx={{
              p: 0.5,
              px: "4px",
              mt: -3,
              left: -44,
              top: "50%",
              color: "grey.800",
              position: "absolute",
              bgcolor: "common.white",
              borderRadius: "24px 0 16px 24px",
              boxShadow: (theme) => theme.customShadows.z12,
            }}
          >
            <Tooltip title="Pending Sales">
              <Badge
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                badgeContent={sales.length}
                color="warning"
                max={99}
              >
                <MIconButton
                  color="inherit"
                  onClick={handleToggle}
                  sx={{
                    p: 0,
                    width: 40,
                    height: 40,
                    transition: (theme) => theme.transitions.create("all"),
                    "&:hover": {
                      color: "primary.main",
                      bgcolor: "transparent",
                    },
                  }}
                >
                  {open ? (
                    <CloseIcon sx={{ width: 20, height: 20 }} />
                  ) : (
                    <PointOfSaleIcon sx={{ width: 20, height: 20 }} />
                  )}
                </MIconButton>
              </Badge>
            </Tooltip>
          </Box>

          <Paper
            sx={{
              height: 1,
              width: "0px",
              overflow: "hidden",
              boxShadow: (theme) => theme.customShadows.z24,
              transition: (theme) => theme.transitions.create("width"),
              ...(open && { width: DRAWER_WIDTH }),
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ py: 2, pr: 1, pl: 2.5 }}
            >
              <Typography variant="subtitle1">Pending Sales</Typography>
              <MIconButton onClick={handleClose}>
                <Icon icon={closeFill} width={20} height={20} />
              </MIconButton>
            </Stack>
            <Divider />

            <Scrollbar sx={{ height: 1 }}>
              <Stack spacing={4} sx={{ pt: 3, px: 3, pb: 15 }}>
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">
                    {sales.length > 0 ? fNumber(sales.length) : "No"} Pending{" "}
                    {sales.length > 1 ? "Sales" : "Sale"}
                  </Typography>
                  <SalesSingle
                    onDelete={handleDelete}
                    sales={sales}
                    handleClick={handleClick}
                  />
                </Stack>
              </Stack>
            </Scrollbar>
          </Paper>
        </Box>
        <DeleteModal
          open={openDelete}
          handleClose={() => {
            setOpenDelete(false);
          }}
          handleDelete={onDelete}
          handleRemove={onRemove}
        />
      </>
    )
  );
}
