import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// material
import { Box, Grid, Card, Button, Typography, Stack } from "@mui/material";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { ShowError } from "../../../utils/loader";
import { CheckPerm } from "../../../utils/apis";
// ----------------------------------------------------------------------

SingleCard.propTypes = {
  admin: PropTypes.object,
};

function SingleCard({ admin }) {
  const { fname, lname, roleName, uid } = admin;
  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        p: 3,
      }}
    >
      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {fname} {lname}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {roleName}
          </Typography>
        </Box>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          sx={{ mt: 2 }}
          component={RouterLink}
          to={`${PATH_DASHBOARD.subpages.admin}/${uid}`}
        >
          View
        </Button>
      </Box>
    </Card>
  );
}

Admins.propTypes = {
  admins: PropTypes.array.isRequired,
};

export default function Admins({ admins }) {
  const hasPermission = CheckPerm("view_administrators");
  return (
    <Box sx={{ mt: 5 }}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: { md: 3, xs: 2 } }}
      >
        <Typography variant="h4">Administrators</Typography>
        {hasPermission && (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            component={RouterLink}
            to={PATH_DASHBOARD.administrative.admins}
          >
            View All
          </Button>
        )}
      </Stack>
      {admins.length > 0 ? (
        <Grid container spacing={3}>
          {admins.map((admin, index) => (
            <Grid key={index} item xs={12} md={4}>
              <SingleCard admin={admin} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <ShowError
          message={
            new Error(
              "This branch does not have any admnistrator attached at the moment!"
            )
          }
        />
      )}
    </Box>
  );
}
