import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
// @mui
import {
  MenuItem,
  Box,
  Alert,
  Dialog,
  Stack,
  Typography,
  CircularProgress,
  Button,
  ListItemButton,
  Autocomplete,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import Scrollbar from "../../Scrollbar";
import useAuth from "../../../hooks/useAuth";
import { GENDERS } from "../../../utils/apis";

// ----------------------------------------------------------------------

InvoiceAddressListDialog.propTypes = {
  addressOptions: PropTypes.array,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  open: PropTypes.bool,
  isBranches: PropTypes.bool,
  branch: PropTypes.string,
  isTo: PropTypes.bool,
  isOnline: PropTypes.bool,
  selected: PropTypes.func,
};
export default function InvoiceAddressListDialog({
  open,
  selected,
  onClose,
  onSelect,
  addressOptions,
  isTo,
  branch,
  isOnline,
}) {
  const [openCustomer, setOpenCustomer] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("");
  const { getCustomers, addCustomer } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);

  const [newCustomer, setNewCustomer] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    dob: "",
    gender: "",
    credit: "",
    barcode: "",
  });
  const [errors, setErrors] = useState("");
  const handleNewChange = (e) => {
    const { name, value } = e.target;
    setNewCustomer((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const filter = useCallback(() => {
    (async () => {
      setLoading(true);
      const data = await getCustomers(search, branch, isOnline);
      setOptions(data);
      setLoading(false);
    })();
  }, [search, branch, isOnline, getCustomers]);

  useEffect(() => {
    if (openCustomer) {
      filter();
    }
  }, [filter, openCustomer]);

  const handleSelect = (address) => {
    onSelect(address);
    onClose();
  };
  const handleSave = async () => {
    try {
      setErrors("");
      if (newCustomer.name) {
        setisSubmitting(true);
        const cid = await addCustomer(newCustomer, branch, isOnline);
        newCustomer.cid = cid;
        handleSelect(newCustomer);
        setNewCustomer({
          name: "",
          email: "",
          phone: "",
          address: "",
          dob: "",
          gender: "",
          credit: "",
          barcode: "",
        });
        setOpenAdd(false);
      } else {
        setErrors("Name is required to proceed!");
      }
    } catch (err) {
      setErrors(err.message);
    }
    setisSubmitting(false);
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ py: 2.5, px: 3 }}
      >
        <Typography variant="h6">
          {" "}
          {isTo ? "Select Customer" : "Select Branch"}{" "}
        </Typography>

        {isTo && (
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              setOpenAdd(!openAdd);
            }}
            startIcon={<AddIcon />}
            sx={{ alignSelf: "flex-end" }}
          >
            {openAdd ? "Select Customer" : "Add New"}
          </Button>
        )}
      </Stack>
      {(openAdd && (
        <Box sx={{ p: 2 }}>
          <Stack spacing={{ xs: 2, md: 3 }}>
            {errors && <Alert severity="error">{errors}</Alert>}
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
              <TextField
                fullWidth
                label="Name *"
                onChange={handleNewChange}
                name="name"
                value={newCustomer.name}
              />
              <TextField
                autoComplete="username"
                type="email"
                fullWidth
                label="Email Address"
                onChange={handleNewChange}
                name="email"
                value={newCustomer.email}
              />
            </Stack>
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
              <TextField
                fullWidth
                autoComplete="tel"
                label="Phone"
                onChange={handleNewChange}
                name="phone"
                value={newCustomer.phone}
              />
              <TextField
                select
                sx={{ width: { xs: "100%", md: 500 } }}
                label="Gender"
                onChange={handleNewChange}
                name="gender"
                value={newCustomer.gender}
              >
                {GENDERS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
              <TextField
                fullWidth
                label="Address"
                onChange={handleNewChange}
                name="address"
                value={newCustomer.address}
                helperText="You should fill this in case of product delivery and shipping."
              />
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="date"
                label="Date of Birth"
                onChange={handleNewChange}
                name="dob"
                value={newCustomer.dob}
                helperText="You should fill this if you wish to send automated birthday messages"
              />
            </Stack>
            <Box sx={{ mt: 3 }}>
              <LoadingButton
                onClick={handleSave}
                fullWidth
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Save
              </LoadingButton>
            </Box>
          </Stack>
        </Box>
      )) ||
        (isTo && (
          <Autocomplete
            sx={{ p: 5 }}
            id="asynchronous-demo"
            open={openCustomer}
            onOpen={() => {
              setOpenCustomer(true);
            }}
            onClose={() => {
              setOpenCustomer(false);
            }}
            getOptionLabel={(option) =>
              `${option.name} ${option.phone ? `(${option.phone})` : ""}`
            }
            options={options}
            onChange={(event, newValue) => {
              handleSelect(newValue);
            }}
            loading={loading}
            isOptionEqualToValue={(option, value) => option.cid === value.cid}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Name or phone number."
                variant="outlined"
                autoFocus
                onChange={(ev) => {
                  // dont fire API if the user delete or not entered anything
                  if (ev.target.value !== "" || ev.target.value !== null) {
                    setSearch(ev.target.value);
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  autoComplete: "off",
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )) || (
          <Scrollbar sx={{ p: 1.5, pt: 0, maxHeight: 80 * 8 }}>
            {addressOptions.map((address) => (
              <ListItemButton
                key={isTo ? address.bid : address.bid}
                selected={selected(isTo ? address.bid : address.bid)}
                onClick={() => handleSelect(address)}
                sx={{
                  p: 1.5,
                  borderRadius: 1,
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography variant="subtitle2">{address.name}</Typography>
              </ListItemButton>
            ))}
          </Scrollbar>
        )}
    </Dialog>
  );
}
