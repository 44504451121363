/* eslint-disable react/jsx-pascal-case */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  ListItemText,
  List,
  Divider,
  ListItemButton,
  Stack,
  Avatar,
  Drawer,
  Tooltip,
  Typography,
  CardActionArea,
  Paper,
  Link,
  Button,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSnackbar } from "notistack";
// hooks
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
//
import { MHidden } from "../../components/@material-extend";
import useAuth from "../../hooks/useAuth";
import sidebarConfig from "./SidebarConfig";
import { ASSETS_PATH, CheckPerm, CheckSub, PAGE_DATA } from "../../utils/apis";
import { PATH_DASHBOARD } from "../../routes/paths";
import { Block } from "../../components/Block";

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.complex,
    }),
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

IconCollapse.propTypes = {
  onToggleCollapse: PropTypes.func,
  collapseClick: PropTypes.bool,
};
const ListWrapperStyle = styled(Paper)(() => ({
  width: "100%",
  backgroundColor: "inherit",
  borderRadius: "0px",
}));

function IconCollapse({ onToggleCollapse, collapseClick }) {
  return (
    <Tooltip title="Collapse Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: "flex",
          cursor: "pointer",
          borderRadius: "50%",
          alignItems: "center",
          color: "text.primary",
          justifyContent: "center",
          border: "solid 1px currentColor",
          ...(collapseClick && {
            borderWidth: 2,
          }),
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: "50%",
            bgcolor: "currentColor",
            transition: (theme) => theme.transitions.create("all"),
            ...(collapseClick && {
              width: 0,
              height: 0,
            }),
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  isOnline: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  isOnline,
}) {
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user, currentBranch, switchBranch } = useAuth();
  const { branches, shop } = user;
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [Asset, setAsset] = useState(null);
  const hasSupportPermission = CheckPerm("send_support_messages");
  const photo =
    user && user.user && user.user.photo
      ? `${ASSETS_PATH.users}${user.user.photo}`
      : "/static/placeholder.svg";

  useEffect(() => {
    const load = async () => {
      setAsset(await import("../../assets/illustration_doc"));
    };
    load();
    return () => {
      setAsset(null);
    };
  }, []);

  const sidebarData = sidebarConfig
    .map((obj) => {
      const items = obj.items.filter((it) => {
        const { code, permission } = PAGE_DATA.filter(
          (pp) => pp.path === it.path
        )[0];
        return (
          (permission === "" || CheckPerm(permission)) && CheckSub(code) === 1
        );
      });
      return {
        subheader: items.length > 0 ? obj.subheader : "",
        items,
        isNew: obj.isNew || false,
      };
    })
    .filter((obj) => obj.subheader !== "");
  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleBranchSwitch = (bid) => {
    try {
      switchBranch(bid);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  const filteredBranches =
    user && user.user
      ? branches.filter((branch) =>
          branch.name.toLowerCase().includes(search.toLowerCase())
        )
      : [];

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: "center",
          }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
            {(shop.logo && (
              <Box
                component="img"
                src={`${ASSETS_PATH.logos}${shop.logo}`}
                sx={{ width: "auto", height: 40 }}
              />
            )) || <Logo disabledLink />}
          </Box>

          <MHidden width="lgDown">
            {!isCollapse && (
              <IconCollapse
                onToggleCollapse={onToggleCollapse}
                collapseClick={collapseClick}
              />
            )}
          </MHidden>
        </Stack>

        {isCollapse ? (
          <Avatar
            alt={user.user.fname}
            src={photo}
            sx={{ mx: "auto", mb: 2 }}
          />
        ) : (
          <AccountStyle>
            <Link
              underline="none"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                if (isOnline) {
                  setOpen(!open);
                } else {
                  enqueueSnackbar(
                    "You cannot switch to another branch while  offline!",
                    { variant: "error" }
                  );
                }
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box sx={{ ml: 2, maxWidth: "80%" }}>
                  <Typography
                    noWrap
                    variant="subtitle2"
                    sx={{ color: "text.primary" }}
                  >
                    {currentBranch.name || ""}
                  </Typography>
                  <Typography
                    noWrap
                    variant="body2"
                    sx={{ color: "text.secondary" }}
                  >
                    {currentBranch.location || ""}
                  </Typography>
                </Box>
                {isOnline &&
                  ((open && <KeyboardArrowDownIcon />) || (
                    <KeyboardArrowUpIcon />
                  ))}
              </Stack>
            </Link>
            <Block
              title={user && user.user ? `Branches - ${branches.length}` : ""}
              open={open && isOnline}
              search={search}
              handleChange={handleSearchChange}
            >
              <ListWrapperStyle>
                <List component="nav">
                  {filteredBranches.map((branch) => (
                    <ListItemButton
                      onClick={() => handleBranchSwitch(branch.bid)}
                      key={branch.bid}
                    >
                      <ListItemText primary={branch.name} />
                    </ListItemButton>
                  ))}
                </List>
                <Divider />
                <List component="nav">
                  <ListItemButton
                    onClick={() => navigate(PATH_DASHBOARD.business.branches)}
                  >
                    <ListItemText
                      sx={{ color: "text.disabled" }}
                      primary="Add Branch"
                    />
                  </ListItemButton>
                </List>
              </ListWrapperStyle>
            </Block>
          </AccountStyle>
        )}
      </Stack>

      <NavSection
        navConfig={sidebarData}
        isOnline={isOnline}
        isShow={!isCollapse}
      />
      <Box sx={{ flexGrow: 1 }} />

      {!isCollapse && hasSupportPermission && isOnline && (
        <Stack
          spacing={3}
          alignItems="center"
          sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: "center" }}
        >
          {Boolean(Asset) && <Asset.default sx={{ width: 1 }} />}
          <div>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Need help? <br /> Please send us a
            </Typography>
          </div>
          <Button
            component={RouterLink}
            to={PATH_DASHBOARD.misc.sendSupport}
            variant="contained"
          >
            Message
          </Button>
        </Stack>
      )}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        displayPrint: "none",
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              ...(isCollapse && {
                width: COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: "blur(6px)",
                WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) =>
                  alpha(theme.palette.background.default, 0.88),
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
