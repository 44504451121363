import {
  format,
  getTime,
  formatDistanceToNow,
  differenceInDays,
} from "date-fns";

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date.replace(/-/g, "/")), "dd MMM yyyy");
}

export function fDateTime(date) {
  return format(new Date(date.replace(/-/g, "/")), "dd MMM yyyy hh:mm aa");
}

export function fTimestamp(date) {
  return getTime(new Date(date.replace(/-/g, "/")));
}
export function fDateDiff(dateStart, dateEnds) {
  return differenceInDays(
    getTime(dateEnds ? new Date(dateEnds.replace(/-/g, "/")) : new Date()),
    getTime(dateStart ? new Date(dateStart.replace(/-/g, "/")) : new Date())
  );
}

export function fDateTimeSuffix(date) {
  return format(new Date(date.replace(/-/g, "/")), "dd/MM/yyyy hh:mm aa");
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date.replace(/-/g, "/")), {
    addSuffix: true,
  });
}
export function fDateServer(date) {
  return format(
    date ? new Date(date.replace(/-/g, "/")) : new Date(),
    date ? "yyyy-MM-dd" : "yyyy-MM-dd HH:mm:ss"
  );
}
