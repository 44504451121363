import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import dashboardReducer from "./slices/dashboard";
import branchReducer from "./slices/branch";
import categoryReducer from "./slices/category";
import adminReducer from "./slices/admin";
import roleReducer from "./slices/role";
import customerReducer from "./slices/customer";
import productReducer from "./slices/product";
import staffReducer from "./slices/staff";
import salesReducer from "./slices/sale";
import returnedReducer from "./slices/returned";
import debtorReducer from "./slices/debtor";
import damagedReducer from "./slices/damaged";
import purchaseReducer from "./slices/purchase";
import onlineReducer from "./slices/online";
import expenseReducer from "./slices/expense";
import withdrawalReducer from "./slices/withdrawal";
import billingReducer from "./slices/billing";
import exportReducer from "./slices/export";
import sendReducer from "./slices/send";
import logReducer from "./slices/log";
import notificationReducer from "./slices/notification";
import supportReducer from "./slices/support";
import cardReducer from "./slices/card";
import globalReducer from "./slices/global";
import deviceReducer from "./slices/device";
import transferReducer from "./slices/transfers";
import invoiceReducer from "./slices/invoice";
import quotationReducer from "./slices/quotation";
import waybillReducer from "./slices/waybill";
import companyReducer from "./slices/company";
import discrepancyReducer from "./slices/discrepancy_report";
import orderReducer from "./slices/order";
import discountCodeReducer from "./slices/discountCode";
import reviewReducer from "./slices/review";
import featuredProductReducer from "./slices/featured_product";
import specialOffersReducer from "./slices/special_offers";
import offlineReducer from "./slices/offline";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};
const globalPersistConfig = {
  key: "global",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sales"],
};

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  branch: branchReducer,
  category: categoryReducer,
  admin: adminReducer,
  role: roleReducer,
  customer: customerReducer,
  product: productReducer,
  staff: staffReducer,
  sale: salesReducer,
  returned: returnedReducer,
  debtor: debtorReducer,
  damaged: damagedReducer,
  purchase: purchaseReducer,
  online: onlineReducer,
  expense: expenseReducer,
  withdrawal: withdrawalReducer,
  billing: billingReducer,
  send: sendReducer,
  log: logReducer,
  notification: notificationReducer,
  export: exportReducer,
  support: supportReducer,
  card: cardReducer,
  device: deviceReducer,
  global: persistReducer(globalPersistConfig, globalReducer),
  transfer: transferReducer,
  invoice: invoiceReducer,
  quotation: quotationReducer,
  waybill: waybillReducer,
  company: companyReducer,
  discrepancy_report: discrepancyReducer,
  order: orderReducer,
  discountCode: discountCodeReducer,
  review: reviewReducer,
  featuredProduct: featuredProductReducer,
  specialOffer: specialOffersReducer,
  offline: offlineReducer,
});

export { rootPersistConfig, rootReducer };
