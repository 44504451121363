import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
// material
import { Box, Paper, Divider, Typography, Stack } from "@mui/material";
import Scrollbar from "../../Scrollbar";
import { MIconButton } from "../../@material-extend";
// redux
import { fCurrency, fNumber } from "../../../utils/formatNumber";
import { getSavedOfflineData } from "../../../utils/jwt";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 260;

// ----------------------------------------------------------------------

OfflineSales.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
export default function OfflineSales({ open, setOpen }) {
  const sales = getSavedOfflineData("sales") || [];

  const handleClose = () => {
    setOpen(false);
  };

  return (
    sales.length > 0 && (
      <Box
        sx={{
          top: 0,
          bottom: 12,
          right: 0,
          position: "fixed",
          zIndex: 2001,
          height: "100vh",
        }}
      >
        <Paper
          sx={{
            height: 1,
            width: "0px",
            overflow: "hidden",
            boxShadow: (theme) => theme.customShadows.z24,
            transition: (theme) => theme.transitions.create("width"),
            ...(open && { width: DRAWER_WIDTH }),
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ py: 2, pr: 1, pl: 2.5 }}
          >
            <Typography variant="subtitle1">
              {sales.length > 0 ? fNumber(sales.length) : "No"} Offline{" "}
              {sales.length > 1 ? "Sales" : "Sale"}
            </Typography>
            <MIconButton onClick={handleClose}>
              <Icon icon={closeFill} width={20} height={20} />
            </MIconButton>
          </Stack>
          <Divider />
          <Scrollbar sx={{ height: 1 }}>
            <Stack spacing={4} sx={{ pt: 1, px: 2, pb: 15 }}>
              <Stack spacing={1.5}>
                <Typography variant="body2">
                  Sales will be automatically uploaded once your device is
                  connected to the internet.
                </Typography>
                <Typography variant="caption" sx={{ color: "text.secondary" }}>
                  Please note that your sales are saved on your device using
                  cookies. This means that your sales are safe even if the
                  browser or this tab is closed. However, your sales will be
                  removed if you clear your browser cache before uploading.
                </Typography>
                <Stack
                  spacing={2}
                  sx={{ pt: 2, width: 1 }}
                  divider={<Divider orientation="horizontal" />}
                >
                  {sales.map((sale, index) => (
                    <Paper
                      key={index}
                      sx={{
                        width: 1,
                        zIndex: 0,
                        overflow: "hidden",
                        position: "relative",
                        bgcolor: "",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          color: "text.secondary",
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        <Stack direction="column" spacing={1} sx={{ width: 1 }}>
                          <Typography variant="subtitle2">
                            Customer:{" "}
                            <strong>
                              {sale.customerName || "Walk In Customer"}
                            </strong>
                          </Typography>
                          <Typography variant="subtitle2">
                            No. of Products:{" "}
                            <strong>
                              {fNumber(JSON.parse(sale.products).length)}{" "}
                              {JSON.parse(sale.products).length > 1
                                ? "Products"
                                : "Product"}
                            </strong>
                          </Typography>
                          <Typography variant="subtitle2">
                            Amount Paid:{" "}
                            <strong>{fCurrency(sale.amount)}</strong>
                          </Typography>
                        </Stack>
                      </Box>
                    </Paper>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          </Scrollbar>
        </Paper>
      </Box>
    )
  );
}
