import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// material
import { Box, Grid, Card, Button, Typography, Stack } from "@mui/material";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { fCurrency } from "../../../utils/formatNumber";
import { ShowError } from "../../../utils/loader";

// ----------------------------------------------------------------------

SingleCard.propTypes = {
  expense: PropTypes.object,
};

function SingleCard({ expense }) {
  const { amount, reason } = expense;
  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        p: 3,
      }}
    >
      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {fCurrency(amount)}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {reason}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

StaffExpenses.propTypes = {
  expenses: PropTypes.array.isRequired,
};

export default function StaffExpenses({ expenses }) {
  return (
    <Box sx={{ mt: 5 }}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: { md: 3, xs: 2 } }}
      >
        <Typography variant="h4">Expenses</Typography>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          component={RouterLink}
          to={PATH_DASHBOARD.accounting.expenses}
        >
          View All
        </Button>
      </Stack>
      {expenses.length > 0 ? (
        <Grid container spacing={3}>
          {expenses.map((expense, index) => (
            <Grid key={index} item xs={12} md={4}>
              <SingleCard expense={expense} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <ShowError
          message={
            new Error(
              "This staff does not have any expense attached at the moment!"
            )
          }
        />
      )}
    </Box>
  );
}
