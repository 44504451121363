import PropTypes from "prop-types";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
// material
import { Card, Alert, TextField, Stack, CardHeader, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { CheckPerm } from "../../../utils/apis";

// ----------------------------------------------------------------------
StaffNOK.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function StaffNOK({ myProfile, uid, fetch }) {
  const { updateStaffNOK } = useAuth();
  const hasPermission = CheckPerm("update_staff");
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const Schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone: Yup.string().required("Phone number is required"),
    relation: Yup.string().required("Relationship is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: myProfile.nokName,
      phone: myProfile.nokPhone,
      email: myProfile.nokEmail,
      relation: myProfile.nokRelation,
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateStaffNOK(values, uid);
        enqueueSnackbar("Next of kin's data has been updated!", {
          variant: "success",
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        fetch();
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        px: 3,
        pb: 3,
      }}
    >
      <CardHeader title="Next of Kin Information" />
      <Box sx={{ p: { md: 3, xs: 1 } }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  disabled={!hasPermission}
                  fullWidth
                  label="Name"
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  disabled={!hasPermission}
                  autoComplete="username"
                  type="email"
                  fullWidth
                  label="Email Address"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />

                <TextField
                  fullWidth
                  disabled={!hasPermission}
                  autoComplete="username"
                  type="tel"
                  label="Phone number"
                  {...getFieldProps("phone")}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  disabled={!hasPermission}
                  fullWidth
                  label="Relationship"
                  {...getFieldProps("relation")}
                  error={Boolean(touched.relation && errors.relation)}
                  helperText={touched.relation && errors.relation}
                />
              </Stack>
            </Stack>
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Update
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
