import PropTypes from "prop-types";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Card,
  Alert,
  TextField,
  Stack,
  CardHeader,
  Box,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { CheckPerm, DISCOUNT_CODES_TYPES } from "../../../utils/apis";

// ----------------------------------------------------------------------
DiscountCodeUpdate.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function DiscountCodeUpdate({ myProfile, uid, fetch }) {
  const { updateDiscountCode } = useAuth();
  const hasPermission = CheckPerm("update_discount_codes");
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const Schema = Yup.object().shape({
    code: Yup.string().required("Code name is required"),
    amount: Yup.number().required("Amount is required"),
    type: Yup.number().required("Type is required"),
    usage: Yup.number().required("Number of usage is required"),
    expire: Yup.string().required("Expiry date is required"),
  });

  const formik = useFormik({
    initialValues: {
      code: myProfile.code,
      amount: myProfile.amount,
      type: myProfile.type,
      usage: myProfile.totalUsage,
      expire: String(myProfile.dateExpire).split(" ")[0],
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateDiscountCode(values, uid);
        enqueueSnackbar("Discount code's data has been updated!", {
          variant: "success",
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        fetch();
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        px: 3,
        pb: 3,
      }}
    >
      <CardHeader title="Discount Code Information" />
      <Box sx={{ p: { md: 3, xs: 1 } }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}
              <Stack direction="column" spacing={2}>
                <TextField
                  disabled={!hasPermission}
                  fullWidth
                  label="Code Name"
                  {...getFieldProps("code")}
                  error={Boolean(touched.code && errors.code)}
                  helperText={touched.code && errors.code}
                />
                <TextField
                  sx={{ mt: 2 }}
                  select
                  value={values.type}
                  disabled={!hasPermission}
                  name="type"
                  onChange={(e) => setFieldValue("type", e.target.value)}
                  fullWidth
                  variant="outlined"
                  label="Code Type"
                >
                  {DISCOUNT_CODES_TYPES.map((option, index) => (
                    <MenuItem key={index} value={index}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  disabled={!hasPermission}
                  fullWidth
                  type="number"
                  label={
                    values.type === 0 ? "Flat Amount (NGN)" : "Percentage (%)"
                  }
                  {...getFieldProps("amount")}
                  error={Boolean(touched.amount && errors.amount)}
                  helperText={
                    (touched.amount && errors.amount) || values.type === 0
                      ? "This will be the amount that will de discounted when applied."
                      : "This will be the percentage discount that will be applied."
                  }
                />
                <TextField
                  disabled={!hasPermission}
                  fullWidth
                  type="date"
                  label="Expiration Date"
                  {...getFieldProps("expire")}
                  error={Boolean(touched.expire && errors.expire)}
                  helperText={touched.expire && errors.expire}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  disabled={!hasPermission}
                  fullWidth
                  type="number"
                  label="Number of Usage"
                  {...getFieldProps("usage")}
                  error={Boolean(touched.usage && errors.usage)}
                  helperText={
                    (touched.usage && errors.usage) ||
                    "This is the number of times this discount code can be used."
                  }
                />
              </Stack>
            </Stack>
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Update
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
