import { replace, round } from "lodash";
import numeral from "numeral";
import { getSavedOfflineData } from "./jwt";

// ----------------------------------------------------------------------

export function fCurrency(number, showText = false) {
  return `${showText ? "N" : "₦"}${numeral(number).format("0,0.00")}`;
}

export function fPercent(number) {
  return numeral(number / 100).format("0.0%");
}
export function fPercentOnly(number, total) {
  const percent = (number / total) * 100;
  return Number.isNaN(percent) ? 0 : round(percent);
}

export function fNumber(number) {
  return String(number).includes(".")
    ? numeral(number).format("0.0")
    : numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format("0.00a"), ".00", "");
}

export function fData(number) {
  return numeral(number).format("0.0 b");
}

export function isNumeric(str) {
  if (typeof str !== "string" && typeof str !== "number") return false;
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
}

export const fRange = (start, stop, step) => {
  if (stop < start) {
    return Array.from(
      { length: (start - stop) / step + 1 },
      (_, i) => start - i * step
    );
  }
  return Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => start + i * step
  );
};
const mtRand = async (min, max) =>
  // min and max included
  Math.floor(Math.random() * (max - min + 1) + min);
export const getSalesID = async () => {
  let rid = "";
  const sales = getSavedOfflineData("sales");
  // eslint-disable-next-line no-constant-condition
  while (true) {
    rid = mtRand(111111, 999999);
    // eslint-disable-next-line no-loop-func
    const check = sales.filter((sale) => parseInt(sale.rid, 10) === rid);
    if (check.length === 0) {
      break;
    }
  }
  return rid;
};
