import { isString } from "lodash";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { useDropzone } from "react-dropzone";
import fileFill from "@iconify/icons-eva/file-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import { motion, AnimatePresence } from "framer-motion";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Stack,
  Paper,
  Button,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import StarBorderPurple500OutlinedIcon from "@mui/icons-material/StarBorderPurple500Outlined";
import StarPurple500OutlinedIcon from "@mui/icons-material/StarPurple500Outlined";
// utils
import { fData } from "../../utils/formatNumber";
//
import { MIconButton } from "../@material-extend";
import { varFadeInRight } from "../animate";
import { UploadIllustration } from "../../assets";
import { ASSETS_PATH } from "../../utils/apis";

// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  display: "flex",
  textAlign: "center",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  "&:hover": { opacity: 0.72, cursor: "pointer" },
  [theme.breakpoints.up("md")]: { textAlign: "left", flexDirection: "row" },
}));

// ----------------------------------------------------------------------
ShowRejectionItems.propTypes = {
  fileRejections: PropTypes.array,
};
function ShowRejectionItems({ fileRejections }) {
  return (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: "error.light",
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );
}

UploadMultiFile.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  sx: PropTypes.object,
  title: PropTypes.string,
  message: PropTypes.any,
  isShowImage: PropTypes.bool,
  isUploading: PropTypes.bool,
  hasPermission: PropTypes.bool,
  onMakePrimary: PropTypes.func,
  onOpenLightBox: PropTypes.func,
};

export default function UploadMultiFile({
  error,
  title,
  message,
  showPreview = false,
  isShowImage,
  files,
  isUploading,
  onRemove,
  onRemoveAll,
  onMakePrimary,
  onOpenLightBox,
  hasPermission,
  sx,
  ...other
}) {
  const hasFile = files.length > 0;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    disabled: !hasPermission,
    ...other,
  });

  return (
    <Box sx={{ width: "100%", ...sx }}>
      {isUploading ? (
        <Stack
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{ p: 2 }}
        >
          <CircularProgress />
          <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
            File(s) uploading. Please wait...
          </Typography>
        </Stack>
      ) : (
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || error) && {
              color: "error.main",
              borderColor: "error.light",
              bgcolor: "error.lighter",
            }),
          }}
        >
          <input {...getInputProps()} />
          {isShowImage && !isUploading && (
            <UploadIllustration sx={{ width: 220 }} />
          )}
          <Box sx={{ p: 2, ml: { md: 2 } }}>
            <Typography gutterBottom variant="h5">
              {title || "Drop or Select file"}
            </Typography>
            {message || (
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Drop files here or click&nbsp;
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ color: "primary.main", textDecoration: "underline" }}
                >
                  browse
                </Typography>
                &nbsp;and select the files you want to upload.
              </Typography>
            )}
          </Box>
        </DropZoneStyle>
      )}
      {onMakePrimary && (
        <Typography
          variant="caption"
          sx={{ color: "#95959D", mt: 2, display: "block" }}
        >
          &middot;&nbsp;Recommended image size: 2160px X 1080px
          <br />
          &middot;&nbsp;Maximum size: 2MB
          <br />
          &middot;&nbsp;Supported image files: JPG or PNG
        </Typography>
      )}

      {fileRejections.length > 0 && (
        <ShowRejectionItems fileRejections={fileRejections} />
      )}

      <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
        <AnimatePresence>
          {files.map((file, index) => {
            const { name, size, preview, isPrimary } = file;
            const key = isString(file) ? file : name;

            if (showPreview) {
              return (
                <ListItem
                  key={key}
                  component={motion.div}
                  {...varFadeInRight}
                  onClick={() => onOpenLightBox(index)}
                  sx={{
                    p: 0,
                    m: 0.5,
                    width: 100,
                    height: 100,
                    borderRadius: 0.5,
                    overflow: "hidden",
                    position: "relative",
                    display: "inline-flex",
                    cursor: "pointer",
                  }}
                >
                  <Paper
                    variant="outlined"
                    component="img"
                    src={
                      isString(file)
                        ? `${ASSETS_PATH.products}${file}`
                        : preview
                    }
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      position: "absolute",
                    }}
                  />
                  {hasPermission && (
                    <>
                      <Box sx={{ top: 2, left: 2, position: "absolute" }}>
                        <Tooltip title="Mark as featured photo">
                          <IconButton
                            size="small"
                            onClick={() => onMakePrimary(file)}
                            sx={{
                              p: "2px",
                              color: "common.white",
                              width: 24,
                              height: 24,
                              bgcolor: (theme) =>
                                alpha(theme.palette.grey[900], 0.72),
                              "&:hover": {
                                bgcolor: (theme) =>
                                  alpha(theme.palette.grey[900], 0.48),
                              },
                            }}
                          >
                            {isPrimary ? (
                              <StarPurple500OutlinedIcon
                                sx={{ width: 18, height: 18 }}
                              />
                            ) : (
                              <StarBorderPurple500OutlinedIcon
                                sx={{ width: 18, height: 18 }}
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box sx={{ top: 2, right: 2, position: "absolute" }}>
                        <Tooltip title="Remove photo">
                          <IconButton
                            size="small"
                            onClick={() => onRemove(file)}
                            sx={{
                              p: "2px",
                              color: "common.white",
                              width: 24,
                              height: 24,
                              bgcolor: (theme) =>
                                alpha(theme.palette.grey[900], 0.72),
                              "&:hover": {
                                bgcolor: (theme) =>
                                  alpha(theme.palette.grey[900], 0.48),
                              },
                            }}
                          >
                            <CloseOutlinedIcon sx={{ width: 18, height: 18 }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </>
                  )}
                </ListItem>
              );
            }

            return (
              <ListItem
                key={key}
                component={motion.div}
                {...varFadeInRight}
                sx={{
                  my: 1,
                  py: 0.75,
                  px: 2,
                  borderRadius: 1,
                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                  bgcolor: "background.paper",
                }}
              >
                <ListItemIcon>
                  <Icon icon={fileFill} width={28} height={28} />
                </ListItemIcon>
                <ListItemText
                  primary={isString(file) ? file : name}
                  secondary={isString(file) ? "" : fData(size)}
                  primaryTypographyProps={{ variant: "subtitle2" }}
                  secondaryTypographyProps={{ variant: "caption" }}
                />
                <ListItemSecondaryAction>
                  <MIconButton
                    edge="end"
                    size="small"
                    onClick={() => onRemove(file)}
                  >
                    <Icon icon={closeFill} />
                  </MIconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </AnimatePresence>
      </List>

      {hasFile && hasPermission && (
        <Stack direction="row" justifyContent="flex-end">
          <Button onClick={onRemoveAll} sx={{ mr: 1.5 }}>
            Remove all
          </Button>
        </Stack>
      )}
    </Box>
  );
}
