/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { merge } from "lodash";
import PropTypes from "prop-types";
// material
import { Card, CardHeader, Box } from "@mui/material";
//
import { BaseOptionChart } from "../../charts";

BarGraph.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  chart: PropTypes.array,
};
export default function BarGraph({ title, subtitle, chart }) {
  const names = chart.map((customer) => customer.name);
  const amounts = chart.map((customer) => parseFloat(customer.totalAmount));
  const chartOptions = merge(BaseOptionChart(), {
    stroke: { show: true },
    plotOptions: {
      bar: { horizontal: false, barHeight: "30%" },
    },
    xaxis: {
      categories: names,
    },
  });
  const CHART_DATA = [{ data: amounts }];
  const [Chart, setChart] = useState(null);

  useEffect(() => {
    const importChart = async () => {
      const chart = await import("react-apexcharts");
      setChart(chart);
    };
    importChart();
    return () => {
      setChart(null);
    };
  }, []);

  return (
    <Card sx={{ boxShadow: "none" }}>
      <CardHeader title={title} subheader={subtitle} />
      <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
        {Boolean(Chart) && (
          <Chart.default
            type="bar"
            series={CHART_DATA}
            options={chartOptions}
            height={364}
          />
        )}
      </Box>
    </Card>
  );
}
