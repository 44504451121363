import PropTypes from "prop-types";
// material
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import { Button, Card, Typography, Stack } from "@mui/material";
// utils
import { fCurrency } from "../../../utils/formatNumber";
import { PATH_DASHBOARD } from "../../../routes/paths";

// ----------------------------------------------------------------------

const RowStyle = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

// ----------------------------------------------------------------------
WalletBalance.propTypes = {
  currentBalance: PropTypes.number,
  withdrawWeek: PropTypes.number,
  withdraws: PropTypes.number,
};
export default function WalletBalance({
  currentBalance,
  withdrawWeek,
  withdraws,
}) {
  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        p: 3,
      }}
    >
      <Typography variant="subtitle2" gutterBottom>
        Your Current Balance
      </Typography>

      <Stack spacing={2}>
        <Typography variant="h3">{fCurrency(currentBalance)}</Typography>
        <RowStyle>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Amount Withdrawn This Week
          </Typography>
          <Typography variant="body2" sx={{ color: "error.main" }}>
            - {fCurrency(withdrawWeek)}
          </Typography>
        </RowStyle>

        <RowStyle>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Total Withdrawals
          </Typography>
          <Typography variant="subtitle1" sx={{ color: "error.main" }}>
            - {fCurrency(withdraws)}
          </Typography>
        </RowStyle>

        <Stack direction="row" spacing={1.5}>
          <Button
            component={RouterLink}
            to={PATH_DASHBOARD.accounting.wallet}
            fullWidth
            variant="contained"
          >
            View Wallet Details
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
}
