import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { sentenceCase } from "change-case";
// material
import {
  Box,
  Grid,
  Card,
  Button,
  Typography,
  Stack,
  Tooltip,
  IconButton,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { fNumber } from "../../../utils/formatNumber";
import { fDateTime } from "../../../utils/formatTime";
import { ShowError } from "../../../utils/loader";
import {
  CheckPerm,
  CARD_ASSIGNED_STATUS,
  CheckSub,
  CopyToBoard,
} from "../../../utils/apis";
import Label from "../../Label";
// ----------------------------------------------------------------------

SingleCard.propTypes = {
  card: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
};
function SingleCard({ card, enqueueSnackbar }) {
  const { status, amount, lastUsed, barcode } = card;
  const stat = CARD_ASSIGNED_STATUS.filter((s) => s.value === status)[0];
  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        p: 3,
      }}
    >
      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {fNumber(amount)} store credit(s)
        </Typography>
        <Box
          sx={{ display: "flex", alignItems: "start", flexDirection: "column" }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="subtitle2">{barcode}</Typography>
            <Tooltip placement="top-start" title="Click to copy">
              <IconButton
                size="small"
                onClick={() => CopyToBoard(barcode, enqueueSnackbar)}
                edge="end"
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            Last Used: {lastUsed ? fDateTime(lastUsed) : ""}
          </Typography>
        </Box>
      </Box>
      <Label variant="ghost" color={stat.color}>
        {sentenceCase(stat.label)}
      </Label>
    </Card>
  );
}

LoyaltyCards.propTypes = {
  cards: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  addCard: PropTypes.func.isRequired,
};

export default function LoyaltyCards({ cards, type, addCard }) {
  const hasPermission = CheckPerm("view_gifts_and_loyalty_cards");
  const permission = CheckSub("15");
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box sx={{ mt: 5 }}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          Loyalty Cards
        </Typography>
        {hasPermission && permission === 1 && (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={addCard}
          >
            Add
          </Button>
        )}
      </Stack>
      {cards.length > 0 ? (
        <Grid container spacing={3}>
          {cards.map((card, index) => (
            <Grid key={index} item xs={12} md={4}>
              <SingleCard
                card={card}
                type={type}
                enqueueSnackbar={enqueueSnackbar}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <ShowError
          message={
            new Error(
              `This ${type} does not have any loyalty cards attached at the moment!`
            )
          }
        />
      )}
    </Box>
  );
}
