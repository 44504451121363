import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
import useAuth from '../hooks/useAuth';
import { ASSETS_PATH } from '../utils/apis';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool
};

export default function Logo({ disabledLink = false, sx }) {
  const { user } = useAuth();
  let logoLink = "/logo/logo.png";
  if (user) {
    const { shop } = user;
    logoLink = shop && shop.logo ? `${ASSETS_PATH.logos}${shop.logo}` : "/logo/logo.png";
  }
  const logo = <Box component="img" src={logoLink} sx={{ width: 'auto', height: 40, ...sx }} />;

  if (disabledLink) {
    // eslint-disable-next-line
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
