import PropTypes from "prop-types";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import VisibilityIcon from "@mui/icons-material/Visibility";
// material
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  MenuItem,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Stack,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import {
  MESSAGE_TYPES,
  RECIPIENT_TYPES,
  LOG_TYPES,
  METHOD_OPTIONS,
  DEVICE_TYPES,
  PRODUCT_STOCK_STATUS,
  ORDER_STATUS,
  DISCOUNT_CODE_STATUS,
  RATINGS_NUMBERS,
  FEATURED_PRODUCT_STATUS,
  PRODUCT_ECOMMERCE_STATUS,
  SPECIAL_OFFER_STATUS,
  EXPENSE_STATUS,
} from "../../../utils/apis";

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));
const SelectStyle = styled(TextField)(({ theme }) => ({
  width: 150,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

DeleteModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  loading: PropTypes.bool,
  selected: PropTypes.number,
  type: PropTypes.string,
  restoreProducts: PropTypes.number,
  handleRestoreProducts: PropTypes.func,
};
function DeleteModal({
  open,
  handleClose,
  handleDelete,
  loading,
  selected,
  type,
  restoreProducts,
  handleRestoreProducts,
}) {
  return (
    <Dialog maxWidth="xs" open={open} onClose={handleClose}>
      <DialogTitle sx={{ color: "error.main" }}>
        Delete {selected} {selected > 1 ? "items" : "item"}{" "}
        <IconButton
          sx={{ position: "absolute", right: 8, top: 8 }}
          onClick={handleClose}
          color="error"
        >
          <CancelIcon />
        </IconButton>{" "}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {selected}{" "}
          {selected > 1 ? "items" : "item"}? This action cannot be undone.
        </DialogContentText>
        {type === "sale" && (
          <FormControlLabel
            sx={{ pt: 2 }}
            control={
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                color="primary"
                checked={restoreProducts === 1}
                onChange={(event) => {
                  handleRestoreProducts(event.target.checked ? 1 : 0);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Restore product(s) back to stock?"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <LoadingButton
            fullWidth
            onClick={handleDelete}
            variant="contained"
            loading={loading}
            color="primary"
          >
            Yes, Proceed
          </LoadingButton>
          <Button fullWidth onClick={handleClose} color="inherit">
            No, Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

ListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  handleDelete: PropTypes.func,
  setStatus: PropTypes.func,
  status: PropTypes.string,
  setEcomStatus: PropTypes.func,
  ecomStatus: PropTypes.string,
  type: PropTypes.string,
  role: PropTypes.string,
  category: PropTypes.string,
  stock: PropTypes.string,
  setRole: PropTypes.func,
  setCategory: PropTypes.func,
  setStock: PropTypes.func,
  setJob: PropTypes.func,
  job: PropTypes.string,
  messageType: PropTypes.string,
  handleMessageTypeChange: PropTypes.func,
  messageRcp: PropTypes.string,
  handleMessageRcpChange: PropTypes.func,
  logType: PropTypes.string,
  handleLogTypeChange: PropTypes.func,
  onMark: PropTypes.func,
  onFilterChange: PropTypes.func,
  paymentType: PropTypes.string,
  handlePaymentTypeChange: PropTypes.func,
  cardType: PropTypes.string,
  handleCardTypeChange: PropTypes.func,
  deviceType: PropTypes.string,
  handleTypeChange: PropTypes.func,
  performance: PropTypes.string,
  performanceSubtitle: PropTypes.string,
  handlePeformanceChange: PropTypes.func,
  handleUpdateCategories: PropTypes.func,
  rating: PropTypes.string,
  setRating: PropTypes.func,
  handleShowChangeSellingPrices: PropTypes.func,
  handleCancel: PropTypes.func,
  handleShowTransfer: PropTypes.func,
};

const STATUS_OPTIONS = [
  {
    key: "branch",
    value: [
      { label: "All", value: "" },
      { label: "Active", value: "1" },
      { label: "Suspended", value: "0" },
    ],
  },
  {
    key: "admin",
    value: [
      { label: "All", value: "" },
      { label: "Active", value: "1" },
      { label: "Suspended", value: "0" },
    ],
  },
  {
    key: "role",
    value: [
      { label: "All", value: "" },
      { label: "Active", value: "1" },
      { label: "Suspended", value: "0" },
    ],
  },
  {
    key: "category",
    value: [
      { label: "All", value: "" },
      { label: "Shown", value: "1" },
      { label: "Hidden", value: "0" },
    ],
  },
  {
    key: "customer",
    value: [],
  },
  {
    key: "company",
    value: [],
  },
  {
    key: "product",
    value: [
      { label: "All", value: "" },
      { label: "Available", value: "1" },
      { label: "Suspended", value: "0" },
    ],
  },
  {
    key: "staff",
    value: [
      { label: "All", value: "" },
      { label: "Active", value: "1" },
      { label: "Suspended", value: "0" },
      { label: "Sacked", value: "3" },
      { label: "Resigned", value: "4" },
    ],
  },
  {
    key: "sale",
    value: [
      { label: "All", value: "" },
      { label: "Paid", value: "0" },
      { label: "Unpaid", value: "1" },
      { label: "Overdue", value: "2" },
      { label: "Draft", value: "4" },
      { label: "Pending Delivery", value: "5" },
    ],
  },
  {
    key: "invoice",
    value: [
      { label: "All", value: "" },
      { label: "Overdue", value: "2" },
      { label: "Unpaid", value: "1" },
      { label: "Paid", value: "0" },
    ],
  },
  {
    key: "returned",
    value: [
      { label: "All", value: "" },
      { label: "Fixed/Repaired", value: "2" },
      { label: "Refunded", value: "1" },
      { label: "Pending", value: "0" },
    ],
  },
  {
    key: "debtor",
    value: [
      { label: "All", value: "" },
      { label: "Paid", value: "2" },
      { label: "Part payment", value: "1" },
      { label: "Pending", value: "0" },
    ],
  },
  {
    key: "damaged",
    value: [
      { label: "All", value: "" },
      { label: "Fixed/Repaired", value: "2" },
      { label: "Refunded", value: "1" },
      { label: "Pending", value: "0" },
    ],
  },
  {
    key: "purchase",
    value: [
      { label: "All", value: "" },
      { label: "Paid", value: "2" },
      { label: "Part payment", value: "1" },
      { label: "Pending", value: "0" },
    ],
  },
  {
    key: "online",
    value: [
      { label: "All", value: "" },
      { label: "Sold", value: "1" },
      { label: "Pending", value: "0" },
    ],
  },
  {
    key: "quotation",
    value: [
      { label: "All", value: "" },
      { label: "Sent", value: "1" },
      { label: "Pending", value: "0" },
    ],
  },
  {
    key: "waybill",
    value: [
      { label: "All", value: "" },
      { label: "Sent", value: "1" },
      { label: "Pending", value: "0" },
    ],
  },
  {
    key: "withdrawal",
    value: [
      { label: "All", value: "" },
      { label: "Paid", value: "1" },
      { label: "Pending", value: "0" },
    ],
  },
  {
    key: "send",
    value: [
      { label: "All", value: "" },
      { label: "Sent", value: "1" },
      { label: "Pending", value: "0" },
    ],
  },
  {
    key: "notification",
    value: [
      { label: "All", value: "" },
      { label: "Unread", value: "0" },
      { label: "Read", value: "1" },
    ],
  },
  {
    key: "support",
    value: [
      { label: "All", value: "" },
      { label: "Pending", value: "0" },
      { label: "Replied", value: "1" },
    ],
  },
  {
    key: "assigned_cards",
    value: [
      { label: "All", value: "" },
      { label: "Pending / Unused", value: "0" },
      { label: "Active", value: "1" },
      { label: "Cancelled", value: "2" },
      { label: "Used", value: "3" },
    ],
  },
  {
    key: "requests_cards",
    value: [
      { label: "All", value: "" },
      { label: "Pending", value: "0" },
      { label: "Delivered", value: "1" },
      { label: "Refunded", value: "2" },
    ],
  },
  {
    key: "discrepancy",
    value: [
      { label: "All", value: "" },
      { label: "Pending", value: "0" },
      { label: "Resolved", value: "1" },
    ],
  },
  {
    key: "order",
    value: [{ label: "All", value: "" }, ...ORDER_STATUS],
  },
  {
    key: "discountCode",
    value: [{ label: "All", value: "" }, ...DISCOUNT_CODE_STATUS],
  },
  {
    key: "featuredProduct",
    value: [{ label: "All", value: "" }, ...FEATURED_PRODUCT_STATUS],
  },
  {
    key: "specialOffers",
    value: [{ label: "All", value: "" }, ...SPECIAL_OFFER_STATUS],
  },
  {
    key: "expense",
    value: [{ label: "All", value: "" }, ...EXPENSE_STATUS],
  },
];
const STATUS_EXEMPT = [
  "billing",
  "log",
  "import",
  "export",
  "payment_analysis",
  "devices",
  "products_performance",
  "product_log",
  "review",
];

export default function ListToolbar({
  numSelected,
  filterName,
  onFilterName,
  handleDelete,
  status,
  setStatus,
  ecomStatus,
  setEcomStatus,
  type,
  role,
  job,
  category,
  stock,
  rating,
  setRole,
  setCategory,
  setStock,
  setRating,
  setJob,
  messageType,
  handleMessageTypeChange,
  messageRcp,
  handleMessageRcpChange,
  logType,
  handleLogTypeChange,
  onMark,
  onFilterChange,
  paymentType,
  handlePaymentTypeChange,
  cardType,
  handleCardTypeChange,
  deviceType,
  handleTypeChange,
  performance,
  performanceSubtitle,
  handlePeformanceChange,
  handleCancel,
  handleShowChangeSellingPrices,
  handleUpdateCategories,
  handleShowTransfer,
}) {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const { getRoles, getCategories, getJobs } = useAuth();
  const [ROLES, setROLES] = useState([]);
  const [restoreProducts, setRestoreProducts] = useState(0);
  const [CATEGORIES, setCATEGORIES] = useState([]);
  const [JOBS, setJOBS] = useState([]);

  const [isSubmitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const onDelete = () => {
    setSubmitting(true);
    handleDelete(restoreProducts);
    setRestoreProducts(0);
    setSubmitting(false);
    handleClose();
  };

  useEffect(() => {
    async function get() {
      if (type === "admin") {
        setROLES(await getRoles());
      }
      if (type === "product") {
        setCATEGORIES(await getCategories());
      }
      if (type === "staff") {
        setJOBS(await getJobs());
      }
    }
    get();
    return () => {
      setROLES([]);
      setCATEGORIES([]);
      setJOBS([]);
      setSubmitting(false);
      setOpen(false);
    };
  }, [getRoles, getCategories, getJobs, type]);

  const handleStatusChange = (event) => {
    onFilterChange();
    setStatus(event.target.value);
  };
  const handleEcomStatusChange = (event) => {
    onFilterChange();
    setEcomStatus(event.target.value);
  };
  const handleRoleChange = (event) => {
    onFilterChange();
    setRole(event.target.value);
  };
  const handleCategoryChange = (event) => {
    onFilterChange();
    setCategory(event.target.value);
  };
  const handleStockChange = (event) => {
    onFilterChange();
    setStock(event.target.value);
  };
  const handleRatingChange = (event) => {
    onFilterChange();
    setRating(event.target.value);
  };
  const handleJobChange = (event) => {
    onFilterChange();
    setJob(event.target.value);
  };

  const STATUS = STATUS_EXEMPT.includes(type)
    ? []
    : STATUS_OPTIONS.filter((status) => status.key === type)[0].value;

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: isLight ? "primary.main" : "text.primary",
          bgcolor: isLight ? "primary.lighter" : "background.paper",
        }),
        flexDirection: { md: "row", xs: "column" },
        height: { md: 96, xs: "auto" },
        pl: { md: "24px", xs: "8px" },
        py: { md: 0, xs: "8px" },
      }}
    >
      {numSelected > 0 ? (
        <Typography
          component="div"
          variant="subtitle1"
          sx={{ width: { md: "auto", xs: 1 }, pb: { md: 0, xs: 1 } }}
        >
          {numSelected} selected
        </Typography>
      ) : (
        (filterName !== undefined && (
          <SearchStyle
            sx={{
              width: { md: 240, xs: "100%" },
              "&.Mui-focused": {
                width: { md: 320, xs: "100%" },
              },
            }}
            value={filterName}
            onChange={onFilterName}
            placeholder="Search..."
            startAdornment={
              <InputAdornment position="start">
                <Box
                  component={Icon}
                  icon={searchFill}
                  sx={{ color: "text.disabled" }}
                />
              </InputAdornment>
            }
          />
        )) ||
        (type === "products_performance" && (
          <Stack direction="column" sx={{ mr: 4 }}>
            <Typography variant="h6">
              {performance === "best" ? "Best Performing" : "Redundant"}{" "}
              Products
            </Typography>
            <Typography variant="subtitle2">{performanceSubtitle}</Typography>
          </Stack>
        ))
      )}
      {numSelected > 0 && type === "notification" && (
        <Tooltip title="Mark as read" onClick={onMark}>
          <IconButton>
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      )}
      {numSelected > 0 ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { md: "center", xs: "right" },
            width: { md: "auto", xs: 1 },
          }}
        >
          {type === "product" && (
            <Stack
              direction={{ md: "row", xs: "column" }}
              alignItems={{ md: "center", xs: "start" }}
              justifyContent="start"
              spacing={{ md: 2, xs: 0.5 }}
              sx={{ width: { md: "auto", xs: 1 } }}
              divider={<Divider flexItem orientation="vertical" />}
            >
              <Button
                onClick={handleShowTransfer}
                variant="contained"
                color="secondary"
                size="small"
              >
                Transfer
              </Button>
              <Button
                onClick={handleShowChangeSellingPrices}
                variant="contained"
                color="secondary"
                size="small"
              >
                Change Selling Prices
              </Button>
              <Button
                color="secondary"
                onClick={handleUpdateCategories}
                variant="contained"
                size="small"
              >
                Change Category
              </Button>
            </Stack>
          )}
          {type !== "assigned_cards" && type !== "specialOffers" && (
            <Tooltip
              title="Delete"
              onClick={() => {
                setOpen(true);
              }}
            >
              <IconButton color="error" sx={{ width: 50, height: 50 }}>
                <Icon icon={trash2Fill} />
              </IconButton>
            </Tooltip>
          )}
          {type === "specialOffers" && (
            <Button color="error" onClick={handleCancel}>
              Cancel Selected
            </Button>
          )}
        </Box>
      ) : (
        <Box sx={{ display: { md: "flex", xs: "none" } }}>
          {(type === "admin" ||
            type === "customer" ||
            type === "company" ||
            type === "product" ||
            type === "staff" ||
            type === "sale" ||
            type === "purchase" ||
            type === "online" ||
            type === "send" ||
            type === "log" ||
            type === "payment_analysis" ||
            type === "requests_cards" ||
            type === "assigned_cards" ||
            type === "assigned_cards" ||
            type === "devices" ||
            type === "review" ||
            type === "products_performance") &&
            numSelected === 0 && (
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                {type === "devices" && numSelected === 0 && (
                  <SelectStyle
                    select
                    label="Filter Type"
                    sx={{ mr: 2 }}
                    value={deviceType}
                    onChange={(event) => {
                      handleTypeChange(event.target.value);
                    }}
                  >
                    {[{ label: "All", value: "" }, ...DEVICE_TYPES].map(
                      (option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )
                    )}
                  </SelectStyle>
                )}
                {type === "log" && numSelected === 0 && (
                  <SelectStyle
                    select
                    label="Filter Type"
                    sx={{ mr: 2 }}
                    value={logType}
                    onChange={(event) => {
                      handleLogTypeChange(event.target.value);
                    }}
                  >
                    {[{ label: "All", value: "" }, ...LOG_TYPES].map(
                      (option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )
                    )}
                  </SelectStyle>
                )}
                {(type === "requests_cards" || type === "assigned_cards") &&
                  numSelected === 0 && (
                    <SelectStyle
                      select
                      label="Filter Type"
                      sx={{ mr: 2 }}
                      value={cardType}
                      onChange={(event) => {
                        handleCardTypeChange(event.target.value);
                      }}
                    >
                      {[
                        { label: "All", value: "" },
                        { label: "Gift Cards", value: "0" },
                        { label: "Loyalty Cards", value: "1" },
                      ].map((option) => (
                        <MenuItem key={option.label} value={option.value}>
                          {sentenceCase(option.label)}
                        </MenuItem>
                      ))}
                    </SelectStyle>
                  )}
                {type === "payment_analysis" && numSelected === 0 && (
                  <SelectStyle
                    select
                    label="Filter Type"
                    sx={{ mr: 2 }}
                    value={paymentType}
                    onChange={(event) => {
                      handlePaymentTypeChange(
                        event.target.value === "All" ? "" : event.target.value
                      );
                    }}
                  >
                    {["All", ...METHOD_OPTIONS].map((option) => (
                      <MenuItem key={option} value={option}>
                        {sentenceCase(option)}
                      </MenuItem>
                    ))}
                  </SelectStyle>
                )}
                {type === "send" && numSelected === 0 && (
                  <SelectStyle
                    select
                    label="Filter Type"
                    sx={{ mr: 2 }}
                    value={messageType}
                    onChange={(event) => {
                      handleMessageTypeChange(event.target.value);
                    }}
                  >
                    {[{ label: "All", value: "" }, ...MESSAGE_TYPES].map(
                      (option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )
                    )}
                  </SelectStyle>
                )}
                {type === "send" && numSelected === 0 && (
                  <SelectStyle
                    select
                    label="Filter Recipient"
                    sx={{ mr: 2 }}
                    value={messageRcp}
                    onChange={(event) => {
                      handleMessageRcpChange(event.target.value);
                    }}
                  >
                    {[{ label: "All", value: "" }, ...RECIPIENT_TYPES].map(
                      (option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )
                    )}
                  </SelectStyle>
                )}
                {type === "staff" && numSelected === 0 && (
                  <SelectStyle
                    select
                    label="Filter Job"
                    value={job}
                    onChange={handleJobChange}
                  >
                    {JOBS.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </SelectStyle>
                )}
                {type === "product" && numSelected === 0 && (
                  <SelectStyle
                    select
                    label="Filter Category"
                    value={category}
                    onChange={handleCategoryChange}
                  >
                    {CATEGORIES.map((option) => (
                      <MenuItem key={option.cid} value={option.cid}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </SelectStyle>
                )}
                {type === "review" && numSelected === 0 && (
                  <SelectStyle
                    sx={{ ml: 2 }}
                    select
                    label="Filter Rating"
                    value={rating}
                    onChange={handleRatingChange}
                  >
                    {[{ label: "All", value: "" }, ...RATINGS_NUMBERS].map(
                      (option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )
                    )}
                  </SelectStyle>
                )}
                {type === "product" && numSelected === 0 && (
                  <SelectStyle
                    sx={{ ml: 2 }}
                    select
                    label="Filter Stock"
                    value={stock}
                    onChange={handleStockChange}
                  >
                    {PRODUCT_STOCK_STATUS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </SelectStyle>
                )}
                {type === "admin" && numSelected === 0 && (
                  <SelectStyle
                    select
                    label="Filter Role"
                    value={role}
                    onChange={handleRoleChange}
                  >
                    {ROLES.map((option) => (
                      <MenuItem key={option.rid} value={option.rid}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </SelectStyle>
                )}
              </Box>
            )}
          {STATUS.length > 0 && (
            <SelectStyle
              sx={{ ml: 2 }}
              select
              label="Filter Status"
              value={status}
              onChange={handleStatusChange}
            >
              {STATUS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </SelectStyle>
          )}
          {type === "product" && (
            <SelectStyle
              sx={{ ml: 2 }}
              select
              label="Filter Ecommerce Status"
              value={ecomStatus}
              onChange={handleEcomStatusChange}
            >
              <MenuItem value="">All</MenuItem>
              {PRODUCT_ECOMMERCE_STATUS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </SelectStyle>
          )}
          {type === "products_performance" && (
            <SelectStyle
              sx={{ ml: 2, minWidth: 250 }}
              select
              label="Sort By"
              value={performance}
              onChange={handlePeformanceChange}
            >
              <MenuItem value="best">Best Performing</MenuItem>
              <MenuItem value="worst">Redundant</MenuItem>
            </SelectStyle>
          )}
        </Box>
      )}
      <DeleteModal
        open={open}
        handleClose={handleClose}
        handleDelete={onDelete}
        loading={isSubmitting}
        selected={numSelected}
        type={type}
        restoreProducts={restoreProducts}
        handleRestoreProducts={(value) => setRestoreProducts(value)}
      />
    </RootStyle>
  );
}
