import PropTypes from "prop-types";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Card,
  Alert,
  TextField,
  Stack,
  CardHeader,
  Box,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { CheckPerm, ONLINE_STORE_OPTIONS } from "../../../utils/apis";

// ----------------------------------------------------------------------
OnlineUpdate.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function OnlineUpdate({ myProfile, uid, fetch }) {
  const { updateOnline, getBranches } = useAuth();
  const hasPermission = CheckPerm("update_online_sales");
  const [BRANCHES, setBRANCHES] = useState([]);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const Schema = Yup.object().shape({});

  const formik = useFormik({
    initialValues: {
      store: myProfile.store,
      orderid: myProfile.orderid,
      date: myProfile.date,
      due: myProfile.due,
      branch: {},
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateOnline(values, uid);
        enqueueSnackbar("Online sale's data has been updated!", {
          variant: "success",
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        fetch();
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  useEffect(() => {
    async function get() {
      const branches = await getBranches();
      setBRANCHES(branches);
      const selectedBranch = branches.filter(
        (branch) => branch.bid === myProfile.branch
      );
      setFieldValue(
        "branch",
        selectedBranch.length > 0 ? selectedBranch[0] : {}
      );
    }
    get();
  }, [getBranches, myProfile.branch, setFieldValue]);

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        px: 3,
        pb: 3,
      }}
    >
      <CardHeader title="Online Sale Information" />
      <Box sx={{ p: 1 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  select
                  fullWidth
                  {...getFieldProps("store")}
                  disabled={!hasPermission}
                  name="store"
                  label="Store"
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{
                    native: false,
                    sx: { textTransform: "capitalize" },
                  }}
                >
                  {ONLINE_STORE_OPTIONS.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: "body2",
                        textTransform: "capitalize",
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  type="text"
                  fullWidth
                  label="Order ID / Number"
                  {...getFieldProps("orderid")}
                  disabled={!hasPermission}
                  error={Boolean(touched.orderid && errors.orderid)}
                  helperText={touched.orderid && errors.orderid}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                  fullWidth
                  label="Invoice Date"
                  {...getFieldProps("date")}
                  disabled={!hasPermission}
                  error={Boolean(touched.date && errors.date)}
                  helperText={touched.date && errors.date}
                />

                {myProfile.status === 1 && (
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={!hasPermission}
                    type="date"
                    fullWidth
                    label="Payment Date"
                    {...getFieldProps("due")}
                    error={Boolean(touched.due && errors.due)}
                    helperText={touched.due && errors.due}
                  />
                )}
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <Autocomplete
                  fullWidth
                  disabled={!hasPermission}
                  options={BRANCHES}
                  {...getFieldProps("branch")}
                  name="branch"
                  value={values.branch}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setFieldValue("branch", newValue);
                    }
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Branch"
                      margin="none"
                      error={Boolean(touched.branch && errors.branch)}
                      helperText={touched.branch && errors.branch}
                    />
                  )}
                />
              </Stack>
            </Stack>
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Update
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
