/* eslint-disable class-methods-use-this */
import onScan from "../utils/onscan";

export default class BarcodeScanner {
  initialize = (events) => {
    onScan.attachTo(document, {
      suffixKeyCodes: [13],
      reactToPaste: false,
      onScan(sCode) {
        events.emit("onbarcodescanned", sCode);
      },
    });
  };

  close = () => {
    onScan.detachFrom(document);
  };
}
