// material
import { Box } from '@mui/material';

export default function VerveIcon({ ...other }) {
  return (
    <Box {...other}>
      <svg
        height="13"
        viewBox="0 0 462 161"
        width="36"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd" transform="matrix(0 -1 1 0 .5 160.5)">
          <path
            d="m79.9417 159.8534c-43.9416 0-79.5631-35.6215-79.5631-79.5631 0-43.9436 35.6215-79.5661 79.5631-79.5661s79.5631 35.6225 79.5631 79.5661c0 43.9416-35.6215 79.5631-79.5631 79.5631"
            fill="#ed342b"
          />
          <path
            d="m45.8608 80.2892c40.3403-17.7967 78.0258-30.8457 78.0258-30.8457v-27.2823s-48.3695 16.6053-108.8585 47.45v21.3561c60.489 30.8447 108.8585 47.4489 108.8585 47.4489v-27.2823s-37.6855-13.0479-78.0258-30.8447"
            fill="#fefefe"
          />
          <path
            d="m84.0912 422.9398c0-15.4229-16.6083-16.6073-16.6083-16.6073v33.2116s16.6083-1.1845 16.6083-16.6043m-33.2136 36.7719v-53.3792s-17.7958 1.1844-17.7958 24.9114c0 11.8605 3.5624 23.723 3.5624 23.723l-18.9792 2.3739s-4.7478-11.8625-4.7478-28.4708c0-23.724 11.8635-45.0761 45.0771-45.0761 26.0969 0 42.7042 16.6083 42.7042 40.3323 0 35.5855-35.5865 37.9564-49.8209 35.5855m28.0285-181.5094 19.5859 3.6733s8.606-28.3618-7.3426-51.4141h-78.3437v24.4816h63.6545c7.3416 9.7925 2.4459 23.2592 2.4459 23.2592m5.1851-102.0465c0-15.4198-16.6083-16.6053-16.6083-16.6053v33.2126s16.6083-1.1844 16.6083-16.6073m-33.2136 36.772v-53.3773s-17.7958 1.1855-17.7958 24.9095c0 11.8595 3.5624 23.723 3.5624 23.723l-18.9792 2.3739s-4.7478-11.8635-4.7478-28.4708c0-23.724 11.8635-45.0771 45.0771-45.0771 26.0969 0 42.7042 16.6083 42.7042 40.3323 0 35.5865-35.5865 37.9574-49.8209 35.5865m-8.1295 125.0202c29.8371-12.4312 57.9361-18.6444 57.9361-18.6444l-.003-24.8574s-47.9868 12.4302-87.7493 33.5624v19.8788c39.7625 21.1332 87.7423 33.5634 87.7423 33.5634v-24.8585s-28.089-6.2131-57.9261-18.6443"
            fill="#03435f"
          />
        </g>
      </svg>
    </Box>
  );
}
