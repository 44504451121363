import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import mitt from "mitt";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Button,
  DialogContentText,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "../redux/store";
import { setBarcodeRes } from "../redux/slices/sale";
import useAuth from "../hooks/useAuth";
import BarcodeScanner from "./BarcodeScanner";
import { frontendPages, PATH_DASHBOARD } from "../routes/paths";

function ShowDetails({ open, handleClose, title, body }) {
  return (
    <Dialog maxWidth="xs" open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        <IconButton
          sx={{ position: "absolute", right: 8, top: 8 }}
          onClick={handleClose}
          color="error"
        >
          <CancelIcon />
        </IconButton>{" "}
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center", mb: 2 }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContentText>
          <Typography
            variant="body1"
            sx={{ whiteSpace: "pre-line", textAlign: "justify" }}
          >
            {body}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          onClick={handleClose}
          color="primary"
          variant="contained"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
ShowDetails.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.string,
};

const useBasePath = (location, params) =>
  Object.values(params).reduce(
    (path, param) => path.replace(`/${param}`, ""),
    location.pathname
  );

export default function BarcodeListener() {
  const events = mitt();
  const { isOnline } = useSelector((state) => state.offline);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const pathname = useBasePath(location, params);
  const { user, isAuthenticated, checkScan } = useAuth();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  useEffect(() => {
    const barcode = new BarcodeScanner();
    barcode.initialize(events);
    return () => {
      barcode.close();
    };
  }, [events]);

  useEffect(() => {
    const scanHandler = async (code) => {
      try {
        if (frontendPages.includes(pathname)) {
          return;
        }

        const barCode = code.replace("Enter", "").replace("TAB", "");
        const getReturn =
          !isOnline ||
          pathname === PATH_DASHBOARD.business.addQuote ||
          pathname === PATH_DASHBOARD.business.addWaybill ||
          pathname === PATH_DASHBOARD.business.addInvoice ||
          pathname === PATH_DASHBOARD.subpages.quotation ||
          pathname === PATH_DASHBOARD.subpages.waybill ||
          pathname === PATH_DASHBOARD.subpages.invoice ||
          pathname === PATH_DASHBOARD.business.addDebtor ||
          pathname === PATH_DASHBOARD.business.addPurchases ||
          pathname === PATH_DASHBOARD.business.addReturned ||
          pathname === PATH_DASHBOARD.business.addSale ||
          pathname === PATH_DASHBOARD.subpages.editSale ||
          pathname === PATH_DASHBOARD.business.addProduct ||
          pathname === PATH_DASHBOARD.administrative.adddiscrepancyReport ||
          pathname === PATH_DASHBOARD.subpages.editDiscrepancyReport;
        if (pathname === PATH_DASHBOARD.business.addProduct) {
          dispatch(setBarcodeRes(barCode));
        } else {
          const data = await checkScan(barCode, getReturn, isOnline);
          if (getReturn) {
            dispatch(setBarcodeRes(data));
          } else {
            // eslint-disable-next-line no-lonely-if
            if (data.body) {
              setTitle(data.title);
              setBody(data.body);
              setOpen(true);
            } else {
              navigate(data.goTo);
            }
          }
        }
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    };
    events.on("onbarcodescanned", scanHandler);
    return () => {
      events.off("onbarcodescanned", scanHandler);
    };
  }, [
    user,
    navigate,
    enqueueSnackbar,
    isAuthenticated,
    isOnline,
    checkScan,
    events,
    dispatch,
    pathname,
  ]);

  return (
    <ShowDetails
      open={open}
      handleClose={() => {
        setOpen(false);
      }}
      title={title}
      body={body}
    />
  );
}
