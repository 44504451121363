import PropTypes from 'prop-types';
// material
import { Paper, Typography } from '@mui/material';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchWord: PropTypes.string,
  isSorting: PropTypes.bool
};

export default function SearchNotFound({ searchWord = '', isSorting, ...other }) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1" sx={{ color: 'error.main' }}>
        {!isSorting ? `No ${searchWord} yet!` : 'Not found!'}
      </Typography>
      {!isSorting ? (
        <Typography variant="body2" align="center">
          Not to worry. They'll show up when they are available.
        </Typography>
      ) : (
        <Typography variant="body2" align="center">
          No results found for &nbsp;
          <strong>&quot;your search criteria&quot;</strong>. Try checking for typos or using complete words.
        </Typography>
      )
      }
    </Paper>
  );
}
