import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { API_PATH } from '../../utils/apis';
import { getCurrentBranch } from '../../utils/jwt';

const initialState = {
    isLoading: false,
    error: false,
    deleteError: false,
    search: '',
    page: 0,
    perPage: 10,
total: 0,
    logs: [],
    summary: {},
    sortBy: null,
    filters: {
        method: ''
    }
};

const slice = createSlice({
    name: 'log',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        removeDeleteError(state) {
            state.deleteError = false;
        },
        hasDeleteError(state, action) {
            state.isLoading = false;
            state.deleteError = action.payload;
        },
        getSuccess(state, action) {
            state.isLoading = false;
            state.logs = action.payload.data;
            state.total = parseInt(action.payload.total,10);
        },
        deletedSuccess(state, action) {
            const deleted = action.payload.split(",");
            state.logs = state.logs.filter((obj) => !deleted.includes(obj.id));
            state.isLoading = false;
        },
        //  SORT & FILTER POSTS
        sortBy(state, action) {
            state.sortBy = action.payload;
        },
        filter(state, action) {
            state.filters.method = action.payload.method;
        },
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {
    filter,
    sortBy
} = slice.actions;

export function getLogs(search, type, page, perpage, order, sortBy) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_PATH.log, { page, type, search, perpage, order, sortBy, branch : getCurrentBranch() });
            const { status, data, total } = response.data;
            if (status === "success") {
                dispatch(slice.actions.getSuccess({data, total}));
            } else {
                dispatch(slice.actions.hasError(new Error("We cannot fetch your logs at the moment! Please check back later.")));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteLog(remove) {
    return async (dispatch) => {
        dispatch(slice.actions.removeDeleteError());
        try {
            const response = await axios.post(API_PATH.log, { remove });
            if (response.data.status === "success") {
                dispatch(slice.actions.deletedSuccess(remove));
            } else if (response.data.status === "permission_denied") {
                dispatch(slice.actions.hasDeleteError(new Error("You do not have permission to perform this action!")));
            } else {
                dispatch(slice.actions.hasDeleteError(new Error("An error has occured! Please try again later.")));
            }
        } catch (error) {
            dispatch(slice.actions.hasDeleteError(error));
        }
    };
}
