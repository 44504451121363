import PropTypes from "prop-types";
import { useState } from "react";
import { useSnackbar } from "notistack";
// @mui
import {
  Box,
  Alert,
  Dialog,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";

// ----------------------------------------------------------------------

ScanCardBarcode.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  code: PropTypes.string,
  branch: PropTypes.string,
  customer: PropTypes.string,
  type: PropTypes.string,
};
export default function ScanCardBarcode({
  open,
  onClose,
  code,
  customer,
  branch,
  type,
}) {
  const { checkCard } = useAuth();
  const [card, setCard] = useState("");
  const [isSubmitting, setisSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState("");

  const handleSave = async () => {
    try {
      setErrors("");
      if (!customer && type === "24") {
        setErrors("Please select a valid customer to proceed!");
        return;
      }
      if (card && card.length === 14) {
        setisSubmitting(true);
        const res = await checkCard(customer, branch, card, type);
        setCard("");
        onClose(res);
        enqueueSnackbar(`Card Verified and applied!`, { variant: "success" });
      } else {
        setErrors("Invalid barcode detected! Please confirm and try again.");
      }
    } catch (err) {
      setErrors(err.message);
    }
    setisSubmitting(false);
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ py: 2.5, px: 3 }}
      >
        <Typography variant="h6"> Scan or Enter Card's Barcode</Typography>
      </Stack>
      {errors && (
        <Alert sx={{ mx: 5 }} severity="error">
          {errors}
        </Alert>
      )}
      <Box sx={{ p: 5 }}>
        <Stack spacing={{ xs: 2, md: 3 }}>
          <TextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            label="Card's Barcode"
            disabled={code !== undefined && code !== ""}
            onChange={(e) => setCard(e.target.value)}
            autoFocus
            name="card"
            value={card}
            helperText="Please scan the card to assign or enter the card's barcode number here."
          />
          <Box sx={{ mt: 3 }}>
            <LoadingButton
              onClick={handleSave}
              fullWidth
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Use
            </LoadingButton>
          </Box>
        </Stack>
      </Box>
    </Dialog>
  );
}
