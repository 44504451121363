import PropTypes from "prop-types";
// material
import { Box, Grid, Typography, Paper, Stack, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { fNumber, fCurrency } from "../../../utils/formatNumber";

// ----------------------------------------------------------------------
SalesSingle.propTypes = {
  sales: PropTypes.array,
  handleClick: PropTypes.func,
  onDelete: PropTypes.func,
};
export default function SalesSingle({ sales, handleClick, onDelete }) {
  return (
    <Grid container spacing={2.5} dir="ltr">
      {sales.map((sale, index) => (
        <Grid item xs={12} key={index} sx={{ p: 1, pt: 1, pl: 1 }}>
          <Paper
            sx={{
              width: 1,
              zIndex: 0,
              overflow: "hidden",
              position: "relative",
              bgcolor: "",
              boxShadow: (theme) => theme.customShadows.z12,
            }}
          >
            <Box sx={{ color: "primary.main", position: "relative" }}>
              <IconButton
                onClick={() => {
                  onDelete(index);
                }}
                color="error"
                sx={{
                  position: "absolute",
                  top: "1px",
                  right: "1px",
                  padding: "10px",
                }}
              >
                <DeleteIcon />
              </IconButton>
              <Box
                onClick={() => {
                  handleClick(index);
                }}
                sx={{
                  p: 2,
                  mt: 4,
                  cursor: "pointer",
                  display: "flex",
                  color: "text.disabled",
                  justifyContent: "center",
                }}
              >
                <Stack direction="column" spacing={1}>
                  <Typography variant="subtitle2">
                    Customer: <strong>{sale.invoiceFrom.name}</strong>
                  </Typography>
                  <Typography variant="subtitle2">
                    No. of Products:{" "}
                    <strong>
                      {fNumber(sale.products.length)}{" "}
                      {sale.products.length > 1 ? "Products" : "Product"}
                    </strong>
                  </Typography>
                  <Typography variant="subtitle2">
                    Amount Paid: <strong>{fCurrency(sale.amount)}</strong>
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
