import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
// @mui
import { Stack, Typography, Box, CircularProgress } from "@mui/material";
// utils
import { fShortenNumber, fCurrency } from "../../../utils/formatNumber";

// ----------------------------------------------------------------------

InvoiceAnalytic.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  percent: PropTypes.number,
  price: PropTypes.number,
  total: PropTypes.number,
  name: PropTypes.string,
  other: PropTypes.string,
};

export default function InvoiceAnalytic({
  title,
  total,
  icon,
  color,
  percent,
  price,
  name,
  other,
}) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="start"
      sx={{ width: 1, minWidth: 200, px: 2 }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ position: "relative" }}
      >
        <Box
          component={Icon}
          icon={icon}
          sx={{ color, width: 24, height: 24, position: "absolute" }}
        />
        <CircularProgress
          variant="determinate"
          value={percent}
          size={56}
          thickness={4}
          sx={{ color, opacity: 0.48 }}
        />

        <CircularProgress
          variant="determinate"
          value={100}
          size={56}
          thickness={4}
          sx={{
            color: "grey.50016",
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0.48,
          }}
        />
      </Stack>

      <Stack spacing={0.5} sx={{ ml: 2 }}>
        <Typography variant="h6">{title}</Typography>

        <Typography variant="subtitle2">
          {fShortenNumber(total)}{" "}
          <Box
            component="span"
            sx={{ color: "text.secondary", typography: "body2" }}
          >
            {name}
          </Box>
        </Typography>

        {price !== undefined && (
          <Typography variant="subtitle2" sx={{ color }}>
            {fCurrency(price)}
          </Typography>
        )}
        {other && (
          <Typography variant="subtitle2" sx={{ color }}>
            {other}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
