/* eslint-disable react/jsx-pascal-case */
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { paramCase } from "change-case";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import JsBarcode from "jsbarcode";
import {
  Link,
  Card,
  Typography,
  CardHeader,
  Stack,
  DialogActions,
  Dialog,
  DialogContent,
  TextField,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Button,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import BadgeIcon from "@mui/icons-material/Badge";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CategoryIcon from "@mui/icons-material/Category";
import NumbersIcon from "@mui/icons-material/Numbers";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import MoneyIcon from "@mui/icons-material/Money";
import Label from "../../Label";
import {
  fCurrency,
  fData,
  fNumber,
  isNumeric,
} from "../../../utils/formatNumber";
import {
  PRODUCT_STATUS,
  CheckPerm,
  PRODUCT_ECOMMERCE_STATUS,
  ASSETS_PATH,
} from "../../../utils/apis";
import { fDate } from "../../../utils/formatTime";
import useAuth from "../../../hooks/useAuth";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { UploadAvatar } from "../../upload";
import Image from "../../Image";

ProductAbout.propTypes = {
  profile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};

ChangeStatus.propTypes = {
  handleStatusUpdate: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  handleStatus: PropTypes.func,
  onClose: PropTypes.func,
  status: PropTypes.string,
};
function ChangeStatus({
  open,
  loading,
  handleStatusUpdate,
  handleStatus,
  status,
  onClose,
}) {
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      onClose={onClose}
      sx={{ textAlign: "center" }}
    >
      <DialogTitle>Change Product's Status</DialogTitle>
      <DialogContent sx={{ width: { xs: "100%", md: 400 } }}>
        <DialogContentText sx={{ mb: 5 }}>
          Please set the new status below
        </DialogContentText>
        <TextField
          select
          sx={{ width: { xs: "100%", md: 300 } }}
          label="New Status"
          value={status}
          onChange={handleStatus}
        >
          {PRODUCT_STATUS.map((stat) => (
            <MenuItem key={stat.value} value={stat.value}>
              {stat.label}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <LoadingButton
            fullWidth
            loading={loading}
            variant="contained"
            onClick={handleStatusUpdate}
          >
            Update
          </LoadingButton>
          <Button fullWidth onClick={onClose} color="inherit">
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

ChangeEcomStatus.propTypes = {
  handleStatusUpdate: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  handleStatus: PropTypes.func,
  onClose: PropTypes.func,
  status: PropTypes.string,
};
function ChangeEcomStatus({
  open,
  loading,
  handleStatusUpdate,
  handleStatus,
  status,
  onClose,
}) {
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      onClose={onClose}
      sx={{ textAlign: "center" }}
    >
      <DialogTitle>Change Product's Ecommerce Status</DialogTitle>
      <DialogContent sx={{ width: { xs: "100%", md: 400 } }}>
        <DialogContentText sx={{ mb: 5 }}>
          Please set the new status below
        </DialogContentText>
        <TextField
          select
          sx={{ width: { xs: "100%", md: 300 } }}
          label="New Status"
          value={status}
          onChange={handleStatus}
        >
          {PRODUCT_ECOMMERCE_STATUS.map((stat) => (
            <MenuItem key={stat.value} value={stat.value}>
              {stat.label}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <LoadingButton
            fullWidth
            loading={loading}
            variant="contained"
            onClick={handleStatusUpdate}
          >
            Update
          </LoadingButton>
          <Button fullWidth onClick={onClose} color="inherit">
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
export default function ProductAbout({ profile, uid, fetch }) {
  const hasPermission = CheckPerm("update_products");
  const {
    branch,
    branchName,
    category,
    categoryName,
    companyName,
    costPrice,
    dateAdded,
    name,
    number,
    quantity,
    sellingPrice,
    dateUpdated,
    status,
    lowStock,
    isEcommerce,
    sales,
    profitMargin,
    profitMarginPercent,
    featuredPhoto,
  } = profile;
  const isService = parseInt(profile.isService || 0, 10) === 1;
  const statusText = PRODUCT_STATUS[parseInt(status, 10)];
  const ecomStatusText = PRODUCT_ECOMMERCE_STATUS[parseInt(isEcommerce, 10)];
  const {
    updateProductStatus,
    updateProductEcommerceStatus,
    user,
    uploadProductPhotos,
    addProductPhotos,
  } = useAuth();
  const isEcommerceActivated = parseInt(
    (user && user.ecommerce && user.ecommerce.status) || 0,
    10
  );
  const [open, setOpen] = useState(false);
  const [ecomOpen, setEcomOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newStatus, setNewStatus] = useState(String(status));
  const [newEcomStatus, setNewEcomStatus] = useState(String(isEcommerce));
  const [Download, setDownload] = useState(null);
  const [Template, setTemplate] = useState(null);
  const [photoURL, setPhotoURL] = useState("");
  const [barcodeSettings, setBarcodeSettings] = useState({
    showName: false,
    showPrice: false,
  });
  const { enqueueSnackbar } = useSnackbar();
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, `${user.shop.sid}07${uid}`, {
    displayValue: false,
  });
  const barcode = canvas.toDataURL();

  const handleStatus = (event) => {
    setNewStatus(event.target.value);
  };
  const handleEcomStatus = (event) => {
    setNewEcomStatus(event.target.value);
  };
  const handleStatusUpdate = async () => {
    if (newStatus) {
      try {
        setLoading(true);
        await updateProductStatus(newStatus, uid);
        setOpen(false);
        setNewStatus("");
        enqueueSnackbar("Product's status has been updated!", {
          variant: "success",
        });
        fetch();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
      setLoading(false);
    } else {
      enqueueSnackbar(`Please select a new status to proceed!`, {
        variant: "error",
      });
    }
  };
  const handleEcomStatusUpdate = async () => {
    if (newEcomStatus) {
      try {
        setLoading(true);
        await updateProductEcommerceStatus(newEcomStatus, uid);
        setOpen(false);
        setNewStatus("");
        enqueueSnackbar("Product's ecommerce status has been updated!", {
          variant: "success",
        });
        fetch();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
      setLoading(false);
    } else {
      enqueueSnackbar(`Please select a new status to proceed!`, {
        variant: "error",
      });
    }
  };
  useEffect(() => {
    const getImports = async () => {
      const pdf = await import("@react-pdf/renderer");
      setDownload(pdf);
      setTemplate(await import("../sale/BarcodePrint"));
    };
    getImports();
    return () => {
      setDownload(null);
      setTemplate(null);
    };
  }, []);
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const upload = async (file) => {
        try {
          // upload to server
          enqueueSnackbar(`Uploading photo...`, {
            variant: "info",
          });
          const formData = new FormData();
          formData.append("product_photos[]", file);
          const res = await uploadProductPhotos(formData);
          if (res && res.length > 0) {
            const photos = res.map((file) => ({
              name: file,
              preview: `${ASSETS_PATH.products}${file}`,
              size: 0,
              isPrimary: true,
            }));
            await addProductPhotos(photos, uid);
            fetch();
          }
          enqueueSnackbar(`Product photo has been uploaded!`, {
            variant: "success",
          });
        } catch (err) {
          enqueueSnackbar(err.message, { variant: "error" });
        }
        setPhotoURL("");
      };
      const file = acceptedFiles[0];
      if (file) {
        setPhotoURL({
          ...file,
          preview: URL.createObjectURL(file),
        });
        upload(file);
      }
    },
    [enqueueSnackbar, addProductPhotos, fetch, uid, uploadProductPhotos]
  );
  return (
    <Stack direction="column" spacing={2}>
      <Card
        variant="outlined"
        sx={{
          border: "0.4px solid",
          borderColor: "background.border",
          boxShadow:
            "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
          borderRadius: "8px",
          textAlign: "left",
          pb: 4,
        }}
      >
        <CardHeader
          title={`${isService ? "Service" : "Product"}'s Photo`}
          sx={{ pb: 4 }}
        />
        <UploadAvatar
          accept={{
            "image/jpeg": [".jpeg", ".png"],
          }}
          file={
            photoURL ||
            (featuredPhoto ? `${ASSETS_PATH.products}${featuredPhoto}` : "")
          }
          maxSize={1000000}
          onDrop={handleDrop}
          caption={
            <Typography
              variant="caption"
              sx={{
                mt: 2,
                mx: "auto",
                display: "block",
                textAlign: "center",
                color: "text.secondary",
              }}
            >
              Allowed photo extensions are: *.jpeg, *.jpg, *.png
              <br /> Maximum photo size allowed: {fData(1000000)}
            </Typography>
          }
        />
      </Card>
      <Card
        variant="outlined"
        sx={{
          border: "0.4px solid",
          borderColor: "background.border",
          boxShadow:
            "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
          borderRadius: "8px",
        }}
      >
        <CardHeader title="Details" />
        <Stack spacing={2} sx={{ p: 3 }}>
          <Stack direction="row">
            <BadgeIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              <Label color={statusText.color}>{statusText.label}</Label>
              {hasPermission && (
                <span>
                  &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                  <Link
                    variant="subtitle2"
                    color="error.main"
                    component="button"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Change
                  </Link>
                </span>
              )}
            </Typography>
          </Stack>

          <Stack direction="row">
            <ShoppingBasketIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              <Label color={ecomStatusText.color}>{ecomStatusText.label}</Label>
              {hasPermission && (
                <span>
                  &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                  <Link
                    variant="subtitle2"
                    color={
                      (isEcommerceActivated && "error.main") || "text.disabled"
                    }
                    underline={(isEcommerceActivated && "hover") || "none"}
                    component="button"
                    disabled={!isEcommerceActivated}
                    onClick={() => {
                      setEcomOpen(true);
                    }}
                  >
                    Change
                  </Link>
                </span>
              )}
              {!isEcommerceActivated && (
                <Typography component="span" variant="caption">
                  <br />
                  To use this feature, please allow it under your{" "}
                  <Link
                    color="info.main"
                    component={RouterLink}
                    to={PATH_DASHBOARD.general.ecommerce}
                  >
                    settings
                  </Link>{" "}
                  page.
                </Typography>
              )}
            </Typography>
          </Stack>
          {number !== "" && (
            <Stack direction="row">
              <NumbersIcon
                sx={{
                  width: 20,
                  height: 20,
                  marginTop: "2px",
                  flexShrink: 0,
                  marginRight: 2,
                }}
              />
              <Typography variant="body2">
                {isService ? "Service ID /" : "Product"} Number:&nbsp;&nbsp;{" "}
                <strong>{number}</strong>
              </Typography>
            </Stack>
          )}
          <Stack direction="row">
            <CategoryIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              Category:&nbsp;&nbsp;
              <Link
                variant="subtitle2"
                color="text.primary"
                component={RouterLink}
                to={`${PATH_DASHBOARD.business.sale}/${category}/${paramCase(
                  categoryName
                )}`}
              >
                {categoryName}
              </Link>
            </Typography>
          </Stack>
          {companyName !== "" && (
            <Stack direction="row">
              <BusinessCenterIcon
                sx={{
                  width: 20,
                  height: 20,
                  marginTop: "2px",
                  flexShrink: 0,
                  marginRight: 2,
                }}
              />
              <Typography variant="body2">
                Purchased From:&nbsp;&nbsp; {companyName}
              </Typography>
            </Stack>
          )}
          {branchName !== "" && (
            <Stack direction="row">
              <BusinessCenterIcon
                sx={{
                  width: 20,
                  height: 20,
                  marginTop: "2px",
                  flexShrink: 0,
                  marginRight: 2,
                }}
              />
              <Typography variant="body2">
                Branch:&nbsp;&nbsp;{" "}
                {branch ? (
                  <Link
                    variant="subtitle2"
                    color="text.primary"
                    component={RouterLink}
                    to={`${
                      PATH_DASHBOARD.subpages.branch
                    }/${branch}/${paramCase(branchName)}`}
                  >
                    {branchName}
                  </Link>
                ) : (
                  branchName
                )}
              </Typography>
            </Stack>
          )}
          {!isService && (
            <Stack direction="row">
              <ProductionQuantityLimitsIcon
                sx={{
                  width: 20,
                  height: 20,
                  marginTop: "2px",
                  flexShrink: 0,
                  marginRight: 2,
                }}
              />
              <Typography variant="body2">
                Quantities Left:&nbsp;&nbsp;{" "}
                <strong>{isNumeric(quantity) ? fNumber(quantity) : "0"}</strong>
              </Typography>
            </Stack>
          )}
          {!isService && (
            <Stack direction="row">
              <ProductionQuantityLimitsIcon
                sx={{
                  width: 20,
                  height: 20,
                  marginTop: "2px",
                  flexShrink: 0,
                  marginRight: 2,
                }}
              />
              <Typography variant="body2">
                Low Stock Limit:&nbsp;&nbsp;{" "}
                <strong>{isNumeric(lowStock) ? fNumber(lowStock) : "0"}</strong>
              </Typography>
            </Stack>
          )}

          <Stack direction="row">
            <LocalOfferIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              {isService ? "Service Cost" : "Selling Price"}:&nbsp;&nbsp;{" "}
              <strong>
                {isNumeric(sellingPrice) ? fCurrency(sellingPrice) : "0"}
              </strong>
            </Typography>
          </Stack>
          {hasPermission && !isService && (
            <Stack direction="row">
              <MoneyIcon
                sx={{
                  width: 20,
                  height: 20,
                  marginTop: "2px",
                  flexShrink: 0,
                  marginRight: 2,
                }}
              />
              <Typography variant="body2">
                Cost Price:&nbsp;&nbsp;{" "}
                <strong>
                  {isNumeric(costPrice) ? fCurrency(costPrice) : "0"}
                </strong>
              </Typography>
            </Stack>
          )}
          {hasPermission && (
            <Stack direction="row">
              <MoneyIcon
                sx={{
                  width: 20,
                  height: 20,
                  marginTop: "2px",
                  flexShrink: 0,
                  marginRight: 2,
                }}
              />
              <Typography variant="body2">
                Profit Margin:&nbsp;&nbsp;{" "}
                <strong>
                  {fCurrency(parseFloat(profitMargin))} (
                  {parseFloat(profitMarginPercent).toFixed(2)}
                  %)
                </strong>
              </Typography>
            </Stack>
          )}

          <Stack direction="row">
            <MoneyIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              Total Sales:&nbsp;&nbsp;{" "}
              <strong>{isNumeric(sales) ? fCurrency(sales) : "0"}</strong>
            </Typography>
          </Stack>
          <Stack direction="row">
            <CalendarTodayIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              Added on{" "}
              {dateAdded !== "0000-00-00" ? fDate(dateAdded) : "Not Specified"}
            </Typography>
          </Stack>
          <Stack direction="row">
            <CalendarTodayIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              Last updated on{" "}
              {dateUpdated !== "0000-00-00"
                ? fDate(dateUpdated)
                : "Not Specified"}
            </Typography>
          </Stack>
          {!isService && <Divider flexItem sx={{ py: 1.7 }} />}
          {!isService && (
            <Stack
              direction="column"
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="overline" color="text.primary">
                Barcode
              </Typography>
              <Image src={barcode} style={{ height: 20 }} />
              <Stack
                direction="column"
                spacing={0}
                alignItems="start"
                justifyContent="start"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                      color="primary"
                      checked={barcodeSettings.showName}
                      onChange={(event) => {
                        setBarcodeSettings((prev) => ({
                          ...prev,
                          showName: event.target.checked,
                        }));
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Show product's name in barcode"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                      color="primary"
                      checked={barcodeSettings.showPrice}
                      onChange={(event) => {
                        setBarcodeSettings((prev) => ({
                          ...prev,
                          showPrice: event.target.checked,
                        }));
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Show product's selling price in barcode"
                  sx={{ mt: "0px!important" }}
                />
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="center"
              >
                {Boolean(Download) && Boolean(Template) ? (
                  <Download.PDFDownloadLink
                    document={
                      <Template.default
                        barcode={`${user.shop.sid}07${uid}`}
                        name={barcodeSettings.showName ? profile.name : ""}
                        price={
                          barcodeSettings.showPrice
                            ? fCurrency(profile.sellingPrice, true)
                            : ""
                        }
                      />
                    }
                    fileName={`${name}-barcode`}
                    style={{ textDecoration: "none", display: "inherit" }}
                  >
                    {({ loading }) => (
                      <LoadingButton
                        loading={loading}
                        variant="link"
                        color="secondary"
                        sx={{
                          fontSize: { xs: 0, md: "0.8125rem" },
                          py: { md: 0.5, xs: 1 },
                        }}
                      >
                        Download
                      </LoadingButton>
                    )}
                  </Download.PDFDownloadLink>
                ) : (
                  <LoadingButton
                    loading
                    variant="link"
                    color="secondary"
                    sx={{
                      fontSize: { xs: 0, md: "0.8125rem" },
                      py: { md: 0.5, xs: 1 },
                    }}
                  >
                    Download
                  </LoadingButton>
                )}
                {Boolean(Download) && Boolean(Template) ? (
                  <Download.BlobProvider
                    document={
                      <Template.default
                        barcode={`${user.shop.sid}07${uid}`}
                        name={barcodeSettings.showName ? profile.name : ""}
                        price={
                          barcodeSettings.showPrice
                            ? fCurrency(profile.sellingPrice, true)
                            : ""
                        }
                      />
                    }
                  >
                    {({ url, loading }) => (
                      <LoadingButton
                        loading={loading}
                        variant="link"
                        color="primary"
                        onClick={() => {
                          const iframe = document.createElement("iframe");
                          document.body.appendChild(iframe);
                          iframe.style.display = "none";
                          iframe.src = url;
                          iframe.onload = () => {
                            setTimeout(() => {
                              iframe.focus();
                              iframe.contentWindow.print();
                            }, 1);
                          };
                        }}
                        sx={{
                          fontSize: { xs: 0, md: "0.8125rem" },
                          py: { md: 0.5, xs: 1 },
                        }}
                      >
                        Print
                      </LoadingButton>
                    )}
                  </Download.BlobProvider>
                ) : (
                  <LoadingButton
                    loading
                    variant="link"
                    color="primary"
                    sx={{
                      fontSize: { xs: 0, md: "0.8125rem" },
                      py: { md: 0.5, xs: 1 },
                    }}
                  >
                    Print
                  </LoadingButton>
                )}
              </Stack>
            </Stack>
          )}
        </Stack>
        <ChangeStatus
          open={open}
          loading={loading}
          handleStatus={handleStatus}
          status={newStatus}
          handleStatusUpdate={handleStatusUpdate}
          onClose={() => {
            setOpen(false);
          }}
        />
        <ChangeEcomStatus
          open={ecomOpen}
          loading={loading}
          handleStatus={handleEcomStatus}
          status={newEcomStatus}
          handleStatusUpdate={handleEcomStatusUpdate}
          onClose={() => {
            setEcomOpen(false);
          }}
        />
      </Card>
    </Stack>
  );
}
