import PropTypes from "prop-types";
import * as Yup from "yup";
import { useEffect, useState, useCallback } from "react";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Card,
  Alert,
  TextField,
  Stack,
  CardHeader,
  Box,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { CheckPerm } from "../../../utils/apis";

// ----------------------------------------------------------------------
DamagedUpdate.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function DamagedUpdate({ myProfile, uid, fetch }) {
  const { updateDamaged, getBranches, getProducts } = useAuth();
  const hasPermission = CheckPerm("update_damaged_products");
  const [BRANCHES, setBRANCHES] = useState([]);
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const Schema = Yup.object().shape({
    product: Yup.object().required("Product is required"),
    quantity: Yup.number()
      .required("Quantity is required!")
      .min(1, "Quantity must be more than 0."),
    branch: Yup.object().required("Branch is required!"),
  });

  const formik = useFormik({
    initialValues: {
      product: { pid: myProfile.product, name: myProfile.productName },
      quantity: myProfile.quantity,
      company: myProfile.companyName,
      reason: myProfile.reason,
      remove_stock: 0,
      branch: {},
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateDamaged(values, uid);
        enqueueSnackbar("Damaged product's data has been updated!", {
          variant: "success",
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        fetch();
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  const filter = useCallback(() => {
    (async () => {
      setLoading(true);
      const data = await getProducts(search, values.branch.bid || "");
      setProducts(data);
      setLoading(false);
    })();
  }, [search, getProducts, values.branch]);
  useEffect(() => {
    filter();
  }, [filter]);

  useEffect(() => {
    async function get() {
      const branches = await getBranches();
      setBRANCHES(branches);
      const selectedBranch = branches.filter(
        (branch) => branch.bid === myProfile.branch
      );
      setFieldValue(
        "branch",
        selectedBranch.length > 0 ? selectedBranch[0] : {}
      );
    }
    get();
  }, [getBranches, myProfile.branch, setFieldValue]);

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        px: 3,
        pb: 3,
      }}
    >
      <CardHeader title="Damaged Product Information" />
      <Box sx={{ p: 1 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}
              <Stack direction="column" spacing={2}>
                <Autocomplete
                  fullWidth
                  disabled={!hasPermission}
                  options={BRANCHES}
                  {...getFieldProps("branch")}
                  name="branch"
                  value={values.branch}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setFieldValue("branch", newValue);
                    }
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Branch"
                      margin="none"
                      error={Boolean(touched.branch && errors.branch)}
                      helperText={touched.branch && errors.branch}
                    />
                  )}
                />
                <Autocomplete
                  fullWidth
                  disabled={!hasPermission}
                  options={products}
                  {...getFieldProps("product")}
                  name="product"
                  value={values.product}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setFieldValue("product", newValue);
                      setFieldValue("company", newValue.company);
                    }
                  }}
                  loading={loading}
                  isOptionEqualToValue={(option, value) =>
                    option.pid === value.pid
                  }
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Product"
                      margin="none"
                      onChange={(ev) => {
                        // dont fire API if the user delete or not entered anything
                        if (
                          ev.target.value !== "" ||
                          ev.target.value !== null
                        ) {
                          setSearch(ev.target.value);
                        }
                      }}
                      error={Boolean(touched.product && errors.product)}
                      helperText={touched.product && errors.product}
                    />
                  )}
                />
                <TextField
                  type="number"
                  fullWidth
                  label="Quantity"
                  {...getFieldProps("quantity")}
                  disabled={!hasPermission}
                  error={Boolean(touched.quantity && errors.quantity)}
                  helperText={touched.quantity && errors.quantity}
                />
                {parseInt(values.quantity, 10) !==
                  parseInt(myProfile.quantity, 10) && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                        color="primary"
                        disabled={!hasPermission}
                        checked={values.remove_stock === 1}
                        onChange={(event) => {
                          const removeStock = event.target.checked ? 1 : 0;
                          setFieldValue("remove_stock", removeStock);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={
                      parseInt(values.quantity, 10) >
                      parseInt(myProfile.quantity, 10)
                        ? `Remove ${
                            parseInt(values.quantity, 10) -
                            parseInt(myProfile.quantity, 10)
                          } product(s) from stock?`
                        : `Add ${
                            parseInt(myProfile.quantity, 10) -
                            parseInt(values.quantity, 10)
                          } product(s) to stock?`
                    }
                  />
                )}
                <TextField
                  disabled={!hasPermission}
                  fullWidth
                  label="Company"
                  {...getFieldProps("company")}
                  error={Boolean(touched.company && errors.company)}
                  helperText={touched.company && errors.company}
                />
                <TextField
                  multiline
                  disabled={!hasPermission}
                  maxRows={4}
                  fullWidth
                  label="Reason"
                  {...getFieldProps("reason")}
                  error={Boolean(touched.reason && errors.reason)}
                  helperText={touched.reason && errors.reason}
                />
              </Stack>
            </Stack>
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Update
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
